import { GlobalUtils } from 'src/app/shared/utils/global-utils';
import { Injectable } from '@angular/core';
import { Storage } from "aws-amplify";
import { UtilsService } from "../utils/utils.service";
import { AWSResourceData, GenericPromiseResponse, ContentType, MediaUploadItem } from 'src/app/models/AppGenericClasses';
import { Globals } from 'src/app/globals';
import { FileUtils } from 'src/app/shared/utils/file-utils';

@Injectable({
  providedIn: 'root'
})
export class AWSS3Service {
  static ACCEPTED_FILE_EXTENSIONS = Globals.ACCEPTED_FILE_EXTENSIONS

  constructor(
    private utils: UtilsService
  ) {
  }

  static uploadFile(item: MediaUploadItem): Promise<any> {
    let contentType = '';
    let ext = '';
    if (item.file && item.file) {
      if (FileUtils.validateExtension(item.file.name, this.ACCEPTED_FILE_EXTENSIONS['application/pdf'])) {
        contentType = 'application/pdf';
        ext = '.pdf';
      } else if (FileUtils.validateExtension(item.file.name, this.ACCEPTED_FILE_EXTENSIONS['application/vnd.ms-excel'])) {
        contentType = 'application/vnd.ms-excel';
        ext = `.${item.file.name.split(',').reverse()[0]}`;
      } else if (FileUtils.validateExtension(item.file.name, this.ACCEPTED_FILE_EXTENSIONS['.zip'])) {
        contentType = 'application/zip';
        ext = '.zip';
      } else if (FileUtils.validateExtension(item.file.name, this.ACCEPTED_FILE_EXTENSIONS['video/*'])) {
        contentType = 'video/mp4';
        ext = '.mp4';
      } else if (FileUtils.validateExtension(item.file.name, this.ACCEPTED_FILE_EXTENSIONS['audio/*'])) {
        contentType = 'audio/mpeg';
        ext = '.mp3';
      } else {
        contentType = 'image/jpeg';
        ext = '.png';
      }

      return Storage.put(
        `${item.data.table}/${item.data.id}/${item.data.field}/resource${ext}`,
        item.file,
        {
          level: 'public',
          contentType
        })
    }
  }

  static getResourceURL(resourceData: AWSResourceData): Promise<GenericPromiseResponse> {
    return new Promise((resolve) => {
      const d = resourceData;
      Storage.get(`${d.path}/${d.id}/${d.field}/resource.${d.ext}`, {
        level: 'public',
        cacheControl: 'no-cache',
        validateObjectExistence: true,
      })
        .then((result) => {
          resolve({ status: 1, data: result })
        })
        .catch((err) => {
          resolve({ status: 2, data: err })
        })
        .finally(() => {
          resolve({ status: 3, data: null })
        });
    })
  }

  seeDialogResource(type: ContentType, resourceData: AWSResourceData) {
    const contentTypeDialogConfig = this.utils.global.contentTypeDialogConfig[type]

    if (contentTypeDialogConfig) {
      const {
        dialogComponent, mediaPlayerConfig, dialogConfig, rawData
      } = contentTypeDialogConfig

      if (dialogComponent) {
        this.utils.dialog.open(dialogComponent, {
          data: {
            ...resourceData,
            ...GlobalUtils.getMediaPlayerConfig(mediaPlayerConfig),
            ...rawData
          },
          ...GlobalUtils.getPrimeDialogConfig(dialogConfig),
        })
      }
    }
  }
}
