<div class="flex flex-column gap-2" style="border-radius: 5px; padding: 50px">
  <ng-container *ngFor="let item of menuItems">
    <div
      class="box box-elevation cursor-pointer p-3 hover:surface-200"
      [routerLink]="item.routerLink"
    >
      <div class="flex gap-2 align-items-center">
        <div class="flex">
          <span class="material-symbols-outlined">arrow_forward</span>
        </div>
        <div class="flex-grow-1">
          <p class="card-title text-lg">{{ item.label | translate }}</p>
        </div>
      </div>
      <p class="text-secondary text-sm">{{ item.description_ | translate }}</p>
    </div>
  </ng-container>
</div>
