/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';

@Component({
  selector: 'app-test-question-detail',
  templateUrl: './test-question-detail.component.html',
  styleUrls: ['./test-question-detail.component.sass']
})
export class TestQuestionDetailComponent extends BaseItemDirective {
  override getItem() {
    this.itemService = this.data.core.testQuestionUnique(this.itemId)
    super.getItem()
  }

  override refreshActionServices(): void {
  }
}
