<div class="flex my-2" style="height: auto" *ngIf="item">
  <div class="box box-elevation px-3 pt-2 w-full">
    <div class="grid">
      <div class="col-12" *ngIf="showHeader">
        <div class="flex align-items-center justify-content-between w-full">
          <div class="flex-grow py-2">
            <b class="text-lg">
              {{ "ENV.TEST-DETAIL" | translate }} |
              {{ item.poll.title }}
            </b>
          </div>
          <div class="flex" *ngIf="showActions">
            <button
              pButton
              pRipple
              class="p-button-text p-button-info"
              icon="pi pi-pencil"
              label="{{ 'ENV.EDIT' | translate }}"
              (click)="onEdit()"
              routerLink="/admin/test/edit/{{ item.id }}"
            ></button>
          </div>
        </div>
        <hr />
      </div>
      <div class="col-6" *ngIf="item.poll.review">
        <p class="text-sm mt-0">
          {{ "FORM.DESCRIPTION" | translate }}
        </p>
        <div>{{ item.poll.review }}</div>
      </div>
      <div class="col">
        <div class="grid">
          <div class="col-6">
            <p class="text-sm mt-0">
              {{ "FORM.TIME-LIMIT" | translate }}
            </p>
            <div>{{ item.formatter.timeLimit }}</div>
          </div>
          <div class="col-6">
            <p class="text-sm mt-0">
              {{ "FORM.QUESTIONS-IN-RANDOM-ORDER" | translate }}
            </p>
            <div>{{ item.formatter.randomQuestions }}</div>
          </div>
          <div class="col-6">
            <p class="text-sm">
              {{ "FORM.MIN-APPROVAL-PERCENTAGE" | translate }}
            </p>
            <div>{{ item.formatter.minApprovalPercentage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
