<div
  class="flex h-full align-items-center justify-content-center drop-zone"
  [ngClass]="required && files.length <= 0 ? 'border-red-400' : ''"
  appDragDrop
  (fileDropped)="onFileDropped($event)"
  *ngIf="multiple || (!multiple && (!files || files.length <= 0))"
>
  <div class="flex flex-column h-auto text-center">
    <span class="material-symbols-outlined">upload_file</span>
    <p>
      {{ "WIDGET.DD-MESSAGE" | translate }}<br />{{ "GENERAL.OR" | translate }}
    </p>
    <span class="p-text-secondary">{{
      "WIDGET.DD-SELECT-FILE" | translate
    }}</span>
    <span class="supported-ext">({{ supported() }})</span>
  </div>
  <input
    #fileInput
    type="file"
    id="fileDropRef"
    [accept]="accepted.join(',')"
    [multiple]="multiple"
    (change)="fileBrowseHandler($event.target)"
  />
</div>
<div class="container files-list drop-zone h-auto" *ngIf="files.length">
  <div
    class="row single-file box box-elevation p-2 h-100 mb-2"
    *ngFor="let file of files; let i = index"
  >
    <div class="flex align-items-center gap-3">
      <div class="flex">
        <span class="material-symbols-outlined" style="font-size: 2rem">
          description
        </span>
      </div>
      <div class="flex-grow-1 overflow-hidden">
        <p class="font-bold w-full ellipsis">
          {{ file?.name }}
        </p>
        <span class="p-text-secondary" *ngIf="file?.size">
          {{ file?.size | formatBytes }}
        </span>
      </div>
      <div class="flex">
        <a class="cursor-pointer" (click)="deleteFile(i)">
          <span
            class="material-symbols-outlined text-danger"
            style="font-size: 35px"
            >delete</span
          >
        </a>
      </div>
    </div>
  </div>
</div>
