import { Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, ViewChild } from '@angular/core';
import { BaseDirective } from './base.directive';
import { Observable } from 'rxjs';
import { Table } from 'primeng/table';
import { AppExportableList, AppFilterData, FormattableObjectProperty, PrimeNGTableColumn } from '../models/AppGenericClasses';
import autoTable from 'jspdf-autotable';
import { Utils } from '../shared/utils/utils';
import { ObjectFormatter } from '../models/ObjectFormatter';
import { SupportSectionContentTableComponent } from '../components/master/units/sections/support-section-content/support-section-content-table/support-section-content-table.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Canvas2Image from './Canvas2Image';


@Directive({
  selector: '[appBaseList]',
})
export abstract class BaseListDirective extends BaseDirective implements AppExportableList {
  @Input() rows: any[] = [];
  @Input() showActions = true
  @Input() fireActions = true
  @Input() presentation = 1
  @Input() rowsPerPageOptions = [10, 25, 50]
  @Input() idsExcluded: number[] = []
  @Input() idsSelected: number[] = []
  @Output() itemSelectedChange = new EventEmitter<any>()
  @Output() inactivationSuccess = new EventEmitter<any>()
  @Input() itemSelection;
  override searchEnabled = true
  rowsPerPage = this.rowsPerPageOptions[0]
  baseRows: any[] = [];
  wildCards = [];
  filteredRows: any[];
  selectedRows: any[] = [];
  itemSelected: { [key: string]: any } = {};
  loading = false;
  multiple = true;
  isPdfDialogVisible = false;

  batchMode = false;


  filterableFields: string[] = []
  customFilters: AppFilterData[] = []
  searchText_: string
  elCourse = null

  inactivationService: Observable<any> | undefined;
  reactivationService: Observable<any> | undefined;
  queryFilters: { [key: string]: any } = {};
  keys = {
    primary_key: '',
    label: ''
  }

  showSortableItems = false
  showCustomFilters = false


  @ViewChild(Table) dt!: Table;
  filename = '';
  availableColumns: PrimeNGTableColumn[] = [];
  columns: PrimeNGTableColumn[] = [];
  isColumnToggleVisible = false;
  dataFormatter = new ObjectFormatter()
  dataFormatterProps: FormattableObjectProperty[] = []

  orderKey = '';
  updateOrderService: Observable<any> = null;

  private editRoute_: string = ""
  protected localUserFiltersKey

  trackByFn = (item: any) => {
    return item[this.keys.primary_key];
  };

  getItemSelectedID() {
    return this.itemSelected[this.keys.primary_key]
  }

  override onAfterViewInit() {
    super.onAfterViewInit()
    if (this.dt) {
      if (this.localUserFiltersKey) {
        const localUserFiltersJSONString = localStorage.getItem(this.localUserFiltersKey)

        if (localUserFiltersJSONString) {
          this.dt.filters = JSON.parse(localUserFiltersJSONString)
        }
      }
      this.dt.onFilter.subscribe(_ => {
        this.assignLocalUserFilters()
      })
    }
  }

  assignLocalUserFilters() {
    if (this.localUserFiltersKey) {
      localStorage.setItem(this.localUserFiltersKey, JSON.stringify(this.dt.filters))
    }
  }

  resetDTFilters() {
    this.dt.clear()
    this.assignLocalUserFilters()
    this.utils.global.search.next("")
    this.searchText = ""
  }

  override onLanguageInit() {
    super.onLanguageInit();
    this.utils.global.search.subscribe((data) => this.handleSearch(data));
    this.manageColumns()
    this.getList();
  }

  override manageChanges(changes: SimpleChanges): void {
    super.manageChanges(changes)
    if ('rows' in changes) {
      this.handleRows(this.rows)
    }
  }

  override handleDialogConfigData() {
    super.handleDialogConfigData()
    if (!this.idsExcluded.length) {
      this.idsExcluded = this.dialogConfig.data.idsExcluded ?? this.idsExcluded;
    }
    if (!this.idsSelected.length) {
      this.idsSelected = this.dialogConfig.data.idsSelected ?? this.idsSelected;
    }
    this.multiple = this.dialogConfig.data.multiple ?? this.multiple;
  }

  override setGlobalBehaviors() {
    super.setGlobalBehaviors()
    this.utils.global.search.next('');
  }

  abstract getList(): void;

  query(fn: Observable<any>): Observable<any> {
    this.loading = true;
    return new Observable<any>((subscriber) => {
      fn.subscribe({
        next: (data) => {
          this.handleRows(data);
          subscriber.next();
          subscriber.complete();
        },
        error: (error) => {
          Utils.error(error);
          this.loading = false;
          subscriber.error(error);
          subscriber.complete();
        },
      });
    });
  }

  handleRows(data: any) {
    data = data.filter((row: any) => !this.idsExcluded.includes(row[this.keys.primary_key]))
    this.loading = false
    this.formatData(data)
    this.rows = data
    this.baseRows = [...data]
    this.filteredRows = [...this.baseRows]
    this.selectedRows = this.rows.filter((row: any) => this.idsSelected.includes(row[this.keys.primary_key]))
    this.applyOrder()

  }

  applyOrder() {
    if (this.orderKey) {
      this.rows = Utils.sortByProperty(this.rows, this.orderKey, 'asc');
    }
  }

  formatData(data: any) {
    this.dataFormatter.props = this.dataFormatterProps
    this.utils.applyFormat(data, this.dataFormatter)
  }

  handleSearch(value: string) {
    this.asyncSearch(value).then((rs) => {
      this.filteredRows = rs
      this.rows = this.filteredRows
      this.applyOrder()
    });
  }

  asyncSearch(value: string): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        let finalRows: any[];
        if (value === '' || value === null || value === undefined) {
          finalRows = [...this.baseRows];
        } else {
          const values = value
            .split(' ')
            .map((it) =>
              it
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
            .filter((it) => it !== '');
          finalRows = [...this.baseRows].filter((it) => {
            const found: boolean[] = [];
            values.forEach((v) => {
              let vFound = false;
              this.filterableFields.forEach((ff) => {
                const columnValue = Utils.ngCellValue(ff, it);
                if (columnValue) {
                  if (
                    columnValue
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .includes(v)
                  ) {
                    vFound = true;
                  }
                }
              });
              found.push(vFound);
            });
            //return this.utils.global.searchOptions.value.type == 0 ? found.every(Boolean) : found.some(Boolean)
            return found.some(Boolean);
          });
        }
        resolve(finalRows);
      }, 20);
    });
  }

  inactivate(row: any) {
    this.handleItemSelected(row);
    this.utils.fireConfirmation({
      message: this.utils.translate.instant('QUESTION.INACTIVATE', {
        value: Utils.ngCellValue(this.keys.label, this.itemSelected)
      }),
    },
      () => {
        this.inactivationService?.subscribe({
          next: () => this.handleInactivationSuccess(),
          error: (err) => this.handleGenericError(err),
          complete: () => this.handleChangeStatusComplete()
        });
      });
  }

  reactivate(row: any): void {
    this.handleItemSelected(row)
    this.utils.fireConfirmation({
      message: this.utils.translate.instant('QUESTION.REACTIVATE', {
        value: Utils.ngCellValue(this.keys.label, this.itemSelected)
      }),
    },
      () => {
        this.utils.showLoad();
        this.reactivationService?.subscribe({
          next: () => this.handleReactivationSuccess(),
          error: (err) => this.handleGenericError(err),
          complete: () => this.handleChangeStatusComplete()
        });
      });
  }

  handleReactivationSuccess() {
    this.utils.toast.deleted(this.title);
    this.getList();
  }

  handleInactivationSuccess(): void {
    this.utils.toast.deleted(this.title);
    this.inactivationSuccess.emit(this.itemSelected)
    this.getList();
  }

  handleGenericError(error: any) {
    Utils.error(error);
    this.utils.toast.error();
  }

  handleChangeStatusComplete(): void {
    this.utils.hideLoad();
  }

  handleItemSelected(row: any) {
    this.itemSelected = row;
    this.itemSelectedChange.emit()
    this.refreshActionServices();
  }

  abstract refreshActionServices(): void

  dynamicColumns() {
  }

  translateColumns() {
    this.columns.forEach((item) => {
      item.header = this.utils.translate.instant(item.header);
    });
  }

  generateColumns() {
    this.columns = this.availableColumns.filter((item) => item.visible);
    this.translateColumns()
  }

  manageColumns() {
    this.dynamicColumns()
    this.generateColumns()
    this.filterableFields = this.filterableFields.concat(
      this.columns.map((col) => {
        return col.field
      })
    );
  }

  isColumnVisible(field: string): boolean {
    return this.columns.filter((item) => item.field === field).length > 0;
  }

  openColumnSidebar() {
    this.isColumnToggleVisible = true;
  }

  getColumn(field) {
    return this.columns.filter((column) => column.field === field)[0];
  }

  exportCSV(filter = false) {
    this.dt.exportCSV({ selectionOnly: filter });
  }

  exportExcel(selected = false) {
    this.exportDataFormatted();
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        this.exportDataFormatted(selected)
      );
      worksheet['!cols'] = this.columns
        .filter((it) => it.visible)
        .map((col) => {
          return { wpx: col.width / 1.5 };
        });
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(
        excelBuffer,
        this.utils.translate.instant(this.filename)
      );
    });
  }

  exportPdf(selected = false) {
    const filename =
      this.utils.translate.instant(this.filename).toLowerCase() +
      '_export_' +
      new Date().getTime();
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const cols = this.exportPDFColumns();
        const doc = new jsPDF.default('l', 'mm', [cols.length * 60, 210]);
        autoTable(doc, {
          body: this.exportDataPDFFormatted(selected),
          columns: cols,
        });
        doc.save(`${filename}.pdf`);
      });
    });
  }

  generatePdf(type, data) {
    const filename =
      this.utils.translate.instant(this.filename).toLowerCase() +
      '_export_' +
      new Date().getTime();

    let text = type.html;
    let match = /\[var:[^\]]+\]/g;

    const div = document.createElement('div');
    div.id = "container-pdf"

    if (this.batchMode == false) {




      let patrones = text.match(match);

      if (patrones) {
        patrones.forEach(patron => {
          text = this.replaceWildcard(text, patron);
        });
      }


      div.innerHTML = text;
      document.body.appendChild(div);

      this.onPrint('container-pdf')

    } else {

      this.selectedDataTable.forEach(r => {
        let tempTxt = text;
        let patrones = tempTxt.match(match);
        this.itemSelection = r;
        this.handleItemSelected(r)

        if (patrones) {
          patrones.forEach(patron => {
            tempTxt = this.replaceWildcard(tempTxt, patron);
          });
        }

        div.innerHTML += tempTxt;
      });

      document.body.appendChild(div);
      this.onPrint('container-pdf')
    }

  }

  generateItemPdf(type) {

  }

  onPrint(divName) {
    let printContents;
    let popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html lang="es">
      <head>
        <link id="theme-link" rel="stylesheet" type="text/css" href="styles.css">
        <style>
        @media print {
          ::ng-deep {
            .p-sidebar-header
              display: none
          }

          .table-container{
            margin: 1rem 0;
            padding: 0 1rem;
            height: auto !important;
          }

          tr, th {
            border-color: var(--surface-300);
            border-bottom: 1px solid black;
            border-top: 1px solid black;
            border-collapse: collapse;
            padding: .5rem;
          }

          td {
            padding: .5rem;
          }

          .table-container, .p-sidebar-content, .order-summary-container {
            overflow: unset
          }

          .btn-pdf {
            display: none
          }

                  .styled-table {
            border-collapse: collapse;
            margin: 25px 0;
            font-size: 18px;
            text-align: left;
        }
        .styled-table th, .styled-table td {
            padding: 12px 15px;
        }
        .styled-table thead tr {
            background-color: #009879;
            color: #ffffff;
            text-align: left;
        }
        .styled-table tbody tr:nth-of-type(even) {
            background-color: #f3f3f3;
        }
        .styled-table tbody tr:hover {
            background-color: #f1f1f1;
        }
        }
        </style>
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>`);
    popupWin.document.close();
    document.getElementById(divName).remove();
  }





  generateTablePDF(type, data) {


    let tempRows = [];

    let text = type.html;
    let match = /\[var:[^\]]+\]/g;

    let patrones = text.match(match);


    if (patrones) {
      patrones.forEach(patron => {
        text = this.replaceWildcard(text, patron);
      });
    }



    const div = document.createElement('div');
    div.id = 'container-pdf'


    let tabla = document.createElement("table");
    tabla.id = "miTabla";
    tabla.classList.add('styled-table')

    let thead = document.createElement("thead");
    let encabezado = "<tr>";

    this.selectedDataColumns
      .filter((it) => it.visible)
      .forEach(c => {
        encabezado += ` <th>${c.header}</th>`
      });

    encabezado += "</tr>";


    thead.innerHTML = encabezado;
    tabla.appendChild(thead);

    let tbody = document.createElement("tbody");

    this.selectedDataTable.forEach((r) => {
      const row: string[] = [];
      this.selectedDataColumns
        .filter((it) => it.visible)
        .forEach((c) => {
          row.push(this.exportValue(c, r));
        });
      tempRows.push(row);
    });


    tempRows.forEach(r => {
      let fila = "<tr>";
      r.forEach(value => {

        fila += "<td>" + value + "</td>";

      });

      fila += "</tr>";
      tbody.innerHTML += fila;

    })

    tabla.appendChild(tbody);


    div.innerHTML = text + tabla.outerHTML;
    document.body.appendChild(div);
    this.onPrint('container-pdf')

  }

  replaceWildcard(texto, variable) {

    const regex = /\[var:(\w+)\]/;

    let wCard = this.wildCards.find(w => w.key_ === variable.match(regex)[1]);
    let patron = new RegExp(variable.replace(/[\[\]]/g, '\\$&'), 'g');

    if (wCard.type_ === 'TEXT') {
      return texto.replace(patron, wCard.content);
    } else {
      /* let content = texto.replace(patron, wCard.content); */
      let parts = wCard.content.split('.')





      switch (parts[0]) {
        case 'el_course':

          if (parts[1] === 'final_note') {
            if ('unitTestsAverageScore' in this.itemSelection) {
              return texto.replace(patron, this.itemSelection.unitTestsAverageScore)
            } else {
              return texto.replace(patron, 'Sin nota');
            }
          }

          if (this.courseDetail !== null) {
            return texto.replace(patron, this.courseDetail[parts[1]])
          } else {
            if (this.itemSelection) {
              //! todo change
              return texto.replace(patron, '')

            } else {
              return texto.replace(patron, 'Sin curso')
            }
          }
        case 'el_course_unit':
          if (this.UnitDetail !== null) {
            return texto.replace(patron, this.UnitDetail[parts[1]])
          } else {
            if ('course_units' in this.itemSelection) {
              return texto.replace(patron, this.itemSelection.course_units.map(u => u[parts[1]]).join(', '))
            } else {
              return texto.replace(patron, 'Sin unidades')
            }

          }

        case 'el_test':


          if (parts[1] === 'name_') {
            if (this.testDetail !== null) {
              return texto.replace(patron, this.testDetail.poll.title)
            } else {
              return texto.replace(patron, 'Sin Evaluaciones')
            }
          }

          if (this.testDetail !== null) {
            return texto.replace(patron, this.testDetail[parts[1]])
          } else {
            if ('tests' in this.itemSelection) {
              return texto.replace(patron, this.itemSelected.tests.map(u => u.name_).join(', '))
            } else {
              if ('course_units' in this.itemSelection) {
                if ('tests' in this.itemSelection.course_units[0]) {
                  return texto.replace(patron, this.itemSelection.course_units.map(cu => cu.tests[0].test.title).join(', '))
                }
              }
              return texto.replace(patron, 'Sin Evaluaciones')
            }

          }

        case 'user_':
          if (parts[1] === 'user_post') {
            return texto.replace(patron, this.itemSelection.user.post.name_);
          }
          if (parts[1] === 'enrolled_at') {
            return texto.replace(patron, this.itemSelection[parts[1]])
          }
          if (this.userDetail !== null) {
            return texto.replace(patron, this.userDetail[parts[1]])
          } else {
            if ('user' in this.itemSelection) {
              return texto.replace(patron, this.itemSelection.user[parts[1]])
            } else {
              return texto.replace(patron, 'Sin Usuario')
            }
          }



        default:
          return texto
          break;
      }

    }



  }

  exportPDFColumns() {
    const finalColumns: string[] = [];
    this.columns
      .filter((it) => it.visible)
      .forEach((c) => {
        if ('complementaryHeader' in c && c.complementaryHeader) {
          finalColumns.push(c.header + ' - ' + c.complementaryHeader);
        } else {
          finalColumns.push(c.header);
        }
      });
    return finalColumns;
  }

  exportFinalRows(selected: boolean) {
    let finalRows: any[];
    if (selected) {
      finalRows = this.selectedRows;
    } else {
      if (this.dt.filteredValue) {
        finalRows = this.dt.filteredValue;
      } else {
        finalRows = this.rows;
      }
    }
    return finalRows;
  }

  exportDataPDFFormatted(selected: boolean) {
    const finalRows = this.exportFinalRows(selected);
    const data: string[][] = [];
    finalRows.forEach((r) => {
      const row: string[] = [];
      this.columns
        .filter((it) => it.visible)
        .forEach((c) => {
          row.push(this.exportValue(c, r));
        });
      data.push(row);
    });
    return data;
  }

  exportDataFormatted(selected = false) {
    const finalRows = this.exportFinalRows(selected);
    const data: string[][] = [];
    finalRows.forEach((r) => {
      const row: string[] = [];
      const columnsCopy = [
        {
          field: this.keys.primary_key,
          header: 'ID',
          visible: true
        },
        ...this.columns,
      ];
      columnsCopy
        .filter((it) => it.visible)
        .forEach((c) => {
          if ('complementaryHeader' in c && c.complementaryHeader) {
            row[c.header + ' - ' + c.complementaryHeader] = this.exportValue(
              c,
              r
            );
          } else {
            row[c.header] = this.exportValue(c, r);
          }
        });
      data.push(row);
    });
    return data;
  }

  exportValue(column, row): string {
    if (column.capitalize) {
      return Utils.capitalizeEachWord(Utils.ngCellValue(column.field, row));
    }
    return Utils.ngCellValue(column.field, row);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    this.utils.fileSaverService.save(
      data,
      fileName.toLowerCase() +
      '_export_' +
      new Date().getTime() +
      EXCEL_EXTENSION
    );
  }

  setReorderMethod() {
  }

  onReorder() {
    this.beforeReorder();
    this.setReorderMethod();
    this.updateOrder();
  }

  beforeReorder() {
    this.rows.forEach((row, idx) => {
      row[this.orderKey] = idx;
    });
  }

  updateOrder() {
    if (this.updateOrderService) {
      this.updateOrderService.subscribe({
        next: this.handleUpdateOrderSuccess,
        error: this.handleUpdateOrderError,
        complete: this.handleUpdateOrderComplete,
      });
    }
  }

  handleUpdateOrderSuccess(data: any) {
    Utils.log(data)
  }

  handleUpdateOrderError(data: any) {
    Utils.log(data);
    this.utils.toast.error();
  }

  handleUpdateOrderComplete() {
  }

  seeSortableItems() {
    this.showSortableItems = true
  }

  seeCustomFilters() {
    this.showCustomFilters = true
  }

  edit(row: any, newPage: boolean = false) {
    this.handleItemSelected(row)
    this.onEdit(newPage)
  }

  generateSimplePDF(row, batch = false) {
    this.handleItemSelected(row);
    this.isPdfDialogVisible = true;
    this.batchMode = batch;

  }

  generateTablePdf() {

    this.isPdfTableDialogVisible = true;
  }


  onEdit(newPage: boolean = false) {
    if (newPage) {
      const url = this.utils.router.serializeUrl(
        this.utils.router.createUrlTree([this.editRoute])
      );

      window.open(url, '_blank');
    } else {
      this.utils.router.navigate([this.editRoute])
    }
  }

  set searchText(value: string) {
    this.searchText_ = value
    this.handleSearch(value)
  }
  get searchText() {
    return this.searchText_
  }

  get editRoute() {
    return this.editRoute_
  }
}
