import { Component } from '@angular/core';
import { BaseGenericFilterDirective } from 'src/app/directives/base-generic-filter.directive';

@Component({
  selector: 'app-filter-generic-group',
  templateUrl: './filter-generic-group.component.html',
  styleUrls: ['./filter-generic-group.component.sass']
})
export class FilterGenericGroupComponent extends BaseGenericFilterDirective {
  sourceKey = 'group';
}
