import { Utils } from "../shared/utils/utils";
import { AppItemListFilter, AppObjectFilterItem } from "./AppGenericClasses";
import { AppSelectableItem, Color, Topic } from "./AppModels";

export class TopicListFilter extends AppItemListFilter<Topic> {
  colorFilter!: AppObjectFilterItem<AppSelectableItem<Color>, Color[]>

  initFilters(): void {
    super.initFilters()
    this.colorFilter = {
      options: Utils.removeDuplicatesById(this.baseItems.map(item => item.color)),
      value: []
    }
    this.filterManager.filters = [this.colorFilter]
  }

  filterData() {
    return this.filteredItems.filter(item => {
      return !this.colorFilter.value.length || this.colorFilter.value.map(sl => sl.id).includes(item.color.id)
    })
  }
}
