import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.sass']
})
export class UnderConstructionComponent {
  lottieOptions: AnimationOptions = {
    path: '/assets/animations/under-construction.json',
  };
}
