import { AppMenuItem } from 'src/app/models/AppGenericClasses';
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { GlobalService } from 'src/app/services/utils/global.service';


@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.sass']
})
export class ConfigurationComponent {
  title = 'MENU.SETTINGS'
  userType = this.globalSv.userType
  menuItems: AppMenuItem[] = [
    new AppMenuItem('MENU.COLORS', { routerLink: '/configuration/color', description_: 'MENU.DESC-COLORS' }),
    new AppMenuItem('MENU.DOCUMENTS-TYPES', { routerLink: '/configuration/document-type', description_: 'MENU.DESC-DOCUMENTS-TYPES' }),
    new AppMenuItem('MENU.GENDERS', { routerLink: '/configuration/gender', description_: 'MENU.DESC-GENDER' }),
    new AppMenuItem('MENU.CITIES', { routerLink: '/configuration/city', description_: 'MENU.DESC-CITIES' }),
    new AppMenuItem('MENU.USERS', { routerLink: '/configuration/user', description_: 'MENU.DESC-USERS' }),
    new AppMenuItem('MENU.GROUP', { routerLink: '/configuration/user-group', description_: 'DESC.DESC-GROUPS' }),
    new AppMenuItem('MENU.TEMPLATES', { routerLink: '/configuration/templates', description_: '' }),
    new AppMenuItem('FORM.SPECIALTY', { routerLink: '/configuration/specialty', description_: 'MENU.DESC-SPECIALTY' }),
    new AppMenuItem('MENU.PENDING-USERS', { routerLink: '/configuration/pending-users', description_: 'MENU.DESC-PENDING-USERS' }),
    new AppMenuItem('FORM.WILDCARDS', { routerLink: '/configuration/wildcards', description_: '', visible: true })
  ]

  constructor(
    private globalSv: GlobalService
  ) {
    console.log(this.globalSv.userType)
  }
}
