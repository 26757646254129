<div class="flex flex-column h-full">
  <div class="flex-grow-1 overflow-hidden">
    <p-scrollPanel styleClass="h-full w-full">
      <ng-container *ngIf="isStudying === 0">
        <app-course-preview> </app-course-preview>
      </ng-container>

      <ng-container *ngIf="isStudying === 1">
        <app-course-view> </app-course-view>
      </ng-container>
    </p-scrollPanel>
  </div>
</div>
