import { Params } from '@angular/router';
import { BaseDirective } from './base.directive';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appBaseConfirmationDialog]',
})
export abstract class BaseFormWrapperDirective extends BaseDirective {
  @Input() editId = 0
  title = null
  processing = false

  override handleRouteParams(params: Params) {
    super.handleRouteParams(params)
    if ('id' in params) {
      this.editId = params['id']
    }
  }
}
