<div class="flex flex-column h-full">
  <div class="flex justify-content-end mb-2">
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-file-export"
      class="p-button-danger me-3 position-relative mr-2"
      (click)="generateTablePdf()"
    ></button>
    <button
      pButton
      pRipple
      class="p-button-text p-button-primary"
      icon="pi pi-filter"
      label="{{ 'ENV.FILTER' | translate }}"
      (click)="seeCustomFilters()"
    ></button>
    <app-prime-ng-export-table [primeNgList]="this"></app-prime-ng-export-table>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-table"
      label="{{ 'FORM.SHOW-HIDE-COLUMNS' | translate }}"
      class="p-button-success me-3 position-relative"
      (click)="openColumnSidebar()"
    ></button>
  </div>
  <div class="flex-grow overflow-hidden h-full">
    <p-table
      [columns]="columns"
      [dataKey]="keys.primary_key"
      [value]="rows"
      [loading]="loading"
      [paginator]="true"
      [rows]="rowsPerPage"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="rowsPerPageOptions"
      styleClass="p-datatable-gridlines"
      [(selection)]="selectedRows"
      [scrollable]="true"
      scrollHeight="flex"
      [resizableColumns]="true"
      [currentPageReportTemplate]="
        'GENERAL.SHOWING-X-TO-Y-OF-Z-ROWS'
          | translate
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      "
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th
              [pSortableColumn]="col.sortable"
              pFrozenColumn
              [frozen]="col.frozen"
              pResizableColumn
              [pResizableColumnDisabled]="!col.resizable"
              [width]="col.width"
              [style.minWidth.px]="col.resizable ? 0 : col.width"
              *ngIf="col.visible"
            >
              <div class="flex align-items-center gap-2">
                {{ col.header | translate }}
                <p-sortIcon
                  *ngIf="col.sortable"
                  [field]="col.sortable"
                ></p-sortIcon>
                <p-columnFilter
                  *ngIf="col.filterable"
                  type="text"
                  [field]="col.sortable"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-user let-i="rowIndex">
        <tr>
          <td>{{ user.name_ }}</td>
          <td *ngFor="let take of user.takes">
            <span
              style="color: green"
              class="material-symbols-outlined"
              *ngIf="take.approved === 1"
            >
              check
            </span>
            <span
              style="color: red"
              class="material-symbols-outlined"
              *ngIf="take.approved === 0"
            >
              close
            </span>
            <span *ngIf="take.approved === 1 || take.approved === 0">{{
              take.result
            }}</span>
            <span *ngIf="take.approved === 2">Sin intentos</span>
          </td>
          <td>{{ user.average }}</td>
          <td>
            <button
              pButton
              pRipple
              icon="pi pi-file-pdf"
              (click)="generateSimplePDF(user)"
              class="p-button-text p-button-raised p-button-danger"
            ></button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ "WIDGET.DT-EMPTY" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-sidebar
  [(visible)]="isColumnToggleVisible"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '100%', 'max-width': '20em' }"
>
  <app-prime-list-columns-toggle
    [(columns)]="columns"
  ></app-prime-list-columns-toggle>
</p-sidebar>

<p-sidebar [(visible)]="showCustomFilters" appendTo="body">
  <ng-template pTemplate="header">
    <h3>{{ "ENV.FILTER" | translate }}</h3>
  </ng-template>
  <ng-container>
    <label for="tags">{{ "MENU.GROUP" | translate }}</label>
    <div class="flex flex-column gap-2 mb-3">
      <span class="p-float-label">
        <label for="tags">{{ "MENU.GROUP" | translate }}</label>
        <p-dropdown
          id="group"
          name="group"
          [options]="groupsData"
          [(ngModel)]="group"
          [filter]="true"
          appendTo="body"
          optionValue="user_group_id"
          optionLabel="name_"
          (ngModelChange)="getList()"
        >
        </p-dropdown>
      </span>
    </div>
    <label for="topics">{{ "CORE.COURSE" | translate }}</label>
    <div class="flex flex-column gap-2 mb-3">
      <span class="p-float-label">
        <label for="topics">{{ "CORE.COURSE" | translate }}</label>
        <p-dropdown
          id="course"
          name="course"
          [options]="coursesData"
          [(ngModel)]="course"
          [filter]="true"
          appendTo="body"
          optionValue="el_course_id"
          optionLabel="name_"
          (ngModelChange)="getList()"
        >
        </p-dropdown>
      </span>
    </div>
  </ng-container>
  <ng-template pTemplate="footer">
    <button pButton>Limpiar filtros</button>
  </ng-template>
</p-sidebar>

<p-dialog
  header="{{ 'MENU.REPORTS' | translate }}"
  [(visible)]="isPdfDialogVisible"
  [modal]="true"
  [style]="{ width: '95%', height: '95%' }"
  [draggable]="false"
  [resizable]="false"
  [contentStyle]="{ height: '100%' }"
>
  <app-modal-simple-pdf
    *ngIf="isPdfDialogVisible"
    [itemSelection]="itemSelected"
    [courseDetail]="courseDetail"
  >
  </app-modal-simple-pdf>
</p-dialog>

<p-dialog
  header="{{ 'MENU.REPORTS' | translate }}"
  [(visible)]="isPdfTableDialogVisible"
  [modal]="true"
  [style]="{ width: '95%', height: '95%' }"
  [draggable]="false"
  [resizable]="false"
  [contentStyle]="{ height: '100%' }"
>
  <app-modal-table-pdf
    *ngIf="isPdfTableDialogVisible"
    [itemSelection]="itemSelected"
    [courseDetail]="courseDetail"
    [selectedDataColumns]="columns"
    [selectedDataTable]="rows"
    [testDetail]="testDetail"
  >
  </app-modal-table-pdf>
</p-dialog>
