<div
  class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3"
  *ngIf="presentation === 1"
>
  <div class="flex w-12 md:w-4 lg:w-2">
    <a class="flex">
      <app-aws-image-viewer
        defaultImage="assets/images/cover-example.jpg"
        [path]="'course'"
        [editId]="item.id"
        field="cover"
        ext="png"
      ></app-aws-image-viewer>
    </a>
  </div>
  <div class="flex flex-column w-12 md:w-8 lg:w-10 px-4 py-3 mb-3">
    <a class="card-item-title text-danger">{{ item.name }}</a>
    <div class="flex flex-row pt-2">
      <ng-container *ngFor="let courseTopic of item.courseTopics">
        <app-color-chip
          [content]="courseTopic.source.topic.name_"
          [color]="courseTopic.source.topic.color"
          class="mr-2 cursor-pointer"
          (click)="op.toggle($event)"
        ></app-color-chip>
        <p-overlayPanel #op>
          <app-topic-item
            [itemId]="courseTopic.source.topic.el_topic_id"
            [showActions]="false"
          ></app-topic-item>
        </p-overlayPanel>
      </ng-container>
    </div>
    <p class="p-text-secondary" *ngIf="showDescription">
      <app-text-wrapper [text]="item.description"></app-text-wrapper>
    </p>
    <div class="flex flex-column md:flex-row w-full">
      <div class="flex flex-column w-full">
        <p class="p-text-secondary font-bold w-full text-sm">
          {{ "FORM.ESTIMATED-DURATION" | translate }}:
          {{ item.estimatedDuration }}
          {{
            globals.COURSE_VISUALIZATION.TIME_LIMIT_UNIT.label
              | translate
              | lowercase
          }}
        </p>
        <div
          class="flex flex-wrap w-12 md:w-8 lg:w-9 card-item-tags"
          *ngIf="item.tags.length"
        >
          <p class="p-text-secondary font-bold w-full">
            {{ "ENV.TAGS" | translate }}:
          </p>
          <div class="flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of item.tagsArr">
              <p-tag [value]="tag"></p-tag>
            </ng-container>
          </div>
        </div>
      </div>
      <app-course-action-buttons
        [source]="item.source"
        (itemDisabled)="itemDisabled.emit(true)"
        (itemEnabled)="itemEnabled.emit(true)"
        *ngIf="showActions"
      ></app-course-action-buttons>
    </div>
  </div>
</div>

<div class="card pb-4" *ngIf="presentation === 2">
  <div
    class="box-elevation border-round-md flex flex-column align-items-center shadow-2 p-3"
  >
    <a class="flex w-full h-7 mb-1">
      <app-image-viewer
        [defaultImage]="'assets/images/cover-example.jpg'"
        [path]="'course'"
        [id]="item.id"
        [field]="'cover'"
      ></app-image-viewer>
    </a>
    <p-tag>
      <div class="text-center">{{ item.name_ }}</div>
    </p-tag>
  </div>
</div>

<div
  class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3"
  *ngIf="presentation === 3"
>
  <div class="flex w-12 md:w-4 lg:w-2">
    <a class="flex">
      <app-aws-image-viewer
        defaultImage="assets/images/cover-example.jpg"
        [path]="'course'"
        [editId]="item.id"
        field="cover"
        ext="png"
      ></app-aws-image-viewer>
    </a>
  </div>
  <div class="flex flex-column w-12 md:w-8 lg:w-10 px-4 py-3 mb-3">
    <a class="card-item-title">{{ item.name_ }}</a>
    <div class="flex flex-row pt-2">
      <app-color-chip
        *ngFor="let courseTopic of item.courseTopics"
        [content]="courseTopic.source.topic.name_"
        [color]="courseTopic.source.topic.color"
        class="mr-2"
      ></app-color-chip>
    </div>
    <p class="p-text-secondary" *ngIf="showDescription">
      {{ item.description }}
    </p>
    <div class="flex flex-column md:flex-row mb-3">
      <div class="flex flex-column w-full">
        <p class="p-text-secondary font-bold w-full text-sm">
          {{ "FORM.ESTIMATED-DURATION" | translate }}:
          {{ item.estimatedDuration }}
          {{
            globals.COURSE_VISUALIZATION.TIME_LIMIT_UNIT.label
              | translate
              | lowercase
          }}
        </p>
        <div
          class="flex flex-wrap w-12 md:w-8 lg:w-9 card-item-tags"
          *ngIf="item.tags.length"
        >
          <p class="p-text-secondary font-bold w-full">
            {{ "ENV.TAGS" | translate }}:
          </p>
          <div class="flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of item.tagsArr">
              <p-tag [value]="tag"></p-tag>
            </ng-container>
          </div>
        </div>
      </div>
      <app-course-action-buttons
        [source]="item.source"
        (itemDisabled)="itemDisabled.emit(true)"
        (itemEnabled)="itemEnabled.emit(true)"
        *ngIf="showActions"
      ></app-course-action-buttons>
    </div>
    <span class="p-text-secondary">
      Usuarios inscritos: {{ item.enrollmentsCount }}
    </span>
  </div>
</div>

<p-dialog
  [(visible)]="showInacivationProhibition"
  [modal]="true"
  [draggable]="false"
>
  <p>No se puede eliminar el curso porque cuenta con usuarios inscritos.</p>
</p-dialog>
