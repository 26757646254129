import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';


@Component({
  selector: 'app-wildcards',
  templateUrl: './wildcards.component.html',
  styleUrls: ['./wildcards.component.sass']
})
export class WildcardsComponent extends BaseDirective {
  override title = 'FORM.WILDCARDS';
}
