/* eslint-disable @typescript-eslint/no-unused-vars */
import { Directive, Input, SimpleChanges, OnChanges, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Globals } from "src/app/globals";
import { Utils } from "src/app/shared/utils/utils";


@Directive({
    selector: '[appBaseFileUploader]',
})
export class BaseFilterUniqueByKey implements OnInit, OnChanges {
    @Input() field: string;
    @Input() matchModeOptions: SelectItem[];
    @Input() data: any[]
    options = []
    globals = Globals

    ngOnInit(): void {
        this.initOptions()
    }

    initOptions() {
        this.options = Utils.uniqueByKeyToPrimengDropdown(this.data, this.field)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('data' in changes) {
            this.initOptions()
        }
    }
}