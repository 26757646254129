/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';

@Component({
  selector: 'app-course-unit-section-content-list',
  templateUrl: './course-unit-section-content-list.component.html',
  styleUrls: ['./course-unit-section-content-list.component.sass']
})
export class CourseUnitSectionContentListComponent extends BaseListDirective {

  getList(): void {
  }

  refreshActionServices(): void {
  }

  handleSearch(value: string): void { }
}
