import { Injectable } from '@angular/core';
import { TokenService } from "../auth/token.service";
import { ConfigDataService } from "./config-data.service";
import { UtilsService } from "../utils/utils.service";
import { Utils } from 'src/app/shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AppConfigProviderService {

  constructor(
    private token: TokenService,
    private config: ConfigDataService,
    private utils: UtilsService
  ) { }

  load(): Promise<any> {
    return new Promise((resolve) => {
      if (!this.token.loggedIn.value) {
        resolve(true)
      } else {
        this.reload().then((res) => resolve(res));
      }
    })
  }

  reload(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.config.refresh()
      this.config.initial().subscribe(
        {
          next: value => {
            this.handleInitialSuccess(value)
            resolve(true)
          },
          error: err => {
            this.handleInitialError(err)
            reject('')
          }
        }
      )
    })
  }

  handleInitialSuccess(data: any) {
    this.utils.global.config = data.config
    this.utils.global.user = data.user
    this.utils.global.userType = data['user-type']
  }

  handleInitialError(err: any) {
    Utils.error(err)
  }

}
