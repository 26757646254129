import { ContentTriggerButtonComponent } from './../../../../../shared/content-trigger-button/content-trigger-button.component';
import { Component, Input, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BaseItemListDirective } from 'src/app/directives/restruct/base-item-list.directive';
import { GenericResponse, PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { TestQuestionList } from 'src/app/models/AppLists';
import { TestQuestion } from 'src/app/models/AppModels';
import { TestQuestionJSON } from 'src/app/models/AppSources';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-test-question-table',
  templateUrl: './test-question-table.component.html',
  styleUrls: ['./test-question-table.component.sass']
})
export class TestQuestionTableComponent extends BaseItemListDirective<TestQuestion, TestQuestionJSON, TestQuestionList> {

  @ViewChild('contentTrigger') contentTrigger!: ContentTriggerButtonComponent
  @Input() pollId = 0;
  override title = 'CORE.QUESTION';
  override filename = 'CORE.QUESTION';
  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('content', 'FORM.CONTENT', 200, { frozen: true, editable: true, type: 'MULTI-TEXT-LINE' }),
    new PrimeNGTableColumn('weight', 'FORM.WEIGHT', 100, { editable: true, type: 'NUMBER' }),
    new PrimeNGTableColumn('answersNum', 'FORM.ANSWERS-NUM', 150),
    new PrimeNGTableColumn('formatter.type', 'FORM.TYPE', 200)
  ];
  override ListModel = TestQuestionList;
  distributeWeights = false

  override getList() {
    if (this.pollId) {
      this.query(this.data.core.testQuestionsOfPoll(this.pollId)).subscribe();
    }
  }

  override handleRows(data: TestQuestionJSON[]): void {
    super.handleRows(data)
    this.itemList.rows.forEach(row => {
      row.resourceContentMenuItems = [
        {
          label: this.utils.translate.instant('FORM.SEE-CONTENT'),
          icon: 'pi pi-eye',
          command: () => {
            this.contentTrigger.item = row.resourceContentData
            this.contentTrigger.seeResource()
          },
          visible: !!row.resourceContent.id
        },
        {
          label: this.utils.translate.instant('FORM.ASSOCIATE-CONTENT'),
          icon: 'pi pi-file-edit',
          command: () => {
            this.openAssociateContentDialog(row)
          }
        },
        {
          label: this.utils.translate.instant('FORM.CREATE-AND-ASSOCIATE-CONTENT'),
          icon: 'pi pi-file-import',
          command: () => {
            this.openCreateContentDialog(row)
          }
        }
      ] as MenuItem[]
    });
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.testQuestionDelete(
      this.itemList.itemSelected.id
    )
  }

  openAssociateContentDialog(row: TestQuestion) {
    this.utils.appDialog.seeContentPicker(this.utils.translate.instant("CORE.QUESTION"), {
      onSuccess: (res: GenericResponse) => {
        const objects = res.complement.data.map(item => {
          return {
            content_id: item.content_id
          }
        })

        this.utils.executeRequest(
          this.data.core.testQuestionContentCreateBatch({ poll_question_id: row.id, objects }), {
          onSuccess: () => {
            this.utils.toast.saved(this.title)
            this.getList()
          }
        })
      }
    }, {
      parentTestQuestionId: row.id,
      idsExcluded: row.resourceContent ? [row.resourceContent.id] : [],
      multiple: false
    })
  }

  openCreateContentDialog(row: TestQuestion) {
    this.utils.appDialog.seeContentFormDialog({
      onSuccess: () => {
        this.getList()
      }
    }, {
      parentTestQuestionId: row.id,
    })
  }

  setUpdateItemMethod(data: Partial<TestQuestion>) {
    this.updateItemService = this.data.core.testQuestionUpdate(this.editedItem.id, data)
  }

  setUpdateItemBatchMethod() {
    this.updateItemBatchService = this.data.core.testQuestionUpdateInBatch({
      objects: this.itemList.rows.map(row => {
        return {
          poll_question_id: row.id,
          content: row.content,
          weight: row.weight
        }
      })
    })
  }

  setReorderMethod() {
    this.updateOrderService = this.data.core.testQuestionUpdateInBatch({
      objects: this.itemList.rows.map(row => {
        return {
          poll_question_id: row.id,
          order_: row.sortValue
        }
      })
    });
  }

  onDistributeWeights() {
    this.itemList.rows.forEach(row => {
      row.weight = (100 / this.itemList.rows.length).toFixed(1)
    })
    console.log(this.itemList.rows);

  }

  get totalWeight() {
    return Utils.sumArrayByKey(this.itemList.rows, 'weight')
  }
}
