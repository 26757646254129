<ng-container *ngIf="!processing">
  <h3 class="w-full text-center">{{ "AUTH.REGISTER-USER" | translate }}</h3>
  <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="pt-3">
    <div class="flex flex-column gap-3">
      <div class="flex gap-3">
        <div class="flex-grow-1">
          <span class="p-float-label gap-2">
            <input
              pInputText
              id="name"
              name="name"
              class="ng-dirty w-full"
              aria-describedby="title-help"
              [(ngModel)]="form.first_name"
              maxlength="100"
              required
            />
            <label for="name">{{ "FORM.NAME" | translate }}</label>
          </span>
        </div>
        <div class="flex-grow-1">
          <span class="p-float-label gap-2">
            <input
              pInputText
              id="surname"
              name="surname"
              class="ng-dirty w-full"
              aria-describedby="title-help"
              [(ngModel)]="form.last_name"
              maxlength="100"
              required
            />
            <label for="surname">{{ "FORM.SURNAME" | translate }}</label>
          </span>
        </div>
      </div>
      <div class="flex gap-3">
        <div class="w-full overflow-hidden">
          <div class="p-float-label gap-2">
            <p-dropdown
              id="dni-type"
              name="dni-type"
              [options]="data.sources['dni-type'].data"
              [(ngModel)]="form.dni_type_id"
              [filter]="true"
              appendTo="body"
              [optionValue]="data.sources['dni-type'].key"
              [optionLabel]="data.sources['dni-type'].label"
              class="w-full"
              styleClass="w-full"
            >
            </p-dropdown>
            <label for="dni_type">{{ "FORM.DNI-TYPE" | translate }}</label>
          </div>
        </div>
        <div class="w-full overflow-hidden">
          <div class="p-float-label gap-2">
            <input
              pInputText
              id="dni"
              name="dni"
              class="ng-dirty w-full"
              aria-describedby="title-help"
              [(ngModel)]="form.dni"
              maxlength="100"
              required
            />
            <label for="dni">{{ "FORM.DNI" | translate }}</label>
          </div>
        </div>
      </div>
      <div class="flex gap-3">
        <div class="flex-grow-1">
          <span class="p-float-label gap-2">
            <input
              pInputText
              id="email"
              name="email"
              class="ng-dirty w-full"
              aria-describedby="title-help"
              [(ngModel)]="form.email"
              maxlength="100"
              required
            />
            <label for="email">{{ "AUTH.EMAIL" | translate }}</label>
          </span>
        </div>
        <div class="flex-grow-1">
          <span class="p-float-label gap-2">
            <input
              pInputText
              id="password"
              name="password"
              class="ng-dirty w-full"
              aria-describedby="title-help"
              [(ngModel)]="form.password"
              maxlength="100"
              required
            />
            <label for="password">{{ "AUTH.PASSWORD" | translate }}</label>
          </span>
        </div>
      </div>
      <div class="flex gap-3">
        <div class="w-full overflow-hidden">
          <span class="p-float-label gap-2">
            <p-dropdown
              id="gender"
              name="gender"
              [options]="data.sources['gender'].data"
              [(ngModel)]="form.gender_id"
              [filter]="true"
              appendTo="body"
              [optionValue]="data.sources['gender'].key"
              [optionLabel]="data.sources['gender'].label"
              class="w-full"
              styleClass="w-full"
            >
            </p-dropdown>
            <label for="gender">{{ "CORE.GENDER" | translate }}</label>
          </span>
        </div>
        <div class="w-full overflow-hidden">
          <span class="p-float-label gap-2">
            <p-dropdown
              id="city"
              name="city"
              [options]="data.sources['city'].data"
              [(ngModel)]="form.city_id"
              [filter]="true"
              appendTo="body"
              [optionValue]="data.sources['city'].key"
              [optionLabel]="data.sources['city'].label"
              class="w-full"
              styleClass="w-full"
            >
            </p-dropdown>
            <label for="city">{{ "CORE.CITY" | translate }}</label>
          </span>
        </div>
      </div>
      <div class="flex gap-3">
        <div class="w-full overflow-hidden">
          <span class="p-float-label gap-2">
            <p-dropdown
              id="group"
              name="group"
              [options]="data.sources['group'].data"
              [(ngModel)]="form.user_group_id"
              [filter]="true"
              appendTo="body"
              [optionValue]="data.sources['group'].key"
              [optionLabel]="data.sources['group'].label"
              class="w-full"
              styleClass="w-full"
            >
            </p-dropdown>
            <label for="group">{{ "MENU.GROUP" | translate }}</label>
          </span>
        </div>
        <div class="w-full overflow-hidden">
          <span class="p-float-label gap-2" *ngIf="form.user_type_id === 2">
            <p-dropdown
              id="specialty"
              name="specialty"
              [options]="data.sources['specialty'].data"
              [(ngModel)]="form.user_post_id"
              [filter]="true"
              appendTo="body"
              [optionValue]="data.sources['specialty'].key"
              [optionLabel]="data.sources['specialty'].label"
              class="w-full"
              styleClass="w-full"
            >
            </p-dropdown>
            <label for="specialty">{{ "FORM.SPECIALTY" | translate }}</label>
          </span>
        </div>
      </div>
    </div>
  </form>
  <div class="flex justify-content-end mt-3">
    <button
      pButton
      pRipple
      class="p-button-text"
      label="{{ 'AUTH.REGISTER' | translate }}"
      [disabled]="!dataForm.valid"
      (click)="dataForm.ngSubmit.emit()"
      *ngIf="!processing"
    ></button>
  </div>
</ng-container>
