<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">
        <span class="cursor-pointer" routerLink="/reports">{{
          "Reportes | " | uppercase
        }}</span
        >{{ title | translate | uppercase }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-success"
        icon="pi pi-plus"
        label="{{ 'ENV.NEW' | translate }} {{
          'CORE.USER' | translate | lowercase
        }}"
        routerLink="/configuration/user/create"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <app-report-course-table></app-report-course-table>
      </div>
    </div>
  </div>
</div>
