/* eslint-disable @typescript-eslint/no-empty-function */
import { Utils } from 'src/app/shared/utils/utils';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { GlobalUtils } from 'src/app/shared/utils/global-utils';
import { FileUtils } from 'src/app/shared/utils/file-utils';
import { S3ProviderListOutputItem } from '@aws-amplify/storage';
import { BehaviorSubject } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { AppSelectableItem, AppSortableItem } from './AppModels';

export type PrimeNGTableColumnType = 'DEFAULT' | 'NUMBER' | 'DATE-TIME' | 'TEXT-LINE' | 'MULTI-TEXT-LINE'
export type ObjectPropertyFormat =
  'COMMA-SEPARATED' |
  'DATE-FORMATTED' |
  'FLAG' |
  'UPPER-CASE' |
  'LOWER-CASE' |
  'CAPITALIZE' |
  'PERCENTAGE' |
  'TIME' |
  'TIME-FORMATTED' |
  'DECIMAL'
export type ContentType = 'AUDIO' | 'VIDEO' | 'PDF' | 'IMG' | 'PLAIN' | 'URL' | 'VIDEO-URL'
export type GenericPromiseStatus = 1 | 2 | 3
export type FileType =
  'image/*' |
  'audio/*' |
  'video/*' |
  '.zip' |
  'application/pdf' |
  'application/vnd.ms-excel' |
  'text/plains'
export type SortType = 'desc' | -1 | 'asc' | 1
export type TimeUnit = 'SECONDS' | 'MINUTES' | 'HOURS'
export type ComponentDataSource = 'INPUT' | 'DIALOG' | 'ROUTE'
export type TextCase = 'TITLE-CASE' | "UPPER-CASE" | "LOWER-CASE" | "DEFAULT"
export type DialogConfigType = 'CONTAINER'
export type ConvertToFormatted<T> = {
  [K in keyof T]: any;
};
export type ELTestFormatted = ConvertToFormatted<ELTest>

export interface GenericPromiseResponse {
  status: GenericPromiseStatus
  data: any
}

export interface YouTubeEmbedParams {
  autoplay?: 0 | 1;
  cc_lang_pref?: string;
  color?: string;
  controls?: 0 | 1;
  disablekb?: 0 | 1;
  end?: number;
  fs?: 0 | 1;
  hl?: string;
  iv_load_policy?: 1 | 3;
  list?: string;
  listType?: 'user_uploads' | 'playlist' | 'search';
  loop?: 0 | 1;
  modestbranding?: 0 | 1;
  mute?: 0 | 1;
  playlist?: string;
  playsinline?: 0 | 1;
  rel?: 0 | 1;
  start?: number;
}

export interface OptionalPrimeTableColumnParams {
  sortable?: string
  type?: PrimeNGTableColumnType
  filterable?: boolean
  resizable?: boolean
  visible?: boolean
  frozen?: boolean
  editable?: boolean
}

export interface OptionalAppMenuItemParams {
  key?: string
  routerLink?: string
  tooltip?: string
  icon?: string
  items?: AppMenuItem[]
  description_?: string
  visible?: boolean
}

export interface MediaPlayerConfig {
  autoPlay?: boolean
}

export interface AppPrimeNGDialogConfig {
  height?: string
  width?: string
  maximizable?: boolean
}

export interface FormattableObjectProperty {
  field: string
  formats: ObjectPropertyFormat[]
}

export interface ELTestVersion {
  el_test_version_id: number
  el_test_id: number
  test: ELTest
  available_from: string
  available_to: string
  allowed_attempts: number
  created: string
  created_by: number
}

export interface ELTest {
  el_test_id: number
  time_limit: number
  random_questions: 0 | 1
  min_approval_percentage: number
  poll: ELPoll
}

export interface ELPoll {
  poll_id: number
  title: string
  review: string
}

export interface GenericResponse<DataType = any> {
  success?: boolean
  complement?: GenericResponseComplement<DataType>
}

export interface GenericResponseComplement<DataType = any> {
  message?: string
  code?: number
  data?: DataType
}

export interface DialogFormData {
  [key: string]: any
  go_to_url_on_save?: string
  redirect_on_save?: boolean
}

export interface DialogListData {
  [key: string]: any
  idsExcluded?: any[]
  idsSelected?: any[]
  multiple?: boolean
}

export interface AppTimerModel {
  hours: number
  minutes: number
  seconds: number
}

export interface BreadcrumItem {
  label: string
  routerLink?: string
}

export interface AppFilterData {
  keyPath: string
  label: string
  identifier: string
}

export interface AppExportableList {
  exportCSV(filter?: boolean): void
  exportExcel(selected?: boolean): void
  exportPdf(selected?: boolean): void
}

export interface AppSortableList<Item extends AppSortableItem> {
  sorter: AppItemSorter<Item>
}

export interface AppFilterable<Item extends AppSortableItem> {
  filterData(): Item[]
}

export interface AppPickerList<DataType> {
  selected: EventEmitter<GenericResponse<DataType[]>>
  onItemSelected(row: DataType): void
  onItemSelectedInBatch(): void
  emitData(data: GenericResponse<DataType[]>): void
}

export interface AppPermission {
  key: string
  label: string
}

export interface AppPermissionGroup {
  label: string
  permissions: AppPermission[]
}

export interface AppUserTypePermission {
  [key: string]: AppPermissionGroup
}

export class AppObjectFilterList {
  [s: string]: AppObjectFilterItem;
}

export class AppObjectFilterItem<Item extends AppSelectableItem<Item> = any, Value = any> {
  options: Item[]
  value: Value

  constructor() {
    this.options = []
    this.value = null
  }
}

export class AppTag {
  label: string
  severity: string
}

export class PrimeNGTableColumn implements OptionalPrimeTableColumnParams {
  field: string
  header: string
  width: number
  sortable?: string
  type?: PrimeNGTableColumnType
  filterable?: boolean
  resizable?: boolean
  visible?: boolean
  frozen?: boolean
  editable?: boolean
  columnType?: boolean

  constructor(
    field: string,
    header: string,
    width: number,
    optionalParams?: OptionalPrimeTableColumnParams,
    isColumType?: boolean
  ) {
    this.field = field
    this.header = header
    this.width = width
    Object.assign(this, GlobalUtils.getOptionalPrimeTableColumnParams(optionalParams))
    this.sortable = optionalParams?.sortable ?? this.getSortable()
    this.columnType = isColumType
  }

  private getSortable() {
    switch (this.type) {
      case 'DATE-TIME':
        return `${this.field}_as_date_time`
      default:
        return this.field
    }
  }
}

export class MediaUploadItemData {
  id = 0
  table = ''
  field = ''
  label? = ''

  constructor(id: number, table: string, field: string, label = 'resource') {
    this.id = id
    this.table = table
    this.field = field
    this.label = label
  }
}

export class MediaUploadItem {
  data: MediaUploadItemData;
  type: string;
  base64: string;
  file: File | null;
  status = 0;

  constructor(
    data: MediaUploadItemData,
    type: string,
    base64 = '',
    file: File = null
  ) {
    this.data = data;
    this.type = type;
    this.base64 = base64;
    this.file = file;
  }
}

export class AWSUploadedFile {
  fileData: S3ProviderListOutputItem
  name: string
  ext: string
  src: string

  constructor(fileData: S3ProviderListOutputItem, src: string) {
    this.fileData = fileData
    this.name = FileUtils.getFileNameFromPath(fileData.key)
    this.ext = FileUtils.getExtFromPath(fileData.key)
    this.src = src
  }
}

export class AWSResourceData {
  resourcePath? = ''
  resourceContent? = ''
  path? = ''
  id? = 0
  field? = ''
  ext? = ''
}

export class LibraryContentItem {
  libraryId = 0
  contentId = 0

  constructor(libraryId: number, contentId: number) {
    this.libraryId = libraryId
    this.contentId = contentId
  }
}
export class TestUnitItem {
  unitId = 0
  testId = 0

  constructor(unitId: number, testId: number) {
    this.unitId = unitId
    this.testId = testId
  }
}

export class TestQuestionContentItem {
  testId = 0
  contentId = 0

  constructor(testId: number, contentId: number) {
    this.testId = testId
    this.contentId = contentId
  }
}

export class AppMenuItem {
  label: string
  tooltip?: string
  routerLink?: string
  key?: string
  icon?: string
  items?: AppMenuItem[]
  description_?: string
  visible?: boolean

  private readonly OPTIONAL_PARAMS_DEFAULT_VALUES?: OptionalAppMenuItemParams = {
    key: '',
    routerLink: '',
    tooltip: '',
    icon: '',
    items: [],
    description_: '',
    visible: true
  }

  constructor(label: string, optionalData?: OptionalAppMenuItemParams) {
    this.label = label
    Object.assign(this, { ...this.OPTIONAL_PARAMS_DEFAULT_VALUES, ...optionalData ?? {} })
    this.tooltip = label
  }
}

export class AppFormatter<T> {
  protected utils: UtilsService
  protected dataFormatted_: T[]
  private source_: any[]
  private formatFn: (data: any[]) => void

  constructor(data: any[], utils: UtilsService, formatFn: (data: any[]) => void) {
    this.utils = utils
    this.source = data
    this.formatFn = formatFn
    this.dataFormatted = this.source
  }

  get dataFormatted(): T[] {
    return this.dataFormatted_
  }

  set dataFormatted(data: any[]) {
    this.formatFn(data)
    this.dataFormatted_ = data
  }

  get source() {
    return this.source_
  }

  set source(data: any[]) {
    this.source_ = Utils.deepCopy<any[]>(data)
  }
}

export class AppTimer {
  seconds = 0
  minutes = 0
  hours = 0
  mode: 'normal' | 'reverse' = "normal"
  timerInterval: NodeJS.Timer = null
  onFinish: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(model: AppTimerModel) {
    this.hours = model.hours
    this.minutes = model.minutes
    this.seconds = model.seconds
  }

  stop() {
    clearInterval(this.timerInterval)
  }

  startInterval() {
    this.timerInterval = setInterval(() => {
      this.update()
    }, 1000)
  }

  update() {
    const model = Utils.convertSecondsToHMS(Utils.getTimerSeconds(this) + (this.mode === 'normal' ? 1 : -1))
    this.seconds = model.seconds
    this.minutes = model.minutes
    this.hours = model.hours

    if (this.mode === 'reverse' && Utils.getTimerSeconds(this) <= 0) {
      this.stop()
      this.onFinish.next(true)
    }
  }

  start() {
    this.mode = "normal"
    this.startInterval()
  }

  reverse() {
    this.mode = "reverse"
    this.startInterval()
  }

  reset() {
    this.hours = 0
    this.minutes = 0
    this.seconds = 0
  }
}

export class AppTimerFormatter extends AppTimer {

  get formatted() {
    return `${Utils.zero(this.hours)}:${Utils.zero(this.minutes)}:${Utils.zero(this.seconds)}`
  }
}

export class AppChronometer extends AppTimer {

  plusHour() {
    this.hours++
  }

  subtractHour() {
    if (this.hours > 0) {
      this.hours--;
    }
  }

  plusMinute() {
    this.minutes++
    if (this.minutes === 60) {
      this.minutes = 0;
      this.plusHour();
    }
  }

  subtractMinute() {
    if (this.minutes > 0) {
      this.minutes--
    }
  }

  plusSecond() {
    this.seconds++
    if (this.seconds === 60) {
      this.seconds = 0;
      this.plusMinute();
    }
  }

  subtractSecond() {
    if (this.seconds > 0) {
      this.seconds--
    }
  }
}

export class AppJSONSanitizer<T> {
  result: T
  private model: T

  constructor(data: T, Model: new () => T) {
    this.model = new Model()
    this.result = this.sanitize(data)
  }

  private sanitize(data: T): T {
    for (const key in this.model) {
      if (data[key] === null || data[key] === undefined) {
        data[key] = this.model[key]
      }
    }
    return data;
  }
}

export class AppItemSorter<Item extends AppSortableItem> {
  items: Item[]
  orientation: 'asc' | 'desc'

  constructor() {
    this.items = []
    this.orientation = 'asc'
  }

  sort() {
    const sorted = this.items.sort((a, b) => {
      const valueA = a.sortValue.toLowerCase();
      const valueB = b.sortValue.toLowerCase();
      if (valueA < valueB || !valueA) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    if (this.orientation === 'desc') {
      return sorted.reverse();
    }
    return sorted;
  }
}

export class AppFormattedList<
  Item extends AppSortableItem & AppSelectableItem<Item>,
  Source
> implements AppSortableList<Item> {
  private source_: Source[]
  private utils: UtilsService
  protected ItemModel: new (source: Source, utils: UtilsService) => Item

  rows: Item[] = []
  selectedRows: Item[] = []
  itemSelected: Item = null

  idsExcluded: number[] = []
  idsSelected: number[] = []

  sorter: AppItemSorter<Item>
  filter: AppItemListFilter<Item>

  constructor(utils: UtilsService) {
    this.utils = utils
    this.initSorter()
    this.initFilter()
    this.utils.global.search.subscribe(async (data) => {
      await this.applyFilter(data)
    });
  }

  async applyFilter(data: string) {
    await this.filter.handleSearch(data)
    this.rows = this.filter.filteredItems
    this.rows = this.filter.filterData()
    this.sorter.items = this.rows
    this.sorter.sort()
  }

  initSorter() {
    this.sorter = new AppItemSorter()
  }

  setFilterInstance() {
    this.filter = new AppItemListFilter<Item>()
  }

  initFilter() {
    this.setFilterInstance()
    this.filter.sorter = this.sorter
    this.filter.filterableFields = ['name']
  }

  get source() {
    return this.source_
  }

  set source(value: Source[]) {
    this.source_ = value
    this.initRowsData()
  }

  initRowsData() {
    this.rows = this.source.map(item => {
      return new this.ItemModel(item, this.utils)
    }).filter(row => {
      return !this.idsExcluded.includes(row.id)
    })
    this.selectedRows = this.rows.filter(row => this.idsSelected.includes(row.id))
    this.filter.filteredItems = [...this.rows]
    this.filter.baseItems = [...this.rows]
    this.filter.initFilters()
    this.sorter.items = this.rows
    this.sorter.sort()
  }
}

export class AppItemListFilter<Item extends AppSortableItem & AppSelectableItem<Item>> implements AppSortableList<Item>, AppFilterable<Item> {
  filteredItems: Item[]
  baseItems: Item[]
  filterableFields: string[] = []

  sorter: AppItemSorter<Item>;
  filterManager!: AppFilterManager

  constructor() {
    this.baseItems = []
    this.filterManager = new AppFilterManager()
  }

  async handleSearch(value: string) {
    await this.asyncSearch(value).then((rs) => {
      this.filteredItems = rs
    });
  }

  async asyncSearch(value: string): Promise<any[]> {
    const normalizedValue = value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    if (!value.trim()) {
      return Promise.resolve([...this.baseItems]);
    }

    const normalizedSearchTerms = normalizedValue.split(' ').filter(term => term !== '');

    const filteredRows = this.baseItems.filter(item => {
      return normalizedSearchTerms.some(term => {
        return this.filterableFields.some(field => {
          const columnValue = Utils.ngCellValue(field, item);
          if (columnValue) {
            const normalizedColumnValue = columnValue
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '');
            return normalizedColumnValue.includes(term);
          }
          return false;
        });
      });
    });

    return Promise.resolve(filteredRows);
  }

  get appliedFilters() {
    return this.filterManager.appliedFilters || 0
  }

  initFilters() { }

  clean() {
    this.filterManager.clean()
    return this.filterData()
  }

  filterData(): Item[] {
    throw new Error('Method not implemented.');
  }
}

export class AppFilterManager {
  private filters_: AppObjectFilterItem<any, any[]>[] = []

  get filters() {
    return this.filters_
  }
  set filters(value: AppObjectFilterItem<any, any[]>[]) {
    this.filters_ = value
  }

  get appliedFilters() {
    return (Utils.sumArray(this.filters.map(filter => filter.value.length))) || 0
  }

  clean() {
    this.filters.forEach(filter => filter.value = [])
  }
}
