<div class="flex flex-grow-1 h-full">
  <ng-container *ngIf="uploading">
    <ng-lottie
      [options]="globals.LOTTIE_ANIMATIONS.uploadingOptions"
      style="max-height: 100%; width: 100%"
    ></ng-lottie>
  </ng-container>
  <ng-container *ngIf="!uploading">
    <ng-container *ngIf="loading">
      <ng-lottie
        [options]="globals.LOTTIE_ANIMATIONS.loadingOptions"
        style="max-height: 100%; width: 100%"
      ></ng-lottie>
    </ng-container>
    <ng-container *ngIf="!loading">
      <div class="w-full h-full relative" *ngIf="image">
        <app-aws-image-viewer [resourceUrl]="image"></app-aws-image-viewer>
        <button
          pButton
          pRipple
          class="p-button-text p-button-danger absolute top-0 right-0 border-noround"
          icon="pi pi-undo"
          style="background: var(--surface-a)"
          (click)="resetImage()"
          pTooltip="{{ 'FORM.CHANGE-IMAGE' | translate }}"
        ></button>
      </div>
      <app-drag-drop
        [accepted]="['image/*']"
        class="w-full ng-dirty"
        [(ngModel)]="files"
        (ngModelChange)="onFilesChanged()"
        [required]="required"
        *ngIf="!image"
      ></app-drag-drop>
    </ng-container>
  </ng-container>
</div>
