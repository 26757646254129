import { ELTest } from '../../../../../models/AppGenericClasses';
import { Component } from '@angular/core';
import { Params } from '@angular/router';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-test-detail-wrapper',
  templateUrl: './test-detail-wrapper.component.html',
  styleUrls: ['./test-detail-wrapper.component.sass']
})
export class TestDetailWrapperComponent extends BaseDirective {

  testId = 0
  test: ELTest = null

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('id' in params) {
      this.testId = params['id']
    }
  }

  override onLanguageInit(): void {
    super.onLanguageInit()
    if (this.testId > 0) {
      this.utils.executeRequest(
        this.data.core.testUnique(this.testId), {
        onSuccess: (data) => {
          this.test = data
        }
      })
    }
  }
}
