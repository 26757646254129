import { Component, Input } from '@angular/core';
import { ContentTableComponent } from '../../../content/content-table/content-table.component';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-library-content-table',
  templateUrl: './library-content-table.component.html',
  styleUrls: ['./library-content-table.component.sass']
})
export class LibraryContentTableComponent extends ContentTableComponent {
  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.TITLE', 150, { frozen: true }),
    new PrimeNGTableColumn('description_', 'FORM.DESCRIPTION', 300),
    new PrimeNGTableColumn('type_', 'FORM.TYPE', 100)
  ];
  @Input() libraryId = 0

  override getList(): void {
    this.query(this.data.core.libraryContents(this.libraryId)).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.libraryContentDelete(
      this.libraryId,
      this.itemSelected[this.keys.primary_key]
    )
  }
}
