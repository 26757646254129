import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-template-table',
  templateUrl: './template-table.component.html',
  styleUrls: ['./template-table.component.sass']
})
export class TemplateTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.NAME', 300, { frozen: true }),
    new PrimeNGTableColumn('type_', 'FORM.TYPE', 300, { frozen: true })];
  override title = 'MENU.TEMPLATES';
  override filterableFields = []
  override keys = {
    primary_key: 'template_id',
    label: 'name_'
  }
  override filename = 'MENU.TEMPLATES';

  override getList(): void {
    this.query(this.data.core.templateToSelect()).subscribe()
  }

  override handleRows(data: any): void {
    console.log(data);
    super.handleRows(data);
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.templateDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }
}
