import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseListFilterablePrimeDirective } from 'src/app/directives/base-list-filterable-prime.directive';
import { PrimeNGTableColumn, AWSResourceData } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-content-table',
  templateUrl: './content-table.component.html',
  styleUrls: ['./content-table.component.sass']
})
export class ContentTableComponent extends BaseListFilterablePrimeDirective {
  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.TITLE', 150, { frozen: true }),
    new PrimeNGTableColumn('description_', 'FORM.DESCRIPTION', 300),
    new PrimeNGTableColumn('tags', 'FORM.TAGS', 100, { filterable: true }),
    new PrimeNGTableColumn('type_', 'FORM.TYPE', 100, { filterable: true }),
    new PrimeNGTableColumn('min_viewing_time', 'FORM.MIN-TIME-SHORT', 150)
  ];
  override title = 'MENU.CONTENTS'
  override filterableFields = ['name_', 'description_', 'type_', 'tags']
  override keys = {
    primary_key: 'content_id',
    label: 'name_'
  }
  override filename = 'MENU.CONTENTS'
  override localUserFiltersKey = "CONTENT-TABLE-COMPONENT"

  activeContentsQuery: Observable<any> = this.data.core.contents()
  inactiveContentsQuery: Observable<any> = this.data.core.inactiveContents()
  inactives = false

  override formatData(data: any): void {
    this.utils.applyContentFormat(data)
  }

  override getList(): void {
    this.query(
      !this.inactives ?
        this.activeContentsQuery :
        this.inactiveContentsQuery
    ).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.contentDelete(
      this.itemSelected[this.keys.primary_key]
    )
    this.reactivationService = this.data.core.contentActivate(
      this.itemSelected[this.keys.primary_key]
    )
  }

  override handleRows(data: any): void {
    data.forEach(row => {
      row.resourceData = {
        path: 'content',
        id: row.content_id,
        field: row.type_,
        ext: this.globals.CONTENT_TYPES[row.type_].ext,
        resourceContent: ['PLAIN', 'URL', 'VIDEO-URL'].includes(row.type_) ? row.content : ''
      } as AWSResourceData
    });
    super.handleRows(data)
  }

  onInactivesChange() {
    this.getList()
  }

  override get editRoute() {
    return `/contents/edit/${this.itemSelected.content_id}`
  }
}
