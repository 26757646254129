import { LibraryDetailComponent } from './../../../shared/library-detail/library-detail.component';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Params } from '@angular/router';
import { BaseDirective } from './../../../../directives/base.directive';
import { Component, ViewChild } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { GenericResponse } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-library-content',
  templateUrl: './library-content.component.html',
  styleUrls: ['./library-content.component.sass']
})
export class LibraryContentComponent extends BaseDirective {

  @ViewChild('primeNgList') list: BaseListDirective
  libraryId = 0
  library: any = null
  contentIds: number[] = []
  title = 'CORE.LIBRARY-CONTENT'

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('id' in params) {
      this.libraryId = +params['id']
      this.utils.executeRequest(
        this.data.core.libraryUnique(this.libraryId), {
        onSuccess: (data) => {
          this.library = data
          this.contentIds = this.library.contents.map(content => content.content_id)
        }
      })
    }
  }

  seeLibrary() {
    this.dialog.open(LibraryDetailComponent, {
      header: this.utils.translate.instant('CORE.LIBRARY'),
      data: {
        itemId: this.libraryId
      },
      dismissableMask: true
    })
  }

  seeContents() {
    this.utils.appDialog.seeContentPicker(this.library.name_, {
      onSuccess: (res: GenericResponse) => {
        const selectedContents = res.complement.data
        const objects = selectedContents.map((content: any) => {
          return {
            library_id: this.libraryId,
            content_id: content.content_id
          }
        })
        this.utils.executeRequest(
          this.data.core.libraryContentCreateBatch({ objects }), {
          onSuccess: (res) => {
            this.handleContentSave(selectedContents)
          }
        })
      }
    }, {
      idsExcluded: this.contentIds
    })
  }

  handleContentSave(contents: number[]) {
    this.contentIds = [
      ...this.contentIds,
      ...contents.map((content: any) => content.content_id)
    ]
    this.utils.toast.saved(this.title)
    this.list.getList()
  }

  handleContentDelete(deletedContent: any) {
    this.contentIds = this.contentIds.filter(id => id != deletedContent.content_id)
  }
}
