<div class="flex flex-column gap-2">
  <label for="url">URL</label>
  <input
    pInputText
    type="text"
    [(ngModel)]="url"
    required
    class="ng-dirty"
    id="url"
    name="url"
    placeholder="https://example.com"
    (ngModelChange)="onUrlChange($event)"
  />
  <div style="height: 500px">
    <app-video-url-player [url]="url"></app-video-url-player>
  </div>
</div>
