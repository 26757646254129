import { Component } from '@angular/core';
import { BaseGenericFilterDirective } from 'src/app/directives/base-generic-filter.directive';


@Component({
  selector: 'app-filter-generic-specialty',
  templateUrl: './filter-generic-specialty.component.html',
  styleUrls: ['./filter-generic-specialty.component.sass']
})
export class FilterGenericSpecialtyComponent extends BaseGenericFilterDirective {
  sourceKey = 'specialty';
}
