<div class="px-3">
  <div class="flex align-items-center">
    <div class="flex-grow-1">
      <h3 class="pt-2 align-items-center" *ngIf="showName">
        {{ item.test?.poll?.title }}
      </h3>
    </div>
    <div class="flex">
      <app-test-approval-required-chip
        [status]="item.approval_required"
      ></app-test-approval-required-chip>
      <p-tag
        *ngIf="item.test?.random_questions"
        icon="pi pi-info-circle"
        value="Aleatorio"
        severity="success"
        class="ml-2"
        [rounded]="true"
        style="vertical-align: middle"
      ></p-tag>
    </div>
  </div>
  <div class="flex justify-content-center gap-5">
    <div class="text-center">
      <p>% Mínimo de aprobación</p>
      <p-knob
        [(ngModel)]="item?.test.min_approval_percentage"
        [readonly]="true"
        valueTemplate="{value}%"
        [strokeWidth]="7"
      ></p-knob>
    </div>
    <div class="text-center flex flex-column">
      <div class="flex">
        <p>Intentos permitidos</p>
      </div>
      <div class="flex-grow-1 p-text-secondary">
        <h1>
          {{ item.allowed_attempts }}
        </h1>
      </div>
    </div>
    <div class="text-center flex flex-column">
      <div class="flex justify-content-center">
        <p>Tiempo límite</p>
      </div>
      <div class="flex-grow-1 p-text-secondary">
        <h1>
          {{ item?.test.time_limit / 60 }}
          {{ "FORM.MINUTES" | translate | lowercase }}
        </h1>
      </div>
    </div>
  </div>
</div>
