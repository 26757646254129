/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { HttpErrorResponse } from '@angular/common/http';
import { Directive, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from "rxjs";
import { BaseDirective } from "./base.directive";
import { NgForm } from "@angular/forms";
import { Utils } from '../shared/utils/utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GlobalDataService } from '../services/data/global-data.service';
import { UtilsService } from '../services/utils/utils.service';
import { AppConfigProviderService } from '../services/data/app-config-provider.service';

@Directive({
  selector: '[appBaseForm]'
})
export abstract class BaseFormDirective extends BaseDirective {

  @ViewChild('dataForm') dataForm: NgForm | undefined
  @Input() editId = 0

  editService: Observable<any> | undefined
  putService: Observable<any> | undefined
  postService: Observable<any> | undefined

  form: { [key: string]: any } = {}
  formSanitized: { [key: string]: any } = {}

  redirectOnSave = true
  goToUrlOnSave = ''
  error: HttpErrorResponse = null

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
    protected router: Router
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef)
  }

  override onLanguageInit() {
    super.onLanguageInit();
  }

  override handleRouteParams(params: Params) {
    super.handleRouteParams(params)
    if ('id' in params) {
      this.editId = params['id']
      console.log(this.editId);

      if (this.editId > 0) {
        this.getItemEdit()
      }
    }
  }

  override manageChanges(changes: SimpleChanges): void {
    if ('editId' in changes) {
      if (this.editId > 0) {
        this.getItemEdit()
      }
    }
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.goToUrlOnSave = this.dialogConfig.data.go_to_url_on_save ?? this.goToUrlOnSave;
    this.redirectOnSave = this.dialogConfig.data.redirect_on_save ?? this.redirectOnSave;
  }

  getItemEdit() {
    this.setItemEditMethod()
    if (this.editService) {
      this.processing = true
      this.utils.showLoad();
      this.editService.subscribe(
        {
          next: value => this.handleItemEditSuccess(value),
          error: err => this.handleItemEditError(err)
        }
      )
    }
  }

  handleItemEditSuccess(data: any) {
    this.processing = false
    this.utils.hideLoad();
    Utils.fillObj(this.form, data);
    this.handleItemEditData(data)
  }

  handleItemEditData(data: any) { }

  handleItemEditError(err: any) {
    this.processing = false
    Utils.error(err)
    this.utils.toast.error()
  }

  sanitize() {
    this.formSanitized = {};
    Object.entries(this.form).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        this.formSanitized[key] = value
      } else {
        switch (typeof value) {
          case 'object':
            this.formSanitized[key] = value
            break
          default:
            this.formSanitized[key] = value
            break
        }
      }
    })
  }

  beforeOnSubmit() {
    this.sanitize()
    this.processing = true
    this.utils.showLoad()
  }

  setItemEditMethod() { }
  abstract setSubmitMethods(): void

  onSubmit() {
    if (this.dataForm?.valid) {
      this.beforeOnSubmit()
      this.setSubmitMethods()
      if (this.editId <= 0) {
        this.onSubmitPOST()
      } else {
        this.onSubmitPUT()
      }
    }
  }

  onSubmitPOST() {
    this.postService?.subscribe(
      {
        next: value => this.handleSave(value),
        error: err => this.handleError(err)
      }
    )
  }

  onSubmitPUT() {
    this.putService?.subscribe(
      {
        next: value => this.handleSave(value),
        error: err => this.handleError(err)
      }
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleSave(data: any = null) {
    this.processing = false
    this.utils.hideLoad()
    this.utils.toast.saved(this.title)
    if (this.editId <= 0) {
      this.cleanForm()
    }
    this.emitData({ success: true, complement: { data } })
    this.goTo();
  }

  goTo() {
    if (!this.redirectOnSave) { return }
    if (!this.goToUrlOnSave) {
      this.utils.back();
      return
    }
    this.utils.router.navigate([this.goToUrlOnSave])
  }

  handleError(error: any = null) {
    this.processing = false
    this.utils.hideLoad()
    Utils.error(error)
    this.utils.toast.error()
  }

  cleanForm() {
    Utils.cleanObj(this.form)
  }

}
