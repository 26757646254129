import { Component } from '@angular/core';
import { BaseFormDirective } from "../../../../directives/base-form.directive";

@Component({
  selector: 'app-topic-form',
  templateUrl: './topic-form.component.html',
  styleUrls: ['./topic-form.component.sass']
})
export class TopicFormComponent extends BaseFormDirective {
  override title = 'MENU.TOPICS'

  override form = {
    name_: '',
    description_: null,
    color_id: null
  }

  override onLanguageInit() {
    super.onLanguageInit()
    this.data.load('colors-to-select')
  }

  override getItemEdit() {
    this.editService = this.data.core.topicUnique(this.editId)
    super.getItemEdit()
  }

  setSubmitMethods(): void {
    this.postService = this.data.core.topicCreate(this.formSanitized)
    this.putService = this.data.core.topicUpdate(this.editId, this.formSanitized)
  }
}
