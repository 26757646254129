<div class="flex flex-column h-full">
  <div class="flex align-items-center" style="height: auto">
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h5 class="module-subtitle">
        {{ title | translate | uppercase }}
      </h5>
      <h2 class="module-title">
        {{ "ENV.TAKE-TEST" | translate }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid || processing"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="
          !processing && takeStarted && ![410, 406].includes(error?.status)
        "
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form
          #dataForm="ngForm"
          class="flex flex-column h-full pb-5"
          (ngSubmit)="onSubmit()"
        >
          <div class="mb-2">
            <app-attemptable-test-detail
              [itemId]="id"
              [takeId]="parentId"
              [type]="type"
              [showActions]="false"
              [componentDataSource]="'INPUT'"
            ></app-attemptable-test-detail>
          </div>
          <ng-container *ngIf="!test">
            <div class="flex align-items-center justify-content-center">
              <button pButton type="button" (click)="startTestTake()">
                Iniciar evaluación
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="takeStarted">
            <ng-container *ngIf="test || error; else loadingTemplate">
              <ng-container *ngIf="error">
                <div
                  class="w-full h-full flex align-items-center justify-content-center"
                >
                  <ng-container *ngIf="error.status === 410">
                    <p>No se ha podido encontrar la evaluación.</p>
                  </ng-container>
                  <ng-container *ngIf="error.status === 406">
                    <p>
                      Ha agotado todos los intentos permitidos para la toma de
                      la evaluación.
                    </p>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="(!error || error.status === 403) && test">
                <div
                  *ngFor="let question of test.poll.questions; index as i"
                  class="box box-elevation p-3 my-3 w-full"
                >
                  <p>
                    <b>{{ i + 1 }}.</b> {{ question.content }}
                  </p>
                  <div class="text-sm" [innerHTML]="question.complement"></div>
                  <div *ngIf="question.type_ === 'OPEN'">
                    <textarea
                      pInputTextarea
                      [(ngModel)]="question.answer_open"
                      id="answer_open_{{ i }}"
                      name="answer_open_{{ i }}"
                      class="w-full ng-dirty"
                      rows="3"
                    >
                    </textarea>
                  </div>
                  <div *ngIf="question.type_ === 'SELECTION-SINGLE'">
                    <div
                      *ngFor="
                        let answer of question.answers_selection;
                        index as j
                      "
                      class="field-radiobutton"
                    >
                      <p-radioButton
                        inputId="{{ answer.poll_answer_selection_id }}{{ i }}{{
                          j
                        }}"
                        name="category{{ i }}{{ j }}"
                        [value]="answer"
                        [(ngModel)]="question.answer_selected"
                        class="ng-dirty"
                      ></p-radioButton>
                      <label
                        for="{{ answer.poll_answer_selection_id }}{{ i }}{{
                          j
                        }}"
                        class="ml-2"
                        >{{ answer.content }}</label
                      >
                    </div>
                  </div>
                  <div *ngIf="question.type_ === 'SELECTION-MULTIPLE'">
                    <div
                      *ngFor="
                        let answer of question.answers_selection;
                        index as j
                      "
                      class="field-checkbox"
                    >
                      <p-checkbox
                        name="group{{ i }}{{ j }}"
                        [value]="answer"
                        [(ngModel)]="question.answers_selected"
                        inputId="{{ answer.poll_answer_selection_id }}{{ i }}{{
                          j
                        }}"
                        class="ng-dirty"
                      ></p-checkbox>
                      <label
                        for="{{ answer.poll_answer_selection_id }}{{ i }}{{
                          j
                        }}"
                        >{{ answer.content }}</label
                      >
                    </div>
                  </div>
                  <div *ngIf="question.type_ === 'RELATION'" cdkDropListGroup>
                    <div class="text-sm mb-2">
                      {{ "FORM.RELATED-QUESTION-INFO" | translate }}
                    </div>
                    <div class="flex align-items-center">
                      <div class="drag-container flex-grow-1">
                        <div class="flex-grow-1 drag-list">
                          <ng-container
                            *ngFor="let answer of question.answers_related"
                          >
                            <div class="draggable-box" style="cursor: default">
                              {{ answer.content }}
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="flex mx-2">
                        <span class="material-symbols-outlined">
                          arrow_forward
                        </span>
                      </div>
                      <div class="drag-container flex-grow-1">
                        <div
                          cdkDropList
                          [cdkDropListData]="question.relatedValuesToSelect"
                          (cdkDropListDropped)="drop($event)"
                          class="flex-grow-1 drag-list"
                        >
                          <ng-container
                            *ngFor="let value of question.relatedValuesToSelect"
                          >
                            <div class="draggable-box" cdkDrag>
                              {{ value.content }}
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="flex">
                        <span class="material-symbols-outlined">
                          swap_vert
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </form>
      </p-scrollPanel>
    </div>
  </div>
  <div class="flex justify-content-end my-3" *ngIf="test">
    Tiempo restante: {{ timer.formatted }}
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
