import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private textKeys = {
    success: {
      saved: 'FORM.DATA-SAVED',
      deleted: 'FORM.ITEM-DELETED'
    }
  }

  constructor(
    private messageService: MessageService,
    private translate: TranslateService
  ) { }

  custom(severity: string, title: string, message: string, sticky = false) {
    this.messageService.add(
      {
        severity,
        summary: this.translate.instant(title),
        detail: this.translate.instant(message),
        sticky
      }
    )
  }

  info(title: string, message: string) {
    this.custom('info', title, message)
  }

  error(title = 'ERROR.ERROR', message = 'ERROR.TRY-AGAIN') {
    this.custom('error', title, message)
  }

  saved(title: string) {
    this.custom('success', title, this.textKeys.success.saved)
  }

  deleted(title: string) {
    this.custom('success', title, this.textKeys.success.deleted)
  }

  loading() {
    this.info('WIDGET.LOADING', 'WIDGET.PLEASE-WAIT')
  }
}
