<div class="flex flex-column h-full">
  <div class="flex justify-content-end mb-2">
    <app-prime-ng-export-table [primeNgList]="this"></app-prime-ng-export-table>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-table"
      label="{{ 'FORM.SHOW-HIDE-COLUMNS' | translate }}"
      class="p-button-success me-3 position-relative"
      (click)="openColumnSidebar()"
    ></button>
  </div>
  <div class="flex-grow overflow-hidden h-full">
    <p-table
      [columns]="columns"
      [dataKey]="keys.primary_key"
      [value]="rows"
      [loading]="loading"
      [paginator]="true"
      [rows]="rowsPerPage"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="rowsPerPageOptions"
      styleClass="p-datatable-gridlines"
      [(selection)]="selectedRows"
      [scrollable]="true"
      scrollHeight="flex"
      [resizableColumns]="true"
      [currentPageReportTemplate]="
        'GENERAL.SHOWING-X-TO-Y-OF-Z-ROWS'
          | translate
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      "
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3rem" pFrozenColumn>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let col of columns">
            <th
              [pSortableColumn]="col.sortable"
              pFrozenColumn
              [frozen]="col.frozen"
              pResizableColumn
              [pResizableColumnDisabled]="!col.resizable"
              [width]="col.width"
              [style.minWidth.px]="col.resizable ? 0 : col.width"
              *ngIf="col.visible"
            >
              <div class="flex align-items-center gap-2">
                {{ col.header | translate }}
                <p-sortIcon
                  *ngIf="col.sortable"
                  [field]="col.sortable"
                ></p-sortIcon>
                <p-columnFilter
                  *ngIf="col.filterable"
                  type="text"
                  [field]="col.sortable"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
          </ng-container>
          <th [width]="100" alignFrozen="right" pFrozenColumn></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr>
          <td pFrozenColumn>
            <p-tableCheckbox [value]="row"></p-tableCheckbox>
          </td>
          <ng-container *ngFor="let col of columns">
            <td
              pFrozenColumn
              [frozen]="col.frozen"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <div style="white-space: pre-line">
                {{ col.field | ngCellValue : row }}
              </div>
            </td>
          </ng-container>
          <td [width]="100" class="p-2" alignFrozen="right" pFrozenColumn>
            <div
              class="flex flex-grow-1 justify-content-center md:justify-content-end mt-2 md:mt-0 gap-2"
            >
              <button
                pButton
                pRipple
                icon="pi pi-pencil"
                pTooltip="{{ 'ENV.EDIT' | translate }}"
                routerLink="/configuration/user-group/edit/{{
                  row.user_group_id
                }}"
                class="p-button-text p-button-raised"
              ></button>
              <button
                pButton
                pRipple
                icon="pi pi-trash"
                pTooltip="{{ 'ENV.DELETE' | translate }}"
                (click)="inactivate(row)"
                class="p-button-text p-button-raised p-button-danger"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ "WIDGET.DT-EMPTY" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-sidebar
  [(visible)]="isColumnToggleVisible"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '100%', 'max-width': '20em' }"
>
  <app-prime-list-columns-toggle
    [(columns)]="columns"
  ></app-prime-list-columns-toggle>
</p-sidebar>
