/* eslint-disable @typescript-eslint/no-empty-function */
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { Component } from '@angular/core';

@Component({
  selector: 'app-course-unit-section-contents',
  templateUrl: './course-unit-section-contents.component.html',
  styleUrls: ['./course-unit-section-contents.component.sass']
})
export class CourseUnitSectionContentsComponent extends BaseItemDirective {
  refreshActionServices(): void {
  }
}
