import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Utils } from 'src/app/shared/utils/utils';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-report-take-evaluation-list',
  templateUrl: './report-take-evaluation-list.component.html',
  styleUrls: ['./report-take-evaluation-list.component.sass'],
})
export class ReportTakeEvaluationListComponent extends BaseListDirective {
  baseColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'CORE.USER', 350),
  ];
  override title = 'MENU.USERS';
  override filterableFields = [];
  override keys = {
    primary_key: 'user_id',
    label: 'name_',
  };
  override filename = 'MENU.USERS';
  override dataFormatter = this.utils.getCourseFormatter();

  group = null;
  course = null;
  groupsData = []
  coursesData = []
  tempData = [];

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(
      utils,
      data,
      route,
      dialog,
      dialogConfig,
      dialogRef
    )

    this.data.core.groups().subscribe(data => {
      this.groupsData = data
    })
    this.data.core.coursesSelectable().subscribe(data => {
      this.coursesData = data
    })



  }


  generateColumns() {
    this.availableColumns = [...this.baseColumns];
    this.testDetail = '';

    console.log(this.tempData);

    let temp = [];

    if (this.tempData.length > 0) {
      this.tempData.forEach(row => {
        this.availableColumns.push(
          new PrimeNGTableColumn(row.name_.replace(/\s/g, "_"), row.name_, 350, { frozen: false }, true),
        );
        temp.push(row.tests[0].test.poll.title);
      });



      this.testDetail = {
        poll: {
          title: temp.join(', ')
        }
      }

      this.availableColumns.push(
        new PrimeNGTableColumn('average', 'Promedio', 350, { frozen: false }),
      )

    }
    super.generateColumns();
  }



  formatData(data: any) {
    this.utils.applyFormat(data, this.dataFormatter);
  }

  override getList(): void {

    if (this.group && this.course) {
      this.query(this.data.core.reportTakeUnitcourses(
        {
          course_id: this.course,
          group_id: this.group
        }
      )).subscribe();
    }
  }


  generateColumnsUnits(data) {

    this.tempData = data;
    this.generateColumns()
  }

  searchTestByUser(data, user_id) {

    let aux = []



    for (let index = 0; index < data.length; index++) {

      let t = []
      let detalTest = null;

      data[index].tests.forEach(test => {
        detalTest = test.test
        t = test.takes.filter(take => take.poll_take.user.user_id === user_id)

      });



      if (t.length > 0) {
        aux[index] = {
          ...t[t.length - 1],
          result: parseFloat(t[t.length - 1].result),
          approved: parseFloat(t[t.length - 1].result) > parseFloat(detalTest.min_approval_percentage) ? 1 : 0,
          take_type: data[index].name_

        }
      } else {

        aux[index] = {
          result: 0,
          approved: 2,
          take_type: data[index].name_
        }
      }


    }
    return aux

  }


  override handleRows(data: any): void {


    let newData = [];


    data.forEach((row, idx) => {
      row.tests.forEach(test => {
        test.takes.forEach(take => {
          if (idx === 0) {

            let takes = this.searchTestByUser(data, take.poll_take.user.user_id)
            let avg = takes.filter(t => ((t.approved === 1 || t.approved === 0) && !isNaN(t.result)))

            console.log(take);

            if (newData.length === 0) {
              newData.push({
                name_: take.poll_take.user.first_name + ' ' + take.poll_take.user.last_name,
                user_id: take.poll_take.user.user_id,
                takes: takes,
                average: Number(((avg.reduce((total, current) => total + parseFloat(current.result), 0)) / avg.length).toPrecision(2)),
                title: take.poll_take.poll.title
              })

              takes.forEach((ta, f) => {
                newData[idx][ta.take_type.replace(/\s/g, "_")] = ta.result

              })
            } else {

              let existUser = newData.find(u => u.user_id === take.poll_take.user_id)
              if (!existUser) {
                newData.push({
                  name_: take.poll_take.user.first_name + ' ' + take.poll_take.user.last_name,
                  user_id: take.poll_take.user.user_id,
                  takes: this.searchTestByUser(data, take.poll_take.user.user_id),
                  average: Number((avg.reduce((total, current) => total + parseFloat(current.result), 0) / avg.length).toPrecision(2)),
                  title: take.poll_take.poll.title
                })

                takes.forEach((ta, f) => {
                  newData[newData.length - 1][ta.take_type.replace(/\s/g, "_")] = ta.result
                });
              }
            }
          }
        });
      });
    });

    console.log(newData);

    this.generateColumnsUnits(data);
    super.handleRows(newData);
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.userDelete(
      this.itemSelected[this.keys.primary_key]
    );
  }

  seeOnlyAvailableUsers(row: any) {
    super.handleItemSelected(row);

    this.utils.appDialog.seeCourseUserAvailableDialog(
      this.itemSelected[this.keys.primary_key],
      this.itemSelected[this.keys.label],
      true,
      {
        onSuccess: () => {
          this.getList();
        },
      }
    );
  }

  seeEnrollments(row: any) {
    this.utils.appDialog.seeCourseEnrollmentsDialog(row[this.keys.primary_key]);
  }

  exportValue(column, row): string {

    if (column.columnType) {
      return this.exportDataUnit(column, row)
    }
    if (column.capitalize) {
      return Utils.capitalizeEachWord(Utils.ngCellValue(column.field, row));
    }
    return Utils.ngCellValue(column.field, row);
  }


  exportDataUnit(column, row) {
    return row.takes.find(r => r.take_type === column.header).result

  }




}
