import { PercentPipe } from "@angular/common";
import { DateUtils } from "../shared/utils/date-utils";
import { Utils } from "../shared/utils/utils";
import { FormattableObjectProperty, ObjectPropertyFormat } from "./AppGenericClasses";
import { Globals } from "../globals";

export type ObjectFormatterConfigParam = {
  [key in ObjectPropertyFormat]?: { [key: string]: any }
}

export interface PercentageFormatConfig {
  multiplier?: number
  divider?: number
  digitsInfo?: string
}

export interface FlagFormatConfig {
  onTrueText?: string
  onFalseText?: string
}

export interface TimeFormatConfig {
  fromUnit?: { label: string, value: number }
  toUnit?: { label: string, value: number }
  fractionDigits?: number
}

export interface ObjectFormatterConfig extends ObjectFormatterConfigParam {
  PERCENTAGE?: PercentageFormatConfig
  FLAG?: FlagFormatConfig
  TIME?: TimeFormatConfig
}

export class ObjectFormatter {

  private globals = Globals
  formatConfig: ObjectFormatterConfig = {
    PERCENTAGE: {
      multiplier: 1,
      divider: 1,
      digitsInfo: '1.0-2'
    },
    'COMMA-SEPARATED': undefined,
    'DATE-FORMATTED': undefined,
    FLAG: {
      onTrueText: '',
      onFalseText: ''
    },
    'UPPER-CASE': undefined,
    CAPITALIZE: undefined,
    TIME: {
      fromUnit: this.globals.TIME_OPTIONS.MINUTES,
      toUnit: this.globals.TIME_OPTIONS.HOURS,
      fractionDigits: 2
    },
    DECIMAL: {
      decimals: 2
    }
  }
  props: FormattableObjectProperty[] = []

  constructor(formatOptions: ObjectFormatterConfig = {}) {
    Object.keys(this.formatConfig).forEach(key => {
      this.formatConfig[key] = { ...this.formatConfig[key], ...formatOptions[key] }
    });
  }

  applyFormat(data: any[]) {
    if (data) {
      data.forEach((obj) => {
        this.applyObjFormatOptions(obj);
      });
    }
  }

  applyObjFormatOptions(obj: any) {
    this.props.forEach((it) => {
      this.applyFormatOptions(obj, it);
    });
  }

  applyFormatOptions(obj: any, prop: FormattableObjectProperty) {
    obj[`original_${prop.field}`] = obj[prop.field] || '';
    if (
      !['string', 'number'].includes(
        typeof Utils.nestedValue(prop.field, obj)
      ) &&
      prop.field.split(',').length <= 1
    ) {
      return;
    }
    prop.formats?.forEach(format => {
      switch (format) {
        case 'COMMA-SEPARATED':
          this.handleCommaSeparated(prop, obj)
          break
        case 'DATE-FORMATTED':
          this.handleDateFormatted(prop, obj)
          break
        case 'FLAG':
          this.handleFlag(prop, obj)
          break
        case 'UPPER-CASE':
          this.handleUppercase(prop, obj)
          break
        case 'LOWER-CASE':
          this.handleLowercase(prop, obj)
          break
        case 'CAPITALIZE':
          this.handleCapitalize(prop, obj)
          break
        case 'TIME':
          this.handleTime(prop, obj)
          break
        case 'TIME-FORMATTED':
          this.handleTimeFormatted(prop, obj)
          break
        case 'PERCENTAGE':
          this.handlePercentage(prop, obj)
          break
        case 'DECIMAL':
          this.handleDecimal(prop, obj)
          break
        default:
          break
      }
    })
  }

  handleDateFormatted(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    let value = Utils.ngCellValue(key, obj);
    const dateParts = DateUtils.dateParts(value)
    obj[`${prop.field}_as_date_time`] = DateUtils.strToDateTime(value)
      .getTime();
    if (dateParts.time) {
      obj[`${prop.field}_date`] = dateParts.date;
      obj[`${prop.field}_time`] = dateParts.time;
      value = DateUtils.printDateFormattedWithTime(value);
    } else {
      value = DateUtils.printDateFormatted(Utils.ngCellValue(key, obj));
    }
    this.applyValue(obj, key, value);
  }

  handleCommaSeparated(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const value = Utils.ngCellValue(key, obj)
      .split(',')
      .filter((x) => x)
      .join(', ');
    this.applyValue(obj, key, value);
  }

  handleCapitalize(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const value = Utils.titleCase(Utils.ngCellValue(key, obj));
    this.applyValue(obj, key, value);
  }

  handleUppercase(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const value = Utils.ngCellValue(key, obj).toUpperCase();
    this.applyValue(obj, key, value);
  }

  handleLowercase(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const value = Utils.ngCellValue(key, obj).toLowerCase();
    this.applyValue(obj, key, value);
  }

  handleFlag(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const config = this.formatConfig.FLAG
    const value = Utils.getFlagYNValue(key, obj) ?
      config.onTrueText :
      config.onFalseText
    this.applyValue(obj, key, value);
  }

  handleTime(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const config = this.formatConfig.TIME
    const initialValue = +Utils.ngCellValue(key, obj)

    if (!isNaN(initialValue)) {
      const value = Utils.convertTimeUnit(
        initialValue,
        config.fromUnit.value,
        config.toUnit.value
      )

      let result = ''

      if (config.fractionDigits > 0) {
        result = value.toFixed(config.fractionDigits)
      } else {
        result = Math.round(value).toString()
      }

      this.applyValue(obj, key, `${result} ${config.toUnit.label}`);
    }
  }

  handleTimeFormatted(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const config = this.formatConfig.TIME
    const initialValue = +Utils.ngCellValue(key, obj)

    if (!isNaN(initialValue)) {
      const value = Utils.convertSecondsToHMS(
        Utils.convertTimeUnit(
          initialValue,
          config.fromUnit.value,
          1
        )
      )

      this.applyValue(obj, key, `${Utils.zero(value.hours)}h ${Utils.zero(value.minutes)}m ${Utils.zero(value.seconds)}s`);
    }
  }

  handlePercentage(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const config = this.formatConfig.PERCENTAGE
    let value = +Utils.ngCellValue(key, obj)

    if (!isNaN(value)) {
      if (config.multiplier) {
        value *= config.multiplier
      }
      if (config.divider) {
        value /= config.divider
      }

      const percentPipe = new PercentPipe('en-US')
      const formattedPercentage = percentPipe.transform(value, config.digitsInfo)
      this.applyValue(obj, key, formattedPercentage);
    }
  }

  handleDecimal(prop: FormattableObjectProperty, obj: any) {
    const key = prop.field;
    const config = this.formatConfig.DECIMAL
    const value = (+Utils.ngCellValue(key, obj)).toFixed(config.decimals);
    this.applyValue(obj, key, value);
  }

  applyValue(row: any, key: string, value: any) {
    if (key.includes('.')) {
      const objKeys = key.split('.')
      let tmpObject = row
      objKeys.forEach((k, idx) => {
        if (tmpObject) {
          if (idx === objKeys.length - 1) {
            tmpObject[k] = value
          } else {
            tmpObject = tmpObject[k]
          }
        }
      })
    }
    if (typeof value === 'string') {
      row[key] = value.trim()
    } else {
      row[key] = value
    }
  }
}
