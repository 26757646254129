import { Component } from '@angular/core';
import { BaseDirective } from 'ngx-lottie';

@Component({
  selector: 'app-test-version-all',
  templateUrl: './test-version-all.component.html',
  styleUrls: ['./test-version-all.component.sass']
})
export class TestVersionAllComponent extends BaseDirective {
   title = 'ENV.VERSIONS-OF';
   testName = localStorage.getItem('testName')


}
