import { Globals } from './../../globals';
import { Directive, Input, OnInit } from '@angular/core';
import { Storage } from "aws-amplify";
import { Utils } from 'src/app/shared/utils/utils';
import { FileUtils } from 'src/app/shared/utils/file-utils';

@Directive({
  selector: '[appImageViewerWrapper]'
})
export class ImageViewerWrapperDirective implements OnInit {
  @Input() path = ''
  @Input() id = 0
  @Input() field = ''

  image: any
  loading = false
  globals = Globals

  ngOnInit() {
    this.onInit()
  }

  onInit() {
    if (this.id > 0) {
      this.loading = true
      Storage.get(`${this.path}/${this.id}/${this.field}/resource.png`, {
        download: true,
        cacheControl: 'no-cache'
      }).then(result => {
        FileUtils.imageFromBlob(result.Body).then(image => this.image = image)
        this.loading = false
      }).catch(err => {
        this.loading = false
        Utils.error(err)
      })
    }
  }

}
