import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseListFilterablePrimeDirective } from 'src/app/directives/base-list-filterable-prime.directive';
import { GenericResponse, PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-user-picker-table',
  templateUrl: './user-picker-table.component.html',
  styleUrls: ['./user-picker-table.component.sass']
})
export class UserPickerTableComponent extends BaseListFilterablePrimeDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('dni', 'FORM.DNI', 150, { frozen: true }),
    new PrimeNGTableColumn('first_name,last_name', 'FORM.NAME', 300, { sortable: 'first_name', filterable: true }),
    new PrimeNGTableColumn('credentials.type.name_', 'CORE.ROLE', 300),
    new PrimeNGTableColumn('group.name_', 'MENU.GROUP', 300, { filterable: true }),
    new PrimeNGTableColumn('post.name_', 'FORM.SPECIALTY', 300, { filterable: true }),
  ];
  override title = 'FORM.AVAILABLE-USERS';
  override filterableFields = ['dni', 'first_name', 'last_name']
  override keys = {
    primary_key: 'user_id',
    label: 'first_name,last_name'
  }
  override filename = 'FORM.AVAILABLE-USERS';

  @Input() parentId = 0
  @Output() formSubmitted = new EventEmitter<any>()

  form = {
    objects: []
  }
  saveService!: Observable<any>

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.parentId = this.dialogConfig.data.parentId ?? this.parentId
  }

  override refreshActionServices(): void { }

  override getList(): void {
    this.query(this.data.core.usersBasic()).subscribe()
  }

  save() {
    this.form.objects = this.selectedRows.map(item => {
      return { user_id: item.user_id }
    })
    this.refreshActionServices()
    if (this.saveService) {
      this.processing = true;
      this.utils.executeRequest(
        this.saveService,
        {
          onSuccess: () => {
            this.utils.toast.saved(this.title)
            this.processing = false
            this.dialogRef.close({
              success: true,
              complement: this.form
            } as GenericResponse)
          }
        }
      )
    } else {
      this.formSubmitted.emit(this.form)
      this.dialogRef.close({
        success: false,
        complement: this.form
      } as GenericResponse)
    }
  }
}

@Component({
  selector: 'app-library-user-picker-table',
  templateUrl: './user-picker-table.component.html',
  styleUrls: ['./user-picker-table.component.sass']
})
export class LibraryUserPickerTableComponent extends UserPickerTableComponent {

  override getList(): void {
    this.query(this.data.core.libraryUsersAvailable(this.parentId)).subscribe()
  }

  override handleRows(data: any): void {
    super.handleRows(data)
    this.rows.forEach((row: any) => {
      if (row.libraries_count > 0) {
        this.selectedRows = [...this.selectedRows, row]
      }
    })
  }

  override refreshActionServices() {
    this.saveService = this.data.core.libraryUserAvailableCreateBatch({ ...this.form, library_id: this.parentId })
  }
}

@Component({
  selector: 'app-course-user-picker-table',
  templateUrl: './user-picker-table.component.html',
  styleUrls: ['./user-picker-table.component.sass']
})
export class CourseUserPickerTableComponent extends UserPickerTableComponent {
  @Input() onlyAvailable = false

  override getList(): void {
    this.query(this.data.core.courseUsersAvailable(this.parentId)).subscribe()
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.onlyAvailable = this.dialogConfig.data.onlyAvailable ?? this.onlyAvailable
  }

  override handleRows(data: any): void {
    super.handleRows(data)

    this.selectedRows = this.rows.filter(row => row.courses_count > 0)

    if (this.onlyAvailable) { this.rows = this.selectedRows }
  }

  override refreshActionServices() {
    this.saveService = this.data.core.courseUserAvailableCreateBatch({ ...this.form, el_course_id: this.parentId })
  }
}

@Component({
  selector: 'app-test-version-user-picker-table',
  templateUrl: './user-picker-table.component.html',
  styleUrls: ['./user-picker-table.component.sass']
})
export class TestVersionUserPickerTableComponent extends UserPickerTableComponent {

  override getList(): void {
    this.query(this.data.core.testVersionUsersAvailable(this.parentId)).subscribe()
  }

  override handleRows(data: any): void {
    super.handleRows(data)
    this.rows.forEach((row: any) => {
      if (row.el_test_versions_count > 0) {
        this.selectedRows = [...this.selectedRows, row]
      }
    })
    console.log(data)
  }

  override refreshActionServices() {
    this.saveService = this.data.core.testVersionUserAvailableCreateBatch({ ...this.form, el_test_version_id: this.parentId })
  }
}
