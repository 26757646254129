import { AppJSONSanitizer } from "./AppGenericClasses"
import { TestTake, TestTakeJSON } from "./TestTakeClasses"

export class TestTakeUnitJSON {
  el_test_take_unit_id = 0
  el_course_unit_take_id = 0
  el_test_take_id = 0
  attempt = 0
  approval_required = 0
  result = 0
  take: TestTakeJSON = new TestTakeJSON()
}

export class TestTakeUnit {
  id: number
  courseUnitTakeId: number
  testTakeId: number
  attempt: number
  approvalRequired: boolean
  result: number
  take: TestTake

  source: TestTakeUnitJSON

  constructor(source: TestTakeUnitJSON) {
    this.source = new AppJSONSanitizer<TestTakeUnitJSON>(source, TestTakeUnitJSON).result
    this.id = this.source.el_test_take_unit_id
    this.courseUnitTakeId = this.source.el_course_unit_take_id
    this.attempt = this.source.attempt
    this.take = new TestTake(this.source.take)
    this.approvalRequired = !!this.source.approval_required
    this.result = this.source.result
  }
}
