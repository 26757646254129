import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CoreDataService {
  constructor(private api: ApiService) { }
  //contenido
  contents() {
    return this.api.get('/content/web/EL')
  }

  contentUnique(id: number) {
    return this.api.unique(id, '/content')
  }

  wildcards() {
    return this.api.get('/wildcard');
  }

  wildcardToSelect() {
    return this.api.get('/wildcard')
  }

  wildcardUnique(id: number) {
    return this.api.unique(id, '/wildcard')
  }

  wildcardCreate(data: object) {
    return this.api.post('/wildcard', data)
  }

  wildcardUpdate(id: number, data: object) {
    return this.api.put(id, '/wildcard', data)
  }

  wildcardDelete(id: number) {
    return this.api.delete(id, '/wildcard')
  }

  contentCreate(data: object) {
    return this.api.post('/content', data)
  }

  contentUpdate(id: number, data: object) {
    return this.api.put(id, '/content', data)
  }

  contentDelete(id: number) {
    return this.api.delete(id, '/content')
  }

  contentActivate(id: number) {
    return this.api.put(id, '/content', { status_: 1 })
  }

  preRequisiteDelete(parentId: number, childId) {
    return this.api.post('/course/prerequisite/delete', { parentId, childId })
  }
  //colores
  colors() {
    return this.api.get('/color')
  }

  colorUnique(id: number) {
    return this.api.unique(id, '/color')
  }

  colorCreate(data: object) {
    return this.api.post('/color', data)
  }

  colorUpdate(id: number, data: object) {
    return this.api.put(id, '/color', data)
  }

  colorDelete(id: number) {
    return this.api.delete(id, '/color')
  }
  //ciudades
  cities() {
    return this.api.get('/city/web')
  }

  cityUnique(id: number) {
    return this.api.unique(id, '/city')
  }

  cityCreate(data: object) {
    return this.api.post('/city', data)
  }

  cityUpdate(id: number, data: object) {
    return this.api.put(id, '/city', data)
  }

  cityDelete(id: number) {
    return this.api.delete(id, '/city')
  }
  //géneros
  genders() {
    return this.api.get('/gender/web')
  }
  userType() {
    return this.api.get('/user/type/selectable')
  }
  userNotifications() {
    return this.api.get('/user/notifications')
  }

  userNotificationDelete(id: number) {
    return this.api.delete(id, '/user/notifications')
  }



  genderUnique(id: number) {
    return this.api.unique(id, '/gender')
  }

  genderCreate(data: object) {
    return this.api.post('/gender', data)
  }

  genderUpdate(id: number, data: object) {
    return this.api.put(id, '/gender', data)
  }

  genderDelete(id: number) {
    return this.api.delete(id, '/gender')
  }
  //Tipos de documentos
  DNITypes() {
    return this.api.get('/dni-type/web')
  }

  DNITypeUnique(id: number) {
    return this.api.unique(id, '/dni-type')
  }


  DNITypeCreate(data: object) {
    return this.api.post('/dni-type', data)
  }

  DNITypeUpdate(id: number, data: object) {
    return this.api.put(id, '/dni-type', data)
  }

  DNITypeDelete(id: number) {
    return this.api.delete(id, '/dni-type')
  }

  coursePrerrequisitePOST(data: object) {
    return this.api.post('/course/prerrequisite/ElCoursePrerrequisite', data)
  }

  //usuarios
  users() {
    return this.api.get('/user')
  }

  userUnique(id: number) {
    return this.api.unique(id, '/user')
  }

  userCreate(data: object) {
    return this.api.post('/user', data)
  }

  userUpdate(id: number, data: object) {
    return this.api.put(id, '/user', data)
  }

  userDelete(id: number) {
    return this.api.delete(id, '/user')
  }

  userTypes() {
    return this.api.get('/user/type')
  }

  userTypeUnique(id: number) {
    return this.api.unique(id, '/user/type')
  }

  userTypeCreate(data: object) {
    return this.api.post('/user/type', data)
  }

  userTypeUpdate(id: number, data: object) {
    return this.api.put(id, '/user/type', data)
  }

  userTypeDelete(id: number) {
    return this.api.delete(id, '/user/type')
  }

  //usuarios pendientes
  pendingUsers() {
    return this.api.get('/user/web/auth-pending')
  }

  userEnable(data: object) {
    return this.api.post('/user/auth-enable', data)
  }

  userReject(data: object) {
    return this.api.post('/user/auth-reject', data)
  }

  //grupo
  groups() {
    return this.api.get('/user/group')
  }

  groupUnique(id: number) {
    return this.api.unique(id, '/user/group')
  }

  groupCreate(data: object) {
    return this.api.post('/user/group', data)
  }

  groupUpdate(id: number, data: object) {
    return this.api.put(id, '/user/group', data)
  }

  groupDelete(id: number) {
    return this.api.delete(id, '/user/group')
  }
  //cargos
  specialties() {
    return this.api.get('/user/post')
  }

  specialtyUnique(id: number) {
    return this.api.unique(id, '/user/post')
  }

  specialtyCreate(data: object) {
    return this.api.post('/user/post', data)
  }

  specialtyUpdate(id: number, data: object) {
    return this.api.put(id, '/user/post', data)
  }

  specialtyDelete(id: number) {
    return this.api.delete(id, '/user/post')
  }

  templateToSelect() {
    return this.api.get('/template')
  }



  templateUnique(id: number) {
    return this.api.unique(id, '/template')
  }

  templateCreate(data: object) {
    return this.api.post('/template', data)
  }

  templateUpdate(id: number, data: object) {
    return this.api.put(id, '/template', data)
  }

  templateDelete(id: number) {
    return this.api.delete(id, '/template')
  }

  colorsToSelect() {
    return this.api.get('/color/to-select')
  }

  availablePrerequisites(id: number) {
    return this.api.get('/course/available-prerequisites/' + id)
  }

  courses() {
    return this.api.get('/course/web')
  }

  coursesSelectable() {
    return this.api.get('/course/selectable')
  }


  reportTakeUnitcourses(data) {
    return this.api.post('/course/unit/report', data)
  }

  reportTakeUserList(data) {
    return this.api.post('/course/unit/report/by/unit-test', data)
  }

  Clonecourses(data) {
    return this.api.post('/course/clone', data)
  }

  coursesWithUserEnrollment() {
    return this.api.get('/course/with/user-enrollments')
  }

  pendingAvailableUserCourses() {
    return this.api.get('/course/pending/available/user')
  }

  coursesFilter(filters: object) {
    return this.api.post('/course/filter', filters)
  }

  inactiveCourses() {
    return this.api.post('/course/filter', { status_: 0 })
  }

  inactiveContents() {
    return this.api.post('/content/filter', { status_: 0 })
  }

  pendingUserCourses(id: number) {
    return this.api.get('/course/pending-users/' + id)
  }

  testTakes(id: number) {
    return this.api.get('/test/takes/' + id)
  }

  gradedTestTakes(id: number) {
    return this.api.get('/test/takes/graded/' + id)
  }

  pendingGradedTestTakes(id: number) {
    return this.api.get('/test/takes/pending/graded/' + id)
  }

  testTake(id: number) {
    return this.api.get('/test/take/mobile/' + id)
  }

  testTakeUnique(id: number) {
    return this.api.unique(id, '/test/take')
  }

  testTakeUpdateResult(data: object) {
    return this.api.post('/test/take/update/result', data)
  }

  unit(id) {
    return this.api.get('/course/unit/' + id)
  }

  units(id) {
    return this.api.get('/course/units/' + id)
  }

  unitTests(id) {
    return this.api.get('/course/unit/tests/' + id)
  }

  unitPost(data: object) {
    return this.api.post('/course/unit', data)
  }

  sectionPost(data: object) {
    return this.api.post('/course/unit/section', data)
  }

  supportSectionsContent(id) {
    return this.api.get('/course/unit/section/support-contents/' + id)
  }

  sectionUpdate(id: number, data: object) {
    return this.api.put(id, '/course/unit/section', data)
  }

  sectionUpdateInBatch(data: object) {
    return this.api.post('/course/unit/section/update/batch', data)
  }

  unitUpdate(id: number, data: object) {
    return this.api.put(id, '/course/unit', data)
  }

  unitUpdateInBatch(data: object) {
    return this.api.post('/course/unit/update/batch', data)
  }

  coursesByTopic(topicId: string | number) {
    return this.api.get('/topic/courses/' + topicId)
  }

  courseCreate(data: object) {
    return this.api.post('/course', data)
  }

  courseUpdate(id: number, data: object) {
    return this.api.put(id, '/course', data)
  }

  unitDelete(id: number) {
    return this.api.delete(id, '/course/unit')
  }

  unitTestDelete(id: number) {
    return this.api.delete(id, '/course/unit/test')
  }

  sectionSupportContentDelete(data) {
    return this.api.post('/course/unit/section/support-content/delete', data)
  }

  unitTestPost(data) {
    return this.api.post('/course/unit/test', data)
  }
  unitTestBatch(data) {
    return this.api.post('/course/unit/test/batch', data)
  }

  supportSectionContentBatch(data) {
    return this.api.post('/course/unit/section/support-content/batch', data)
  }

  courseDelete(id: number) {
    return this.api.delete(id, '/course')
  }

  courseActivate(id: number) {
    return this.api.put(id, '/course', { status_: 1 })
  }

  sectionDelete(id: number) {
    return this.api.delete(id, '/course/unit/section')
  }

  sectionUnique(id: number) {
    return this.api.unique(id, '/course/unit/section')
  }
  unitUnique(id: number) {
    return this.api.unique(id, '/course/unit')
  }

  unitSection(id: number) {
    return this.api.unique(id, '/course/unit/section')
  }

  unitTestCreate(data: object) {
    return this.api.post('/course/unit/test', data)
  }

  unitTestUpdate(id: number, data: object) {
    return this.api.put(id, '/course/unit/test', data)
  }

  unitTestUnique(id: number) {
    return this.api.unique(id, '/course/unit/test')
  }

  unitTakeTestTakes(id) {
    return this.api.get('/test/take/by/unit-take/' + id)
  }

  testVersionTestTakes(id) {
    return this.api.get('/test/take/by/version/' + id)
  }

  courseUnique(id: number) {
    return this.api.unique(id, '/course')
  }

  courseUserAvailableCreateBatch(data: { el_course_id: number, objects: any[] }) {
    return this.api.post('/course/user-available/batch', data)
  }

  courseUsersAvailable(id: number) {
    return this.api.get('/user/course-users-available/' + id)
  }

  finishCourse(data: any) {
    return this.api.post('/course/enrollment/finish', data)
  }

  libraries() {
    return this.api.get('/library/web/EL')
  }

  libraryUsers(id: string | number) {
    return this.api.get('/library/user-available/' + id)
  }

  libraryCreate(data: object) {
    return this.api.post('/library', data)
  }

  libraryUpdate(id: number, data: object) {
    return this.api.put(id, '/library', data)
  }

  libraryDelete(id: number) {
    return this.api.delete(id, '/library')
  }

  libraryUnique(id: number) {
    return this.api.unique(id, '/library')
  }

  libraryUniqueBasic(id: number) {
    return this.api.unique(id, '/library/basic')
  }

  libraryContents(id: string | number) {
    return this.api.get('/library/content/' + id)
  }

  libraryContentCreate(data: object) {
    return this.api.post('/library/content', data)
  }

  libraryContentCreateBatch(data: { objects: any[] }) {
    return this.api.post('/library/content/batch', data)
  }

  libraryUserAvailableCreateBatch(data: { library_id: number, objects: any[] }) {
    return this.api.post('/library/user-available/batch', data)
  }

  libraryUsersAvailable(id: number) {
    return this.api.get('/user/library-users-available/' + id)
  }

  libraryContentUpdate(id: number, data: object) {
    return this.api.put(id, '/library/content', data)
  }

  libraryContentDelete(parentId: number, childId: number) {
    return this.api.post('/library/content/delete', { parentId, childId })
  }

  prerequisited(id) {
    return this.api.unique(id, '/course/prerequisite');
  }

  prerequisitedSave(data) {
    return this.api.post('/course/prerequisite/batch', data);
  }

  testQuestionContentCreateBatch(data: { poll_question_id: number, objects: any[] }) {
    return this.api.post('/test/question/content/batch', data)
  }

  testQuestions() {
    return this.api.get('/test/question')
  }

  testQuestionsOfPoll(pollId: string | number) {
    return this.api.get('/test/question/of-poll/' + pollId)
  }

  testQuestionUnique(id: number) {
    return this.api.unique(id, '/test/question')
  }

  testQuestionCreate(data: object) {
    return this.api.post('/test/question', data)
  }

  testQuestionUpdate(id: number, data: object) {
    return this.api.put(id, '/test/question', data)
  }

  testQuestionUpdateInBatch(data: object) {
    return this.api.post('/test/question/update/batch', data)
  }

  testQuestionDelete(id: number) {
    return this.api.delete(id, '/test/question')
  }

  tests() {
    return this.api.get('/test/web')
  }

  testsWithoutOpenQuestions() {
    return this.api.get('/test/without-open-questions')
  }

  testsWithOpenQuestions() {
    return this.api.get('/test/with-open-questions')
  }

  testUnique(id: number) {
    return this.api.unique(id, '/test')
  }

  testUniqueOfUnit(id: number) {
    return this.api.unique(id, '/test/by/unit-test')
  }

  testCreate(data: object) {
    return this.api.post('/test', data)
  }

  testUpdate(id: number, data: object) {
    return this.api.put(id, '/test', data)
  }

  testDelete(id: number) {
    return this.api.delete(id, '/test')
  }

  testsVersions() {
    return this.api.get('/test/version/web')
  }

  testsVersionsOfUser() {
    return this.api.get('/test/version/web/user-available')
  }

  testVersionsWeb(id: number) {
    return this.api.get('/test/test-versions/' + id)
  }

  removeTest(id) {
    return this.api.delete(id, ' /course/unit/test')
  }

  testVersionUnique(id: number) {
    return this.api.unique(id, '/test/version')
  }

  testVersionCreate(data: object) {
    return this.api.post('/test/version', data)
  }

  testVersionUpdate(id: number, data: object) {
    return this.api.put(id, '/test/version', data)
  }

  testVersionDelete(id: number) {
    return this.api.delete(id, '/test/version')
  }

  testVersionUserAvailableCreateBatch(data: { el_test_version_id: number, objects: any[] }) {
    return this.api.post('/test/version/user-available/batch', data)
  }

  testVersionUsersAvailable(id: number) {
    return this.api.get('/user/el-test-version-users-available/' + id)
  }

  unitSections(id: number) {
    return this.api.get('/course/unit/sections/' + id)
  }

  topics() {
    return this.api.get('/topic/web')
  }

  topicUnique(id: number) {
    return this.api.unique(id, '/topic')
  }

  topicCreate(data: object) {
    return this.api.post('/topic', data)
  }

  topicUpdate(id: number, data: object) {
    return this.api.put(id, '/topic', data)
  }

  topicDelete(id: number) {
    return this.api.delete(id, '/topic')
  }

  usersBasic() {
    return this.api.get('/user/web/basic')
  }

  fullCourse(id: number) {
    return this.api.get('/course/full/' + id)
  }

  enrolledUserinCourse(idCourse: number, idUser: number) {
    return this.api.get(`/course/enrollment/is-studying/${idCourse}/${idUser}`)
  }

  courseLatestEnrollments(id: number) {
    return this.api.get(`/course/enrollments/latest/${id}`)
  }

  courseEnrollmentPrerequisitesCompleted(id: number) {
    return this.api.get(`/course/enrollment/prerequisite-completed/${id}`)
  }

  courseEnrollmentAnnul(id: object) {
    return this.api.post('/course/enrollment/annul', id)
  }

  
  courseEnrollmentRestore(id: object) {
    return this.api.post('/course/enrollment/restore', id)
  }

  courseEnrollmentPOST(data: object) {
    return this.api.post('/course/enrollment', data)
  }

  courseEnrollmentCurrent(data: object) {
    return this.api.post('/course/enrollment/current', data)
  }

  courseEnrollmentUnitCreate(data: object) {
    return this.api.post('/course/enrollment/unit', data)
  }

  courseEnrollmentUnitUpdate(id: number, data: object) {
    return this.api.put(id, '/course/enrollment/unit', data)
  }

  courseEnrollmentalidateStatus(id) {
    return this.api.get(`/course/enrollment/validate-finalization/${id}`)
  }

  courseEnrollmentUnitUpdateStatus(data: object) {
    return this.api.post('/course/enrollment/unit/status', data)
  }

  courseEnrollmentUnitTakes(data: object) {
    return this.api.post('/course/enrollment/unit/takes', data)
  }

  courseEnrollmentUnitSectionCreate(data: object) {
    return this.api.post('/course/enrollment/unit/section/web', data)
  }

  courseEnrollmentUnitSectionCompletion(data: object) {
    return this.api.post('/course/enrollment/unit/section/completion', data)
  }

  courseEnrollmentUnitStatus(data: object) {
    return this.api.post('/course/enrollment/unit/status', data)
  }

  getPercentages(id: number) {
    return this.api.get('/course/remaining-percentage/' + id)
  }

  viewTakeForm(id: number) {
    return this.api.get('/test/view/take/' + id)
  }

  viewTakeFormInCourse(id: number) {
    return this.api.get('/test/view/take/in-course/' + id)
  }

  viewRateTakeForm(id: number) {
    return this.api.get('/test/view/take/rate/' + id)
  }

  viewTakeDetail(id: number) {
    return this.api.get('/test/view/take/detail/' + id)
  }

  initTestTake(data: object) {
    return this.api.post('/test/take', data)
  }

  finishTestTake(data: object) {
    return this.api.post('/test/take/finish', data)
  }

  notifyCourse(id: number) {
    return this.api.get(`/course/notification/${id}`)
  }

  notifyTestVersion(id: number) {
    return this.api.get(`/test/version/notification/${id}`)
  }
}
