import { Utils } from 'src/app/shared/utils/utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zero'
})
export class ZeroPipe implements PipeTransform {

  transform(num: number): unknown {
    return Utils.zero(num);
  }

}
