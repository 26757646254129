import { Utils } from "./utils"

export class DevUtils {
    static printTables(bdTables: string[]) {
        let script = ""
        bdTables.forEach(table => {
            script += `delete ${table.trim()} from ${table.trim()};\n`
        })
        Utils.log(script)
    }

    static printTablesToReset() {
        this.printTables([
            '	poll_take_question',
            '	poll_take_answer_selection',
            '	poll_take_answer_related',
            '	poll_take_answer_open',
            '	poll_take',
            '	poll_question_content',
            '	poll_question',
            '	poll_answer_selection',
            '	poll_answer_related_value',
            '	poll_answer_related',
            '	poll',
            '	library_user_available',
            '	library_content',
            '	library',
            '	el_topic',
            '	el_test_version_user_available',
            '	el_test_version',
            '	el_test_take_version',
            '	el_test_take_unit',
            '	el_test_take_course',
            '	el_test_take',
            '	el_test',
            '	el_course_user_available',
            '	el_course_unit_test',
            '	el_course_unit_take',
            '	el_course_unit_section_take',
            '	el_course_unit_section_support_content',
            '	el_course_unit_section',
            '	el_course_unit',
            '	el_course_topic',
            '	el_course_test',
            '	el_course_prerequisite',
            '	el_course_enrollment_finalization',
            '	el_course_enrollment',
            '	el_course',
            '	content'
        ])
    }

    static printTestTakeTablesToReset() {
        this.printTables([
            '	poll_take_question',
            '	poll_take_answer_selection',
            '	poll_take_answer_related',
            '	poll_take_answer_open',
            '	poll_take',
            '	el_test_take_version',
            '	el_test_take_unit',
            '	el_test_take_course',
            '	el_test_take',
            '	el_course_unit_take',
            '	el_course_unit_section_take'
        ])
    }
}