/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, ViewChild } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { AWSUtils } from 'src/app/shared/utils/aws-utils';
import { ContentTriggerButtonComponent } from '../content-trigger-button/content-trigger-button.component';
import { Content } from 'src/app/models/AppModels';

@Component({
  selector: 'app-course-unit-section-content-item',
  templateUrl: './course-unit-section-content-item.component.html',
  styleUrls: ['./course-unit-section-content-item.component.sass']
})
export class CourseUnitSectionContentItemComponent extends BaseItemDirective {

  @ViewChild('contentTrigger') contentTrigger!: ContentTriggerButtonComponent

  @Input() content!: Content

  refreshActionServices(): void { }

  override onItemReady(): void {
    super.onItemReady()
    this.content = new Content(this.item)
  }

  viewSupportContent(resource: Content) {
    if (this.fireActions) {
      AWSUtils.initResourceData(resource.source, 'content')
      this.contentTrigger.item = resource.source
      this.contentTrigger.seeResource()
    }
  }
}
