<p-columnFilter [field]="field" [matchModeOptions]="matchModeOptions" [matchMode]="'specialty'" display="menu"
    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
    <ng-template pTemplate="header">
        <div class="px-3 pt-3 pb-0">
            <span class="p-text-bold">{{ 'FORM.SPECIALTY' | translate }}</span>
        </div>
    </ng-template>
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <p-multiSelect [ngModel]="value" [options]="sources['specialty'].data"
            placeholder="{{ 'FORM.SELECT-A' | translate }} {{ ( 'FORM.SPECIALTY' | translate).toLowerCase() }}"
            (onChange)="filter($event.value)" optionLabel="{{ sources['specialty'].label }}"
            selectedItemsLabel="{0} {{ ('FORM.SPECIALTY' | translate).toLowerCase() }} {{ ('FORM.SELECTED' | translate).toLowerCase() }}"
            [maxSelectedLabels]="1"></p-multiSelect>
    </ng-template>
</p-columnFilter>