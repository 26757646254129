import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class ConfigDataService {

  constructor(
    private api: ApiService
  ) { }

  refresh() {
    this.api.refresh()
  }

  initial() {
    return this.api.get('/app-config/initial')
  }

}
