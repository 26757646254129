import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../shared/utils/utils';

@Pipe({
  name: 'nestedValue'
})
export class NestedValuePipe implements PipeTransform {

  transform(key: string, row: any): any {
    return Utils.nestedValue(key, row);
  }

}
