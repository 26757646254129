<div class="flex flex-column h-full">
    <div class="flex" style="height: auto">
        <div class="flex flex-column justify-content-start">
            <h2 class="module-title cursor-pointer" routerLink="/configuration/color">
                {{ "MENU.COLORS" | translate | uppercase }}
            </h2>
        </div>
        <div class="flex mx-3">
            <i class="pi pi-angle-double-right" style="font-size: 2rem; color: var(--primary-color)"></i>
        </div>
        <div class="flex flex-grow-1 flex-column justify-content-start">
            <h2 class="module-title">
                {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW") | translate }}
                {{ "CORE.COLOR" | translate | lowercase }}
            </h2>
        </div>
        <div class="flex flex-grow-1 flex-initial align-items-center justify-content-end">
            <button pButton pRipple class="p-button-text" icon="pi pi-cloud-upload" label="{{ 'ENV.SAVE' | translate }}"
                [disabled]="
            !dataForm.valid
          " (click)="dataForm.ngSubmit.emit()" *ngIf="!processing"></button>
        </div>
    </div>
    <p-divider styleClass="divider-slim"></p-divider>
    <div class="flex flex-grow-1 relative">
        <div class="card-form absolute h-full w-full">
            <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
                <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
                    <div class="flex flex-column lg:mb-4">
                        <div class="flex flex-column flex-grow-1">
                            <div class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
                                <input pInputText id="title" name="title" class="ng-dirty" aria-describedby="title-help"
                                    placeholder="{{ 'FORM.NAME' | translate }} del {{ 'CORE.COLOR'| translate | lowercase}}"
                                    [(ngModel)]="form.name_" maxlength="100" required />
                                <label for="title">{{ "FORM.NAME" | translate }}</label>

                            </div>
                        </div>
                        <div class="flex flex-row gap-2 grid mt-4 lg:mt-0 mb-4 sm:justify-content-center">

                            <div class="flex flex-column align-items-center justify-content-center mr-auto">
                                <label class="mb-2" for="surface_dark">{{ "FORM.COLOR-SURFACE-DARK" | translate
                                    }}</label>
                                <p-colorPicker [(ngModel)]="form.surface_dark" name="dark-color" class="mb-2"
                                    [inline]="true" (onChange)="updateInputBackground()"></p-colorPicker>
                                <div class="flex flex-row p-inputgroup">
                                    <span id="dark-span" class="p-inputgroup-addon"><p-checkbox
                                            [(ngModel)]="form.surface_dark"
                                            [style.background]="form.surface_dark"></p-checkbox>
                                    </span>
                                    <input pInputText id="surface_dark" name="surface_dark" class="ng-dirty"
                                        aria-describedby="title-help"
                                        placeholder="{{ 'FORM.COLOR-SURFACE-DARK' | translate }}"
                                        [(ngModel)]="form.surface_dark" required maxlength="8" class="p-inputtext-sm" />
                                </div>
                            </div>

                            <div class="flex flex-column align-items-center justify-content-center mr-auto">
                                <label class="mb-2" for="surface_light">{{ "FORM.COLOR-SURFACE-LIGHT" | translate
                                    }}</label>
                                <p-colorPicker [(ngModel)]="form.surface_light" name="light-color" [inline]="true"
                                    (onChange)="updateInputBackground()" class="mb-2"></p-colorPicker>
                                <div class="flex flex-row p-inputgroup">
                                    <span id="light-span" class="p-inputgroup-addon"><p-checkbox
                                            [(ngModel)]="form.surface_light"
                                            [style.background]="form.surface_light"></p-checkbox>
                                    </span>
                                    <input pInputText id="surface_light" name="surface_light" class="ng-dirty"
                                        aria-describedby="title-help" [(ngModel)]="form.surface_light"
                                        placeholder="{{ 'FORM.COLOR-SURFACE-LIGHT' | translate }}" required
                                        maxlength="8" class="p-inputtext-sm " />
                                </div>
                            </div>
                            <div class="flex flex-column align-items-center justify-content-center mr-auto">
                                <label class="mb-2" for="text_color_dark">{{ "FORM.COLOR-TEXT-DARK" | translate
                                    }}</label>
                                <p-colorPicker [(ngModel)]="form.text_color_dark" class="mb-2" name="text-dark-color"
                                    [inline]="true" (onChange)="updateInputBackground()"></p-colorPicker>
                                <div class="flex flex-row p-inputgroup">
                                    <span id="dark-text-span" class="p-inputgroup-addon"><p-checkbox
                                            [(ngModel)]="form.text_color_dark"
                                            [style.background]="form.text_color_dark"></p-checkbox>
                                    </span>
                                    <input pInputText id="text_color_dark" name="text_color_dark" class="ng-dirty"
                                        aria-describedby="title-help" [(ngModel)]="form.text_color_dark"
                                        placeholder="{{ 'FORM.COLOR-TEXT-DARK' | translate }}" required maxlength="8"
                                        class="p-inputtext-sm" />
                                </div>
                            </div>
                            <div class="flex flex-column align-items-center justify-content-center mr-auto">
                                <label class="mb-2" for="text_color_light">{{ "FORM.COLOR-TEXT-LIGHT" | translate
                                    }}</label>
                                <p-colorPicker [(ngModel)]="form.text_color_light" class="mb-2" name="text-color-light"
                                    [inline]="true" (onChange)="updateInputBackground()"></p-colorPicker>
                                <div class="flex flex-row p-inputgroup">
                                    <span id="light-text-span" class="p-inputgroup-addon"><p-checkbox
                                            [(ngModel)]="form.text_color_light"
                                            [style.background]="form.text_color_light"></p-checkbox>
                                    </span>
                                    <input pInputText id="text_color_light" name="text_color_light" class="ng-dirty"
                                        aria-describedby="title-help" [(ngModel)]="form.text_color_light"
                                        placeholder="{{ 'FORM.COLOR-TEXT-LIGHT' | translate }}" required maxlength="8"
                                        class="p-inputtext-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </p-scrollPanel>
        </div>
    </div>
</div>