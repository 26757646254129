import { PercentPipe } from "@angular/common"
import { UtilsService } from "../services/utils/utils.service"
import { Utils } from "../shared/utils/utils"
import { Globals } from "../globals"
import { TestJSON, TestQuestionJSON } from "./AppSources"

export class TestFormatter {
  utils: UtilsService
  timeLimit_: number
  minApprovalPercentage_: number
  randomQuestions_: number

  constructor(source: TestJSON, utils: UtilsService) {
    this.utils = utils
    this.timeLimit_ = source.time_limit
    this.minApprovalPercentage_ = source.min_approval_percentage
    this.randomQuestions_ = source.random_questions
  }

  get timeLimit(): string {
    const value = Utils.convertTimeUnit(
      this.timeLimit_,
      Globals.TIME_OPTIONS.SECONDS.value,
      Globals.TIME_OPTIONS.MINUTES.value
    )
    return `${value.toFixed(1)} ${this.utils.translate.instant(Globals.TIME_OPTIONS.MINUTES.label)}`.toLowerCase()
  }

  get minApprovalPercentage(): string {
    return new PercentPipe('en-US').transform(
      this.minApprovalPercentage_ / 100,
      '1.2'
    )
  }

  get randomQuestions(): string {
    return this.utils.getFlagText(this.randomQuestions_)
  }
}

export class TestQuestionFormatter {
  utils: UtilsService
  type_: string

  constructor(source: TestQuestionJSON, utils: UtilsService) {
    this.utils = utils
    this.type_ = source.type_
  }

  get type(): string {
    return this.utils.translate.instant(`FORM.${this.type_}`)
  }
}
