import { Component } from '@angular/core';
import { AppMenuItem } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})
export class AdminComponent {
  title = 'MENU.ADMINISTRATION'
  menuItems: AppMenuItem[] = [
    new AppMenuItem('MENU.TESTS', { routerLink: '/admin/test', description_: 'MENU.DESC-TESTS' })
  ]
}
