<div
  class="col p-0 relative app-vg-overlay-play-container"
  style="background: gray; height: 100%"
  (click)="seeResource()"
>
  <div class="app-vg-overlay-play absolute center-relatively">
    <div class="app-vg-overlay-play-inner"></div>
  </div>
</div>

<p-dialog
  [(visible)]="showResource"
  [modal]="true"
  [maximizable]="true"
  [style]="{ width: '70vw', heigth: '70vh' }"
  [draggable]="false"
>
  <app-aws-video-player
    *ngIf="showResource"
    [resourceUrl]="resourceUrl"
    [autoPlay]="autoPlay"
  ></app-aws-video-player>
</p-dialog>
