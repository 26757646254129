import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AppExportableList } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-prime-ng-export-table',
  templateUrl: './prime-ng-export-table.component.html',
  styleUrls: ['./prime-ng-export-table.component.sass']
})
export class PrimeNgExportTableComponent implements OnInit {
  @Input() primeNgList!: AppExportableList;
  csvItems!: MenuItem[];
  excelItems!: MenuItem[];
  pdfItems!: MenuItem[];

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.get('GENERAL.APP-NAME').subscribe(() => {
      this.onInit();
    });
  }

  onInit() {
    this.csvItems = [
      {
        label: this.translate.instant('GENERAL.ALL'),
        icon: 'pi pi-copy',
        command: () => {
          this.primeNgList.exportCSV();
        },
      },
      {
        label: this.translate.instant('FORM.SELECTED-ROWS'),
        icon: 'pi pi-check-square',
        command: () => {
          this.primeNgList.exportCSV(true);
        },
      },
    ];
    this.excelItems = [
      {
        label: this.translate.instant('GENERAL.ALL'),
        icon: 'pi pi-copy',
        command: () => {
          this.primeNgList.exportExcel();
        },
      },
      {
        label: this.translate.instant('FORM.SELECTED-ROWS'),
        icon: 'pi pi-check-square',
        command: () => {
          this.primeNgList.exportExcel(true);
        },
      },
    ];
    this.pdfItems = [
      {
        label: this.translate.instant('GENERAL.ALL'),
        icon: 'pi pi-copy',
        command: () => {
          this.primeNgList.exportPdf();
        },
      },
      {
        label: this.translate.instant('FORM.SELECTED-ROWS'),
        icon: 'pi pi-check-square',
        command: () => {
          this.primeNgList.exportPdf(true);
        },
      },
    ];
  }
}
