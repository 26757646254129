import { BaseDirective } from './../../../../../directives/base.directive';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-test-question',
  templateUrl: './test-question.component.html',
  styleUrls: ['./test-question.component.sass']
})
export class TestQuestionComponent extends BaseDirective {
  override title = 'MENU.QUESTIONS'
  @Input() pollId = 0
}
