import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private iss = {
    login: 'http://aod-soul-elearning-api-rd4tnwfa7a-uc.a.run.app/auth/login'
  }
  loggedIn = new BehaviorSubject<boolean>(this.isValid())

  constructor() { }

  isValid() {
    const token = this.getToken()
    if (token && token !== '') {
      const payload = this.payload(token)
      if (payload) {
        return Object.values(this.iss).indexOf(payload.iss) > -1
      }
    }
    return false
  }

  payload(token: string) {
    return this.decode(token.split('.')[1])
  }

  decode(payload: string) {
    return JSON.parse(atob(payload))
  }

  getToken() {
    return localStorage.getItem('access_token')
  }

  set(token: string) {
    localStorage.setItem('access_token', token)
  }

}
