<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">
        {{ title | translate | uppercase }} | {{testName}}
      </h2>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
           <app-test-version-table-all>
           </app-test-version-table-all>
      </div>
    </div>
  </div>
</div>
