<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="flex flex-wrap gap-3 mb-4">
    <div class="block w-12">
      <label for="private">{{
        "QUESTION.APPROVAL-REQUIRED" | translate
      }}</label>
    </div>
    <div class="flex align-items-center">
      <p-radioButton
        name="required"
        [value]="1"
        [(ngModel)]="form.approval_required"
        inputId="required1"
        class="ng-dirty"
      ></p-radioButton>
      <label for="required1" class="ml-2">{{
        "GENERAL.YES" | translate
      }}</label>
    </div>
    <div class="flex align-items-center">
      <p-radioButton
        name="required"
        [value]="0"
        [(ngModel)]="form.approval_required"
        inputId="required2"
        class="ng-dirty"
      ></p-radioButton>
      <label for="required2" class="ml-2">{{ "GENERAL.NO" | translate }}</label>
    </div>
  </div>
  <div class="flex flex-column gap-2 mb-4">
    <span class="p-float-label">
      <p-inputNumber
        [showButtons]="true"
        inputId="allowed_attempts"
        name="allowed_attempts"
        class="ng-dirty w-full"
        [min]="1"
        [required]="true"
        [(ngModel)]="form.allowed_attempts"
      ></p-inputNumber>
      <label for="allowed_attempts">{{
        "FORM.ALLOWED-ATTEMPTS" | translate
      }}</label>
    </span>
  </div>
  <div class="flex justify-content-end">
    <button
      pButton
      class="p-button-primary"
      label="{{ 'ENV.SAVE' | translate }}"
      [disabled]="!dataForm.valid"
      (click)="dataForm.ngSubmit.emit()"
      *ngIf="!processing"
    ></button>
  </div>
</form>
