/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { ObjectFormatter } from 'src/app/models/ObjectFormatter';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-test-take-summary',
  templateUrl: './test-take-summary.component.html',
  styleUrls: ['./test-take-summary.component.sass']
})
export class TestTakeSummaryComponent extends BaseItemDirective {
  @Input() showResult = true
  override itemFormatter: ObjectFormatter = this.utils.getTestTakeFormatter();

  override getItem() {
    this.itemService = this.data.core.testTakeUnique(this.itemId)
    super.getItem()
  }

  override refreshActionServices(): void {
  }

  set result(value: number) {
    this.item.result = value
  }

  get result() {
    return Utils.floatStringToNumber(this.item.result)
  }
}
