<div class="flex row mx-0">
  <div class="col-8 row mx-0">
    <button
      pButton
      pRipple
      class="col-2 p-button-text p-button-info p-button-raised mt-1 mr-1 text-center"
      *ngFor="let item of wildcards"
      (click)="copyWildcard(item.key_)"
      [label]="item.key_"
    ></button>
  </div>
  <div class="col-4">
    <input
      type="text"
      id="searchWildcard"
      aria-describedby="name-help"
      class="w-full ng-dirty"
      placeholder="{{ 'ENV.SEARCH' | translate }} {{
        'FORM.WILDCARD' | translate
      }}"
      (input)="searchWildcard($event)"
      pInputText
    />
  </div>
</div>
