import { MediaPlayerConfig, AppPrimeNGDialogConfig, TimeUnit, DialogConfigType, AppTag, AppUserTypePermission } from 'src/app/models/AppGenericClasses';
import { OptionalPrimeTableColumnParams, YouTubeEmbedParams } from "./models/AppGenericClasses"
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

export const Globals: {
  [key: string]: any,
  CONTENT_TYPES: {
    [key: string]: {
      label: string,
      fileType: string[],
      ext: string,
      materialIcon: string,
      severity: string
    }
  },
  DEFAULT_YOUTUBE_EMBED_PARAMS: YouTubeEmbedParams,
  DEFAULT_OPTIONAL_PRIME_TABLE_COLUMN_PARAMS: OptionalPrimeTableColumnParams,
  DEFAULT_MEDIA_PLAYER_CONFIG: MediaPlayerConfig,
  DEFAULT_PRIME_DIALOG_CONFIG: AppPrimeNGDialogConfig,
  TIME_OPTIONS: {
    [key in TimeUnit]: {
      label: string,
      value: number
    }
  },
  DIALOG_CONFIG: {
    [key in DialogConfigType]: DynamicDialogConfig
  },
  ENROLLMENT_STATUS: { [key: string]: AppTag },
  USER_TYPE_PERMISSIONS: AppUserTypePermission
} = Object.freeze({
  API_URL: 'https://aod-soul-elearning-api-rd4tnwfa7a-uc.a.run.app',
  API_KEY: '36973b68eb1b16d9d2bff8e26fb8a38d',
  ACCEPTED_FILE_EXTENSIONS: {
    'image/*': /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i,
    'audio/*': /(\.m4v|\.mp4|\.mp3|\.wav|\.aac)$/i,
    'video/*': /(\.mp4)$/i,
    '.zip': /(\.zip)$/i,
    'application/pdf': /(\.pdf)$/i,
    'application/vnd.ms-excel': /(\.xls|\.xlsx)$/i,
    'text/plain': /(\.txt)$/i,
  },
  REG_EXP: {
    chipFieldSeparator: /,| $/i
  },
  CONTENT_TYPES: {
    AUDIO: {
      label: 'FORM.AUDIO',
      fileType: ['audio/*'],
      ext: 'mp3',
      materialIcon: 'volume_up',
      severity: 'info'
    },
    VIDEO: {
      label: 'FORM.VIDEO',
      fileType: ['video/*'],
      ext: 'mp4',
      materialIcon: 'video_camera_back',
      severity: 'danger'
    },
    PDF: {
      label: 'FORM.PDF',
      fileType: ['application/pdf'],
      ext: 'pdf',
      materialIcon: 'picture_as_pdf',
      severity: 'warning'
    },
    IMG: {
      label: 'FORM.IMG',
      fileType: ['image/*'],
      ext: 'png',
      materialIcon: 'image',
      severity: 'info'
    },
    PLAIN: {
      label: 'FORM.CONTENT',
      fileType: ['text/plain'],
      ext: 'txt',
      materialIcon: 'web',
      severity: 'info'
    },
    URL: {
      label: 'FORM.URL',
      fileType: [],
      ext: '',
      materialIcon: 'link',
      severity: 'info'
    },
    'VIDEO-URL': {
      label: 'FORM.EXTERNAL-VIDEO',
      fileType: [],
      ext: '',
      materialIcon: 'link',
      severity: 'danger'
    }
  },
  LOTTIE_ANIMATIONS: {
    loadingOptions: {
      path: '/assets/animations/image-loading.json',
    },
    uploadingOptions: {
      path: '/assets/animations/uploading.json',
    }
  },
  DEFAULT_YOUTUBE_EMBED_PARAMS: {
    autoplay: 0,
    cc_lang_pref: undefined,
    color: undefined,
    controls: 1,
    disablekb: undefined,
    end: undefined,
    fs: undefined,
    hl: undefined,
    iv_load_policy: undefined,
    list: undefined,
    listType: undefined,
    loop: 0,
    modestbranding: undefined,
    mute: 0,
    playlist: undefined,
    playsinline: undefined,
    rel: undefined,
    start: undefined,
  },
  DEFAULT_OPTIONAL_PRIME_TABLE_COLUMN_PARAMS: {
    sortable: '',
    type: 'DEFAULT',
    filterable: false,
    resizable: false,
    visible: true,
    frozen: false,
    formats: [],
    editable: false
  },
  DEFAULT_MEDIA_PLAYER_CONFIG: {
    autoPlay: false
  },
  DEFAULT_PRIME_DIALOG_CONFIG: {
    height: '70vh',
    width: '70vw',
    maximizable: true
  },
  TIME_OPTIONS: {
    SECONDS: {
      label: 'FORM.SECONDS',
      value: 1
    },
    MINUTES: {
      label: 'FORM.MINUTES',
      value: 60
    },
    HOURS: {
      label: 'FORM.HOURS',
      value: 3600
    }
  },
  EL_TEST_VISUALIZATION: {
    TIME_LIMIT_UNIT: null
  },
  CONTENT_VISUALIZATION: {
    TIME_LIMIT_UNIT: null
  },
  COURSE_VISUALIZATION: {
    TIME_LIMIT_UNIT: null
  },
  UNIT_VISUALIZATION: {
    TIME_LIMIT_UNIT: null
  },
  DIALOG_CONFIG: {
    CONTAINER: {
      width: '80%',
      height: '100%',
      styleClass: 'p-overflow-hidden',
      contentStyle: { height: '100%', overflow: 'hidden', padding: '.3rem' }
    }
  },
  ENROLLMENT_STATUS: {
    F: {
      severity: 'success',
      label: 'FORM.COMPLETED'
    },
    C: {
      severity: 'warning',
      label: 'FORM.STUDYING'
    },
    A: {
      severity: 'danger',
      label: 'FORM.ANULLED'
    }
  },
  TEST_APPROVAL_REQUIRED_STATUS: {
    0: {
      severity: 'success',
      label: 'FORM.OPTIONAL'
    },
    1: {
      severity: 'danger',
      label: 'FORM.OBLIGATORY-M'
    }
  },
  USER_TYPE_PERMISSIONS: {
    CONTENT: {
      label: "PERMISSION.CONTENT-GROUP",
      permissions: [
        {
          key: "could_manage_contents",
          label: "PERMISSION.COULD-MANAGE-CONTENTS"
        }
      ]
    },
    COURSE: {
      label: "PERMISSION.COURSE-GROUP",
      permissions: [
        {
          key: "could_enroll_in_course",
          label: "PERMISSION.COULD-ENROLL-IN-COURSE"
        },
        {
          key: "could_see_all_courses",
          label: "PERMISSION.COULD-SEE-ALL-COURSES"
        },
        {
          key: "could_manage_course",
          label: "PERMISSION.COULD-MANAGE-COURSE"
        }
      ]
    },
    GENERAL: {
      label: "PERMISSION.GENERAL-GROUP",
      permissions: [
        {
          key: "could_manage_app",
          label: "PERMISSION.COULD-MANAGE-APP"
        },
        {
          key: "could_upload_files",
          label: "PERMISSION.COULD-UPLOAD-FILES"
        }
      ]
    },
    LIBRARY: {
      label: "PERMISSION.LIBRARY-GROUP",
      permissions: [
        {
          key: "could_see_all_libraries",
          label: "PERMISSION.COULD-SEE-ALL-LIBRARIES"
        },
        {
          key: "could_manage_libraries",
          label: "PERMISSION.COULD-MANAGE-LIBRARIES"
        }
      ]
    },
    TEST: {
      label: "PERMISSION.TEST-GROUP",
      permissions: [
        {
          key: "could_see_all_test_versions",
          label: "PERMISSION.COULD-SEE-ALL-TEST-VERSIONS"
        },
        {
          key: "could_manage_tests",
          label: "PERMISSION.COULD-MANAGE-TESTS"
        },
        {
          key: "could_grade_tests",
          label: "PERMISSION.COULD-GRADE-TESTS"
        }
      ]
    },
    TOPIC: {
      label: "PERMISSION.TOPIC-GROUP",
      permissions: [
        {
          key: "could_manage_topics",
          label: "PERMISSION.COULD-MANAGE-TOPICS"
        }
      ]
    }
  }
})

Globals.EL_TEST_VISUALIZATION.TIME_LIMIT_UNIT = Globals.TIME_OPTIONS.MINUTES
Globals.UNIT_VISUALIZATION.TIME_LIMIT_UNIT = Globals.TIME_OPTIONS.MINUTES
Globals.COURSE_VISUALIZATION.TIME_LIMIT_UNIT = Globals.TIME_OPTIONS.HOURS
Globals.CONTENT_VISUALIZATION.TIME_LIMIT_UNIT = Globals.TIME_OPTIONS.SECONDS
