import { Component, Input } from '@angular/core';
import { BaseItemListDirective } from 'src/app/directives/restruct/base-item-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { TestQuestionList } from 'src/app/models/AppLists';
import { TestQuestion } from 'src/app/models/AppModels';
import { TestQuestionJSON } from 'src/app/models/AppSources';

@Component({
  selector: 'app-test-question-weight-detail',
  templateUrl: './test-question-weight-detail.component.html',
  styleUrls: ['./test-question-weight-detail.component.sass']
})
export class TestQuestionWeightDetailComponent extends BaseItemListDirective<TestQuestion, TestQuestionJSON, TestQuestionList> {

  @Input() pollId = 0;
  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('content', 'FORM.CONTENT', 200, { frozen: true }),
    new PrimeNGTableColumn('weight', 'FORM.PREV-WEIGHT', 100),
    new PrimeNGTableColumn('newWeight', 'FORM.NEW-WEIGHT', 100)
  ];
  override ListModel = TestQuestionList;
  currentQuestionId = 0
  currentWeight = "0"

  override getList() {
    if (this.pollId) {
      this.query(this.data.core.testQuestionsOfPoll(this.pollId)).subscribe();
    }
  }

  handleDialogConfigData(): void {
    this.pollId = this.dialogConfig.data.pollId ?? this.pollId
    this.currentQuestionId = +(this.dialogConfig.data.questionId ?? this.currentQuestionId)
    this.currentWeight = this.dialogConfig.data.currentWeight ?? this.currentWeight
    super.handleDialogConfigData()
  }

  handleRows(data: TestQuestionJSON[]): void {
    data.forEach(row => {
      if (row.poll_question_id === this.currentQuestionId) {
        row.new_weight = this.currentWeight
      } else {
        row.new_weight = ((100 - parseFloat(this.currentWeight)) / (data.length - 1)).toFixed(2)
      }
    })
    super.handleRows(data)
  }

  override refreshActionServices(): void {
    throw "Method not implemented.";
  }
}
