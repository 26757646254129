<div class="grid grid-nogutter surface-section text-800" *ngIf="course">
  <div
    class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center"
    style="background: #2d2f31"
  >
    <section>
      <span
        class="block text-6xl font-bold mb-1"
        style="color: white"
        *ngIf="showName"
        >{{ course.name }}</span
      >
      <p
        class="mt-0 mb-4 line-height-3"
        style="color: white"
        *ngIf="showDescription"
      >
        {{ course.description }}
      </p>
      <div class="flex flex-wrap gap-2" *ngIf="course.tags">
        <ng-container *ngFor="let tag of course.tags.split(',')">
          <p-tag [value]="tag"></p-tag>
        </ng-container>
      </div>
    </section>
  </div>
  <div class="col-12 md:col-6 overflow-hidden" style="background: #2d2f31">
    <img
      appBaseHtmlElementResource
      defaultImage="assets/images/cover-example.jpg"
      [path]="'course'"
      [editId]="course.id"
      field="cover"
      ext="png"
      style="
        clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%);
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      "
    />
  </div>
</div>
