<div class="flex flex-column h-full" *ngIf="course">
  <div class="flex">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h1 class="cursor-pointer" (click)="selectCourse()">
        {{ course.name_ }}
      </h1>
      <div
        class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
      >
        <button
          pButton
          pRipple
          class="p-button-text p-button-danger"
          icon="pi pi-sign-out"
          label="Abandonar curso"
          (click)="finishCourse()"
        ></button>
      </div>
    </div>
  </div>
  <div class="flex flex-grow-1">
    <div class="flex w-12 lg:w-9 relative">
      <div class="absolute h-full w-full" *ngIf="selectedCourse">
        <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
          <app-course-preview
            [itemId]="course.el_course_id"
            [showName]="false"
            [showSummarizedInfo]="false"
          ></app-course-preview>
        </p-scrollPanel>
      </div>
      <div
        class="absolute h-full w-full"
        *ngIf="selectedUnit && !selectedSection"
      >
        <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
          <p-tabView class="h-100">
            <p-tabPanel header="Descripción general">
              <app-unit-item
                [item]="selectedUnit"
                [idx]="selectedUnit.idx"
                [showActions]="false"
                [fireActions]="false"
              ></app-unit-item>
            </p-tabPanel>
          </p-tabView>
        </p-scrollPanel>
      </div>
      <div class="absolute h-full w-full" *ngIf="selectedSection">
        <p-scrollPanel
          #sectionScrollPanel
          [style]="{ width: '100%', height: '100%' }"
        >
          <p-messages
            class="w-full"
            [(value)]="messages"
            [enableService]="false"
            [closable]="true"
          ></p-messages>
          <p-tabView class="h-100">
            <p-tabPanel header="Descripción general">
              <div class="ml-2">
                <div class="flex align-items-center">
                  <div class="flex-grow-1">
                    <h2>
                      Unidad #{{ selectedUnit.idx + 1 }} |
                      {{ selectedUnit.name_ }}
                    </h2>
                  </div>
                </div>
                <h3>
                  Clase #{{ selectedSection.idx + 1 }} |
                  {{ selectedSection.name_ }}
                </h3>
                <div [innerHTML]="selectedSection.content"></div>
              </div>
              <p-divider></p-divider>
              <div class="ml-2">
                <div class="flex align-items-center">
                  <div class="flex-grow-1">
                    <h3 class="p-text-secondary flex align-items-center gap-2">
                      <app-course-unit-section-content-item
                        [item]="selectedSection.resource_content"
                        [fireActions]="false"
                        [showName]="false"
                      ></app-course-unit-section-content-item>
                      {{ selectedSection.resource_content.name_ }}
                    </h3>
                  </div>
                </div>
                <div
                  [ngStyle]="{
                    width: '100%',
                    background:
                      selectedSection.resource_content.type_ === 'VIDEO-URL'
                        ? 'black'
                        : 'transparent',
                    'justify-content': 'center',
                    'align-items': 'center'
                  }"
                  class="mb-3"
                >
                  <ng-container
                    *ngIf="
                      selectedSection.resource_content.type_ === 'VIDEO-URL'
                    "
                  >
                    <div style="height: 500px">
                      <app-video-url-player
                        [url]="selectedSection.resource_content.content"
                        [youtubeEmbedParams]="youtubeEmbedParams"
                        (play)="onPlayerPlay()"
                        (pause)="onPlayerPause()"
                      ></app-video-url-player>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="selectedSection.resource_content.type_ === 'URL'"
                  >
                    <a
                      appBaseHtmlElementResource
                      [resourceUrl]="selectedSection.resource_content.content"
                      target="_blank"
                      style="color: inherit; text-decoration: none"
                      class="flex align-items-center"
                      (click)="completeProgressBar()"
                    >
                      <button
                        pButton
                        type="button"
                        label="{{ 'GENERAL.SEE' | translate }} enlace"
                        icon="pi pi-file"
                      ></button>
                    </a>
                  </ng-container>
                  <ng-container
                    *ngIf="selectedSection.resource_content.type_ === 'PDF'"
                  >
                    <iframe
                      appBaseHtmlElementResource
                      [path]="'content'"
                      [field]="'PDF'"
                      [ext]="'pdf'"
                      [editId]="selectedSection.resource_content.content_id"
                      width="100%"
                      height="500px"
                    ></iframe>
                    <a
                      appBaseHtmlElementResource
                      [path]="'content'"
                      [field]="'PDF'"
                      [ext]="'pdf'"
                      [editId]="selectedSection.resource_content.content_id"
                      target="_blank"
                      style="color: inherit; text-decoration: none"
                      class="flex align-items-center mb-3"
                    >
                      <button
                        pButton
                        type="button"
                        label="{{ 'GENERAL.SEE' | translate }} PDF"
                        icon="pi pi-file"
                      ></button>
                    </a>
                    <div class="card">
                      <p-progressBar
                        [value]="calculateProgress(selectedSection)"
                        [style]="{ height: '15px' }"
                      ></p-progressBar>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="selectedSection.resource_content.type_ === 'IMG'"
                  >
                    <div style="height: 500px">
                      <app-aws-image-viewer
                        [path]="'content'"
                        [field]="'IMG'"
                        [ext]="'png'"
                        [editId]="selectedSection.resource_content.content_id"
                      >
                      </app-aws-image-viewer>
                    </div>
                    <div class="card">
                      <p-progressBar
                        [value]="calculateProgress(selectedSection)"
                        [style]="{ height: '15px' }"
                      ></p-progressBar>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="selectedSection.resource_content.type_ === 'VIDEO'"
                  >
                    <div style="height: 500px">
                      <app-aws-video-player
                        [path]="'content'"
                        [field]="'VIDEO'"
                        [ext]="'mp4'"
                        [editId]="selectedSection.resource_content.content_id"
                        [autoPlay]="false"
                      >
                      </app-aws-video-player>
                    </div>
                    <p-progressBar
                      [value]="calculateProgress(selectedSection)"
                      [style]="{ height: '15px' }"
                    ></p-progressBar>
                  </ng-container>
                  <ng-container
                    *ngIf="selectedSection.resource_content.type_ === 'AUDIO'"
                  >
                    <app-aws-audio-player
                      [path]="'content'"
                      [field]="'AUDIO'"
                      [ext]="'mp3'"
                      [editId]="selectedSection.resource_content.content_id"
                      [autoPlay]="false"
                    >
                    </app-aws-audio-player>
                    <p-progressBar
                      [value]="calculateProgress(selectedSection)"
                      [style]="{ height: '15px' }"
                    ></p-progressBar>
                  </ng-container>
                </div>
                <div class="mb-3">
                  {{ selectedSection.resource_content.description_ }}
                </div>
                <div
                  class="flex flex-wrap gap-2"
                  *ngIf="selectedSection.resource_content.tags"
                >
                  <ng-container
                    *ngFor="
                      let tag of selectedSection.resource_content.tags.split(
                        ','
                      )
                    "
                  >
                    <p-tag [value]="tag"></p-tag>
                  </ng-container>
                </div>
                <div class="flex">
                  <div class="flex flex-column mt-4 mr-4">
                    <strong class="p-text-secondary"
                      >{{
                        (
                          selectedSection.resource_content.min_viewing_time / 60
                        ).toFixed(1)
                      }}
                      minutos</strong
                    >
                    Tiempo mínimo de visualización
                  </div>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel
              header="Contenidos de soporte"
              *ngIf="selectedSection.support_contents.length"
            >
              <div
                class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3 cursor-pointer"
                *ngFor="let support of selectedSection.support_contents"
                (click)="viewSupportContent(support)"
              >
                <div class="flex w-12 md:w-4 lg:w-2">
                  <a class="flex">
                    <app-aws-image-viewer
                      defaultImage="assets/images/cover-example.jpg"
                      [path]="'content'"
                      [editId]="support.content_id"
                      field="cover"
                      ext="png"
                    ></app-aws-image-viewer>
                  </a>
                </div>
                <div
                  class="flex flex-column w-12 md:w-8 lg:w-10 px-4 py-3 mb-3"
                >
                  <p class="card-item-title">{{ support.name_ }}</p>
                  <p
                    class="p-text-secondary ellipsis-3"
                    [pTooltip]="support.description_"
                    tooltipPosition="top"
                  >
                    {{ support.description_ }}
                  </p>
                  <div *ngIf="support.tags">
                    <ng-container *ngFor="let tag of support.tags.split(',')">
                      <p-tag [value]="tag" class="ml-2 mr-1"></p-tag>
                    </ng-container>
                  </div>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </p-scrollPanel>
      </div>
    </div>
    <p-divider layout="vertical"></p-divider>
    <div class="flex-grow-1 hidden lg:flex relative" style="width: 350px">
      <div class="absolute h-full w-full">
        <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
          <div class="card p-2">
            <p-accordion>
              <p-accordionTab header="Contenido del curso">
                <div class="course-progress">
                  <div class="flex gap-2 p-text-secondary font-bold">
                    <div class="flex">Progreso del curso:</div>
                    <div class="flex-grow-1 text-right">
                      {{ calculateCourseProgress / 100 | percent : "1.2-2" }}
                    </div>
                  </div>
                  <div class="my-2">
                    <p-progressBar
                      [value]="calculateCourseProgress"
                      [style]="{ height: '15px' }"
                    ></p-progressBar>
                  </div>
                </div>
                <div class="flex justify-content-between">
                  <div class="flex flex-column mb-4" style="text-align: start">
                    <strong style="color: rgb(92, 90, 90)"
                      >{{ estimatedDuration }} hora(s)</strong
                    >
                    Total
                  </div>
                  <div class="flex flex-column mb-4" style="text-align: end">
                    <strong style="color: rgb(92, 90, 90)"
                      >{{ course.validity_period }} días</strong
                    >
                    Validez de certificación
                  </div>
                </div>
                <div class="flex flex-wrap gap-2" *ngIf="course.tags">
                  <ng-container *ngFor="let tag of course.tags.split(',')">
                    <p-tag [value]="tag" class="mr-1"></p-tag>
                  </ng-container>
                </div>
                <div>
                  <h2 style="color: rgb(92, 90, 90)">Descripción</h2>
                  <p class="m-0">
                    {{ course.description_ }}
                  </p>
                </div>
                <div class="mb-1" *ngIf="course.objective">
                  <h2 style="color: rgb(92, 90, 90)">Objetivo</h2>
                  <p class="m-0" [innerHTML]="course.objective">
                    {{ course.objective }}
                  </p>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
          <div class="card p-2" *ngFor="let unit of units; index as unitIdx">
            <div class="box-elevation border-round-xl p-2">
              <h3
                class="mb-3 cursor-pointer"
                style="color: rgb(92, 90, 90)"
                (click)="onlySelectUnit(unitIdx)"
              >
                Unidad #{{ unitIdx + 1 }} | {{ unit.name_ }}
              </h3>
              <div
                class="mt-2 mb-3"
                *ngIf="unit.sections.length || unit.tests.length"
              >
                <div class="text-sm flex justify-content-between">
                  <div class="flex-grow">Avance de la unidad</div>
                  <div class="flex">{{ calculateUnitProgress(unit) }}%</div>
                </div>
                <p-progressBar
                  [value]="calculateUnitProgress(unit)"
                  [style]="{ height: '15px' }"
                ></p-progressBar>
              </div>
              <div
                class="cursor-pointer"
                *ngFor="let section of unit.sections; index as sectionIdx"
                (click)="selectSection(unitIdx, sectionIdx)"
              >
                <div
                  class="box-elevation border-round-md border-2 flex flex-column border-transparent shadow-0 my-2"
                  [ngClass]="{
                      'selected-card': section === selectedSection,
                      'white-card': section.couldGoToSection,
                      'disabled-card': !section.couldGoToSection,
                      'green-card': section.isComplete,
                    }"
                >
                  <div
                    class="flex align-items-center justify-content-between w-full"
                  >
                    <p class="pl-3 flex align-items-center gap-2">
                      <app-course-unit-section-content-item
                        [item]="section.resource_content"
                        [fireActions]="false"
                        [showName]="false"
                      >
                      </app-course-unit-section-content-item>
                      Clase #{{ sectionIdx + 1 }} | {{ section.name_ }}
                    </p>
                    <i
                      class="pi pi-check-circle"
                      [ngClass]="{
                        'green-icon': section.isComplete,
                        'grey-icon': !section.isComplete
                      }"
                      [ngStyle]="{ 'font-size': '24px', padding: '8px' }"
                    >
                    </i>
                  </div>
                  <ng-container
                    *ngIf="
                      ['IMG', 'VIDEO', 'AUDIO', 'PDF'].includes(
                        section.resource_content.type_
                      )
                    "
                  >
                    <div class="px-2">
                      <p-progressBar
                        [value]="calculateProgress(section)"
                        [style]="{ height: '7px' }"
                      ></p-progressBar>
                    </div>
                  </ng-container>
                  <div class="m-2">
                    <app-section-tags
                      [unit]="unit"
                      [section]="section"
                    ></app-section-tags>
                  </div>
                </div>
              </div>
              <p-divider></p-divider>
              <div *ngFor="let test of unit.tests; index as testIdx">
                <div
                  class="box-elevation border-round-md border-2 flex flex-column border-transparent shadow-0 my-2 white-card cursor-pointer"
                  [ngClass]="{
                    'green-card': test.isComplete,
                    'disabled-card': !test.couldGoToTest,
                  }"
                  routerLink="/tests/take/{{ test.el_course_unit_test_id }}/U/{{
                    unit.el_course_unit_take_id
                  }}/{{ test.test.el_test_id }}"
                >
                  <div
                    class="flex align-items-center justify-content-between w-full"
                  >
                    <p class="pl-3 flex align-items-center gap-2">
                      <span
                        class="material-symbols-outlined"
                        style="font-size: 2rem"
                      >
                        quiz
                      </span>
                      Evaluación #{{ testIdx + 1 }} |
                      {{ test.test?.poll?.title }}
                    </p>
                    <i
                      class="pi pi-check-circle"
                      [ngClass]="{
                        'green-icon': test.isComplete,
                        'grey-icon': !test.isComplete
                      }"
                      [ngStyle]="{ 'font-size': '24px', padding: '8px' }"
                    >
                    </i>
                  </div>
                  <div class="flex flex-column gap-2 pl-3 pr-2">
                    <div
                      class="flex align-items-center gap-2"
                      *ngIf="test.hasTakes"
                    >
                      <p class="text-md">
                        Calificación más alta:
                        {{ test.highestRating / 100 | percent : "1.2-2" }}
                      </p>
                      <p-tag
                        severity="success"
                        value="Aprobado"
                        *ngIf="test.approved"
                      ></p-tag>
                      <p-tag
                        severity="danger"
                        value="Reprobado"
                        *ngIf="!test.approved"
                      ></p-tag>
                    </div>
                    <span
                      class="text-sm text-right"
                      *ngIf="test.remainingAttempts"
                    >
                      Intentos restantes: {{ test.remainingAttempts }}
                    </span>
                  </div>
                  <p-divider styleClass="m-1"></p-divider>
                  <div class="flex justify-content-end m-2">
                    <ng-container *ngIf="!test.isComplete">
                      <p-tag
                        [severity]="'danger'"
                        [value]="'Requiere aprobación' | translate"
                        *ngIf="test.approval_required"
                      >
                      </p-tag>
                      <p-tag
                        [severity]="'success'"
                        [value]="'No requiere aprobación' | translate"
                        *ngIf="!test.approval_required"
                      >
                      </p-tag>
                    </ng-container>

                    <p-tag
                      [severity]="'success'"
                      [value]="'Completado' | translate"
                      *ngIf="test.isComplete"
                    >
                    </p-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>

<app-content-trigger-button
  #contentTrigger
  style="display: none"
></app-content-trigger-button>
