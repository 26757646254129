/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { ObjectFormatter } from 'src/app/models/ObjectFormatter';

@Component({
  selector: 'app-library-detail',
  templateUrl: './library-detail.component.html',
  styleUrls: ['./library-detail.component.sass']
})
export class LibraryDetailComponent extends BaseItemDirective {
  override itemFormatter: ObjectFormatter = this.utils.getLibraryFormatter();

  override getItem() {
    this.itemService = this.data.core.libraryUnique(this.itemId)
    super.getItem()
  }

  override refreshActionServices(): void {
  }
}
