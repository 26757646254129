import { UtilsService } from "../services/utils/utils.service"
import { Utils } from "../shared/utils/utils"
import { AppJSONSanitizer } from "./AppGenericClasses"
import { CourseUnit, CourseUnitJSON } from "./CourseUnitClasses"
import { CourseUnitTake, CourseUnitTakeJSON } from "./CourseUnitTakeClasses"
import { TestTakeUnit } from "./TestTakeUnitClasses"

export class CourseEnrollmentJSON {
  el_course_enrollment_id = 0
  el_course_id = 0
  user_id = 0
  enrollment_status = ""
  enrolled_at = ""
  units: CourseUnitTakeJSON[] = []
  course_units: CourseUnitJSON[] = []
  takes = []
}

export class CourseEnrollment {
  id: number
  courseId: number
  userId: number
  enrollmentStatus: string
  enrolled_at: string
  units: CourseUnit[]
  unitTakes: CourseUnitTake[]
  testTakes: TestTakeUnit[]

  source: CourseEnrollmentJSON

  constructor(source: CourseEnrollmentJSON, utils: UtilsService) {
    this.source = new AppJSONSanitizer<CourseEnrollmentJSON>(source, CourseEnrollmentJSON).result
    this.id = this.source.el_course_enrollment_id
    this.courseId = this.source.el_course_id
    this.userId = this.source.user_id
    this.enrollmentStatus = this.source.enrollment_status
    this.enrolled_at = this.source.enrolled_at
    this.units = this.source.course_units.map(cUnit => new CourseUnit(cUnit, utils))
    this.unitTakes = this.source.units.map(unit => new CourseUnitTake(unit))
    this.testTakes = this.source.takes.map(take => new TestTakeUnit(take))
  }

  get activeUnits() {
    return this.units.filter(unit => unit.active)
  }

  get activeSections() {
    return this.activeUnits.map(unit => unit.sections).flat().filter(section => section.active)
  }

  get completedSectionTakes() {
    return this.activeSections.filter(section => {
      const sectionTake = this.unitTakes.map(uTake => uTake.sections).flat().find(sTake => sTake.courseUnitSectionId === section.id)
      return sectionTake && sectionTake.completionDate
    }).length
  }

  get requiredTests() {
    return this.units.map(unit => unit.tests).flat().filter(test => test.approvalRequired)
  }

  get completedTestTakes() {
    return this.requiredTests.filter(cTest => {
      const testTakes = this.unitTakes.map(uTake => uTake.takes).flat().filter(tTake => tTake.take.testId === cTest.testId)
        .map(tTake => {
          tTake.result = Utils.floatStringToNumber(tTake.take.result.toString())
          return tTake
        })
      const testMinApprovalPerc = Utils.floatStringToNumber(cTest.test.minApprovalPercentage.toString())
      const highestRating = Utils.sortByProperty<TestTakeUnit>(testTakes, 'result', 'desc')[0]?.result
      const approved = highestRating >= testMinApprovalPerc
      return testTakes.length && approved
    }).length
  }

  get percentage() {
    return (this.completedSectionTakes + this.completedTestTakes) / (this.activeSections.length + this.requiredTests.length);
  }

  get unitTestsAverageScore() {
    let sum = 0
    let result = "0";

    this.requiredTests.forEach(cTest => {
      const testTakes = this.unitTakes.map(uTake => uTake.takes).flat().filter(tTake => tTake.take.testId === cTest.testId)
        .map(tTake => {
          tTake.result = Utils.floatStringToNumber(tTake.take.result.toString())
          return tTake
        })
      const result = Utils.sortByProperty<TestTakeUnit>(testTakes, 'result', 'desc')[0]?.result

      sum += !isNaN(result) ? result : 0
    })

    if (this.requiredTests.length > 0) {
      result = (sum / this.requiredTests.length).toFixed(2)
    }

    return result
  }
}
