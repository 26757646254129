import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';


@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.sass']
})
export class UserFormComponent extends BaseFormDirective {
  override title = 'MENU.USERS'
  override form = {
    first_name: null,
    last_name: null,
    dni_type_id: null,
    dni: null,
    city_id: null,
    email: null,
    user_group_id: null,
    user_type_id: null,
    user_post_id: null
  }
  path = 'user'

  override onLanguageInit() {
    super.onLanguageInit()
    this.data.load('group')
    this.data.load('user-type')
    this.data.load('dni-type')
    this.data.load('city')
    this.data.load('specialty')
  }

  override getItemEdit() {
    this.editService = this.data.core.userUnique(this.editId)
    super.getItemEdit()
  }

  override handleItemEditSuccess(data: any): void {
    super.handleItemEditSuccess(data)
    this.form.user_type_id = data.credentials.type.user_type_id
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.userCreate(this.formSanitized)
    this.putService = this.data.core.userUpdate(this.editId, this.formSanitized)
  }
}
