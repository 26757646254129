import { Utils } from 'src/app/shared/utils/utils';
import { Component, ViewChild } from '@angular/core';
import { BaseFormDirective } from "../../../../directives/base-form.directive";
import { ImageUploadComponent } from "../../../controls/image-upload/image-upload.component";
import { Params } from '@angular/router';

@Component({
  selector: 'app-course-form',
  templateUrl: './course-form.component.html',
  styleUrls: ['./course-form.component.sass']
})
export class CourseFormComponent extends BaseFormDirective {
  @ViewChild('iuCover') iuCover: ImageUploadComponent | undefined
  override title = 'MENU.COURSES'

  override form = {
    name_: null,
    description_: null,
    objective: null,
    tags: '',
    estimated_duration: null,
    validity_period: 1,
    topics: [],
    sequential_units: 0
  }

  sequentialOptions: any[] = [
    { label: 'GENERAL.YES', value: 1 },
    { label: 'GENERAL.NO', value: 0 },

  ];

  tags: never[] = []
  topics: number[] = []
  defaultTopic: any = null;
  defaultTopicId = 0;

  override onLanguageInit() {
    super.onLanguageInit()
    this.data.load('topics')
  }

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('defaultTopic' in params) {
      this.defaultTopicId = +params['defaultTopic']
      this.topics.push(this.defaultTopicId);
      this.utils.executeRequest(
        this.data.core.topicUnique(this.defaultTopicId), {
        onSuccess: (data) => {
          this.defaultTopic = data
        }
      })
    }
  }

  override getItemEdit() {
    this.editService = this.data.core.courseUnique(this.editId)
    super.getItemEdit()
  }

  override handleItemEditSuccess(data: any) {
    super.handleItemEditSuccess(data)
    this.topics = data['course_topics'].map((it: any) => it.el_topic_id)
    const tags = data['tags']
    this.tags = tags ? tags.split(',') : []
  }

  setSubmitMethods(): void {
    this.postService = this.data.core.courseCreate(this.formSanitized)
    this.putService = this.data.core.courseUpdate(this.editId, this.formSanitized)
  }

  override onSubmit() {
    this.form.topics = this.topics as never[]
    this.form.tags = this.tags.join(',')
    super.onSubmit()
  }

  override cleanForm() {
    super.cleanForm()
    this.topics = []
    this.tags = []
  }

  override handleSave(data: any) {
    if (this.iuCover) {
      this.iuCover.upload(data).then(() => {
        super.handleSave(data)
      })
        .catch(err => {
          Utils.error(err)
        })
    } else {
      super.handleSave(data)
    }
  }

  seeTopic() {
    this.utils.appDialog.seeTopic(this.defaultTopicId)
  }
}
