import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.sass']
})
export class GroupFormComponent extends BaseFormDirective {
  override title = 'MENU.GROUP'
  override form = {
    name_: null,
    description_: null
  }
  path = 'user-group'

  override getItemEdit() {
    this.editService = this.data.core.groupUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.groupCreate(this.formSanitized)
    this.putService = this.data.core.groupUpdate(this.editId, this.formSanitized)
  }
}
