import { Component, Input } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-course-prerrequisite-table',
  templateUrl: './course-prerrequisite-table.component.html',
  styleUrls: ['./course-prerrequisite-table.component.sass']
})
export class CoursePrerrequisiteTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.TITLE', 150, { frozen: true }),
    new PrimeNGTableColumn('description_', 'FORM.DESCRIPTION', 300)
  ];

  override title = 'CORE.PRE-REQUISITES';
  override keys = {
    primary_key: 'el_prerequisite_course_id',
    label: 'name_'
  }

  @Input() courseId = 0
  override getList(): void {
    this.query(this.data.core.prerequisited(this.courseId)).subscribe();
  }

  refreshActionServices(): void {
    this.inactivationService = this.data.core.preRequisiteDelete(
      this.courseId,
      this.itemSelected[this.keys.primary_key]
    )
  }

  handleRows(data: any): void {
    super.handleRows(data[0].prerequisites)
  }
}
