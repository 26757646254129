<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">{{ title | translate | uppercase }}</h2>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-column h-full">
    <div class="flex flex-grow-1 relative">
      <div class="card-form absolute h-full w-full">
        <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
          <app-test-take-detail [itemId]="itemId"></app-test-take-detail>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>
