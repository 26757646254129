import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';
import { AppTimerFormatter } from 'src/app/models/AppGenericClasses';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-test-take-form',
  templateUrl: './test-take-form.component.html',
  styleUrls: ['./test-take-form.component.sass']
})
export class TestTakeFormComponent extends BaseFormDirective {
  @Input() showEditBtn = true
  @Input() id = 0
  @Input() type: "V" | "C" | "U" = "V"
  @Input() parentId = 0
  @Input() testId = 0
  testTake: any = null
  test: any = null
  timer: AppTimerFormatter = new AppTimerFormatter({ hours: 0, minutes: 0, seconds: 0 })
  takeStarted = false
  override form = {
    el_test_take_id: 0,
    questions: []
  }
  override redirectOnSave = false

  override handleRouteParams(params: Params): void {
    this.id = params['id'] ?? this.id
    this.type = params['type'] ?? this.type
    this.parentId = params['parentId'] ?? this.parentId
    this.testId = params['testId'] ?? this.testId
  }

  override onLanguageInit(): void {
    super.onLanguageInit()
    this.initObservers()
  }

  startTestTake() {
    this.takeStarted = true
    if (this.id > 0) {
      const initTestTakeExtraData: { [key: string]: any } = {}
      switch (this.type) {
        case "C":
          initTestTakeExtraData.el_course_test_id = this.id
          break;
        case "U":
          initTestTakeExtraData.el_course_unit_test_id = this.id
          break;
        default:
          break;
      }
      this.utils.executeRequest(
        this.data.core.initTestTake(Object.assign(
          {
            parent_type: this.type,
            parent_id: this.parentId
          }, initTestTakeExtraData
        )),
        {
          onSuccess: () => {
            this.getViewTakeFormInCourse()
          },
          onError: (error: HttpErrorResponse) => {
            this.error = error
            if (error.status === 403) {
              this.getViewTakeFormInCourse()
            }
          },
        }
      )
    }
  }

  initObservers() {
    this.timer.onFinish.subscribe(finished => {
      if (finished) {
        this.onSubmit()
      }
    })
  }

  getViewTakeFormInCourse() {
    this.utils.executeRequest(
      this.data.core.viewTakeFormInCourse(this.testId), {
      onSuccess: (data) => {
        console.log(data);
        this.testTake = data
        this.test = data.test
        const timerModel = Utils.convertSecondsToHMS(
          data.test.time_limit - Utils.secondsBetweenDates(DateUtils.strToDateTime(data.time_from), new Date())
        )
        this.timer.seconds = timerModel.seconds
        this.timer.minutes = timerModel.minutes
        this.timer.hours = timerModel.hours
        this.timer.reverse()
        this.title = this.test.poll.title
        if (this.test.random_questions) {
          this.test.poll.questions = Utils.shuffleArray(this.test.poll.questions)
        } else {
          this.test.poll.questions = Utils.sortByProperty(this.test.poll.questions, 'order_')
        }
        this.getRelatedQuestions(this.test.poll.questions).forEach(question => this.initRelatedQuestionExtraData(question));
        console.log(this.test);
      }
    })
  }

  getRelatedQuestions(questions: any[]) {
    return questions.filter(question => question.type_ === 'RELATION')
  }

  initRelatedQuestionExtraData(question: any) {
    this.initRelatedValuesToSelect(question)
    question.answers_related.forEach((answer: any) => this.initAnswerRelatedExtraData(answer))
  }

  initAnswerRelatedExtraData(answer: any) {
    answer.selectedRelatedValue = null
  }

  initRelatedValuesToSelect(question: any) {
    question.relatedValuesToSelect = question.answers_related.map((answer: any) => answer.related_value)
    question.selectedRelatedValues = []
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.finishTestTake(this.form)
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  override onSubmit() {
    this.form.el_test_take_id = this.testTake.el_test_take_id
    this.form.questions = this.getQuestionsFormData()
    console.log(this.form);
    super.onSubmit()
  }

  override handleSave(data: any) {
    super.handleSave(data)
    location.reload()
    //this.router.navigate([`/tests/take/${this.id}/V/${this.parentId}`])
  }

  getQuestionsFormData() {
    return this.test.poll.questions.map(question => {
      let answerSelected = []

      switch (question.type_) {
        case "SELECTION-SINGLE":
          answerSelected.push(question.answer_selected?.poll_answer_selection_id)
          break;
        case "SELECTION-MULTIPLE":
          question.answers_selected?.forEach((answer: any) => {
            answerSelected.push(answer.poll_answer_selection_id)
          })
          break;
        case "RELATION":
          question.answers_related?.forEach((answer: any, idx: number) => {
            answerSelected.push({
              poll_answer_related_id: answer.poll_answer_related_id,
              poll_answer_related_value_id: question.relatedValuesToSelect[idx].poll_answer_related_value_id
            })
          });
          break;
        default:
          break;
      }

      answerSelected = answerSelected.filter(x => x)

      return {
        poll_question_id: question.poll_question_id,
        justification: "",
        answer_open: question.answer_open ?? "",
        answer_selection: answerSelected,
        answer_related: answerSelected
      }
    })
  }
}

