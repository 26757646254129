<p-columnFilter [field]="field" [matchModeOptions]="matchModeOptions" [matchMode]="'group'"
                display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false">
  <ng-template pTemplate="header">
    <div class="px-3 pt-3 pb-0">
      <span class="p-text-bold">{{ 'MENU.GROUP' | translate }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <p-multiSelect
      [ngModel]="value"
      [options]="sources['group'].data"
      placeholder="{{ 'FORM.SELECT-A' | translate }} {{ ( 'MENU.GROUP' | translate).toLowerCase() }}"
      (onChange)="filter($event.value)"
      optionLabel="{{ sources['group'].label }}"
      selectedItemsLabel="{0} {{ ('MENU.GROUPS' | translate).toLowerCase() }} {{ ('FORM.SELECTED-F' | translate).toLowerCase() }}"
      [maxSelectedLabels]="1"
    ></p-multiSelect>
  </ng-template>
</p-columnFilter>
