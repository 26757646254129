<vg-player style="height: 50px" (onPlayerReady)="onPlayerReady($event)">
  <vg-controls>
    <vg-play-pause></vg-play-pause>
    <vg-playback-button></vg-playback-button>

    <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

    <vg-mute></vg-mute>
  </vg-controls>

  <audio
    #mediaPlayer
    [vgMedia]="mediaPlayer"
    [src]="resource"
    preload="auto"
  ></audio>
</vg-player>
