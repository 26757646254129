<div>
  <div
    class="grid align-items-center box box-elevation media-item my-3"
    *ngFor="let item of mediaItems"
  >
    <div class="col-1 text-center">
      <i class="pi pi-file" style="font-size: 2.5rem"></i>
    </div>
    <div class="col">
      <h5 class="name">
        {{ item.data.label }}
      </h5>
      <p class="size">
        {{ item.file.size | formatBytes }}
      </p>
    </div>
    <div class="col-3 text-center">
      <div *ngIf="item.status === 0">
        <i class="pi pi-pause" style="font-size: 2.5rem"></i>
      </div>
      <div *ngIf="item.status === 1">
        <p-progressSpinner
          [style]="{ width: '50px', height: '50px' }"
        ></p-progressSpinner>
      </div>
      <div *ngIf="item.status === 2">
        <i
          class="pi pi-check-circle"
          style="font-size: 2.5rem; color: #78cc86"
        ></i>
      </div>
    </div>
  </div>
  <div class="d-flex mt-3" *ngIf="doDismiss">
    <p-progressBar
      [value]="progressFinish"
      [showValue]="false"
      class="w-100"
    ></p-progressBar>
  </div>
</div>
