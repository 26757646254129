import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewInit, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService } from '../services/utils/utils.service';
import { GlobalDataService } from '../services/data/global-data.service';
import { ActivatedRoute, Params } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Globals } from '../globals';
import { ComponentDataSource, GenericResponse } from '../models/AppGenericClasses';

@Directive({
  selector: '[appBase]',
})
export class BaseDirective implements OnInit, OnChanges, AfterViewInit {
  @Input() componentDataSource: ComponentDataSource = 'ROUTE'
  @Input() containerStyleClass = ""
  isPdfTableDialogVisible = false;
  title = '';
  processing = false
  globals = Globals
  searchEnabled = true
  underConstruction = false

  @Input() selectedDataTable;
  @Input() selectedDataColumns;

  @Input() courseDetail = null
  @Input() UnitDetail = null
  @Input() testDetail = null
  @Input() userDetail = null
  @Input() batchMode = null

  user = this.utils.global.user
  userType = this.utils.global.userType

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) { }

  ngAfterViewInit(): void {
    this.onAfterViewInit()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.manageChanges(changes)
  }

  ngOnInit() {
    this.onInit()
  }

  onInit() {
    this.initLanguage()
  }

  onAfterViewInit() {

  }

  initLanguage() {
    this.utils.translate
      .get('GENERAL.APP-NAME')
      .subscribe(() => this.onLanguageInit())
  }

  onLanguageInit() {
    this.setGlobalBehaviors()
    if (this.dialogConfig.data && this.componentDataSource !== 'INPUT') {
      this.handleDialogConfigData()
    }
    if (this.componentDataSource === 'ROUTE') {
      this.route.params.subscribe(params => {
        this.handleRouteParams(params)
      })
    }
  }

  handleRouteParams(params: Params) { }
  handleDialogConfigData() { }

  setGlobalBehaviors() {
    this.utils.global.searchEnabled.next(this.searchEnabled)
  }

  manageChanges(changes: SimpleChanges) { }

  back() {
    if (this.dialogConfig.data) {
      this.dialogRef.close()
    } else {
      this.utils.back()
    }
  }

  emitData(data: GenericResponse) {
    this.dialogRef.close(data)
  }
}
