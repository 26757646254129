import { Params } from '@angular/router';
import { BaseDirective } from 'src/app/directives/base.directive';
import { Component, ViewChild } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { CourseItemPrerrequisiteTablePickerComponent } from './course-item-prerrequisite-table-picker/course-item-prerrequisite-table-picker.component';
import { DialogListData } from 'src/app/models/AppGenericClasses';
@Component({
  selector: 'app-course-item-prerequisite',
  templateUrl: './course-item-prerequisite.component.html',
  styleUrls: ['./course-item-prerequisite.component.sass']
})
export class CourseItemPrerequisiteComponent extends BaseDirective {

  @ViewChild('primeNgList') list: BaseListDirective
  courseId = 0
  course: any = null
  prerequisiteIds: number[] = []
  title = 'CORE.PRE-REQUISITES'

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('id' in params) {
      this.courseId = +params['id']
      this.utils.executeRequest(
        this.data.core.courseUnique(this.courseId), {
        onSuccess: (data) => {
          this.course = data
          this.prerequisiteIds = this.course?.course_prerequisites?.map(course => course.el_prerequisite_course_id) || []
        }
      })
    }
  }

  seeCourses() {
    const ref = this.dialog.open(CourseItemPrerrequisiteTablePickerComponent, {
      header: this.utils.translate.instant(
        'FORM.ADD-PREREQUISITE-IN-X',
        { value: this.course.name_ }
      ),
      width: '80%',
      height: '100%',
      styleClass: 'p-overflow-hidden',
      contentStyle: { height: '100%', overflow: 'hidden', padding: '0' },
      data: {
        el_course_id: this.courseId
      } as DialogListData
    })
    ref.onClose.subscribe((data: { elPrerequisiteId: number }[]) => {
      if (data?.length) {
        const objects = data.map((item: { elPrerequisiteId: number }) => {
          return {
            el_course_id: this.courseId,
            el_prerequisite_course_id: item
          }
        })
        this.utils.executeRequest(
          this.data.core.prerequisitedSave({ objects }), {
          onSuccess: (res) => {
            this.handlePrerequisiteSave(res, data)
          }
        })
      }
    })
  }

  handlePrerequisiteSave(data: any, contents) {
    console.log(contents)
    this.prerequisiteIds = [
      ...this.prerequisiteIds,
      ...contents
    ]
    this.utils.toast.saved(this.title)
    this.list.getList()
  }

  handlePrerequisiteDelete(deletedPrerequisite: { elPrerequisiteId: number }) {
    this.prerequisiteIds = this.prerequisiteIds.filter(id => id != deletedPrerequisite['el_prerequisite_course_id'])
  }
}
