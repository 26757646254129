<div class="h-full" [ngClass]="!downloading ? 'hidden' : ''">
  <ng-lottie
    class="w-full"
    [options]="globals.LOTTIE_ANIMATIONS.loadingOptions"
  ></ng-lottie>
</div>
<ng-container *ngTemplateOutlet="img"></ng-container>

<p-galleria
  *ngIf="showResource && gallery"
  [value]="[resource]"
  [(visible)]="showResource"
  [fullScreen]="true"
  [showItemNavigators]="false"
  [showThumbnails]="false"
>
  <ng-template pTemplate="item" let-item>
    <ng-container
      *ngTemplateOutlet="img; context: { height: '80vh' }"
    ></ng-container>
  </ng-template>
</p-galleria>

<ng-template #img let-height="height">
  <img
    [src]="resource || defaultImage"
    class="w-full cursor-pointer"
    alt="CurrentImage"
    [ngClass]="downloading ? 'hidden' : ''"
    [style.height]="height ?? '100%'"
    (click)="seeResource()"
  />
</ng-template>
