import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.sass']
})
export class SignUpComponent extends BaseFormDirective {
  override title = 'AUTH.SIGN-UP'
  override form = {
    first_name: null,
    last_name: null,
    dni_type_id: null,
    dni: null,
    city_id: null,
    email: null,
    password: null,
    gender_id: null,
    user_group_id: null,
    user_type_id: 2,
    user_post_id: null
  }
  path = 'sign-up'

  override onLanguageInit() {
    super.onLanguageInit()
    this.data.load('group')
    this.data.load('user-type')
    this.data.load('dni-type')
    this.data.load('city')
    this.data.load('specialty')
    this.data.load('gender')
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.userCreate(this.formSanitized)
  }
}
