import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.sass']
})
export class UserTypeComponent extends BaseDirective {
  override title = 'MENU.USER-TYPES';
}
