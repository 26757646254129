import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.sass']
})
export class GenderComponent extends BaseDirective {
  override title = 'MENU.GENDERS';
}
