<div class="flex flex-column h-full">
  <div class="flex align-items-center" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h2
        class="module-title cursor-pointer"
        routerLink="/courses/units/sections/{{ form.el_course_unit_id }}"
      >
        {{ "FORM.SECTIONS" | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color)"
      ></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW-F") | translate }}
        {{ "FORM.SECTION" | translate | lowercase }}
      </h2>
    </div>

    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-4">
          <div class="flex flex-column gap-2 my-4">
            <span class="p-float-label">
              <input
                pInputText
                id="name"
                name="name"
                class="ng-dirty w-full"
                aria-describedby="title-help"
                [(ngModel)]="form.name_"
                required
              />
              <label for="title">{{ "FORM.NAME" | translate }}*</label>
            </span>
          </div>

          <div class="flex flex-column gap-2 my-4">
            <div class="flex align-items-end justify-content-between">
              <label for="contents">{{ "CORE.CONTENT" | translate }}*</label>
              <button
                pButton
                pRipple
                class="p-button-text p-button-success"
                icon="pi pi-plus"
                label="{{ 'ENV.NEW' | translate }} {{
                  'CORE.CONTENT' | translate | lowercase
                }}"
                type="button"
                (click)="createContentDialog()"
              ></button>
            </div>
            <p-dropdown
              id="contents"
              name="contents"
              [options]="contents"
              [(ngModel)]="form.content_id"
              [filter]="true"
              class="ng-dirty"
              optionValue="content_id"
              optionLabel="name_"
              appendTo="body"
            >
              <ng-template let-content pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div class="flex align-items-center flex-grow w-full">
                    <span
                      class="material-symbols-outlined"
                      style="font-size: 2rem"
                      [pTooltip]="
                        globals.CONTENT_TYPES[content.type_].label | translate
                      "
                    >
                      {{ globals.CONTENT_TYPES[content.type_].materialIcon }}
                    </span>
                    <span>{{ content.name_ }}</span>
                  </div>
                  <div class="flex">
                    <app-content-trigger-button
                      (click)="onSeeContent()"
                      [item]="content"
                    ></app-content-trigger-button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label for="content">{{
              "FORM.ADDITIONAL-CLASS-DESCRIPTION" | translate
            }}</label>
            <app-html-editor
              name="content"
              [(ngModel)]="form.content"
            ></app-html-editor>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label for="tags">{{ "FORM.REQUIRED-DISPLAY" | translate }}*</label>
            <p-selectButton
              [options]="requiredOptions"
              id="visualize_required"
              name="visualize_required"
              optionLabel="label"
              optionValue="value"
              class="ng-dirty"
              [(ngModel)]="form.visualize_required"
              required
            >
              <ng-template let-item pTemplate>
                {{ item.label | translate }}
              </ng-template>
            </p-selectButton>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
