<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">
        {{ "FORM.SUPPORT-CONTENT" | translate | uppercase }} | {{ sectionName }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-success"
        icon="pi pi-plus"
        label="{{ 'FORM.ADD' | translate }} {{
          'FORM.SUPPORT-CONTENT' | translate | lowercase
        }}"
        (click)="seeSuportContent()"
      ></button>
    </div>
  </div>

  <div class="flex flex-grow-1">
    <div class="flex w-12 lg:flex relative">
      <div class="absolute h-full w-full">
        <p-scrollPanel
          [style]="{ width: '100%', height: '100%' }"
          *ngIf="!loading; else loadingTemplate"
        >
          <ng-container *ngFor="let row of rows; let i = index">
            <div class="row flex w-full" *ngIf="i % 3 === 0">
              <ng-container *ngFor="let item of rows.slice(i, i + 3)">
                <div class="col-3">
                  <app-support-section-item
                    [item]="item"
                    (itemDisabled)="getList()"
                  >
                  </app-support-section-item>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
