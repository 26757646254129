/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, AbstractControl } from '@angular/forms';
import { AppChronometer } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-chronometer',
  templateUrl: './chronometer.component.html',
  styleUrls: ['./chronometer.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChronometerComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ChronometerComponent,
      multi: true,
    }
  ]
})
export class ChronometerComponent implements ControlValueAccessor {

  @Input() required = false;
  chronometer: AppChronometer

  plusHour() {
    this.chronometer.plusHour()
    this.onChange(this.chronometer)
  }
  subtractHour() {
    this.chronometer.subtractHour()
    this.onChange(this.chronometer)
  }

  plusMinute() {
    this.chronometer.plusMinute()
    this.onChange(this.chronometer)
  }
  subtractMinute() {
    this.chronometer.subtractMinute()
    this.onChange(this.chronometer)
  }

  plusSecond() {
    this.chronometer.plusSecond()
    this.onChange(this.chronometer)
  }
  subtractSecond() {
    this.chronometer.subtractSecond()
    this.onChange(this.chronometer)
  }

  public onChange: (value: any) => void;

  writeValue(timer: AppChronometer) {
    this.chronometer = timer;
  }

  registerOnTouched() {
  }

  validate(timer: AbstractControl) {
    return !this.checkValidations(timer.value) && {
      invalid: true
    };
  }

  checkValidations(value) {
    console.log(value);

    const validations = [];
    if (value) {
      validations.push(value.hours <= 24 && value.minutes <= 59 && value.seconds <= 59);
      if (this.required) {
        validations.push((value.hours * 3600 + value.minutes * 60 + value.seconds) > 0);
      }
    }
    return validations.every(validation => validation === true);
  }

  registerOnChange(onChange: (value: any) => void) {
    this.onChange = onChange;
  }
}
