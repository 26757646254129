import { Component } from '@angular/core';
import { BaseListFilterablePrimeDirective } from 'src/app/directives/base-list-filterable-prime.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.sass']
})
export class UserTableComponent extends BaseListFilterablePrimeDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('first_name,last_name', 'FORM.NAME', 150, { frozen: true }),
    new PrimeNGTableColumn('dni_type.abbreviation', 'CORE.DOCUMENT-TYPE', 100, { filterable: true }),
    new PrimeNGTableColumn('dni', 'FORM.DNI', 100, { filterable: true }),
    new PrimeNGTableColumn('city.name_', 'CORE.CITY', 100, { filterable: true }),
    new PrimeNGTableColumn('email', 'AUTH.EMAIL', 100, { filterable: true }),
    new PrimeNGTableColumn('credentials.type.name_', 'CORE.ROLE', 150, { filterable: true }),
    new PrimeNGTableColumn('group.name_', 'MENU.GROUP', 100, { filterable: true }),
    new PrimeNGTableColumn('post.name_', 'FORM.SPECIALTY', 150, { filterable: true })

  ];
  override title = 'MENU.USERS';
  override filterableFields = ['dni', 'first_name', 'last_name', 'email']
  override keys = {
    primary_key: 'user_id',
    label: ''
  }
  override filename = 'MENU.USERS';
  override localUserFiltersKey = "USER-TABLE-COMPONENT"

  override getList(): void {
    this.query(this.data.core.users()).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.userDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }
}
