<div class="flex flex-column h-full">
    <div class="flex" style="height: auto">
        <div class="flex flex-column justify-content-start">
            <h2 class="module-title cursor-pointer" routerLink="/configuration/specialty">
                {{ "FORM.SPECIALTY" | translate | uppercase }}
            </h2>
        </div>
        <div class="flex mx-3">
            <i class="pi pi-angle-double-right" style="font-size: 2rem; color: var(--primary-color)"></i>
        </div>
        <div class="flex flex-grow-1 flex-column justify-content-start">
            <h2 class="module-title">
                {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW") | translate }}
                {{ "FORM.SPECIALTY" | translate | lowercase }}
            </h2>
        </div>
        <div class="flex flex-grow-1 flex-initial align-items-center justify-content-end">
            <button pButton pRipple class="p-button-text" icon="pi pi-cloud-upload" label="{{ 'ENV.SAVE' | translate }}"
                [disabled]="
              !dataForm.valid
            " (click)="dataForm.ngSubmit.emit()" *ngIf="!processing"></button>
        </div>
    </div>
    <p-divider styleClass="divider-slim"></p-divider>
    <div class="flex flex-grow-1 relative">
        <div class="card-form absolute h-full w-full">
            <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
                <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
                    <div class="flex flex-column lg:flex-row mb-4">
                        <div class="flex flex-column flex-grow-1">
                            <div class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
                                <input pInputText id="title" name="title" class="ng-dirty" aria-describedby="title-help"
                                    [(ngModel)]="form.name_" maxlength="100" required />
                                <label Htmlfor="title">{{ "FORM.NAME" | translate }}</label>
                            </div>
                        </div>
                    </div>
                </form>
            </p-scrollPanel>
        </div>
    </div>
</div>