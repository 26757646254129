<div class="flex">
  <p-dropdown
    #dd
    id="test"
    name="test"
    appendTo="body"
    placeholder="{{ 'FORM.SELECT-A-F' | translate }} {{
      ('CORE.TEST' | translate).toLowerCase()
    }}"
    optionLabel="name_"
    [options]="rows"
    [(ngModel)]="templateType"
    [filter]="true"
    aria-describedby="id_user-help"
    class="w-full ng-dirty"
  >
    <ng-template let-item pTemplate="selectedItem">
      <div class="text-capitalize">{{ item.name_ }}</div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="text-capitalize">{{ item.name_ }}</div>
    </ng-template>
  </p-dropdown>
</div>

<button
  pButton
  pRipple
  type="button"
  icon="pi pi-file-pdf"
  class="p-button-text w-100"
  [label]="'GENERAL.GENERATE-PDF' | translate"
  style="position: absolute; top: 20px; right: 60px"
  (click)="generateTablePDF(templateType, itemSelection)"
  [disabled]="!templateType"
></button>
