import { AppJSONSanitizer } from "./AppGenericClasses"
import { Content } from "./AppModels"
import { ContentJSON } from "./AppSources"

export class CourseUnitSectionJSON {
  el_course_unit_section_id = 0
  name_ = ""
  content = ""
  status_ = 0

  resource_content: ContentJSON = new ContentJSON()
  support_contents: ContentJSON[] = []
}

export class CourseUnitSection {
  id: number
  name: string
  content: string
  duration: string
  active: boolean

  resourceContent: Content
  supportContents: Content[]

  source: CourseUnitSectionJSON

  constructor(source: CourseUnitSectionJSON) {
    this.source = new AppJSONSanitizer<CourseUnitSectionJSON>(source, CourseUnitSectionJSON).result
    this.id = this.source.el_course_unit_section_id
    this.name = this.source.name_
    this.content = this.source.content
    this.active = this.source.status_ != 0 ? true : false
    this.resourceContent = new Content(this.source.resource_content)
    this.supportContents = this.source.support_contents.map(content => new Content(content))
  }
}
