import { AppMenuItem } from './../../../models/AppGenericClasses';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { MenuItem } from 'primeng/api';
import { UtilsService } from '../../../services/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-master-container',
  templateUrl: './master-container.component.html',
  styleUrls: ['./master-container.component.sass'],
})
export class MasterContainerComponent implements OnInit {
  optionSidebar = 'test'
  menus: AppMenuItem[] = [
    new AppMenuItem('MENU.DASHBOARD', { icon: 'dashboard', routerLink: '/' }),
    new AppMenuItem('MENU.TOPICS', { icon: 'topic', routerLink: '/topics' }),
    new AppMenuItem('MENU.COURSES', { icon: 'school', routerLink: '/courses' }),
    new AppMenuItem('MENU.CONTENTS', { key: 'CONTENTS', icon: 'file_copy', routerLink: '/contents' }),
    new AppMenuItem('MENU.LIBRARIES', { icon: 'collections_bookmark', routerLink: '/libraries' }),
    new AppMenuItem('MENU.GRADES', { icon: 'grade', routerLink: '/grading' }),
    new AppMenuItem('MENU.TEST-VERSIONS', { icon: 'quiz', routerLink: '/tests' }),
    new AppMenuItem('MENU.REPORTS', { icon: 'query_stats', routerLink: '/reports' }),
    new AppMenuItem('MENU.SETTINGS', { icon: 'settings', routerLink: '/configuration' }),
    //new AppMenuItem('MENU.ADMINISTRATION', { icon: 'shield_person', routerLink: '/admin' }),
  ];
  userMenu: MenuItem[] = [
    {
      label: this.translate.instant('AUTH.LOGOUT'),
      icon: 'pi pi-plus',
      command: () => {
        this.auth.logout();
      },
    },
  ];
  user = this.utils.global.user
  userType = this.utils.global.userType

  accountName = `${this.user?.first_name} ${this.user?.last_name}`;
  sidebarVisible = false;
  searchText = '';
  showReminders = false


  constructor(private auth: AuthService, public utils: UtilsService, private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.userType.could_manage_app) { return }

    if (this.userType.could_manage_course) {
      this.menus = [
        new AppMenuItem('MENU.DASHBOARD', { icon: 'dashboard', routerLink: '/' }),
        new AppMenuItem('MENU.TOPICS', { icon: 'topic', routerLink: '/topics' }),
        new AppMenuItem('MENU.COURSES', { icon: 'school', routerLink: '/courses' }),
        new AppMenuItem('MENU.CONTENTS', { key: 'CONTENTS', icon: 'file_copy', routerLink: '/contents' }),
        new AppMenuItem('MENU.LIBRARIES', { icon: 'collections_bookmark', routerLink: '/libraries' }),
        new AppMenuItem('MENU.GRADES', { icon: 'grade', routerLink: '/grading' }),
        new AppMenuItem('MENU.TEST-VERSIONS', { icon: 'quiz', routerLink: '/tests' }),
        new AppMenuItem('MENU.REPORTS', { icon: 'query_stats', routerLink: '/reports' })
      ];
    } else {
      this.menus = [
        new AppMenuItem('MENU.DASHBOARD', { icon: 'dashboard', routerLink: '/' }),
        new AppMenuItem('MENU.TOPICS', { icon: 'topic', routerLink: '/topics' }),
        new AppMenuItem('MENU.COURSES', { icon: 'school', routerLink: '/courses' }),
        new AppMenuItem('MENU.LIBRARIES', { icon: 'collections_bookmark', routerLink: '/libraries' }),
        new AppMenuItem('MENU.TEST-VERSIONS', { icon: 'quiz', routerLink: '/tests' })
      ];
    }
  }

  onInput(event: any) {
    this.utils.global.search.next(event.target.value);
  }

  seeReminders() {
    this.showReminders = true
  }

  closeReminders() {
    this.showReminders = false
  }
}
