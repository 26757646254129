<div class="flex flex-column h-full">
  <div class="flex align-items-center" style="height: auto">
    <ng-container *ngIf="defaultTopicId > 0 && defaultTopic">
      <div class="flex flex-column justify-content-start">
        <h5 class="module-subtitle">
          {{ "MENU.TOPICS" | translate | uppercase }}
        </h5>
        <h2 class="module-title cursor-pointer" (click)="seeTopic()" pTooltip="{{ 'CORE.TOPIC' | translate }} | {{
            'ENV.SEE-DETAIL' | translate
          }}" tooltipPosition="bottom">
          {{ defaultTopic.name_ }}
        </h2>
      </div>
      <div class="flex mx-4">
        <i class="pi pi-angle-double-right" style="font-size: 2rem; color: var(--primary-color)"></i>
      </div>
    </ng-container>
    <div class="flex flex-column justify-content-start">
      <h2 class="module-title cursor-pointer" routerLink="/courses/units/preview/{{form.el_course_id}}">
        {{ "MENU.UNITS" | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i class="pi pi-angle-double-right" style="font-size: 2rem; color: var(--primary-color)"></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW-F") | translate }}
        {{ "CORE.UNIT" | translate | lowercase }}
      </h2>
    </div>


    <div class="flex flex-grow-1 flex-initial align-items-center justify-content-end">
      <button pButton pRipple class="p-button-text" icon="pi pi-cloud-upload" label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid" (click)="dataForm.ngSubmit.emit()" *ngIf="!processing"></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column lg:flex-row">
            <div class="flex flex-column flex-grow-1">
              <div class="flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
                <span class="p-float-label">
                  <input pInputText id="title" name="title" class="ng-dirty w-full" aria-describedby="title-help"
                    [(ngModel)]="form.name_" required />
                  <label for="title">{{ "FORM.TITLE" | translate }}*</label>
                </span>
              </div>
              <div class="flex flex-column gap-2 mt-2">
                <span class="p-float-label">
                  <textarea pInputTextarea id="description" name="description" rows="5" class="ng-dirty w-full"
                    [(ngModel)]="form.description_" required></textarea>
                  <label for="description">{{
                    "FORM.DESCRIPTION" | translate
                    }}*</label>
                </span>
              </div>
            </div>
          </div>

          <div class="flex flex-column gap-2 my-4">
            <span class="p-float-label">
              <p-inputNumber [showButtons]="true" inputId="estimated_duration" name="estimated_duration"
                [(ngModel)]="form.estimated_duration" class="ng-dirty w-full" [min]="0"
                [required]="true"></p-inputNumber>
              <label for="estimated_duration">{{
                "FORM.ESTIMATED-DURATION" | translate
                }} (En minutos)*</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 my-4" *ngIf="currentPercentage > 0">
            <label for="tags">Obligatoriedad*</label>
            <p-selectButton [options]="requiredOptions" id="content-type" name="content-type" optionLabel="label"
              optionValue="value" class="ng-dirty" [(ngModel)]="form.approval_required"
              (ngModelChange)="onApprovalRequiredChange($event)">
              <ng-template let-item pTemplate>
                {{ item.label | translate }}
              </ng-template>
            </p-selectButton>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label *ngIf="form.approval_required !== 0" for="percentaje">{{
              ("FORM.PERCENTAGE" | translate) +
              ": " +
              form.course_percentage +
              "%"
              }}</label>
            <div>
              <input *ngIf="form.approval_required !== 0" type="text" pInputText id="percentage" name="percentage"
                [(ngModel)]="form.course_percentage" class="w-full" (ngModelChange)="validatePercentage()"
                [required]="true" [max]="currentPercentage" />
              <p-slider *ngIf="form.approval_required !== 0" [(ngModel)]="form.course_percentage" class="w-full"
                id="percentage" name="percentage" [max]="currentPercentage"></p-slider>
            </div>
          </div>

          <div class="flex flex-column gap-2 my-4">
            <label for="tags">{{ "FORM.TAGS" | translate }}</label>
            <p-chips inputId="tags" name="tags" class="w-full" [separator]="globals.REG_EXP.chipFieldSeparator"
              [placeholder]="'WIDGET.CHIP-FIELD-EXAMPLE' | translate" [(ngModel)]="tags"></p-chips>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label for="tags">{{
              "FORM.SEQUENTIAL-SECTIONS" | translate
              }}*</label>
            <p-selectButton [options]="sequentialOptions" id="squential-sections" name="squential-sections"
              optionLabel="label" optionValue="value" class="ng-dirty" [(ngModel)]="form.sequential_sections" required>
              <ng-template let-item pTemplate>
                {{ item.label | translate }}
              </ng-template>
            </p-selectButton>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>