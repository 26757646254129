<div class="flex flex-column h-full">
  <div class="flex" *ngIf="form.dataForm">
    <app-breadcrum [items]="breadcrumItems"></app-breadcrum>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!form.dataForm.valid"
        (click)="form.dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <app-topic-form
    #form
    [editId]="editId"
    [componentDataSource]="'INPUT'"
    class="h-full"
  ></app-topic-form>
</div>
