/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { CourseJSON, Course, CourseParser } from 'src/app/models/CourseClasses';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.sass']
})
export class CourseDetailComponent extends BaseItemDirective<CourseJSON> {
  course!: Course

  refreshActionServices(): void { }

  override onItemReady(): void {
    super.onItemReady()
    console.log(this.item);

    this.course = new CourseParser(this.item, this.utils).result
  }
}
