import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { BaseItemListDirective } from 'src/app/directives/restruct/base-item-list.directive';
import { BaseItemRestructDirective } from 'src/app/directives/restruct/base-item-restruct.directive';
import { Topic } from 'src/app/models/AppModels';
import { TopicJSON } from 'src/app/models/AppSources';
import { Course, CourseJSON, CourseList } from 'src/app/models/CourseClasses';
import { Template, TemplateJSON } from 'src/app/models/Template';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-modal-simple-pdf',
  templateUrl: './modal-simple-pdf.component.html',
  styleUrls: ['./modal-simple-pdf.component.sass']
})
export class ModalSimplePDFComponent extends BaseListDirective {

  override filterableFields = ['name_', 'description_', 'tags']
  templateType = null;

  override filename = 'Test vmo';




  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(utils,
      data,
      route,
      dialog,
      dialogConfig,
      dialogRef)

    this.data.core.wildcardToSelect().subscribe(data => {
      this.wildCards = data;
    })
  }



  override getList() {
    this.query(this.data.core.templateToSelect()).subscribe()
  }



  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  override generatePdf(type, data) {

    super.generatePdf(type, data);
  }

  handleRows(data: any): void {
    console.log(data);

    data = data.filter(r => r.type_ === 'SIMPLE')

    super.handleRows(data);
  }


}






