/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { Params } from '@angular/router';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';
import { DateUtils } from 'src/app/shared/utils/date-utils';

@Component({
  selector: 'app-test-version-form',
  templateUrl: './test-version-form.component.html',
  styleUrls: ['./test-version-form.component.sass'],
})
export class TestVersionFormComponent extends BaseFormDirective {
  override title = 'MENU.TESTS';
  override form: any = {
    el_test_id: 0,
    available_from: null,
    available_to: null,
    allowed_attempts: 1
  };

  timeLimit = 0;
  tags = [];
  versionDateFrom: Date = null;
  versionDateTo: Date = null;
  instantiatedIn: 'GENERAL' | 'TEST-VERSION' = 'GENERAL';

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params);
    if ('el_test_id' in params) {
      this.form.el_test_id = params['el_test_id'];
    }
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData();
    this.form.el_test_id = this.dialogConfig.data.el_test_id ?? this.form.el_test_id;
  }

  override getItemEdit() {
    this.editService = this.data.core.testUnique(this.editId);
    super.getItemEdit();
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.testVersionCreate(this.formSanitized);
  }

  override onSubmit(): void {
    this.form.available_from = DateUtils.dateToFormat(
      this.versionDateFrom,
      'yyyy-MM-dd hh:mm'
    );
    this.form.available_to = DateUtils.dateToFormat(
      this.versionDateTo,
      'yyyy-MM-dd hh:mm'
    );

    super.onSubmit();
  }

  onVersionDateToChange() {
    if (this.versionDateTo < this.versionDateFrom) {
      this.versionDateTo = this.versionDateFrom;
    }
  }

  cleanForm(): void { }

  handleSave(data?: any): void {
    super.handleSave(data)
    this.dialogRef.close({ success: true })
  }
}
