import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ContentTableComponent } from '../../master/content/content-table/content-table.component';
import { GenericResponse } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-content-picker-table',
  templateUrl: './content-picker-table.component.html',
  styleUrls: ['./content-picker-table.component.sass']
})
export class ContentPickerTableComponent extends ContentTableComponent {

  @Output() selected = new EventEmitter<GenericResponse>()
  @Input() showSelectOneButton = true

  handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.showSelectOneButton = this.dialogConfig.data.showSelectOneButton ?? this.showSelectOneButton
  }

  onItemSelected(row: any) {
    this.emitData({ success: true, complement: { data: [row] } })
  }

  onItemSelectedInBatch() {
    this.emitData({ success: true, complement: { data: this.selectedRows } })
  }

  emitData(data: GenericResponse) {
    this.dialogRef.close(data)
    this.selected.emit(data)
  }
}
