
import { BaseFormDirective } from 'src/app/directives/base-form.directive';
import { Component, ViewChild } from '@angular/core';
import { ImageUploadComponent } from "../../../controls/image-upload/image-upload.component";
import { Params } from '@angular/router';
import { TopicItemComponent } from '../../topic/topic-item/topic-item.component';

@Component({
  selector: 'app-unit-form',
  templateUrl: './unit-form.component.html',
  styleUrls: ['./unit-form.component.sass']
})
export class UnitFormComponent extends BaseFormDirective {

  @ViewChild('iuCover') iuCover: ImageUploadComponent | undefined
  override title = 'MENU.UNIT'
  currentPercentage = 0
  requiredOptions: any[] = [
    { label: 'FORM.REQUIRED-F', value: 1 },
    { label: 'FORM.OPTIONAL', value: 0 },

  ];

  sequentialOptions: any[] = [
    { label: 'GENERAL.YES', value: 1 },
    { label: 'GENERAL.NO', value: 0 },

  ];

  override form = {
    name_: null,
    description_: null,
    tags: '',
    estimated_duration: null,
    validity_period: 1,
    course_percentage: 0,
    sequential_sections: 0,
    approval_required: 0,
    el_course_id: 1,
    order_: 1
  }
  tags: never[] = []
  topics: number[] = []
  defaultTopic: any = null;
  defaultTopicId = 0;
  value: number = 50;

  override onLanguageInit() {
    super.onLanguageInit()
    this.data.load('topics')
    this.utils.executeRequest(this.data.core.getPercentages(this.form.el_course_id),
      {
        onSuccess: (percentage) => {
          console.log(percentage)
          this.currentPercentage = percentage
        }
      })
  }

  onApprovalRequiredChange(selectedValue: number): void {
    if (selectedValue === 0) {
      this.form.course_percentage = 0;
    }
  }
  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)

    if ('courseId' in params) {
      this.form.el_course_id = +params['courseId']
    }
  }

  override getItemEdit() {
    this.editService = this.data.core.unitUnique(this.editId)
    super.getItemEdit()
  }

  override handleItemEditSuccess(data: any) {
    super.handleItemEditSuccess(data)
    const tags = data['tags']
    this.tags = tags ? tags.split(',') : []
  }
  validatePercentage() {
    if (this.form.course_percentage > this.currentPercentage) {
      this.form.course_percentage = this.currentPercentage;
    }
  }

  setSubmitMethods(): void {
    this.postService = this.data.core.unitPost(this.formSanitized)
    this.putService = this.data.core.unitUpdate(this.editId, this.formSanitized)
  }

  override onSubmit() {
    this.form.tags = this.tags.join(',')
    console.log(this.form)
    super.onSubmit()
  }

  override cleanForm() {
    super.cleanForm()
    this.topics = []
    this.tags = []
  }

  override handleSave(data: any) {
    super.handleSave(data)
  }

  seeTopic() {
    this.dialog.open(TopicItemComponent, {
      header: this.utils.translate.instant('CORE.TOPIC'),
      data: {
        showActions: false,
        itemId: this.defaultTopicId
      },
      dismissableMask: true
    })
  }
}
