import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-report-take-user-list',
  templateUrl: './report-take-user-list.component.html',
  styleUrls: ['./report-take-user-list.component.sass']
})
export class ReportTakeUserListComponent extends BaseListDirective {
  baseColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'CORE.USER', 350),
  ];
  override title = 'MENU.USERS';
  override filterableFields = [];
  override keys = {
    primary_key: 'user_id',
    label: 'name_',
  };
  isVisibledetailTake = false
  test: any = null
  override filename = 'MENU.USERS';
  override dataFormatter = this.utils.getCourseFormatter();
  selectedTeskTake
  group = null;
  course = null;
  groupsData = []
  coursesData = []
  tempData = [];
  unitsData = [];
  unit = null;

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(
      utils,
      data,
      route,
      dialog,
      dialogConfig,
      dialogRef
    )

    this.data.core.groups().subscribe(data => {
      this.groupsData = data
    })
    this.data.core.coursesSelectable().subscribe(data => {
      this.coursesData = data
    })



  }


  generateColumns() {
    this.availableColumns = [...this.baseColumns];

    for (let index = 0; index < this.tempData[0].allowed_attempts; index++) {
      this.availableColumns.push(
        new PrimeNGTableColumn(`Intento_${index + 1}`, `Intento ${index + 1}`, 350, { frozen: false }, true),
      )

    }
    this.availableColumns.push(
      new PrimeNGTableColumn('action', 'Acción', 350, { frozen: false }, true),
    )
    super.generateColumns();
  }

  openDetailTake(takeId) {

    this.isVisibledetailTake = true;
    this.selectedTeskTake = takeId
  }



  formatData(data: any) {
    this.utils.applyFormat(data, this.dataFormatter);
  }

  override getList(): void {

    if (this.group && this.unit) {
      this.query(this.data.core.reportTakeUserList(
        {
          course_unit: this.unit,
          group_id: this.group
        }
      )).subscribe();
    }
  }


  onChangeCourse() {
    this.unitsData = this.course.units


  }

  generateColumnsUnits(data) {

    this.tempData = data;
    this.generateColumns()
  }

  searchTestByUser(data, user_id) {

    let aux = []


    let takes = data[0].takes.filter(t => t.poll_take.user.user_id === user_id)
    let idx = (takes.length - 1) % data[0].allowed_attempts;

    if (takes.length > data[0].allowed_attempts && idx !== 0) {


      let temp = takes.slice(-idx);



      for (let index = 0; index < data[0].allowed_attempts; index++) {

        if (temp[index] !== undefined) {
          aux[index] = {
            ...temp[index],
            approved: parseFloat(temp[index].result) > parseFloat(data[0].test.min_approval_percentage) ? 1 : 0,
            take_type: `Intento ${index + 1}`,
            [`Intento_${index + 1}`]: temp[index].result

          };


        } else {
          aux[index] = {
            result: 0,
            approved: 2,
            take_type: `Intento ${index + 1}`,
            [`Intento_${index + 1}`]: 0
          }

        }

      }

      return aux;
    }

    if (takes.length > data[0].allowed_attempts && idx === 0) {


      let temp = takes.slice(-data[0].allowed_attempts);



      for (let index = 0; index < data[0].allowed_attempts; index++) {

        if (temp[index] !== undefined) {
          aux[index] = {
            ...temp[index],
            approved: parseFloat(temp[index].result) > parseFloat(data[0].test.min_approval_percentage) ? 1 : 0,
            take_type: `Intento ${index + 1}`,
            [`Intento_${index + 1}`]: temp[index].result
          };

        } else {
          aux[index] = {
            result: 0,
            approved: 2,
            take_type: `Intento ${index + 1}`,
            [`Intento_${index + 1}`]: 0
          }
        }

      }

      return aux;
    }



    if (takes.length === data[0].allowed_attempts) {
      aux = takes.map((t, index) => {
        return {
          ...t,
          approved: parseFloat(t.result) > parseFloat(data[0].test.min_approval_percentage) ? 1 : 0,
          take_type: `Intento ${index + 1}`,
          [`Intento_${index + 1}`]: t.result
        }
      })
      return aux;
    }


    for (let index = 0; index < data[0].allowed_attempts; index++) {
      if (takes[index] !== undefined) {
        aux[index] = {
          ...takes[index],
          approved: parseFloat(takes[index].result) > parseFloat(data[0].test.min_approval_percentage) ? 1 : 0,
          take_type: `Intento ${index + 1}`,
          [`Intento_${index + 1}`]: takes[index].result
        };

      } else {
        aux[index] = {
          result: 0,
          approved: 2,
          take_type: `Intento ${index + 1}`,
          [`Intento_${index + 1}`]: 0
        }
      }
    }

    return aux;





  }


  override handleRows(data: any): void {


    let newData = [];

    this.testDetail = data[0]?.test

    data[0].takes.forEach((take, idx) => {
      let takes = this.searchTestByUser(data, take.poll_take.user.user_id)
      if (idx === 0) {
        newData.push(
          {
            user: take.poll_take.user,
            takes,
            name_: take.poll_take.user.first_name + ' ' + take.poll_take.user.last_name,
            user_id: take.poll_take.user.user_id,
          }
        )

        takes.forEach((tak, j) => {
          newData[idx][`Intento_${j + 1}`] = tak.result
        })


      } else {
        let existUser = newData.find(u => u.user.user_id === take.poll_take.user_id);
        if (!existUser) {
          newData.push(
            {
              user: take.poll_take.user,
              takes,
              name_: take.poll_take.user.first_name + ' ' + take.poll_take.user.last_name,
              user_id: take.poll_take.user.user_id,
            }
          )

          takes.forEach((tak, j) => {
            newData[newData.length - 1][`Intento_${j + 1}`] = tak.result
          });




        }

      }
    });


    this.generateColumnsUnits(data)
    super.handleRows(newData);
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.userDelete(
      this.itemSelected[this.keys.primary_key]
    );
  }

  seeOnlyAvailableUsers(row: any) {
    super.handleItemSelected(row);

    this.utils.appDialog.seeCourseUserAvailableDialog(
      this.itemSelected[this.keys.primary_key],
      this.itemSelected[this.keys.label],
      true,
      {
        onSuccess: () => {
          this.getList();
        },
      }
    );
  }

  seeEnrollments(row: any) {
    this.utils.appDialog.seeCourseEnrollmentsDialog(row[this.keys.primary_key]);
  }

  exportValue(column, row): string {

    if (column.columnType) {
      return this.exportDataUnit(column, row)
    }
    if (column.capitalize) {
      return Utils.capitalizeEachWord(Utils.ngCellValue(column.field, row));
    }
    return Utils.ngCellValue(column.field, row);
  }


  exportDataUnit(column, row) {


    return row.takes.find(r => r.take_type === column.header).result

  }

  desestructureTakes(data) {

    return this.rows;
  }
}

