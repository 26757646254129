<ng-container *ngIf="text">
  <p
    class="mb-2 mt-0"
    *ngIf="text"
    [ngClass]="seeMore || !onStringLength ? '' : 'ellipsis-' + ellipsisLinesNum"
  >
    {{ text }}
  </p>
  <ng-container *ngIf="text.length >= onStringLength">
    <div
      class="underline cursor-pointer font-bold select-none inline p-text-secondary text-sm"
    >
      <div (click)="toggle()" *ngIf="!seeMore">
        {{ "ENV.SEE-MORE" | translate }}...
      </div>
      <div (click)="toggle()" *ngIf="seeMore">
        {{ "ENV.SEE-LESS" | translate }}
      </div>
    </div>
  </ng-container>
</ng-container>
