<div class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3">
  <div
    class="flex flex-column w-12 md:w-12 lg:w-12 px-4 py-3 mb-3 justify-content-between"
  >
    <div class="flex gap-2 align-items-center sm:flex-wrap lg:flex-nowrap">
      <div class="flex w-full align-items-center gap-2">
        <div
          class="flex"
          *ngIf="item.test.poll.questions_count > 0"
          pTooltip="{{
            'WIDGET.UNIT-TEST-HAS-OPEN-QUESTION-WARNING' | translate
          }}"
          tooltipPosition="bottom"
        >
          <span class="material-symbols-outlined text-danger"> warning </span>
        </div>
        <div class="flex-grow-1">
          <a class="card-item-title">{{ item.test.poll.title }}</a>
        </div>
      </div>
      <div class="flex-grow-1">
        <button
          pButton
          pRipple
          icon="pi pi-ellipsis-h"
          class="p-button-text p-button-raised p-button-info"
          (click)="menu.toggle($event)"
        ></button>
        <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
      </div>
    </div>
    <div
      class="content w-100"
      [pTooltip]="item.test.poll.review"
      tooltipPosition="top"
    >
      <p class="p-text-secondary ellipsis-3">
        {{ item.test.poll.review }}
      </p>
    </div>
    <div class="flex w-12 md:w-12 lg:w-12 card-item-tags align-items-center">
      <span class="p-text-secondary font-bold mr-2">
        {{ "FORM.ALLOWED-ATTEMPTS" | translate }}:
      </span>
      <p>{{ item.allowed_attempts }}</p>
    </div>
    <div class="flex flex-column justify-content-end mt-2">
      <div
        class="flex w-12 md:w-12 lg:w-12 card-item-tags align-items-center justify-content-between"
      >
        <span class="p-text-secondary font-bold"
          >{{ "FORM.TIME-LIMIT" | translate }}:</span
        >
        <div class="flex card-item-tags align-items-center">
          <span class="material-symbols-outlined">schedule</span>
          {{ (item.test.time_limit / 60).toFixed(0) + " minuto(s)" }}
        </div>
      </div>
    </div>
  </div>
</div>
