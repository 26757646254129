<div class="grid grid-nogutter gap-2">
  <div class="col">
    <div class="p-text-secondary font-bold mb-2">
      {{ "FORM.MAIN-CONTENT" | translate }}
    </div>
    <app-course-unit-section-content-list
      [rows]="[item.resource_content]"
      [fireActions]="fireActions"
    ></app-course-unit-section-content-list>
  </div>
  <div class="col">
    <ng-container *ngIf="item.support_contents.length">
      <div class="p-text-secondary font-bold mb-2">
        {{ "FORM.SUPPORT-CONTENT" | translate }}
      </div>
      <app-course-unit-section-content-list
        [rows]="item.support_contents"
        [fireActions]="fireActions"
      ></app-course-unit-section-content-list>
    </ng-container>
  </div>
</div>
