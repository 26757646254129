<div class="flex flex-column gap-2" *ngIf="item">
  <app-test-detail
    [showHeader]="showHeader"
    [itemId]="item.elTestId"
    componentDataSource="INPUT"
    [showActions]="false"
  ></app-test-detail>
  <div class="flex flex-column gap-3 px-3">
    <p>
      {{ item.takesCount }} intentos realizados de
      {{ item.allowedAttempts }} intentos permitidos
    </p>
    <p-progressBar
      [value]="item.takesProgress"
      [showValue]="false"
      class="w-full"
      [style]="{ height: '15px' }"
    ></p-progressBar>
    <app-user-test-takes
      [parentId]="takeId"
      [type]="type"
    ></app-user-test-takes>
  </div>
</div>
