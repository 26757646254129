import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-test-take-detail-wrapper',
  templateUrl: './test-take-detail-wrapper.component.html',
  styleUrls: ['./test-take-detail-wrapper.component.sass']
})
export class TestTakeDetailWrapperComponent extends BaseDirective {
  title = 'ENV.TAKE-DETAIL';
  @Input() itemId = 0

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    this.itemId = params["id"] ?? this.itemId
  }
}
