import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-document-type-form',
  templateUrl: './document-type-form.component.html',
  styleUrls: ['./document-type-form.component.sass']
})
export class DocumentTypeFormComponent extends BaseFormDirective {
  override title = 'MENU.DOCUMENTS-TYPES'
  override form = {
    abbreviation: null,
    name_: null
  }
  path = 'document-type'

  override getItemEdit() {
    this.editService = this.data.core.DNITypeUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.DNITypeCreate(this.formSanitized)
    this.putService = this.data.core.DNITypeUpdate(this.editId, this.formSanitized)
  }
}
