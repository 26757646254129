<div class="flex flex-column h-full">
  <div class="flex gap-2">
    <div class="flex-grow-1">
      <div class="flex gap-2 mb-2">
        <div class="flex p-inputgroup bg-transparent" style="max-width: 300px">
          <input
            type="text"
            pInputText
            [(ngModel)]="searchText"
            placeholder="{{ 'ENV.SEARCH' | translate }}"
            class="bg-transparent text-right"
            autocomplete="false"
          />
          <span class="p-inputgroup-addon bg-transparent"
            ><i class="pi pi-search" style="line-height: 1.25"></i
          ></span>
        </div>
        <button
          pButton
          pTooltip="Resetear filtros"
          tooltipPosition="bottom"
          icon="pi pi-filter-slash"
          class="p-button-warning ml-2 mr-4"
          (click)="resetDTFilters()"
        ></button>
      </div>
    </div>
    <div class="flex justify-content-end mb-2 mx-3">
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        (click)="save()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <div class="flex-grow overflow-hidden h-full">
    <p-table
      [columns]="columns"
      [dataKey]="keys.primary_key"
      [value]="rows"
      [loading]="loading"
      [paginator]="true"
      [globalFilterFields]="filterableFields"
      [rows]="rowsPerPage"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="rowsPerPageOptions"
      styleClass="p-datatable-gridlines"
      [(selection)]="selectedRows"
      selectionMode="multiple"
      [scrollable]="true"
      scrollHeight="flex"
      [resizableColumns]="true"
      [currentPageReportTemplate]="
        'GENERAL.SHOWING-X-TO-Y-OF-Z-ROWS'
          | translate
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      "
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3rem" pFrozenColumn>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let col of columns">
            <th
              [pSortableColumn]="col.sortable"
              pFrozenColumn
              [frozen]="col.frozen"
              pResizableColumn
              [pResizableColumnDisabled]="!col.resizable"
              [width]="col.width"
              [style.minWidth.px]="col.resizable ? 0 : col.width"
              *ngIf="col.visible"
            >
              <div class="flex align-items-center gap-2">
                {{ col.header | translate }}
                <p-sortIcon
                  *ngIf="col.sortable"
                  [field]="col.field"
                ></p-sortIcon>
                <div [ngSwitch]="col.field" *ngIf="col.filterable">
                  <div *ngSwitchCase="'group.name_'">
                    <app-filter-generic-group
                      [field]="'group.user_group_id'"
                      [matchModeOptions]="matchModeOptions"
                    >
                    </app-filter-generic-group>
                  </div>
                  <div *ngSwitchCase="'post.name_'">
                    <app-filter-generic-specialty
                      [field]="'post.user_post_id'"
                      [matchModeOptions]="matchModeOptions"
                    >
                    </app-filter-generic-specialty>
                  </div>
                  <div *ngSwitchDefault>
                    <p-columnFilter
                      type="text"
                      [field]="col.sortable"
                      display="menu"
                      class="ml-auto"
                    ></p-columnFilter>
                  </div>
                </div>
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr>
          <td pFrozenColumn>
            <p-tableCheckbox [value]="row"></p-tableCheckbox>
          </td>
          <ng-container *ngFor="let col of columns">
            <td
              pFrozenColumn
              [frozen]="col.frozen"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <div style="white-space: pre-line">
                {{ col.field | ngCellValue : row }}
              </div>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ "WIDGET.DT-EMPTY" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-sidebar
  [(visible)]="isColumnToggleVisible"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '100%', 'max-width': '20em' }"
>
  <app-prime-list-columns-toggle
    [(columns)]="columns"
  ></app-prime-list-columns-toggle>
</p-sidebar>
