import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';
import { Globals } from 'src/app/globals';
import { AppPermissionGroup } from 'src/app/models/AppGenericClasses';
import { AppConfigProviderService } from 'src/app/services/data/app-config-provider.service';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-user-type-form',
  templateUrl: './user-type-form.component.html',
  styleUrls: ['./user-type-form.component.sass']
})
export class UserTypeFormComponent extends BaseFormDirective {
  override title = 'MENU.USERS'
  override form = {
    name_: null
  }
  permissionGroups: AppPermissionGroup[] = []

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
    protected router: Router,
    protected appConfig: AppConfigProviderService
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef, router)
  }

  override onLanguageInit() {
    super.onLanguageInit()
    Object.keys(Globals.USER_TYPE_PERMISSIONS).forEach(key => {
      const permissionGroup = Globals.USER_TYPE_PERMISSIONS[key]
      this.permissionGroups.push(permissionGroup)
      permissionGroup.permissions.forEach(permission => {
        this.form[permission.key] = 0
      })
    })
  }

  override getItemEdit() {
    this.editService = this.data.core.userTypeUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.userTypeCreate(this.formSanitized)
    this.putService = this.data.core.userTypeUpdate(this.editId, this.formSanitized)
  }

  override handleSave(data?: any): void {
    super.handleSave(data)
    this.appConfig.reload()
  }
}
