import { Component } from '@angular/core';

@Component({
  selector: 'app-grading',
  templateUrl: './grading.component.html',
  styleUrls: ['./grading.component.sass']
})
export class GradingComponent {
  title = 'MENU.GRADES';
}
