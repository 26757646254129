import { Component, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { ImageUploadComponent } from 'src/app/components/controls/image-upload/image-upload.component';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';
import { AWSUtils } from 'src/app/shared/utils/aws-utils';
import { ContentFormComponent } from '../../../content/content-form/content-form.component';
import { DevUtils } from 'src/app/shared/utils/dev-utils';

@Component({
  selector: 'app-section-form',
  templateUrl: './section-form.component.html',
  styleUrls: ['./section-form.component.sass']
})
export class SectionFormComponent extends BaseFormDirective {

  @ViewChild('iuCover') iuCover: ImageUploadComponent | undefined
  override title = 'FORM.SECTION'

  contents = [];

  override form = {
    el_course_unit_id: null,
    name_: null,
    content: null,
    order_: 1,
    visualize_required: null,
    content_id: null,
  }
  tags: never[] = []
  topics: number[] = []
  defaultTopic: any = null;
  defaultTopicId = 0;
  value = 50;

  requiredOptions: any[] = [
    { label: 'FORM.REQUIRED-F', value: 1 },
    { label: 'FORM.OPTIONAL', value: 0 },
  ];

  sequentialOptions: any[] = [
    { label: 'GENERAL.YES', value: 1 },
    { label: 'GENERAL.NO', value: 0 },
  ];


  override onLanguageInit() {
    super.onLanguageInit()
    this.loadContents()
    console.log(DevUtils.printTestTakeTablesToReset());
  }

  loadContents() {
    this.data.core.contents().subscribe(data => {
      this.contents = data.map(content => {
        AWSUtils.initResourceData(content, 'content')
        return content
      })
    })
  }

  createContentDialog() {
    this.dialogRef = this.dialog.open(ContentFormComponent, {
      width: '80%',
      height: '100%',
      styleClass: 'p-overflow-hidden',
      contentStyle: { height: '100%', overflow: 'hidden', padding: '0' },
      data: {
        redirect_on_save: false
      },
      maximizable: true
    })
    this.dialogRef.onClose.subscribe((data: any) => {
      if (data?.success) {
        this.loadContents()
      }
    })
  }

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('unitId' in params) {
      this.form.el_course_unit_id = +params['unitId']
    }
  }

  override getItemEdit() {
    this.editService = this.data.core.sectionUnique(this.editId)
    super.getItemEdit()
  }

  setSubmitMethods(): void {
    this.postService = this.data.core.sectionPost(this.formSanitized)
    this.putService = this.data.core.sectionUpdate(this.editId, this.formSanitized)
  }

  onSeeContent() {
    event.stopPropagation();
  }

  override cleanForm() {
    this.form = {
      el_course_unit_id: null,
      name_: null,
      content: null,
      order_: null,
      visualize_required: null,
      content_id: null,
    }
    super.cleanForm()
  }
}

