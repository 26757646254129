import { Component } from '@angular/core';
import { CourseItemComponent } from '../course-item/course-item.component';

@Component({
  selector: 'app-course-item-inactive',
  templateUrl: './course-item-inactive.component.html',
  styleUrls: ['./course-item-inactive.component.sass']
})
export class CourseItemInactiveComponent extends CourseItemComponent {

}
