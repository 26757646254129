import { Component, Input, SimpleChanges } from '@angular/core';
import { BaseItemListDirective } from 'src/app/directives/restruct/base-item-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { TestTakeAttempt, TestTakeAttemptJSON, TestTakeAttemptList } from 'src/app/models/TestTakeAttemptClasses';

@Component({
  selector: 'app-user-test-takes',
  templateUrl: './user-test-takes.component.html',
  styleUrls: ['./user-test-takes.component.sass']
})
export class UserTestTakesComponent extends BaseItemListDirective<TestTakeAttempt, TestTakeAttemptJSON, TestTakeAttemptList> {
  @Input() parentId = 0
  @Input() type: 'V' | 'U' | 'C' = 'V'
  ListModel = TestTakeAttemptList;

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('attempt', 'FORM.ATTEMPT-NUM', 150, { type: 'NUMBER' }),
    new PrimeNGTableColumn('formatter.timeFrom', 'FORM.START-DATE', 300, { type: 'DATE-TIME' }),
    new PrimeNGTableColumn('formatter.timeTo', 'FORM.END-DATE', 300, { type: 'DATE-TIME' }),
    new PrimeNGTableColumn('formatter.result', 'FORM.RESULT', 150, { type: 'NUMBER' })
  ];
  override title = 'Evaluaciones calificadas';
  override filename = 'MENU.TESTS';


  getList(): void {
    switch (this.type) {
      case 'V':
        this.query(this.data.core.testVersionTestTakes(this.parentId)).subscribe()
        break;
      case 'U':
        this.query(this.data.core.unitTakeTestTakes(this.parentId)).subscribe()
        break;
    }
  }

  refreshActionServices(): void {
    throw new Error('Method not implemented.');
  }

  manageChanges(changes: SimpleChanges): void {
    super.manageChanges(changes)
    if ('parentId' in changes) {
      this.initComponent()
    }
  }
}
