import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.sass']
})
export class SectionsComponent extends BaseListDirective {

  override filterableFields = ['name_', 'description_', 'tags']
  override orderKey = 'order_';
  unitName = '';
  courseId = null;
  unitId = null;

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef)
  }

  onInit(): void {
    this.courseId = this.route.snapshot.paramMap.get('courseId')
    this.unitId = this.route.snapshot.paramMap.get('id')
    this.unitName = localStorage.getItem('unit_name') ?? ''
    super.onInit()
  }

  override getList() {
    this.query(this.data.core.unitSections(this.unitId)).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  setReorderMethod() {
    this.updateOrderService = this.data.core.sectionUpdateInBatch({
      objects: this.rows
    });
    super.setReorderMethod();
  }
}
