import { HtmlEditorComponent } from './components/controls/html-editor/html-editor.component';
import { ContentTriggerButtonComponent } from './components/shared/content-trigger-button/content-trigger-button.component';
import { BaseFileUploaderDirective } from './directives/controls/base-file-uploader.directive';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthContainerComponent } from './components/auth/auth-container/auth-container.component';
import { MasterContainerComponent } from './components/master/master-container/master-container.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { PasswordRecoverComponent } from './components/auth/password-recover/password-recover.component';
import { PasswordResetComponent } from './components/auth/password-reset/password-reset.component';
import { CourseComponent, DashboardCoursesComponent } from './components/master/course/course.component';
import { CourseFormComponent } from './components/master/course/course-form/course-form.component';
import { TopicComponent } from './components/master/topic/topic.component';
import { TopicFormComponent } from './components/master/topic/topic-form/topic-form.component';
import { CardModule } from "primeng/card";
import { LottieModule } from "ngx-lottie";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import player from 'lottie-web';
import awsconfig from './../aws-exports';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { TooltipModule } from "primeng/tooltip";
import { ButtonModule } from "primeng/button";
import { MenuModule } from "primeng/menu";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BaseDirective } from './directives/base.directive';
import { ConfirmationDialogComponent } from './components/controls/confimation-dialog/confirmation-dialog.component';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from "primeng/dynamicdialog";
import { MessageService } from "primeng/api";
import { AppConfigProviderService } from "./services/data/app-config-provider.service";
import { SidebarModule } from "primeng/sidebar";
import { RippleModule } from "primeng/ripple";
import { DividerModule } from "primeng/divider";
import { InputTextareaModule } from "primeng/inputtextarea";
import { EditorModule } from "primeng/editor";
import { ChipsModule } from "primeng/chips";
import { InputNumberModule } from "primeng/inputnumber";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { CourseItemComponent } from './components/master/course/course-item/course-item.component';
import { ColorChipComponent } from './components/controls/color-chip/color-chip.component';
import { Amplify } from "aws-amplify";
import { ImageUploadComponent } from './components/controls/image-upload/image-upload.component';
import { DragDropComponent } from './components/controls/drag-drop/drag-drop.component';
import { DragDropDirective } from './directives/controls/drag-drop.directive';
import { DragDropValidatorDirective } from './directives/controls/drag-drop-validator.directive';
import { ImageViewerComponent } from './components/controls/image-viewer/image-viewer.component';
import { ImageViewerWrapperDirective } from './directives/controls/image-viewer-wrapper.directive';
import { ToastModule } from "primeng/toast";
import { TopicItemComponent } from './components/master/topic/topic-item/topic-item.component';
import { TopicCoursesComponent } from './components/master/topic/topic-courses/topic-courses.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ContentComponent } from './components/master/content/content.component';
import { ContentTableComponent } from './components/master/content/content-table/content-table.component';
import { ContentFormComponent } from './components/master/content/content-form/content-form.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { PrimeListColumnsToggleComponent } from './components/controls/prime/prime-list-columns-toggle/prime-list-columns-toggle.component';
import { PrimeNgExportTableComponent } from './components/controls/prime/prime-ng-export-table/prime-ng-export-table.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ResourceNotFoundComponent } from './components/shared/resource-not-found/resource-not-found.component';
import { BaseResourceListDirective } from './directives/base-resource-list.directive';
import { MediaUploaderComponent } from './components/controls/media-uploader/media-uploader.component';
import { AwsVideoPlayerComponent } from './components/controls/aws-video-player/aws-video-player.component';
import { AwsVideoViewerComponent } from './components/controls/aws-video-viewer/aws-video-viewer.component';
import { AwsImageViewerComponent } from './components/controls/aws-image-viewer/aws-image-viewer.component';
import { AwsHtmlViewerComponent } from './components/controls/aws-html-viewer/aws-html-viewer.component';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { AwsAudioPlayerComponent } from './components/controls/aws-audio-player/aws-audio-player.component';
import { ReplaceFilesWarningComponent } from './components/shared/replace-files-warning/replace-files-warning.component';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { BaseHtmlElementResourceDirective } from './directives/base-html-element-resource.directive';
import { AwsUploadedFileListComponent } from './components/shared/aws-uploaded-file-list/aws-uploaded-file-list.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ConfigurationComponent } from './components/master/configuration/configuration.component';
import { ColorComponent } from './components/master/configuration/color/color.component';
import { ColorFormComponent } from './components/master/configuration/color/color-form/color-form.component';
import { DocumentTypeComponent } from './components/master/configuration/document-type/document-type.component';
import { DocumentTypeFormComponent } from './components/master/configuration/document-type/document-type-form/document-type-form.component';
import { GenderComponent } from './components/master/configuration/gender/gender.component';
import { GenderFormComponent } from './components/master/configuration/gender/gender-form/gender-form.component';
import { CityComponent } from './components/master/configuration/city/city.component';
import { CityFormComponent } from './components/master/configuration/city/city-form/city-form.component';
import { UserComponent } from './components/master/configuration/user/user.component';
import { UserFormComponent } from './components/master/configuration/user/user-form/user-form.component';
import { CityTableComponent } from './components/master/configuration/city/city-table/city-table.component';
import { ColorTableComponent } from './components/master/configuration/color/color-table/color-table.component';
import { DocumentTypeTableComponent } from './components/master/configuration/document-type/document-type-table/document-type-table.component';
import { GenderTableComponent } from './components/master/configuration/gender/gender-table/gender-table.component';
import { UserTableComponent } from './components/master/configuration/user/user-table/user-table.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { NgCellValuePipe } from './pipes/ng-cell-value.pipe';
import { NestedValuePipe } from './pipes/nested-value.pipe';
import { LibraryComponent } from './components/master/library/library.component';
import { LibraryTableComponent } from './components/master/library/library-table/library-table.component';
import { LibraryFormComponent } from './components/master/library/library-form/library-form.component';
import { LibraryContentComponent } from './components/master/library/library-content/library-content.component';
import { LibraryContentTableComponent } from './components/master/library/library-content/library-content-table/library-content-table.component';
import { ContentPickerTableComponent } from './components/controls/content-picker-table/content-picker-table.component';
import { VideoUrlFieldComponent } from './components/controls/video-url-field/video-url-field.component';
import { VideoUrlPlayerComponent } from './components/controls/video-url-player/video-url-player.component';
import { CourseItemPrerequisiteComponent } from './components/master/course/course-item/course-item-prerequisite/course-item-prerequisite.component';
import { CourseItemEnrolledUsersComponent } from './components/master/course/course-item/course-item-enrolled-users/course-item-enrolled-users.component';
import { UserPickerTableComponent, LibraryUserPickerTableComponent, CourseUserPickerTableComponent, TestVersionUserPickerTableComponent } from './components/controls/user-picker-table/user-picker-table.component';
import { ModuleMenuComponent } from './components/shared/module-menu/module-menu.component';
import { ModuleMenuWrapperComponent } from './components/shared/module-menu-wrapper/module-menu-wrapper.component';
import { AdminComponent } from './components/master/admin/admin.component';
import { TestComponent } from './components/master/admin/test/test.component';
import { TestTableComponent } from './components/master/admin/test/test-table/test-table.component';
import { TestFormComponent } from './components/master/admin/test/test-form/test-form.component';
import { CheckboxModule } from 'primeng/checkbox';
import { SliderModule } from 'primeng/slider';
import { ProgressBarModule } from 'primeng/progressbar';

import { TestDetailWrapperComponent } from './components/master/admin/test/test-detail-wrapper/test-detail-wrapper.component';
import { TestQuestionComponent } from './components/master/admin/test/test-question/test-question.component';
import { TestQuestionTableComponent } from './components/master/admin/test/test-question/test-question-table/test-question-table.component';
import { TestQuestionFormComponent } from './components/master/admin/test/test-question/test-question-form/test-question-form.component';
import { CustomTabSelectorComponent } from './components/controls/custom-tab-selector/custom-tab-selector.component';
import { CoursePrerrequisiteTableComponent } from './components/master/course/course-item/course-item-prerequisite/course-prerrequisite-table/course-prerrequisite-table.component';
import { CourseEnrolledUsersTableComponent } from './components/master/course/course-item/course-item-enrolled-users/course-enrolled-users-table/course-enrolled-users-table.component';
import { CourseItemPrerrequisiteTablePickerComponent } from './components/master/course/course-item/course-item-prerequisite/course-item-prerrequisite-table-picker/course-item-prerrequisite-table-picker.component';
import { TestDetailComponent } from './components/shared/test-detail/test-detail.component';
import { LibraryDetailComponent } from './components/shared/library-detail/library-detail.component';
import { TestQuestionDetailComponent } from './components/shared/test-question-detail/test-question-detail.component';
import { TestVersionComponent } from './components/master/test-version/test-version.component';
import { TestVersionTableComponent } from './components/master/test-version/test-version-table/test-version-table.component';
import { CalendarModule } from 'primeng/calendar';
import { CourseViewComponent } from './components/master/course/course-view/course-view.component';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { CoursePreviewWrapperComponent } from './components/master/course/course-preview-wrapper/course-preview-wrapper.component';
import { AvatarModule } from 'primeng/avatar';
import { CoursePreviewComponent } from './components/master/course/course-preview/course-preview.component';
import { UnitsComponent } from './components/master/units/units.component';
import { UnitItemComponent } from './components/master/units/unit-item/unit-item.component';
import { UnitFormComponent } from './components/master/units/unit-form/unit-form.component';
import { EvaluationsComponent } from './components/master/units/evaluations/evaluations.component';
import { SectionsComponent } from './components/master/units/sections/sections.component';
import { SectionItemComponent } from './components/master/units/sections/section-item/section-item.component';
import { TagModule } from 'primeng/tag';
import { CommonModule } from '@angular/common';
import { SuportSectionComponent } from './components/master/units/sections/suport-section/suport-section.component';
import { EvaluationItemComponent } from './components/master/units/evaluations/evaluation-item/evaluation-item.component';
import { EvaluationsTableComponent } from './components/controls/evaluations-table/evaluations-table.component';
import { SectionFormComponent } from './components/master/units/sections/section-form/section-form.component';
import { SupportSectionContentComponent } from './components/master/units/sections/support-section-content/support-section-content.component';
import { SupportSectionItemComponent } from './components/master/units/sections/support-section-content/support-section-item/support-section-item.component';
import { SupportSectionContentTableComponent } from './components/master/units/sections/support-section-content/support-section-content-table/support-section-content-table.component';
import { TestVersionAllComponent } from './components/master/test-version/test-version-table/test-version-all/test-version-all.component';
import { TestVersionTableAllComponent } from './components/master/test-version/test-version-table/test-version-all/test-version-table-all/test-version-table-all.component';
import { TestTakeComponent } from './components/master/admin/test/test-take/test-take.component';
import { TestTakeDetailComponent } from './components/master/admin/test/test-take/test-take-detail/test-take-detail.component';
import { KnobModule } from 'primeng/knob';
import { GroupComponent } from './components/master/configuration/group/group.component';
import { GroupTableComponent } from './components/master/configuration/group/group-table/group-table.component';
import { GroupFormComponent } from './components/master/configuration/group/group-form/group-form.component';
import { FilterGenericGroupComponent } from './components/controls/filter-generic-group/filter-generic-group.component';
import { BaseListFilterablePrimeDirective } from './directives/base-list-filterable-prime.directive';
import { BaseListSourceDirective } from './directives/base-list-source.directive';
import { BaseGenericFilterDirective } from './directives/base-generic-filter.directive';
import { TestPickerTableComponent, TestVersionFormTableComponent, UnitTestPickerTableComponent } from './components/controls/test-picker-table/test-picker-table.component';
import { TestVersionFormComponent } from './components/master/test-version/test-version-form/test-version-form.component';
import { SpecialtyComponent } from './components/master/configuration/specialty/specialty.component';
import { SpecialtyFormComponent } from './components/master/configuration/specialty/specialty-form/specialty-form.component';
import { SpecialtyTableComponent } from './components/master/configuration/specialty/specialty-table/specialty-table.component';
import { FilterGenericSpecialtyComponent } from './components/controls/filter-generic-specialty/filter-generic-specialty.component';
import { PendingUserComponent } from './components/master/configuration/pending-user/pending-user.component';
import { PendingUserTableComponent } from './components/master/configuration/pending-user/pending-user-table/pending-user-table.component';
import { TestTakeFormComponent } from './components/shared/test-take-form/test-take-form.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { GradingComponent } from './components/master/grading/grading.component';
import { GradingTableComponent } from './components/master/grading/grading-table/grading-table.component';
import { GradedTestTableComponent } from './components/master/grading/graded-test/graded-test-table/graded-test-table.component';
import { PendingGradedTestTableComponent } from './components/master/grading/pending-graded-test/pending-graded-test-table/pending-graded-test-table.component';
import { GradedTestComponent } from './components/master/grading/graded-test/graded-test.component';
import { PendingGradedTestComponent } from './components/master/grading/pending-graded-test/pending-graded-test.component';
import { BreadcrumComponent } from './components/shared/breadcrum/breadcrum.component';
import { TopicFormWrapperComponent } from './components/master/topic/topic-form-wrapper/topic-form-wrapper.component';
import { DashboardComponent } from './components/master/dashboard/dashboard.component';
import { CourseListComponent } from './components/master/course/course-list/course-list.component';
import { TestVersionTestFormComponent } from './components/master/test-version/test-version-test-form/test-version-test-form.component';
import { UnitTestFormComponent } from './components/master/units/unit-test-form/unit-test-form.component';
import { UnitTestAddDataFormComponent } from './components/master/units/unit-test-add-data-form/unit-test-add-data-form.component';
import { RatePendingTestFormComponent } from './components/master/grading/rate-pending-test-form/rate-pending-test-form.component';
import { TestTakeSummaryComponent } from './components/master/admin/test/test-take/test-take-summary/test-take-summary.component';
import { TestTakeDetailWrapperComponent } from './components/master/admin/test/test-take/test-take-detail-wrapper/test-take-detail-wrapper.component';
import { CourseUnitSectionContentsComponent } from './components/shared/course-unit-section-contents/course-unit-section-contents.component';
import { CourseUnitSectionContentListComponent } from './components/shared/course-unit-section-content-list/course-unit-section-content-list.component';
import { CourseUnitSectionContentItemComponent } from './components/shared/course-unit-section-content-item/course-unit-section-content-item.component';
import { CourseTestSummaryComponent } from './components/shared/course-test-summary/course-test-summary.component';
import { AppChipComponent, EnrollmentStatusChipComponent, TestApprovalRequiredChipComponent } from './components/controls/app-chip/app-chip.component';
import { ChronometerComponent } from './components/controls/chronometer/chronometer.component';
import { ZeroPipe } from './pipes/zero.pipe';
import { OrderListModule } from 'primeng/orderlist';
import { SortableListComponent } from './components/controls/sortable-list/sortable-list.component';
import { FilterContentTypeComponent } from './components/controls/filter-content-type/filter-content-type.component';
import { FilterCommaSeparatedComponent } from './components/controls/filter-comma-separated/filter-comma-separated.component';
import { ReportComponent } from './components/master/report/report.component';
import { ReportCoursesComponent } from './components/master/report/report-courses/report-courses.component';
import { ReportCourseTableComponent } from './components/master/report/report-courses/report-course-table/report-course-table.component';
import { ReportCourseUserComponent } from './components/master/report/report-courses/report-course-table/report-course-user/report-course-user.component';
import { ReportCourseUserTableComponent } from './components/master/report/report-courses/report-course-table/report-course-user/report-course-user-table/report-course-user-table.component';
import { SectionTagsComponent } from './components/controls/section-tags/section-tags.component';
import { CourseSummarizedInfoComponent } from './components/shared/course-summarized-info/course-summarized-info.component';
import { CourseBannerComponent } from './components/shared/course-banner/course-banner.component';
import { CourseActionButtonsComponent } from './components/shared/course-action-buttons/course-action-buttons.component';
import { CourseDetailComponent } from './components/shared/course-detail/course-detail.component';
import { UserTypeComponent } from './components/master/configuration/user-type/user-type.component';
import { UserTypeFormComponent } from './components/master/configuration/user-type/user-type-form/user-type-form.component';
import { UserTypeTableComponent } from './components/master/configuration/user-type/user-type-table/user-type-table.component';
import { CustomToggleButtonComponent } from './components/controls/custom-toggle-button/custom-toggle-button.component';
import { AttemptableTestDetailComponent } from './components/shared/attemptable-test-detail/attemptable-test-detail.component';
import { UserTestTakesComponent } from './components/shared/user-test-takes/user-test-takes.component';
import { TextWrapperComponent } from './components/controls/text-wrapper/text-wrapper.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TestQuestionWeightDetailComponent } from './components/shared/test-question-weight-detail/test-question-weight-detail.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CourseItemInactiveComponent } from './components/master/course/course-item-inactive/course-item-inactive.component';
import { ReminderListComponent } from './components/shared/reminder-list/reminder-list.component';
import { CourseUserDetailComponent } from './components/shared/course-user-detail/course-user-detail.component';
import { CarouselModule } from 'primeng/carousel';
import { UnderConstructionComponent } from './components/shared/under-construction/under-construction.component';
import { AvailableTestVersionListComponent } from './components/shared/available-test-version-list/available-test-version-list.component';
import { AvailableLibrariesListComponent } from './components/shared/available-libraries-list/available-libraries-list.component';
import { ConfirmAccountComponent } from './components/auth/confirm-account/confirm-account.component';
import { ReportTakeEvaluationComponent } from './components/master/report/report-take-evaluation/report-take-evaluation.component';
import { ReportTakeEvaluationListComponent } from './components/master/report/report-take-evaluation/report-take-evaluation-list/report-take-evaluation-list.component';
import { NotificationsComponent } from './components/shared/notifications/notifications.component';
import { ReportTakeUserComponent } from './components/master/report/report-take-user/report-take-user.component';
import { ReportTakeUserListComponent } from './components/master/report/report-take-user/report-take-user-list/report-take-user-list.component';
import { TemplateComponent } from './components/master/configuration/template/template.component';
import { TemplateTableComponent } from './components/master/configuration/template/template-table/template-table.component';
import { TemplateFormComponent } from './components/master/configuration/template/template-form/template-form.component';
import { QuillModule } from 'ngx-quill';
import { HtmlCodeEditorComponent } from './components/master/configuration/template/html-code-editor/html-code-editor.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { WilcardFilterComponent } from './components/master/configuration/template/wilcard-filter/wilcard-filter.component';
import { WildcardsComponent } from './components/master/configuration/wildcards/wildcards.component';
import { WildCardTableComponent } from './components/master/configuration/wildcards/wild-card-table/wild-card-table.component';
import { WildCardFormComponent } from './components/master/configuration/wildcards/wild-card-form/wild-card-form.component';
import { ModalSimplePDFComponent } from './components/master/modal-simple-pdf/modal-simple-pdf.component';
import { ModalTablePDFComponent } from './components/master/modal-table-pdf/modal-table-pdf.component';
import { DataTreatmentComponent } from './components/shared/data-treatment/data-treatment.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function playerFactory() {
  return player
}

export function AppConfigProviderFactory(provider: AppConfigProviderService) {
  return () => provider.load()
}

@NgModule({
  declarations: [
    AppComponent,
    AuthContainerComponent,
    MasterContainerComponent,
    SignUpComponent,
    SignInComponent,
    PasswordRecoverComponent,
    PasswordResetComponent,
    CourseComponent,
    CourseFormComponent,
    TopicComponent,
    TopicFormComponent,
    BaseDirective,
    ConfirmationDialogComponent,
    CourseItemComponent,
    ColorChipComponent,
    ImageUploadComponent,
    DragDropComponent,
    DragDropDirective,
    DragDropValidatorDirective,
    ImageViewerComponent,
    ImageViewerWrapperDirective,
    TopicItemComponent,
    TopicCoursesComponent,
    ContentComponent,
    ContentTableComponent,
    ContentFormComponent,
    PrimeListColumnsToggleComponent,
    PrimeNgExportTableComponent,
    ResourceNotFoundComponent,
    BaseResourceListDirective,
    MediaUploaderComponent,
    AwsVideoPlayerComponent,
    AwsVideoViewerComponent,
    AwsImageViewerComponent,
    AwsHtmlViewerComponent,
    AwsAudioPlayerComponent,
    ReplaceFilesWarningComponent,
    BaseHtmlElementResourceDirective,
    AwsUploadedFileListComponent,
    BaseFileUploaderDirective,
    ConfigurationComponent,
    ColorComponent,
    ColorFormComponent,
    DocumentTypeComponent,
    DocumentTypeFormComponent,
    GenderComponent,
    GenderFormComponent,
    CityComponent,
    CityFormComponent,
    UserComponent,
    UserFormComponent,
    CityTableComponent,
    ColorTableComponent,
    DocumentTypeTableComponent,
    GenderTableComponent,
    UserTableComponent,
    FormatBytesPipe,
    ContentTriggerButtonComponent,
    NgCellValuePipe,
    NestedValuePipe,
    LibraryComponent,
    LibraryTableComponent,
    LibraryFormComponent,
    LibraryContentComponent,
    LibraryContentTableComponent,
    ContentPickerTableComponent,
    VideoUrlFieldComponent,
    HtmlEditorComponent,
    VideoUrlPlayerComponent,
    CourseItemPrerequisiteComponent,
    CourseItemEnrolledUsersComponent,
    UserPickerTableComponent,
    LibraryUserPickerTableComponent,
    CourseUserPickerTableComponent,
    ModuleMenuComponent,
    ModuleMenuWrapperComponent,
    AdminComponent,
    TestComponent,
    TestTableComponent,
    TestFormComponent,
    TestDetailWrapperComponent,
    TestQuestionComponent,
    TestQuestionTableComponent,
    TestQuestionFormComponent,
    CustomTabSelectorComponent,
    CoursePrerrequisiteTableComponent,
    CourseEnrolledUsersTableComponent,
    CourseItemPrerrequisiteTablePickerComponent,
    TestDetailComponent,
    LibraryDetailComponent,
    TestQuestionDetailComponent,
    TestVersionComponent,
    TestVersionTableComponent,
    TestVersionUserPickerTableComponent,
    CourseViewComponent,
    CoursePreviewWrapperComponent,
    CoursePreviewComponent,
    UnitsComponent,
    UnitItemComponent,
    UnitFormComponent,
    EvaluationsComponent,
    SectionsComponent,
    SectionItemComponent,
    SuportSectionComponent,
    EvaluationItemComponent,
    EvaluationsTableComponent,
    SectionFormComponent,
    SupportSectionContentComponent,
    SupportSectionItemComponent,
    SupportSectionContentTableComponent,
    TestVersionTableAllComponent,
    TestVersionAllComponent,
    TestTakeComponent,
    TestTakeDetailComponent,
    GroupComponent,
    GroupTableComponent,
    GroupFormComponent,
    FilterGenericGroupComponent,
    BaseListFilterablePrimeDirective,
    BaseListSourceDirective,
    BaseGenericFilterDirective,
    TestPickerTableComponent,
    TestVersionFormComponent,
    SpecialtyComponent,
    SpecialtyFormComponent,
    SpecialtyTableComponent,
    FilterGenericSpecialtyComponent,
    PendingUserComponent,
    PendingUserTableComponent,
    TestTakeFormComponent,
    GradingComponent,
    GradingTableComponent,
    GradedTestTableComponent,
    PendingGradedTestTableComponent,
    GradedTestComponent,
    PendingGradedTestComponent,
    BreadcrumComponent,
    TopicFormWrapperComponent,
    DashboardComponent,
    DashboardCoursesComponent,
    CourseListComponent,
    TestVersionFormTableComponent,
    TestVersionTestFormComponent,
    UnitTestFormComponent,
    UnitTestAddDataFormComponent,
    UnitTestPickerTableComponent,
    RatePendingTestFormComponent,
    TestTakeSummaryComponent,
    TestTakeDetailWrapperComponent,
    CourseUnitSectionContentsComponent,
    CourseUnitSectionContentListComponent,
    CourseUnitSectionContentItemComponent,
    EnrollmentStatusChipComponent,
    CourseTestSummaryComponent,
    TestApprovalRequiredChipComponent,
    AppChipComponent,
    ChronometerComponent,
    ZeroPipe,
    SortableListComponent,
    FilterContentTypeComponent,
    FilterCommaSeparatedComponent,
    ReportComponent,
    ReportCoursesComponent,
    ReportCourseTableComponent,
    ReportCourseUserComponent,
    ReportCourseUserTableComponent,
    FilterCommaSeparatedComponent,
    SectionTagsComponent,
    CourseSummarizedInfoComponent,
    CourseBannerComponent,
    CourseActionButtonsComponent,
    CourseDetailComponent,
    UserTypeComponent,
    UserTypeFormComponent,
    UserTypeTableComponent,
    CustomToggleButtonComponent,
    AttemptableTestDetailComponent,
    UserTestTakesComponent,
    TextWrapperComponent,
    TestQuestionWeightDetailComponent,
    CourseItemInactiveComponent,
    ReminderListComponent,
    CourseUserDetailComponent,
    UnderConstructionComponent,
    AvailableTestVersionListComponent,
    AvailableLibrariesListComponent,
    ConfirmAccountComponent,
    ReportTakeEvaluationComponent,
    ReportTakeEvaluationListComponent,
    NotificationsComponent,
    ReportTakeUserComponent,
    ReportTakeUserListComponent,
    TemplateComponent,
    TemplateTableComponent,
    TemplateFormComponent,
    HtmlCodeEditorComponent,
    WilcardFilterComponent,
    WildcardsComponent,
    WildCardTableComponent,
    WildCardFormComponent,
    ModalSimplePDFComponent,
    ModalTablePDFComponent,
    DataTreatmentComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CardModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LottieModule.forRoot({ player: playerFactory }),
    MonacoEditorModule.forRoot(),
    ReactiveFormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    MenuModule,
    ScrollPanelModule,
    BrowserAnimationsModule,
    SidebarModule,
    RippleModule,
    DividerModule,
    ColorPickerModule,
    InputTextareaModule,
    EditorModule,
    ChipsModule,
    InputNumberModule,
    DropdownModule,
    MultiSelectModule,
    ToastModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    RadioButtonModule,
    TableModule,
    SplitButtonModule,
    SelectButtonModule,
    DynamicDialogModule,
    DialogModule,
    GalleriaModule,
    VgBufferingModule,
    VgControlsModule,
    VgCoreModule,
    VgOverlayPlayModule,
    MessageModule,
    MessagesModule,
    CheckboxModule,
    SliderModule,
    TagModule,
    CalendarModule,
    TabViewModule,
    AccordionModule,
    KnobModule,
    AvatarModule,
    KnobModule,
    DragDropModule,
    YouTubePlayerModule,
    OrderListModule,
    OverlayPanelModule,
    InputSwitchModule,
    CarouselModule,
    QuillModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfigProviderFactory,
      deps: [AppConfigProviderService],
      multi: true
    },
    DialogService,
    MessageService,
    DynamicDialogConfig,
    DynamicDialogRef
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private translate: TranslateService) {
    Amplify.configure(awsconfig)
    translate.setDefaultLang('es')
  }

}
