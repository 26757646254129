/* eslint-disable @typescript-eslint/no-empty-function */
import { Observable } from 'rxjs';
import { BaseDirective } from '../base.directive';
import { Directive, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Utils } from '../../shared/utils/utils';
import { Params } from '@angular/router';
import { AppItem } from '../../models/AppModels';
import { UtilsService } from '../../services/utils/utils.service';

@Directive({
  selector: '[appBaseItemRestruct]',
})
export abstract class BaseItemRestructDirective<Item extends AppItem, Source> extends BaseDirective {
  @Input() itemId = 0
  @Input() source: Source = null
  @Input() item: Item = null;
  @Input() showName = true;
  @Input() showDescription = true;
  @Input() showActions = true;
  @Input() fireActions = true;
  @Input() presentation = 1;
  @Output() requestReload: EventEmitter<any> = new EventEmitter();
  @Output() itemDisabled: EventEmitter<boolean> = new EventEmitter(true);
  @Output() itemEnabled: EventEmitter<boolean> = new EventEmitter(true);
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  canInactivate = true
  canReactivate = true
  inactivationService: Observable<any> | undefined;
  reactivationService: Observable<any> | undefined;
  itemService: Observable<any> | undefined;
  ItemModel: new (source: Source, utils: UtilsService) => Item

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    this.itemId = params["id"] ?? this.itemId
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.itemId = this.dialogConfig.data.itemId ?? this.itemId
    this.showActions = this.dialogConfig.data.showActions ?? this.showActions
    this.source = this.dialogConfig.data.source ?? this.source
    this.item = this.dialogConfig.data.item ?? this.item
    if (this.source) {
      this.initItem()
    }
  }

  override onLanguageInit(): void {
    super.onLanguageInit()
    this.initComponent()
  }

  initComponent() {
    if (this.itemId > 0) {
      this.getSource()
    }
  }

  initItem() {
    this.item = new this.ItemModel(this.source, this.utils)
  }

  getSource() {
    if (this.itemService) {
      this.utils.executeRequest(
        this.itemService, {
        onSuccess: (data: Source) => {
          this.handleGetItemSuccess(data)
        }
      })
    }
  }

  inactivate(): void {
    if (this.canInactivate) {
      this.utils.fireConfirmation({
        message: this.utils.translate.instant('QUESTION.INACTIVATE', {
          value: this.item.name
        }),
      },
        () => {
          this.utils.showLoad();
          this.inactivationService?.subscribe({
            next: () => this.handleInactivationSuccess(),
            error: (err) => this.handleChangeStatusError(err),
            complete: () => this.handleChangeStatusComplete()
          });
        });
    } else {
      this.handleInactivationProhibition()
    }
  }

  handleInactivationProhibition() { }

  handleInactivationSuccess(): void {
    this.utils.toast.deleted(this.title);
    this.itemDisabled.emit(true);
  }

  reactivate(): void {
    if (this.canReactivate) {
      this.utils.fireConfirmation({
        message: this.utils.translate.instant('QUESTION.REACTIVATE', {
          value: this.item.name
        }),
      },
        () => {
          this.utils.showLoad();
          console.log(this.reactivationService);

          this.reactivationService?.subscribe({
            next: () => this.handleReactivationSuccess(),
            error: (err) => this.handleChangeStatusError(err),
            complete: () => this.handleChangeStatusComplete()
          });
        });
    } else {
      this.handleReactivationProhibition()
    }
  }

  handleReactivationProhibition() { }

  handleReactivationSuccess(): void {
    this.utils.toast.saved(this.title);
    this.itemEnabled.emit(true);
  }

  handleChangeStatusError(error: any): void {
    Utils.error(error);
    this.utils.toast.error();
  }

  handleChangeStatusComplete(): void {
    this.utils.hideLoad();
  }

  handleItemSelected(): void {
    this.itemSelected.emit(this.item);
  }

  handleGetItemSuccess(data: Source) {
    this.source = data
    this.initItem()
    this.onItemReady()
  }

  refreshActionServices(): void { }

  override manageChanges(changes: SimpleChanges): void {
    super.manageChanges(changes);
    if ('itemId' in changes) {
      this.initComponent()
    }
    if ('source' in changes) {
      this.initItem()
      this.onItemReady()
      this.refreshActionServices();
    }
  }

  onItemReady() {
  }
}
