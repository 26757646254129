import { Component } from '@angular/core';
import { BaseFormWrapperDirective } from 'src/app/directives/base-form-wrapper.directive';
import { BreadcrumItem } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-topic-form-wrapper',
  templateUrl: './topic-form-wrapper.component.html',
  styleUrls: ['./topic-form-wrapper.component.sass']
})
export class TopicFormWrapperComponent extends BaseFormWrapperDirective {
  title = "MENU.TOPICS"
  breadcrumItems: BreadcrumItem[] = []

  override onLanguageInit(): void {
    super.onLanguageInit()
    this.breadcrumItems = [
      {
        label: this.title,
        routerLink: '/topics'
      },
      {
        label: this.utils.translateParts(
          [this.editId > 0 ? "ENV.EDIT" : "ENV.NEW", "CORE.TOPIC"]
        )
      }
    ]
  }
}
