import { UtilsService } from "../services/utils/utils.service";
import { AppFormattedList } from "./AppGenericClasses";
import { TopicListFilter } from "./AppListFilters";
import { Test, TestQuestion, Topic } from "./AppModels";
import { TestJSON, TestQuestionJSON, TopicJSON } from "./AppSources";

export class TopicList extends AppFormattedList<Topic, TopicJSON> {

  override filter: TopicListFilter

  constructor(utils: UtilsService) {
    super(utils);
    this.ItemModel = Topic
  }

  override setFilterInstance(): void {
    this.filter = new TopicListFilter()
  }
}

export class TestList extends AppFormattedList<Test, TestJSON> {

  constructor(utils: UtilsService) {
    super(utils);
    this.ItemModel = Test
  }
}

export class TestQuestionList extends AppFormattedList<TestQuestion, TestQuestionJSON> {

  constructor(utils: UtilsService) {
    super(utils);
    this.ItemModel = TestQuestion
  }

  initFilter(): void {
    super.initFilter()
    this.filter.filterableFields = ['content', 'formatter.type']
  }
}
