<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">{{ title | translate | uppercase }}</h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
      *ngIf="userType.could_manage_tests"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-success"
        icon="pi pi-plus"
        label="{{ 'ENV.NEW-F' | translate }} {{
          'CORE.TEST' | translate | lowercase
        }}"
        routerLink="/admin/test/test-version/create"
      ></button>
      <button
        pButton
        pRipple
        class="p-button-text p-button-primary"
        icon="pi pi-plus"
        label="{{ 'ENV.USE-COURSE-TEST' | translate }}"
        (click)="seeTestVersionFormTableDialog()"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <app-test-version-table #primeNgList></app-test-version-table>
      </div>
    </div>
  </div>
</div>
