/* eslint-disable @typescript-eslint/no-empty-function */
import { Globals } from '../globals';
import { AppTag } from '../models/AppGenericClasses';
import { BaseDirective } from './base.directive';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appBaseTag]',
})
export abstract class BaseTagDirective extends BaseDirective {
  @Input() status = ""
  @Input() item: AppTag
  statuses: { [key: string]: AppTag }

  globals = Globals

  onInit(): void {
    super.onInit()
    if (this.status) {
      this.handleStatus()
    }
  }

  manageChanges(changes: SimpleChanges): void {
    super.manageChanges(changes)
    if ('status' in changes) {
      this.handleStatus()
    }
  }

  handleStatus() {
    this.item = this.statuses[this.status]
  }
}
