<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h2
        class="module-title cursor-pointer"
        routerLink="/configuration/templates"
      >
        {{ "MENU.TEMPLATE" | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color)"
      ></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW") | translate }}
        {{ "MENU.TEMPLATE" | translate | lowercase }}
      </h2>
    </div>
    <!-- <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <a
        class="btn btn-link text-app-custom text-decoration-none"
        (click)="formCollapsed = !formCollapsed"
      >
        <span *ngIf="formCollapsed">
          <button
            pButton
            pRipple
            class="p-button-text p-button-success"
            icon="pi pi-address-book"
            label="{{ 'FORM.SHOW-FORM' | translate }}"
          ></button>
        </span>

        <span *ngIf="!formCollapsed">
          <button
            pButton
            pRipple
            class="p-button-text p-button-success"
            icon="pi pi-eye-slash"
            label="{{ 'FORM.HIDE-FORM' | translate }}"
          ></button>
        </span>
      </a>
    </div> -->
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <a
        class="btn btn-link text-app-custom text-decoration-none"
        (click)="shouldShowWildcard = !shouldShowWildcard"
      >
        <button
          pButton
          pRipple
          class="p-button-text p-button-success"
          icon="pi pi-bars"
          label="{{ 'FORM.WILDCARDS' | translate }}"
        ></button>
      </a>
      <a
        class="btn btn-link text-app-custom text-decoration-none"
        (click)="form.code_type = form.code_type == 1 ? 2 : 1"
      >
        <button
          pButton
          pRipple
          class="p-button-text p-button-success"
          icon="pi pi-code"
          label="{{ 'FORM.EDITOR-HTML' | translate }}"
          *ngIf="form.code_type == 1"
        ></button>
        <button
          pButton
          pRipple
          class="p-button-text p-button-success"
          icon="pi pi-book"
          label="{{ 'FORM.EDITOR-CONTENT' | translate }}"
          *ngIf="form.code_type == 2"
        ></button>
      </a>
    </div>

    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <div class="container-fluid no-gutters" *ngIf="shouldShowWildcard">
    <hr class="m-0" />
    <app-wilcard-filter></app-wilcard-filter>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column lg:flex-row mb-4">
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <input
                  pInputText
                  id="title"
                  name="title"
                  class="ng-dirty"
                  aria-describedby="title-help"
                  [(ngModel)]="form.name_"
                  maxlength="100"
                  required
                />
                <label Htmlfor="title">{{ "FORM.NAME" | translate }}</label>
              </div>
            </div>
          </div>
          <div class="flex flex-column lg:flex-row mb-4">
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="dni-type"
                  name="dni-type"
                  [options]="optionsTypes"
                  [(ngModel)]="form.type_"
                  [filter]="true"
                  appendTo="body"
                  [optionValue]="'id'"
                  [optionLabel]="'label'"
                  class="w-full"
                  styleClass="w-full"
                  (ngModelChange)="onChangeType()"
                >
                </p-dropdown>
                <label Htmlfor="title">{{ "FORM.TYPE" | translate }}</label>
              </div>
            </div>
          </div>
          <div class="flex flex-column lg:flex-row mb-4">
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
                ngClass="col-12"
              >
                <div
                  class="d-flex flex-column h-100 position-relative"
                  *ngIf="form.code_type == 1"
                >
                  <div class="position-absolute w-100 h-100">
                    <quill-editor
                      format="html"
                      [modules]="data.quillModules"
                      [(ngModel)]="htmlTemplates.quill"
                      name="quill"
                      required
                      class="no-validation ql-full"
                    ></quill-editor>
                  </div>
                </div>
                <app-html-code-editor
                  *ngIf="form.code_type == 2"
                  [(ngModel)]="htmlTemplates.editor"
                  [ngModelOptions]="{ standalone: true }"
                  class="no-validation"
                ></app-html-code-editor>
              </div>
            </div>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
