import { Globals } from "src/app/globals";
import { AWSResourceData } from "src/app/models/AppGenericClasses";

export class AWSUtils {
  static initResourceData(resource: any, path: string): void {
    console.log(resource);

    resource.resourceData = {
      path: path,
      id: resource.content_id,
      field: resource.type_,
      ext: Globals.CONTENT_TYPES[resource.type_].ext,
      resourceContent: ['PLAIN', 'URL', 'VIDEO-URL'].includes(resource.type_) ? resource.content : ''
    } as AWSResourceData
  }
}
