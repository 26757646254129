<div class="box box-elevation border-round-xl px-3 pb-2 mb-3">
  <div class="flex flex-column w-12">
    <div class="flex align-items-center">
      <div class="flex-grow-1">
        <h3 class="pt-2">Clase #{{ idx + 1 }} | {{ item.name_ }}</h3>
      </div>
      <div class="flex flex-row align-items-center">
        <button
          pButton
          pRipple
          icon="pi pi-pencil"
          pTooltip="{{ 'ENV.EDIT' | translate }}"
          routerLink="/courses/units/sections/edit/{{
            item.el_course_unit_section_id
          }}"
          class="p-button-text p-button-raised mr-1"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-ellipsis-h"
          class="p-button-text p-button-raised p-button-info"
          (click)="menu.toggle($event)"
        ></button>
        <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
      </div>
    </div>
    <div class="mb-2" [innerHTML]="item.content"></div>
    <app-course-unit-section-contents
      [item]="item"
      [fireActions]="true"
    ></app-course-unit-section-contents>
  </div>
</div>
