import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-report-take-user',
  templateUrl: './report-take-user.component.html',
  styleUrls: ['./report-take-user.component.sass']
})
export class ReportTakeUserComponent extends BaseDirective {
  override title = 'MENU.USER-TAKES';
}
