<ng-container *ngIf="!downloading; else loadingTemplate">
  <div
    class="article-content h-full w-full"
    [innerHTML]="resourceContent"
  ></div>
</ng-container>
<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
