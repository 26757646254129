/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-rate-pending-test-form',
  templateUrl: './rate-pending-test-form.component.html',
  styleUrls: ['./rate-pending-test-form.component.sass']
})
export class RatePendingTestFormComponent extends BaseFormDirective {
  override title = "ENV.RATE-TEST";
  testTake: any = null
  pollTake: any = null
  form = {
    el_test_take_id: 0,
    objects: [],
  }

  onInit(): void {
    super.onInit()
    this.utils.executeRequest(
      this.data.core.viewRateTakeForm(this.editId),
      {
        onSuccess: (data) => {
          console.log(data);

          this.testTake = data
          this.pollTake = data.poll_take
        }
      }
    )
  }

  override onSubmit(): void {
    this.form.el_test_take_id = this.editId
    this.form.objects = this.pollTake.questions.map(question => {
      const { poll_take_question_id, result } = question
      return {
        poll_take_question_id,
        result
      }
    })
    super.onSubmit()
  }

  override getItemEdit(): void {
  }

  setSubmitMethods(): void {
    this.putService = this.data.core.testTakeUpdateResult(this.form)
  }
}
