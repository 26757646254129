import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.sass']
})
export class TemplateFormComponent extends BaseFormDirective {
  override title = 'MENU.TEMPLATES'
  formCollapsed = false;
  shouldShowWildcard = false;
  override form = {
    name_: null,
    type_: 'SIMPLE',
    code_type: 1,
    html: null
  }
  path = 'templates'

  optionsTypes = [
    {
      label: this.utils.translate.instant('MENU.SIMPLE'),
      id: 'SIMPLE'
    },
    {
      label: this.utils.translate.instant('MENU.TABLE'),
      id: 'TABLE'
    }
  ]

  htmlTemplates = {
    quill: '',
    editor: '',
  };

  override getItemEdit() {
    this.editService = this.data.core.templateUnique(this.editId)
    super.getItemEdit()
  }

  handleItemEditSuccess(data: any) {

    super.handleItemEditSuccess(data);

    switch (this.form.code_type) {
      case 1:
        this.htmlTemplates.quill = this.form.html;
        break;
      case 2:
        this.htmlTemplates.editor = this.form.html;
        break;
    }



  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.templateCreate(this.formSanitized)
    this.putService = this.data.core.templateUpdate(this.editId, this.formSanitized)
  }

  override onSubmit() {


    switch (this.form.code_type) {
      case 1:
        this.form.html = this.htmlTemplates.quill;
        break;
      case 2:
        this.form.html = this.htmlTemplates.editor;
        break;
    }
    super.onSubmit()
  }

  onChangeType() {

  }
}
