import { AWSS3Service } from 'src/app/services/data/aws-s3.service';
import { Component, Input } from '@angular/core';
import { ImageViewerWrapperDirective } from "../../../directives/controls/image-viewer-wrapper.directive";
import { Utils } from 'src/app/shared/utils/utils';
import { MediaUploadItem } from 'src/app/models/AppGenericClasses';
import { FileUtils } from 'src/app/shared/utils/file-utils';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.sass']
})
export class ImageUploadComponent extends ImageViewerWrapperDirective {

  @Input() required = false
  files: File[] = []
  uploading = false

  onFilesChanged() {
    this.image = undefined
    if (this.files.length) {
      FileUtils.imageFromBlob(this.files[0]).then(image => this.image = image)
    }
  }

  resetImage() {
    this.files = []
    this.image = undefined
  }

  upload(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.files.length > 0) {
        this.uploading = true
        for (const item of this.files) {
          AWSS3Service.uploadFile(new MediaUploadItem(
            {
              id,
              table: this.path,
              field: this.field
            },
            'image',
            '',
            item
          ))
            .then(() => {
              this.uploading = false
              this.files = []
              resolve(true)
            })
            .catch(err => {
              this.uploading = false
              Utils.error(err)
              reject()
            })
        }
      } else {
        resolve(true)
      }
    })
  }

}
