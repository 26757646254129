import { UtilsService } from "../services/utils/utils.service";
import { AppJSONSanitizer } from "./AppGenericClasses";
import { AppItem, AppSelectableItem, Topic } from "./AppModels";
import { TopicJSON } from "./AppSources";

export class CourseTopicJSON {
  el_topic_id = 0
  el_course_id = 0
  topic: TopicJSON = new TopicJSON()
}

export class CourseTopic implements AppItem, AppSelectableItem<CourseTopic> {
  id_: number;
  name_: string;
  value_: CourseTopic;

  topic: Topic

  source: CourseTopicJSON

  constructor(source: CourseTopicJSON, utils: UtilsService) {
    this.source = new AppJSONSanitizer<CourseTopicJSON>(source, CourseTopicJSON).result
    this.id = source.el_topic_id
    this.name = source.topic.name_
    this.topic = new Topic(source.topic, utils)
  }

  get id(): number {
    return this.id_
  }
  set id(value: number) {
    this.id_ = value
  }

  get name(): string {
    return this.name_
  }
  set name(value: string) {
    this.name_ = value
  }

  get value(): CourseTopic {
    return this
  }
}
