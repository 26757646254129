<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">{{ "MENU.COURSES" | translate | uppercase }}</h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-success"
        icon="pi pi-plus"
        label="{{ 'ENV.NEW' | translate }} {{
          'CORE.COURSE' | translate | lowercase
        }}"
        routerLink="/courses/create"
        *ngIf="userType.could_manage_course"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <app-course-list class="h-full" [couldShowInactives]="true"></app-course-list>
</div>
