/* eslint-disable @typescript-eslint/no-unused-vars */
import { Directive, Input, Output, EventEmitter } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { MediaUploaderComponent } from "src/app/components/controls/media-uploader/media-uploader.component";
import { MediaUploadItem } from "src/app/models/AppGenericClasses";


@Directive({
  selector: '[appBaseFileUploader]',
})
export class BaseFileUploaderDirective {
  @Input() path = ''
  @Input() field = ''
  @Input() files: File[] = [];
  @Output() uploadState = new EventEmitter<any>()
  mediaItems: MediaUploadItem[] = []
  ref!: DynamicDialogRef;

  constructor(
    protected dialog: DialogService
  ) {
  }

  upload(id: string | number) {
    for (const item of this.files) {
      this.mediaItems.push(new MediaUploadItem(
        {
          id: +id,
          table: this.path,
          field: this.field
        },
        'file',
        '',
        item
      ))
    }
    if (this.mediaItems.length) {
      this.openUploaderDialog(id)
    } else {
      this.onUploadSuccess()
    }
  }

  onUploadSuccess() {
    this.files = []
    this.mediaItems = []
    this.uploadState.emit({ success: true })
  }

  onUploadError() {
    this.uploadState.emit({ success: false })
  }

  openUploaderDialog(data = null) {
    this.ref = this.dialog.open(MediaUploaderComponent, {
      header: '',
      width: '60%',
      baseZIndex: 10000,
      showHeader: false,
      data: this.mediaItems,
      closeOnEscape: false,
    });
    const sub = this.ref.onClose.subscribe((success) => {
      if (success) {
        this.onUploadSuccess()
      } else {
        this.onUploadError()
      }
      sub.unsubscribe()
    });
  }
}
