<div class="flex flex-column h-full" *ngIf="test">
  <div class="flex flex-grow-1 relative">
    <div class="absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <div class="flex flex-column h-full">
          <app-test-detail
            [source]="test"
            [componentDataSource]="'INPUT'"
          ></app-test-detail>
          <div class="flex flex-grow-1">
            <div class="flex w-12 relative">
              <div class="absolute h-full w-full">
                <app-test-question
                  [pollId]="test.poll.poll_id"
                ></app-test-question>
              </div>
            </div>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </div>
</div>
