import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-suport-section',
  templateUrl: './suport-section.component.html',
  styleUrls: ['./suport-section.component.sass'],
})
export class SuportSectionComponent extends BaseListDirective {
  @ViewChild('pdfViewer') pdfViewer: ElementRef;

  options: AnimationOptions = {
    path: '/assets/animations/not-found.json',
  }

  override title = 'FORM.AVAILABLE-USERS';
  override filterableFields = ['dni', 'first_name', 'last_name'];
  override keys = {
    primary_key: 'user_id',
    label: 'first_name,last_name',
  };
  override filename = 'FORM.AVAILABLE-USERS';

  @Input() resource: SafeResourceUrl | any;
  @Output() formSubmitted = new EventEmitter<any>();


  form = {
    objects: [],
  };
  saveService!: Observable<any>;

  override handleDialogConfigData(): void {
    super.handleDialogConfigData();
    switch (this.dialogConfig.data.data.type_) {
      case 'VIDEO-URL':
        const url = new URL(this.dialogConfig.data.data.content);
        const videoId = url.searchParams.get('v');
        const youtubeUrl = `https://www.youtube.com/embed/${videoId}`;
        this.resource =
          this.sanitizer.bypassSecurityTrustResourceUrl(youtubeUrl);
        break;
      case 'PDF':
        this.resource = this.dialogConfig.data.data.content;

        if(this.resource === '') return;
        const iframe = document.createElement('iframe');
        iframe.src = this.resource;
        iframe.className = 'w-full h-full';
        iframe.frameBorder = '0';
        const modalDiv = document.getElementById("modal-pdf");
        modalDiv.appendChild(iframe);
        break;
      case 'IMG':
      case 'AUDIO':
        this.resource = this.dialogConfig.data.data.content;
        console.log(this.resource)
        break;
      default:
        this.resource = ''
        break;
    }
  }

  constructor(
    private sanitizer: DomSanitizer,
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef);

  }

  override refreshActionServices(): void {}

  override getList(): void {
    this.query(this.data.core.usersBasic()).subscribe();
  }

  save() {
    this.form.objects = this.selectedRows.map((item) => {
      return { user_id: item.user_id };
    });
    this.refreshActionServices();
    if (this.saveService) {
      this.processing = true;
      this.utils.executeRequest(this.saveService, {
        onSuccess: () => {
          this.utils.toast.saved(this.title);
          this.processing = false;
        },
      });
    } else {
      this.formSubmitted.emit(this.form);
      this.dialogRef.close(this.form);
    }
  }
}
