import { BaseDirective } from './../../../directives/base.directive';
import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, isDevMode } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { YouTubePlayer } from '@angular/youtube-player';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { YouTubeEmbedParams } from 'src/app/models/AppGenericClasses';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { GlobalUtils } from 'src/app/shared/utils/global-utils';

@Component({
  selector: 'app-video-url-player',
  templateUrl: './video-url-player.component.html',
  styleUrls: ['./video-url-player.component.sass']
})
export class VideoUrlPlayerComponent extends BaseDirective {
  @Input() url = '';
  @Input() youtubeEmbedParams: YT.PlayerVars = {}
  @Output() play: EventEmitter<any> = new EventEmitter<any>(null)
  @Output() pause: EventEmitter<any> = new EventEmitter<any>(null)
  @ViewChild('youtubePlayer', { static: false }) youtubePlayer: YouTubePlayer;
  sanitizedUrl: SafeResourceUrl;
  platform = '';


  constructor(
    utils: UtilsService,
    data: GlobalDataService,
    route: ActivatedRoute,
    dialog: DialogService,
    dialogConfig: DynamicDialogConfig,
    dialogRef: DynamicDialogRef,
    private sanitizer: DomSanitizer
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef)
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.url = this.dialogConfig.data.resourceContent ?? this.url
    this.handleUrlChange()
  }

  override onAfterViewInit(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  onYoutubePlayerReady(ev: any) {
    console.log(ev);
    console.log(this.youtubeEmbedParams);
  }

  onYoutubePlayerStateChange(ev: any) {
    console.log(ev);
    switch (ev.data) {
      case 1:
        this.play.emit(ev);
        break;
      case 2:
        this.pause.emit(ev);
        break;
    }
  }

  handleUrlChange() {
    if (this.url.includes('youtube.com') || this.url.includes('youtu.be')) {
      this.platform = 'youtube';
    } else if (this.url.startsWith('https://')) {
      this.platform = 'server';
    } else {
      this.platform = 'unknown';
    }
  }

  override manageChanges(changes: SimpleChanges): void {
    super.manageChanges(changes)
    if ('url' in changes) {
      this.handleUrlChange()
    }
  }

  sanitizeAndMarkAsSafe(url: string) {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getYoutubeVideoID(url: string) {
    const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : ''
  }
}
