import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../services/utils/utils.service";
import {TranslateService} from "@ngx-translate/core";
import {MessageService} from "primeng/api";
import {Auth} from "aws-amplify";
import {AnimationOptions} from "ngx-lottie";

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.sass']
})
export class ConfirmAccountComponent implements OnInit {
  validating = false
  dataError = 0
  username = ''
  code = ''
  couldResendCode = false
  seconds = 59
  interval = null
  waitTime = 59

  options: AnimationOptions = {
    path: '/assets/animations/loading.json',
  }

  constructor(
    private route: ActivatedRoute,
    private utils: UtilsService,
    private translate: TranslateService,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.translate.get('GENERAL.APP-NAME').subscribe()
    this.username = this.route.snapshot.paramMap.get('username')
    this.resetTimer()
  }

  resetTimer() {
    this.seconds = this.waitTime
    this.interval = setInterval(() => {
      this.seconds -= 1
      if (this.seconds <= 0) {
        this.couldResendCode = true
        clearInterval(this.interval)
      }
    }, 1000)
  }

  onSubmit() {
    this.validating = true
    Auth.confirmSignUp(this.username, this.code.toString())
      .then(rs => this.handleVerifySuccess())
      .catch(error => this.handleVerifyError(error))
  }

  handleVerifySuccess() {
    console.log('SUCCESS!!')
    this.dataError = 1
    this.validating = false
  }

  handleVerifyError(error) {
    console.log(error)
    console.log(error.code)
    if (error.code == 'CodeMismatchException') {
      this.dataError = -1
    } else {
      this.dataError = -2
    }
    this.validating = false
  }

  resendCode() {
    this.validating = true
    Auth.resendSignUp(this.username)
      .then(rs => this.handleResendSuccess())
      .catch(error => this.handleResendError(error))
  }

  handleResendSuccess() {
    this.couldResendCode = false
    this.validating = false
    this.resetTimer()
    this.messageService.add(
      {
        key: 'tc',
        severity: 'success',
        summary: this.translate.instant('AUTH.CONFIRMATION-CODE'),
        detail: this.translate.instant('AUTH.CONFIRMATION-CODE-RESEND')
      }
    )
  }

  handleResendError(error) {
    console.log(error)
    this.dataError = -2
    this.validating = false
  }

  printSeconds() {
    return this.utils.zero(this.seconds)
  }

}
