import { Component, EventEmitter, Output } from '@angular/core';
import { TestTableComponent } from '../../master/admin/test/test-table/test-table.component';
import { AppPickerList, GenericResponse } from 'src/app/models/AppGenericClasses';
import { Test } from 'src/app/models/AppModels';

@Component({
  selector: 'app-test-picker-table',
  templateUrl: './test-picker-table.component.html',
  styleUrls: ['./test-picker-table.component.sass']
})
export class TestPickerTableComponent extends TestTableComponent implements AppPickerList<Test> {

  @Output() selected = new EventEmitter<GenericResponse<Test[]>>()

  onItemSelected(row: Test) {
    this.emitData({ success: true, complement: { data: [row] } })
  }

  onItemSelectedInBatch() {
    this.emitData({ success: true, complement: { data: this.itemList.selectedRows } })
  }

  emitData(data: GenericResponse<Test[]>) {
    this.dialogRef.close(data)
    this.selected.emit(data)
  }
}

@Component({
  selector: 'app-test-version-form-table',
  templateUrl: './test-picker-table.component.html',
  styleUrls: ['./test-picker-table.component.sass']
})
export class TestVersionFormTableComponent extends TestPickerTableComponent {

  onItemSelected(row: Test) {
    this.handleItemSelected(row)
    this.utils.appDialog.seeTestVersionFormDialog(this.itemList.itemSelected.id, {
      onSuccess: (res) => {
        this.dialogRef.close(res)
      }
    }, {
      height: ""
    })
  }
}

@Component({
  selector: 'app-test-version-form-table',
  templateUrl: './test-picker-table.component.html',
  styleUrls: ['./test-picker-table.component.sass']
})
export class UnitTestPickerTableComponent extends TestPickerTableComponent {

  unitId = 0

  override getList() {
    this.query(this.data.core.testsWithoutOpenQuestions()).subscribe();
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.unitId = this.dialogConfig.data.el_course_unit_id ?? this.unitId;
  }

  onItemSelected(row: Test) {
    this.handleItemSelected(row)
    this.utils.appDialog.seeUnitTestAddDataFormDialog(this.itemList.itemSelected.id, this.unitId, {
      onSuccess: (res) => {
        this.dialogRef.close(res)
      }
    }, {
      height: ""
    })
  }
}
