import { Directive } from '@angular/core';
import { GlobalDataService } from '../services/data/global-data.service';
import { UtilsService } from '../services/utils/utils.service';
import { FilterService, SelectItem } from 'primeng/api';
import { BaseListSourceDirective } from './base-list-source.directive';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Directive({
  selector: '[appBaseListFilterablePrime]'
})
export class BaseListFilterablePrimeDirective extends BaseListSourceDirective {

  matchModeOptions: SelectItem[];

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
    protected filterService: FilterService
  ) {
    super(
      utils,
      data,
      route,
      dialog,
      dialogConfig,
      dialogRef
    );
  }

  onInit() {
    super.onInit();
    this.filterService.register('group', (value, filter): boolean => {
      return this.genericListFilter(
        value,
        filter,
        'user_group_id'
      );
    });
    this.filterService.register('specialty', (value, filter): boolean => {
      return this.genericListFilter(
        value,
        filter,
        'user_post_id'
      );
    });
    this.filterService.register('unique-by-key', (value, filter): boolean => {
      return this.genericListFilter(
        value,
        filter,
        'value'
      );
    });
    this.matchModeOptions = [{ label: 'Custom Equals', value: 'user_group_id' }];
  }
  genericListFilter(value, filter, key): boolean {
    if (filter === undefined || filter === null || filter.length <= 0) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    const filterIds = filter.map((f) => f[key].toString());
    return (
      value
        .toString()
        .split(',')
        .filter((id) => filterIds.includes(id.trim())).length > 0
    );
  }
  genericListArrayFilter(value, filter, key, nestedKey): boolean {
    if (filter === undefined || filter === null || filter.length <= 0) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    const filterIds = filter.map((f) => f[key].toString());
    return (
      value.filter((el) => filterIds.includes(el[nestedKey].toString()))
        .length > 0
    );
  }
}
