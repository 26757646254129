import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Globals} from '../../globals';
import {TokenService} from './token.service';
import {Router} from '@angular/router';
import {AppConfigProviderService} from "../data/app-config-provider.service";
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private router: Router,
    private appConfigProvider: AppConfigProviderService
  ) {
  }

  changeStatus(value: boolean) {
    this.token.loggedIn.next(value)
  }

  auth(data: {}) {
    return this.http.post(Globals.API_URL + '/auth/login', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'app-key': Globals.API_KEY
      })
    });
  }

  recover(data: {}) {
    return this.http.post(Globals.API_URL + '/auth/recover', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'app-key': Globals.API_KEY
      })
    });
  }

  logout() {
    localStorage.clear()
    this.changeStatus(false)
    this.router.navigateByUrl('/auth/sign-in')
  }

  handleSuccess(data: any, callback: () => void) {
    this.token.set(data.access_token);
    this.appConfigProvider.reload().then(
      () => {
        callback()
        this.changeStatus(true)
        this.router.navigateByUrl('/')
      }
    )
  }

}
