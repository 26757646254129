import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Globals} from '../../globals';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.refresh()
  }

  options = {
    headers: new HttpHeaders()
  }

  request(rq: Observable<any>): Observable<any> {
    return new Observable<any>(subscriber => {
      rq.subscribe(
        {
          next: data => {
            subscriber.next(data)
            subscriber.complete()
          },
          error: error => {
            if (error.status === 401) {
              localStorage.clear()
              this.router.parseUrl('/auth/sign-in')
            }
            subscriber.error(error)
            subscriber.complete()
          }
        }
      )
    })
  }

  refresh() {
    this.options = {
      headers: new HttpHeaders({
        'app-key': Globals.API_KEY,
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    }
  }

  post(path: string, data: any) {
    return this.request(this.http.post(Globals.API_URL + path, data, this.options))
  }

  get(path: string) {
    return this.request(this.http.get(Globals.API_URL + path, this.options))
  }

  put(id: number, path: string, data: any) {
    return this.request(this.http.put(Globals.API_URL + path + '/' + id, data, this.options))
  }

  delete(id: number, path: string) {
    return this.request(this.http.delete(Globals.API_URL + path + '/' + id, this.options))
  }

  unique(id: number, path: string) {
    return this.request(this.http.get(`${Globals.API_URL + path}/${id}`, this.options))
  }
}
