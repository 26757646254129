import { Component, ViewChild } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { Params } from '@angular/router';
import { BaseItemListDirective } from 'src/app/directives/restruct/base-item-list.directive';
import { CourseUnitTest, CourseUnitTestJSON, CourseUnitTestList } from 'src/app/models/CourseUnitTestClasses';

@Component({
  selector: 'app-evaluations',
  templateUrl: './evaluations.component.html',
  styleUrls: ['./evaluations.component.sass']
})
export class EvaluationsComponent extends BaseItemListDirective<CourseUnitTest, CourseUnitTestJSON, CourseUnitTestList> {
  @ViewChild('primeNgList') list: BaseListDirective;
  unitId = null
  unitName = ''
  currentTestIds: number[] = []
  override ListModel = CourseUnitTestList;

  onInit(): void {
    this.unitName = localStorage.getItem('unit_name') ?? ''
    super.onInit();
  }

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('id' in params) {
      this.unitId = params['id']
    }
  }

  override getList() {
    this.query(this.data.core.unitTests(this.unitId)).subscribe();
  }

  seeCreateAndAssociateTestDialog() {
    this.utils.appDialog.seeCourseUnitTestFormDialog(this.unitId, {
      onSuccess: () => {
        this.getList()
      }
    })
  }

  seeAssociateTestDialog() {
    this.utils.appDialog.seeUnitTestPickerTableDialog(this.unitId, {
      onSuccess: () => {
        this.getList()
      }
    }, {
      idsExcluded: this.currentTestIds
    })
  }

  override handleRows(data: CourseUnitTestJSON[]): void {
    this.currentTestIds = data.map(item => item.test.el_test_id)
    super.handleRows(data)
  }

  refreshActionServices(): void {
    throw new Error('Method not implemented.');
  }
}

