import { Component } from '@angular/core';
import { BaseFilterUniqueByKey } from 'src/app/directives/controls/base-filter-unique-by-key.directive';

@Component({
  selector: 'app-filter-content-type',
  templateUrl: './filter-content-type.component.html',
  styleUrls: ['./filter-content-type.component.sass']
})
export class FilterContentTypeComponent extends BaseFilterUniqueByKey {
}
