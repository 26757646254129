import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { FormattableObjectProperty, PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-test-version-table-all',
  templateUrl: './test-version-table-all.component.html',
  styleUrls: ['./test-version-table-all.component.sass']
})
export class TestVersionTableAllComponent extends BaseListDirective {

  testId

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
    public router: Router
  ) {
    super(utils,
      data,
      route,
      dialog,
      dialogConfig,
      dialogRef)
  }
  onInit(): void {
    this.testId = this.route.snapshot.paramMap.get('id')
    super.onInit()
  }
  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('available_from', 'FORM.AVAILABLE-FROM', 250),
    new PrimeNGTableColumn('available_to', 'FORM.AVAILABLE-TO', 300),
    new PrimeNGTableColumn('allowed_attempts', 'FORM.ATTEMPTS-ALLOWED', 200),
    new PrimeNGTableColumn('created', 'FORM.CREATION-DATE', 200)
  ];
  override title = 'MENU.TESTS';
  override filterableFields = ['poll.title']
  override keys = {
    primary_key: 'el_test_version_id',
    label: 'test.poll.title'
  }
  override filename = 'MENU.TESTS';
  override dataFormatterProps: FormattableObjectProperty[] = [
    {
      field: 'available_from',
      formats: ['DATE-FORMATTED']
    },
    {
      field: 'available_to',
      formats: ['DATE-FORMATTED']
    },
    {
      field: 'created',
      formats: ['DATE-FORMATTED']
    }
  ];

  override getList() {
    this.query(this.data.core.testVersionsWeb(this.testId)).subscribe();
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.testVersionDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }
}
