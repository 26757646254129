import { LibraryUserPickerTableComponent } from './../../../controls/user-picker-table/user-picker-table.component';
import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-library-table',
  templateUrl: './library-table.component.html',
  styleUrls: ['./library-table.component.sass']
})
export class LibraryTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.TITLE', 150, { frozen: true }),
    new PrimeNGTableColumn('description_', 'FORM.DESCRIPTION', 300),
    new PrimeNGTableColumn('tags', 'FORM.TAGS', 200)
  ];
  override title = 'MENU.LIBRARIES';
  override filterableFields = ['name_', 'description_', 'tags']
  override keys = {
    primary_key: 'library_id',
    label: 'name_'
  }
  override filename = 'MENU.LIBRARIES';

  override formatData(data: any): void {
    this.utils.applyLibraryFormat(data)
  }

  override getList(): void {
    this.query(this.data.core.libraries()).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.libraryDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  seeUsers(row: any) {
    const header = this.utils.translate.instant(
      'FORM.AVAILABLE-USERS-IN-X', {
      value: row.name_
    })
    this.dialog.open(LibraryUserPickerTableComponent, {
      header,
      width: '80%',
      height: '100%',
      styleClass: 'p-overflow-hidden',
      contentStyle: { height: '100%', overflow: 'hidden', padding: '.3rem' },
      data: {
        parentId: row.library_id
      }
    })
  }
}
