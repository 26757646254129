<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h2
        class="module-title cursor-pointer"
        routerLink="/configuration/wildcards"
      >
        {{ "FORM.WILDCARDS" | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color)"
      ></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW") | translate }}
        {{ "FORM.WILDCARD" | translate | lowercase }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    ></div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>

  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column lg:flex-row mb-4">
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <input
                  (input)="validateInput()"
                  pInputText
                  [(ngModel)]="form.key_"
                  type="text"
                  class="text-uppercase w-100"
                  id="key"
                  name="key"
                  placeholder="{{ 'FORM.NAME' | translate }}"
                  required
                />

                <label Htmlfor="title">{{ "FORM.NAME" | translate }}</label>
              </div>
            </div>
          </div>
          <div class="flex flex-column lg:flex-row mb-4">
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="dni-type"
                  name="dni-type"
                  [options]="optionsTypes"
                  [(ngModel)]="form.type_"
                  [filter]="true"
                  appendTo="body"
                  [optionValue]="'id'"
                  [optionLabel]="'label'"
                  class="w-full"
                  styleClass="w-full"
                >
                </p-dropdown>
                <label Htmlfor="title">{{ "FORM.TYPE" | translate }}</label>
              </div>
            </div>
          </div>
          <div
            class="flex flex-column lg:flex-row mb-4"
            *ngIf="form.type_ !== 'TABLE-FIELDS'"
          >
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <input
                  pInputText
                  [(ngModel)]="form.content"
                  type="text"
                  class="text-uppercase w-100"
                  id="content"
                  name="content"
                  placeholder="{{ 'FORM.CONTENT' | translate }}"
                  required
                />
                <label Htmlfor="title">{{ "FORM.CONTENT" | translate }}</label>
              </div>
            </div>
          </div>
          <div
            class="flex flex-column lg:flex-row mb-4"
            *ngIf="form.type_ === 'TABLE-FIELDS'"
          >
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="category"
                  name="category"
                  [options]="tableField"
                  [(ngModel)]="selectedCategory"
                  [filter]="true"
                  appendTo="body"
                  optionLabel="label"
                  (ngModelChange)="onCategoryChange($event)"
                  class="w-full"
                  styleClass="w-full"
                >
                </p-dropdown>
                <label Htmlfor="columns">{{ "FORM.TABLE" | translate }}</label>
              </div>
            </div>
          </div>
          <div
            class="flex flex-column lg:flex-row mb-4"
            *ngIf="form.type_ === 'TABLE-FIELDS'"
          >
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="columns"
                  name="columns"
                  [options]="columnOptions"
                  [(ngModel)]="selectedColumn"
                  [filter]="true"
                  appendTo="body"
                  class="w-full"
                  styleClass="w-full"
                >
                </p-dropdown>
                <label Htmlfor="columns">{{
                  "FORM.COLUMNS" | translate
                }}</label>
              </div>
            </div>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
