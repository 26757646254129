import { Component } from '@angular/core';
import { BaseItemRestructDirective } from 'src/app/directives/restruct/base-item-restruct.directive';
import { Topic } from 'src/app/models/AppModels';
import { TopicJSON } from 'src/app/models/AppSources';

@Component({
  selector: 'app-topic-item',
  templateUrl: './topic-item.component.html',
  styleUrls: ['./topic-item.component.sass'],
})
export class TopicItemComponent extends BaseItemRestructDirective<Topic, TopicJSON> {
  override title = 'CORE.TOPIC';
  override ItemModel = Topic;

  override getSource() {
    this.itemService = this.data.core.topicUnique(this.itemId)
    super.getSource()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.topicDelete(this.item.id)
  }
}
