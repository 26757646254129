import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-wild-card-form',
  templateUrl: './wild-card-form.component.html',
  styleUrls: ['./wild-card-form.component.sass']
})
export class WildCardFormComponent extends BaseFormDirective {
  override title = 'FORM.WILDCARDS'
  formCollapsed = false;
  shouldShowWildcard = false;
  selectedCategory: string | null = null;


  tableField = require('../table-field.json');

  columnOptions: string[] = [];
  selectedColumn: string | null = null;

  override form = {
    key_: null,
    type_: null,
    content: null
  }

  path = 'wildcards'

  optionsTypes = [
    {
      label: this.utils.translate.instant('MENU.TEXT'),
      id: 'TEXT'
    },
    {
      label: this.utils.translate.instant('MENU.TABLE-FIELDS'),
      id: 'TABLE-FIELDS'
    }
  ]

  htmlTemplates = {
    quill: '',
    editor: '',
  };

  override getItemEdit() {
    this.editService = this.data.core.wildcardUnique(this.editId)
    super.getItemEdit()
  }

  handleItemEditSuccess(data: any) {
    if (data.type_ === "TABLE-FIELDS") {
      let table = data.content.split('.');
      this.selectedCategory = this.tableField.find(f => f.value === table[0])
      this.columnOptions = this.selectedCategory['columns']
      this.selectedColumn = table[1]

    }

    super.handleItemEditSuccess(data);
  }



  override   onSubmit() {

    if (this.form.type_ === 'TABLE-FIELDS') {
      this.form.content = `${this.selectedCategory['value']}.${this.selectedColumn}`
    }

    super.onSubmit();
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.wildcardCreate(this.formSanitized)
    this.putService = this.data.core.wildcardUpdate(this.editId, this.formSanitized)
  }

  validateInput(): void {
    const inputValue = this.form.key_;
    if (inputValue && inputValue.includes(' ')) {
      this.form.key_ = inputValue.replace(/\s/g, '');
    }
  }

  onCategoryChange(event: any): void {
    this.columnOptions = event["columns"];
  }

}

