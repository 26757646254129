import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../shared/utils/utils';

@Pipe({
  name: 'ngCellValue'
})
export class NgCellValuePipe implements PipeTransform {

  transform(key: string, row: any, separator = ' '): string {
    return Utils.ngCellValue(key, row, separator);
  }

}
