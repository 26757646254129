<div class="flex flex-column h-full w-full">
  <div class="h-full w-full" *ngIf="dialogConfig.data.data.type_ === 'VIDEO-URL'">
    <iframe class="w-full h-full" [src]="resource" frameborder="0" allowfullscreen></iframe>
  </div>
  <div id="modal-pdf" class="h-full" [ngClass]="dialogConfig.data.data.type_ !== 'PDF'? 'hidden': ''">

  </div>
  <img [src]="resource" *ngIf="dialogConfig.data.data.type_ === 'IMG'" alt="" />

  <div style="height: 200px; width: 100%;" *ngIf="dialogConfig.data.data.type_ === 'AUDIO'">
    <app-aws-audio-player [resourceUrl]="this.resource">
    </app-aws-audio-player>
  </div>
</div>
