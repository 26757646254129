<div class="p-1" *ngIf="!loading; else loadingTemplate">
  <app-course-item
    [source]="row"
    [presentation]="2"
    *ngFor="let row of rows"
  ></app-course-item>
</div>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
