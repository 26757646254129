import { AppJSONSanitizer } from "./AppGenericClasses"
import { CourseUnitSectionTake, CourseUnitSectionTakeJSON } from "./CourseUnitSectionTakeClasses"
import { TestTakeUnit, TestTakeUnitJSON } from "./TestTakeUnitClasses"

export class CourseUnitTakeJSON {
  el_course_unit_take_id = 0
  el_course_unit_id = 0
  el_course_enrollment_id = 0
  start_date = ""
  completion_date = ""
  take_status = ""
  result_percentage = 0
  failed = 0
  sections: CourseUnitSectionTakeJSON[] = []
  takes: TestTakeUnitJSON[]
  status_ = 1
}

export class CourseUnitTake {
  id: number
  courseUnitId: number
  courseEnrollmentId: number
  startDate: string
  completionDate: string
  takeStatus: string
  resultPercentage: number
  failed: number
  sections: CourseUnitSectionTake[]
  takes: TestTakeUnit[]
  active_: boolean

  source: CourseUnitTakeJSON

  constructor(source: CourseUnitTakeJSON) {
    this.source = new AppJSONSanitizer<CourseUnitTakeJSON>(source, CourseUnitTakeJSON).result
    this.id = this.source.el_course_unit_take_id
    this.courseUnitId = this.source.el_course_unit_id
    this.courseEnrollmentId = this.source.el_course_enrollment_id
    this.startDate = this.source.start_date
    this.completionDate = this.source.completion_date
    this.takeStatus = this.source.take_status
    this.resultPercentage = this.source.result_percentage
    this.failed = this.source.failed
    this.sections = this.source.sections.map(section => new CourseUnitSectionTake(section))
    this.takes = this.source.takes.map(take => new TestTakeUnit(take))
    this.active = this.source.status_ != 0 ? true : false
  }

  set active(value: boolean) {
    this.active_ = value
  }
  get status() {
    return this.active_
  }
}
