import { Directive } from '@angular/core';
import { GlobalDataService } from '../services/data/global-data.service';
import { BaseListDirective } from './base-list.directive';

@Directive({
  selector: '[appBaseListSource]'
})
export class BaseListSourceDirective extends BaseListDirective {
  
  source: GlobalDataService;
  listSources = [];

  getList(): void {
    throw new Error('Method not implemented.');
  }
  refreshActionServices(): void {
    throw new Error('Method not implemented.');
  }

  onInit() {
    super.onInit()
    this.listSources.forEach(s => this.source.load(s));
  }
}