<div class="flex flex-column h-full">
  <div class="flex align-items-center" style="height: auto">
    <ng-container *ngIf="defaultTopicId > 0 && defaultTopic">
      <div class="flex flex-column justify-content-start">
        <h5 class="module-subtitle cursor-pointer" routerLink="/topics">
          {{ "MENU.TOPICS" | translate | uppercase }}
        </h5>
        <h2 class="module-title" (click)="seeTopic()">
          {{ defaultTopic.name_ }}
        </h2>
      </div>
      <div class="flex mx-3">
        <i
          class="pi pi-angle-double-right"
          style="font-size: 2rem; color: var(--primary-color); margin-top: 10px"
        ></i>
      </div>
    </ng-container>
    <div class="flex flex-column justify-content-start">
      <h2 class="module-title cursor-pointer" routerLink="/courses">
        {{ "MENU.COURSES" | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color)"
      ></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW") | translate }}
        {{ "CORE.COURSE" | translate | lowercase }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column lg:flex-row">
            <div class="flex w-12 lg:w-4 pr-0 lg:pr-4">
              <app-image-upload
                #iuCover
                class="w-full"
                style="max-height: 260px"
                [path]="'course'"
                [id]="editId"
                [field]="'cover'"
              ></app-image-upload>
            </div>
            <div class="flex flex-column flex-grow-1">
              <div class="flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
                <span class="p-float-label">
                  <input
                    pInputText
                    id="title"
                    name="title"
                    class="ng-dirty w-full"
                    aria-describedby="title-help"
                    [(ngModel)]="form.name_"
                    required
                  />
                  <label for="title">{{ "FORM.TITLE" | translate }}*</label>
                </span>
              </div>
              <div class="flex flex-column gap-2 mt-2">
                <span class="p-float-label">
                  <textarea
                    pInputTextarea
                    id="description"
                    name="description"
                    rows="5"
                    class="ng-dirty w-full"
                    [(ngModel)]="form.description_"
                    required
                  ></textarea>
                  <label for="description"
                    >{{ "FORM.DESCRIPTION" | translate }}*</label
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label>{{ "FORM.OBJECTIVE" | translate }}</label>
            <p-editor
              name="objective"
              [(ngModel)]="form.objective"
              [style]="{ height: '200px' }"
            ></p-editor>
          </div>
          <div
            class="flex flex-column gap-2 my-4"
            *ngIf="!(defaultTopicId > 0)"
          >
            <span class="p-float-label">
              <p-multiSelect
                id="topics"
                name="topics"
                [options]="data.sources['topics'].data"
                [(ngModel)]="topics"
                [filter]="true"
                [optionValue]="data.sources['topics'].key"
                [optionLabel]="data.sources['topics'].label"
              ></p-multiSelect>
              <label for="topics">{{ "FORM.TOPICS" | translate }}</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <span class="p-float-label">
              <input
                pInputText
                id="estimated_duration"
                name="estimated_duration"
                class="ng-dirty w-full"
                aria-describedby="estimated_duration-help"
                [(ngModel)]="form.estimated_duration"
                required
              />
              <label for="estimated_duration"
                >{{ "FORM.ESTIMATED-DURATION" | translate }} ({{
                  globals.COURSE_VISUALIZATION.TIME_LIMIT_UNIT.label
                    | translate
                }})*</label
              >
            </span>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <span class="p-float-label">
              <p-inputNumber
                id="validity_period"
                name="validity_period"
                class="ng-dirty w-full"
                aria-describedby="validity_period-help"
                [(ngModel)]="form.validity_period"
                [min]="1"
              ></p-inputNumber>
              <label for="validity_period"
                >{{ "FORM.VALIDITY-PERIOD" | translate }}*</label
              >
            </span>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label for="tags">{{ "FORM.TAGS" | translate }}</label>
            <p-chips
              inputId="tags"
              name="tags"
              class="w-full"
              [separator]="globals.REG_EXP.chipFieldSeparator"
              [placeholder]="'WIDGET.CHIP-FIELD-EXAMPLE' | translate"
              [(ngModel)]="tags"
            ></p-chips>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label for="tags">{{ "FORM.SEQUENTIAL-UNITS" | translate }}</label>
            <p-selectButton [options]="sequentialOptions" id="squential-units" name="squential-units"
              optionLabel="label" optionValue="value" class="ng-dirty" [(ngModel)]="form.sequential_units" required>
              <ng-template let-item pTemplate>
                {{ item.label | translate }}
              </ng-template>
            </p-selectButton>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
