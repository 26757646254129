import { Component } from '@angular/core';
import { TestFormComponent } from '../../admin/test/test-form/test-form.component';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-test-version-test-form',
  templateUrl: './test-version-test-form.component.html',
  styleUrls: ['./test-version-test-form.component.sass']
})
export class TestVersionTestFormComponent extends TestFormComponent {

  testVersionForm = {
    el_test_id: 0,
    available_from: null,
    available_to: null,
    allowed_attempts: 1
  }
  versionDateFrom: Date = null
  versionDateTo: Date = null

  override setItemEditMethod(): void {
    this.editService = this.data.core.testVersionUnique(this.editId)
  }

  override setSubmitMethods(): void {
    super.setSubmitMethods()
    this.putService = this.data.core.testVersionUpdate(this.editId, this.formSanitized)
  }

  override handleItemEditData(data) {
    const tags = data.test.poll.tag
    this.tags = tags ? tags.split(',') : []
    this.timeLimit = data.test.time_limit / this.globals.EL_TEST_VISUALIZATION.TIME_LIMIT_UNIT.value
    this.versionDateFrom = DateUtils.strToDateTime(data.available_from)
    this.versionDateTo = DateUtils.strToDateTime(data.available_to)
    Utils.fillObj(this.form, data.test);
    Utils.fillObj(this.testVersionForm, data);

  }

  override onSubmit() {
    this.testVersionForm.available_from = DateUtils.dateToFormat(this.versionDateFrom, 'yyyy-MM-dd hh:mm')
    this.testVersionForm.available_to = DateUtils.dateToFormat(this.versionDateTo, 'yyyy-MM-dd hh:mm')
    this.form.test_version_data = this.testVersionForm
    if (this.editId) {
      delete this.form.test_version_data.available_from
      delete this.form.test_version_data.available_to
    }
    super.onSubmit()
  }

  onVersionDateFromChange() {
    if (this.versionDateFrom > this.versionDateTo) {
      this.versionDateTo = this.versionDateFrom
    }
  }

  onVersionDateToChange() {
    if (this.versionDateTo < this.versionDateFrom) {
      this.versionDateFrom = this.versionDateTo
    }
  }
}
