import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-library-form',
  templateUrl: './library-form.component.html',
  styleUrls: ['./library-form.component.sass']
})
export class LibraryFormComponent extends BaseFormDirective {

  override title = 'MENU.LIBRARIES'

  override form = {
    name_: null,
    description_: null,
    tags: null,
    module: 'EL'
  }

  tags: string[] = []

  override getItemEdit() {
    this.editService = this.data.core.libraryUnique(this.editId)
    super.getItemEdit()
  }

  setSubmitMethods(): void {
    this.postService = this.data.core.libraryCreate(this.formSanitized)
    this.putService = this.data.core.libraryUpdate(this.editId, this.formSanitized)
  }

  override handleItemEditSuccess(data: any) {
    super.handleItemEditSuccess(data)
    const tags = data['tags']
    this.tags = tags ? tags.split(',') : []
  }

  override onSubmit() {
    this.form.tags = this.tags.join(',')
    super.onSubmit()
  }

  override cleanForm(): void {
    super.cleanForm()
    this.tags = []
  }
}
