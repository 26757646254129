<ng-container *ngIf="item">
  <app-test-take-summary
    [item]="item"
    [showActions]="showActions"
    [showResult]="showResult"
  ></app-test-take-summary>
  <div
    *ngFor="let questionData of pollTake.questions; index as i"
    class="box box-elevation p-3 my-3 w-full"
  >
    <div class="flex align-items-center">
      <div class="flex-grow-1">
        <p>
          <b>{{ i + 1 }}.</b> {{ questionData.question.content }} |
          <b>{{ "FORM." + questionData.question.type_ | translate }}</b>
        </p>
      </div>
      <div class="flex"></div>
    </div>
    <div class="text-sm" [innerHTML]="questionData.question.complement"></div>
    <div *ngIf="questionData.question.type_ === 'OPEN'">
      <div class="flex align-items-center">
        <div class="flex-grow-1">
          <p>
            {{ "FORM.ANSWER" | translate }}:
            <i>{{ questionData.open.content }}</i>
          </p>
        </div>
        <div class="flex">
          <b>Calificación: {{ questionData.result }}</b>
        </div>
      </div>
    </div>
    <div *ngIf="questionData.question.type_ === 'SELECTION-SINGLE'">
      <div *ngFor="let selection of questionData.selection; index as i">
        <div class="flex align-items-center">
          <div class="flex-grow-1">
            <p class="ml-2">
              {{ "FORM.ANSWER" | translate }}:
              {{ selection.answer_selection.content }}
            </p>
          </div>
          <div class="flex">
            <p-tag
              styleClass="align-self-center p-text-secondary text-lg"
              severity="success"
              *ngIf="selection.answer_selection.is_correct"
            >
              <span class="material-symbols-outlined" style="font-size: 2rem">
                done
              </span>
            </p-tag>
            <p-tag
              styleClass="align-self-center p-text-secondary text-lg"
              severity="danger"
              *ngIf="!selection.answer_selection.is_correct"
            >
              <span class="material-symbols-outlined" style="font-size: 2rem">
                close
              </span>
            </p-tag>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="questionData.question.type_ === 'SELECTION-MULTIPLE'">
      <p>{{ "FORM.ANSWERS" | translate }}:</p>
      <div *ngFor="let selection of questionData.selection; index as i">
        <div class="flex align-items-center">
          <div class="flex-grow-1">
            <p class="ml-2">
              <span>{{ i + 1 }}.</span>
              {{ selection.answer_selection.content }}
            </p>
          </div>
          <div class="flex">
            <p-tag
              styleClass="align-self-center p-text-secondary"
              severity="success"
              *ngIf="selection.answer_selection.is_correct"
            >
              <span class="material-symbols-outlined" style="font-size: 1.5rem">
                done
              </span>
            </p-tag>
            <p-tag
              styleClass="align-self-center p-text-secondary"
              severity="danger"
              *ngIf="!selection.answer_selection.is_correct"
            >
              <span class="material-symbols-outlined" style="font-size: 1.5rem">
                close
              </span>
            </p-tag>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="questionData.question.type_ === 'RELATION'">
      <p>{{ "FORM.ANSWERS" | translate }}:</p>
      <div class="flex align-items-center">
        <div class="example-container flex-grow-1">
          <div class="flex-grow-1 example-list">
            <ng-container *ngFor="let related of questionData.related">
              <div class="example-box" style="cursor: default">
                {{ related.answer_related.content }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="flex mx-2">
          <span class="material-symbols-outlined"> arrow_forward </span>
        </div>
        <div class="example-container flex-grow-1">
          <div class="flex-grow-1 example-list">
            <ng-container *ngFor="let related of questionData.related">
              <div
                class="example-box"
                style="border: 2px solid"
                [ngClass]="
                  related.answer_related.content ===
                  related.answer_related_value.answer_related.content
                    ? 'border-green-400'
                    : 'border-red-400'
                "
              >
                <div class="flex w-full align-items-center">
                  <div class="flex-grow-1">
                    {{ related.answer_related_value.content }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
