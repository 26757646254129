import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent extends BaseDirective {
  underConstruction = false
  coursesWithUserEnrollmentQuery: Observable<any> = this.data.core.coursesWithUserEnrollment()
  availableUserCoursesQuery: Observable<any> = this.data.core.pendingAvailableUserCourses()
}
