/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { Message } from 'primeng/api';
import { ScrollPanel } from 'primeng/scrollpanel';
import { ContentTriggerButtonComponent } from 'src/app/components/shared/content-trigger-button/content-trigger-button.component';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { AWSUtils } from 'src/app/shared/utils/aws-utils';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-course-view',
  templateUrl: './course-view.component.html',
  styleUrls: ['./course-view.component.sass']
})
export class CourseViewComponent extends BaseItemDirective implements OnDestroy {
  @ViewChild('contentTrigger') contentTrigger!: ContentTriggerButtonComponent
  @ViewChild('sectionScrollPanel') scrollPanel: ScrollPanel;

  course = null
  courseId = 0
  courseEnrollmentId = 0
  selectedSection: any
  selectedUnit: any
  selectedCourse: any
  estimatedDuration = null
  messages: Message[] | undefined
  timerInterval: NodeJS.Timer = null

  units: any[] = []
  unitTakes: any[] = []
  sequentialUnits = 0
  currentUnitIdx = 0

  youtubeEmbedParams: YT.PlayerVars = {
    controls: 1,
    autoplay: 0,
    loop: 0,
    disablekb: 1,
    fs: 0
  };
  progressBarValue = 0

  ngOnDestroy(): void {
    clearInterval(this.timerInterval)
  }

  override getItem() {
    this.itemService = this.data.core.fullCourse(this.courseId);
    super.getItem();
  }

  override handleGetItemSuccess(data: any) {
    this.utils.executeRequest(this.data.core.courseEnrollmentCurrent({
      user_id: this.utils.global.user.user_id,
      el_course_id: this.courseId
    }), {
      onSuccess: (currentEnrollment: any) => {
        this.courseEnrollmentId = currentEnrollment.el_course_enrollment_id
        this.utils.executeRequest(this.data.core.courseEnrollmentUnitTakes({
          el_course_id: this.courseId,
          el_course_enrollment_id: this.courseEnrollmentId
        }), {
          onSuccess: (unitTakes) => {
            this.unitTakes = unitTakes
          },
          onError: () => {
            super.handleGetItemSuccess(data)
          },
          onComplete: () => {
            super.handleGetItemSuccess(data)
          }
        })
      }
    })
  }

  sectionScrollPanelToTop() {
    if (this.scrollPanel) {
      this.scrollPanel.scrollTop(0);
    }
  }

  override async onItemReady() {
    this.course = this.item;
    this.sequentialUnits = this.course.sequential_units
    this.units = this.course.units
    this.units = this.units.filter((unit: any) => unit.status_)
    this.units = Utils.sortByProperty(this.units, 'order_', 'asc')

    this.units.forEach((unit: any, uIndex) => {
      unit.el_course_unit_take_id = 0
      unit.sections = unit.sections.filter(section => section.status_);
      unit.sections = Utils.sortByProperty(unit.sections, 'order_', 'asc')
      unit.couldGoToUnit = this.couldGoToUnit(uIndex)

      unit.sections.forEach((section: any, sIndex: number) => {
        section.el_course_unit_section_take_id = 0
        section.isComplete = false
        section.couldGoToSection = this.couldGoToSection(sIndex, uIndex)
        section.contentTimer = 0
      });

      unit.tests.forEach(uTest => {
        uTest.isComplete = false
        uTest.hasTakes = false
        uTest.remainingAttempts = uTest.test.allowed_attempts
        uTest.couldGoToTest = this.couldGoToTest(uIndex)
      })

      this.initUnitTakeData(unit)

      unit.isComplete = [
        !unit.sections.length || unit.sections.every(section => section.isComplete),
        !unit.tests.length || unit.tests.every(test => test.isComplete)
      ].every(Boolean);
    });

    const incUnitIdx = this.units.findIndex(unit => !unit.isComplete && unit.sections.length)
    const incUnit = this.units[incUnitIdx]

    if (incUnit) {
      await this.selectSection(
        incUnitIdx,
        incUnit.sections.findIndex(section => !section.isComplete)
      );
    } else {
      this.selectCourse()
    }

    this.estimatedDuration = this.course.estimated_duration.split(' ')[0];
    this.updateCourseModel()
  }

  initUnitTakeData(unit: any) {

    console.log(this.unitTakes, unit);

    const unitTake = this.unitTakes.find(uTake => {
      return uTake.el_course_unit_id === unit.el_course_unit_id && uTake.take_status !== 'FAILED' && uTake.failed !== 1
    })

    if (unitTake) {
      unit.tests.forEach(uTest => {
        const testTakes = unitTake.takes.filter(tTake => tTake.take.el_test_id === uTest.el_test_id)
          .map(tTake => {
            tTake.result = Utils.floatStringToNumber(tTake.take.result)
            return tTake
          })

        uTest.hasTakes = !!testTakes.length
        const testMinApprovalPerc = Utils.floatStringToNumber(uTest.test.min_approval_percentage)
        uTest.highestRating = Utils.sortByProperty(testTakes, 'result', 'desc')[0]?.result
        uTest.approved = uTest.highestRating >= testMinApprovalPerc
        uTest.remainingAttempts = uTest.allowed_attempts - testTakes.length
        uTest.isComplete = !uTest.approval_required || (testTakes.length && uTest.approved)
      })

      if (unit.tests.find(uTest => !uTest.isComplete && uTest.remainingAttempts <= 0)) {
        this.utils.executeRequest(
          this.data.core.courseEnrollmentUnitUpdate(unitTake.el_course_unit_take_id, {
            failed: 1,
            take_status: "FAILED"
          }), {
          onSuccess: () => {
            unit.sections.forEach(section => {
              localStorage.removeItem(
                `${this.courseEnrollmentId}${unit.el_course_unit_id}${section.el_course_unit_section_id}`
              )
            })
          }
        })
      } else {

        this.utils.executeRequest(
          this.data.core.courseEnrollmentalidateStatus(this.courseEnrollmentId), {
          onSuccess: () => {
          }
        })


        unit.sections.forEach((section: any) => {
          section.contentTimer = localStorage.getItem(
            `${this.courseEnrollmentId}${unit.el_course_unit_id}${section.el_course_unit_section_id}`
          ) ?? 0
          const sectionTake = unitTake.sections.find(sTake => sTake.el_course_unit_section_id === section.el_course_unit_section_id)
          if (sectionTake) {
            section.el_course_unit_section_take_id = sectionTake.el_course_unit_section_take_id
            section.isComplete = sectionTake.completion_date;
            if (section.isComplete) {
              section.contentTimer = section.resource_content.min_viewing_time * this.globals.CONTENT_VISUALIZATION.TIME_LIMIT_UNIT.value
            }
          }
        });
      }
    }
  }

  updateCourseModel() {
    this.units.forEach((unit: any, uIndex: number) => {
      unit.couldGoToUnit = this.couldGoToUnit(uIndex)
      unit.sections.forEach((section: any, sIndex: number) => {
        section.couldGoToSection = this.couldGoToSection(sIndex, uIndex)
      });
      unit.tests.forEach((test: any) => {
        test.couldGoToTest = this.couldGoToTest(uIndex)
      });
      unit.isComplete = [
        !unit.sections.length || unit.sections.every(section => section.isComplete),
        !unit.tests.length || unit.tests.every(test => test.isComplete)
      ].every(Boolean);
    });
  }

  refreshActionServices(): void { }

  previousUnitsCompleted(unitIdx: any) {
    const prevUnits = this.units.slice(0, unitIdx)
    return !prevUnits.length || prevUnits.every((unit: any) => unit.isComplete);
  }

  previousSections(sectionIdx: number, unitIdx: number) {
    return this.units[unitIdx].sections.slice(0, sectionIdx)
  }

  previousSectionsCompleted(sectionIdx: number, unitIdx: number) {
    const prevSections = this.previousSections(sectionIdx, unitIdx)
    return !prevSections.length || prevSections.every((section: any) => section.isComplete || (section.couldGoToSection && !section.visualize_required));
  }

  couldGoToSection(sectionIdx: number, unitIdx: number) {
    const unit = this.units[unitIdx]
    let result = true

    if (this.sequentialUnits) {
      result = this.previousUnitsCompleted(unitIdx)
    }
    if (result && unit.sequential_sections) {
      result = this.previousSectionsCompleted(sectionIdx, unitIdx)
    }
    return result
  }

  couldGoToTest(unitIdx: number) {
    return this.units[unitIdx].sections.every(section => section.isComplete)
  }

  couldGoToUnit(unitIdx: number) {
    return !this.sequentialUnits ||
      (this.sequentialUnits && this.previousUnitsCompleted(unitIdx))
  }

  isCurrentSection(section: any) {
    return this.selectedSection && (section.el_course_unit_section_id === this.selectedSection.el_course_unit_section_id)
  }

  async selectSection(unitIdx: number, sectionIdx: any) {
    this.sectionScrollPanelToTop()

    if (!this.couldGoToUnit(unitIdx)) {
      this.messages = [{ severity: 'warn', detail: 'Debes completar la unidad anterior antes de acceder a esta clase' }];
      return;
    }

    this.messages = [];
    await this.selectUnit(unitIdx)
    const section = this.selectedUnit.sections[sectionIdx];

    if (!section) { return }

    if (!section.couldGoToSection) {
      this.messages = [{ severity: 'warn', detail: 'Debes completar la clase anterior para acceder a esta' }];
      return
    }

    if (!this.isCurrentSection(section)) {
      clearInterval(this.timerInterval);
    }

    this.selectedSection = section
    this.selectedSection.idx = sectionIdx
    await this.createUnitSectionTake()

    if (!this.selectedSection.isComplete && ['IMG', 'VIDEO', 'AUDIO', 'PDF'].includes(this.selectedSection.resource_content.type_)) {
      this.startSectionTimer()
    } else {
      await this.completeProgressBar()
    }
  }

  onSelectUnit(unitIdx: number) {
    this.selectedUnit = this.units[unitIdx]
    this.selectedUnit.idx = unitIdx
    this.selectedCourse = null

    let durationUnit = 0;
    this.selectedUnit.sections.forEach(s => {
      s.duration = Utils.convertSecondsToHMS(s.resource_content.min_viewing_time)
      durationUnit += s.resource_content.min_viewing_time
    });
    this.selectedUnit.duration = Utils.convertSecondsToHMS(durationUnit)
    console.log(this.selectedUnit);

  }

  async selectUnit(unitIdx: number) {
    this.onSelectUnit(unitIdx)
    await this.createUnitTake()
  }

  onlySelectUnit(unitIdx: any): void {
    this.onSelectUnit(unitIdx)
    this.selectedSection = null
  }

  selectCourse() {
    this.selectedUnit = null
    this.selectedSection = null
    this.selectedCourse = this.course
  }

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params);
    if ('id' in params) {
      this.courseId = +params['id'];
      this.itemId = this.courseId;
    }
  }

  onPlayerPlay(): void {
    this.startSectionTimer();
  }

  onPlayerPause(): void {
    clearInterval(this.timerInterval);
  }

  async onSectionComplete() {
    this.selectedSection.isComplete = true;
    this.updateCourseModel()
    await this.unitSectionTakeCompletion()
    this.unitTakeCompletion()
  }

  async completeProgressBar() {
    this.selectedSection.isComplete = true;
    this.updateCourseModel()
    await this.onSectionComplete()
  }

  startSectionTimer(): void {
    const timeLimitInSeconds = (this.selectedSection.resource_content.min_viewing_time * this.globals.CONTENT_VISUALIZATION.TIME_LIMIT_UNIT.value);
    this.timerInterval = setInterval(() => {
      if (this.selectedSection) {
        this.selectedSection.contentTimer++;

        localStorage.setItem(
          `${this.courseEnrollmentId}${this.selectedUnit.el_course_unit_id}${this.selectedSection.el_course_unit_section_id}`,
          this.selectedSection.contentTimer
        )

        this.progressBarValue = (this.selectedSection.contentTimer / timeLimitInSeconds) * 100;
        if (this.selectedSection.contentTimer >= timeLimitInSeconds) {
          this.onSectionComplete()
          clearInterval(this.timerInterval);
        }
      }
    }, 1000);
  }

  calculateProgress(section: any): number {
    const timeLimitInSeconds = section.resource_content.min_viewing_time * this.globals.CONTENT_VISUALIZATION.TIME_LIMIT_UNIT.value;
    const progress = (section.contentTimer / timeLimitInSeconds) * 100;

    return Math.min(progress, 100);
  }

  calculateUnitProgress(unit: any): number {
    const completedSections = unit.sections.filter(section => section.isComplete && section.visualize_required).length;
    const totalSections = unit.sections.filter(section => section.status_ === 1 && section.visualize_required).length;
    const completedTests = unit.tests.filter(test => test.isComplete && test.approval_required).length;
    const totalTests = unit.tests.filter(test => test.approval_required).length;

    return ((completedSections + completedTests) / (totalSections + totalTests)) * 100;
  }

  get calculateCourseProgress(): number {
    const sections = this.units.map(unit => unit.sections).flat()
    const completedSections = sections.filter(section => section.status_ === 1 && section.isComplete).length;
    const totalSections = sections.filter(unit => unit.status_ === 1).length;

    const tests = this.units.map(unit => unit.tests).flat()
    const completedTests = tests.filter(test => test.isComplete).length;
    const totalTests = tests.filter(test => test.approval_required).length;

    return ((completedSections + completedTests) / (totalSections + totalTests)) * 100;
  }

  finishCourse() {
    this.utils.executeRequest(this.data.core.finishCourse({
      el_course_id: this.course.el_course_id,
      user_id: this.utils.global.user.user_id,
      enrollment_status: "F",
      approved: 0,
    }), {
      onSuccess: () => {
        location.reload()
      }
    })
  }

  viewSupportContent(resource: any) {
    if (this.fireActions) {
      AWSUtils.initResourceData(resource, 'content')
      this.contentTrigger.item = resource
      this.contentTrigger.seeResource()
    }
  }

  createUnitTake() {
    if (this.selectedUnit && this.selectedUnit.el_course_unit_take_id === 0) {
      return new Promise((resolve) => {
        this.utils.executeRequest(this.data.core.courseEnrollmentUnitCreate({
          el_course_unit_id: this.selectedUnit.el_course_unit_id,
          el_course_enrollment_id: this.courseEnrollmentId,
          start_date: Utils.dateToFormat(new Date(), 'yyyy-MM-dd hh:mm')
        }), {
          onSuccess: async (data: any) => {
            this.selectedUnit.el_course_unit_take_id = data
          },
          onError: () => {
            resolve(true)
          },
          onComplete: () => {
            resolve(true)
          }
        })
      })
    }
  }

  createUnitSectionTake() {
    if (this.selectedSection && this.selectedSection.el_course_unit_section_take_id === 0) {
      return new Promise((resolve) => {
        this.utils.executeRequest(this.data.core.courseEnrollmentUnitSectionCreate({
          el_course_unit_take_id: this.selectedUnit.el_course_unit_take_id,
          el_course_unit_section_id: this.selectedSection.el_course_unit_section_id,
          start_date: Utils.dateToFormat(new Date(), 'yyyy-MM-dd hh:mm')
        }), {
          onSuccess: (data) => {
            this.selectedSection.el_course_unit_section_take_id = data
          },
          onError: () => {
            resolve(true)
          },
          onComplete: () => {
            resolve(true)
          }
        })
      })
    }
  }

  unitSectionTakeCompletion() {
    if (this.selectedSection.isComplete && this.selectedSection.el_course_unit_section_take_id > 0) {
      return new Promise((resolve) => {
        this.utils.executeRequest(this.data.core.courseEnrollmentUnitSectionCompletion({
          el_course_unit_id: this.selectedUnit.el_course_unit_id,
          el_course_enrollment_id: this.courseEnrollmentId,
          el_course_unit_section_id: this.selectedSection.el_course_unit_section_id
        }), {
          onError: () => {
            resolve(true)
          },
          onComplete: () => {
            this.utils.executeRequest(
              this.data.core.courseEnrollmentalidateStatus(this.courseEnrollmentId), {
              onSuccess: () => {
              }
            })

            resolve(true)

          }
        })
      })
    }
  }

  unitTakeCompletion() {
    if (this.selectedUnit.isComplete && this.selectedUnit.el_course_unit_take_id > 0) {
      this.utils.executeRequest(this.data.core.courseEnrollmentUnitStatus({
        el_course_unit_id: this.selectedUnit.el_course_unit_id,
        el_course_enrollment_id: this.courseEnrollmentId,
        take_status: 'APPROVED'
      }), {
        onError: () => {
        }
      })
    }
  }
}
