import { BaseDirective } from './base.directive';
import { Directive } from '@angular/core';

@Directive({
  selector: '[appBaseConfirmationDialog]',
})
export abstract class BaseConfirmationDialogDirective extends BaseDirective {
  message = ''

  onInit(): void {
    this.message = this.dialogConfig.data.message
  }

  confirm() {
    this.dialogRef.close({ confirmed: true })
  }

  reject() {
    this.dialogRef.close({ confirmed: false })
  }
}
