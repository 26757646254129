import { Component } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-evaluations-table',
  templateUrl: './evaluations-table.component.html',
  styleUrls: ['./evaluations-table.component.sass']
})
export class EvaluationsTableComponent extends BaseListDirective {
  evaluations = [];
  override title = 'MENU.TESTS';
  override filterableFields = ['poll.title']
  override keys = {
    primary_key: 'el_test_version_id',
    label: 'test.poll.title'
  }
  override filename = 'MENU.TESTS';
  evaluationSelected = null;
  unitId = 0;

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(utils,
      data,
      route,
      dialog,
      dialogConfig,
      dialogRef)
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.unitId = +this.dialogConfig.data.unit_id ?? this.unitId
  }

  override getList() {
    this.query(this.data.core.testsVersions()).subscribe();
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.testVersionDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  override handleRows(data: any): void {
    super.handleRows(data)
  }

  removeUser(testId) {

    const evaluation = this.evaluations.find(it => it.el_test_id === testId);

    if (evaluation.el_course_unit_test_id !== undefined) {
      this.data.core.unitTestDelete(evaluation.el_course_unit_test_id)
        .subscribe(() => {
          this.utils.toast.saved(this.title)
        })
    }

    this.evaluations = this.evaluations.filter((it) => it.el_test_id !== testId);
    this.processing = true;
  }

  appendUser(selected) {
    setTimeout(() => {
      this.evaluationSelected = null;
    }, 200);


    const rs = this.evaluations.filter(
      (it) => it.el_test_id === selected.el_test_id
    );


    if (rs?.length) {
      return;
    }
    this.evaluations.push({
      el_test_id: selected.el_test_id,
      title: selected.test.poll.title,
      el_course_unit_test_id: selected.el_course_unit_test_id,
      allowed_attempts: 1,
      approval_required: 0,
      el_course_unit_id: this.unitId
    });
  }

  save() {
    const objects = this.evaluations
    this.utils.executeRequest(
      this.data.core.unitTestBatch({ objects }), {
      onSuccess: () => {
        this.dialogRef.close()
      }
    })
  }

}
