<p-columnFilter
  [field]="field"
  [matchModeOptions]="matchModeOptions"
  [matchMode]="'unique-by-key'"
  display="menu"
  [showMatchModes]="false"
  [showOperator]="false"
  [showAddButton]="false"
>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <span class="p-float-label">
      <p-multiSelect
        [ngModel]="value"
        [options]="options"
        placeholder="{{ 'FORM.SELECT-A-F' | translate }} {{
          ('ENV.TAG' | translate).toLowerCase()
        }}"
        (onChange)="filter($event.value)"
        selectedItemsLabel="{0} {{ ('ENV.TAGS' | translate).toLowerCase() }} {{
          ('FORM.SELECTED-F' | translate).toLowerCase()
        }}"
        [maxSelectedLabels]="1"
        [optionLabel]="'label'"
        appendTo="body"
      ></p-multiSelect>
      <label class="p-text-bold">{{ "ENV.TAG" | translate }}</label>
    </span>
  </ng-template>
</p-columnFilter>
