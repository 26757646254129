<button
  pButton
  pRipple
  type="button"
  icon="pi pi-check"
  [label]="labelOn"
  class="p-button-raised p-button-success w-100 p-button-ellipsis"
  (click)="toggle()"
  [pTooltip]="labelOn"
  tooltipPosition="bottom"
  [tooltipDisabled]="!showTooltip"
  *ngIf="data"
></button>
<button
  pButton
  pRipple
  type="button"
  icon="pi pi-times"
  [label]="labelOff"
  class="p-button-outlined p-button-secondary w-100 p-button-ellipsis"
  (click)="toggle()"
  [pTooltip]="labelOff"
  tooltipPosition="bottom"
  [tooltipDisabled]="!showTooltip"
  *ngIf="!data"
></button>
