/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-test-form',
  templateUrl: './test-form.component.html',
  styleUrls: ['./test-form.component.sass']
})
export class TestFormComponent extends BaseFormDirective {
  override title = 'MENU.TESTS'
  override form: any = {
    poll: {
      title: null,
      review: null,
      module: 'EL',
      type_: 'TEST',
      tag: null
    } as any,
    time_limit: 0,
    min_approval_percentage: 80,
    random_questions: false
  }

  timeLimit = 0
  tags = []

  override getItemEdit() {
    this.editService = this.data.core.testUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.testCreate(this.formSanitized)
    this.putService = this.data.core.testUpdate(this.editId, this.formSanitized)
  }

  override onSubmit(): void {
    this.form.poll.tag = this.tags.join(',')
    this.form.time_limit = this.timeLimit * (
      this.globals.EL_TEST_VISUALIZATION.TIME_LIMIT_UNIT.value /
      this.globals.TIME_OPTIONS.SECONDS.value
    )
    super.onSubmit()
  }

  override handleItemEditData(data) {
    const tags = data.poll.tag
    this.tags = tags ? tags.split(',') : []
    this.timeLimit = data.time_limit / this.globals.EL_TEST_VISUALIZATION.TIME_LIMIT_UNIT.value
  }

  cleanForm(): void { }
}
