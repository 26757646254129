<div class="flex flex-column" [ngClass]="containerStyleClass">
  <div class="flex flex-grow-1 h-full" *ngIf="!horizontal">
    <div class="flex w-12 relative">
      <div
        class="absolute h-full w-full"
        *ngIf="!loading; else loadingTemplate"
      >
        <ng-container>
          <div
            *ngFor="let row of rows"
            class="box-elevation border-round-md border-2 flex flex-column border-transparent shadow-0 my-2 white-card cursor-pointer"
            routerLink="/tests/take/{{ row.el_test_version_id }}/V/{{
              row.el_test_version_id
            }}/{{ row.test.el_test_id }}"
            (click)="handleItemSelected(row)"
          >
            <div class="flex align-items-center justify-content-between w-full">
              <p class="pl-3 flex align-items-center gap-2">
                <span class="material-symbols-outlined" style="font-size: 2rem">
                  quiz </span
                >{{ row.test?.poll?.title }}
              </p>
            </div>
            <div class="flex flex-column gap-2 pl-3 pr-2">
              <p class="mb-2">
                {{ row.takes.length }} intentos realizados de
                {{ row.allowed_attempts }} intentos permitidos
              </p>
              <p-progressBar
                [value]="row.takesProgress"
                [showValue]="false"
                class="w-full"
                [style]="{ height: '15px' }"
              ></p-progressBar>
              <div class="flex align-items-center gap-2" *ngIf="row.hasTakes">
                <p class="text-md">
                  Calificación más alta:
                  {{ row.highestRating / 100 | percent : "1.2-2" }}
                </p>
                <p-tag
                  severity="success"
                  value="Aprobado"
                  *ngIf="row.approved"
                ></p-tag>
                <p-tag
                  severity="danger"
                  value="Reprobado"
                  *ngIf="!row.approved"
                ></p-tag>
              </div>
              <span class="text-sm text-right" *ngIf="row.remainingAttempts">
                Intentos restantes: {{ row.remainingAttempts }}
              </span>
            </div>
            <p-divider styleClass="m-1"></p-divider>
            <div class="flex justify-content-end m-2">
              <p-tag
                [severity]="'danger'"
                [value]="'Requiere aprobación' | translate"
                *ngIf="row.approval_required"
              >
              </p-tag>
              <p-tag
                [severity]="'success'"
                [value]="'No requiere aprobación' | translate"
                *ngIf="!row.approval_required"
              >
              </p-tag>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="flex flex-grow-1 h-full" *ngIf="horizontal">
    <div class="flex w-12">
      <div class="h-full w-full" *ngIf="!loading; else loadingTemplate">
        <p-carousel
          [value]="rows"
          [numVisible]="rows.length <= 3 ? rows.length : 3"
          [numScroll]="1"
          [circular]="false"
        >
          <ng-template let-row pTemplate="item">
            <div class="m-3">
              <div
                class="box-elevation border-round-md border-2 flex flex-column border-transparent shadow-0 my-2 white-card cursor-pointer"
                routerLink="/tests/take/{{ row.el_test_version_id }}/V/{{
                  row.el_test_version_id
                }}/{{ row.test.el_test_id }}"
                (click)="handleItemSelected(row)"
              >
                <div
                  class="flex align-items-center justify-content-between w-full"
                >
                  <p class="pl-3 flex align-items-center gap-2">
                    <span
                      class="material-symbols-outlined"
                      style="font-size: 2rem"
                    >
                      quiz </span
                    >{{ row.test?.poll?.title }}
                  </p>
                </div>
                <div class="flex flex-column gap-2 pl-3 pr-2">
                  <p class="mb-2">
                    {{ row.takes.length }} intentos realizados de
                    {{ row.allowed_attempts }} intentos permitidos
                  </p>
                  <p-progressBar
                    [value]="row.takesProgress"
                    [showValue]="false"
                    class="w-full"
                    [style]="{ height: '15px' }"
                  ></p-progressBar>
                  <div
                    class="flex align-items-center gap-2"
                    *ngIf="row.hasTakes"
                  >
                    <p class="text-md">
                      Calificación más alta:
                      {{ row.highestRating / 100 | percent : "1.2-2" }}
                    </p>
                    <p-tag
                      severity="success"
                      value="Aprobado"
                      *ngIf="row.approved"
                    ></p-tag>
                    <p-tag
                      severity="danger"
                      value="Reprobado"
                      *ngIf="!row.approved"
                    ></p-tag>
                  </div>
                  <span
                    class="text-sm text-right"
                    *ngIf="row.remainingAttempts"
                  >
                    Intentos restantes: {{ row.remainingAttempts }}
                  </span>
                </div>
                <p-divider styleClass="m-1"></p-divider>
                <div class="flex justify-content-end m-2">
                  <p-tag
                    [severity]="'danger'"
                    [value]="'Requiere aprobación' | translate"
                    *ngIf="row.approval_required"
                  >
                  </p-tag>
                  <p-tag
                    [severity]="'success'"
                    [value]="'No requiere aprobación' | translate"
                    *ngIf="!row.approval_required"
                  >
                  </p-tag>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <div class="loading-container">
      <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
    </div>
  </ng-template>
</div>
