import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { DialogListData, PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { CourseEnrolledUsersTableComponent } from '../../../course/course-item/course-item-enrolled-users/course-enrolled-users-table/course-enrolled-users-table.component';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-report-course-table',
  templateUrl: './report-course-table.component.html',
  styleUrls: ['./report-course-table.component.sass']
})
export class ReportCourseTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.NAME', 250, { frozen: true }),
    new PrimeNGTableColumn('description_', 'FORM.DESCRIPTION', 350),
    new PrimeNGTableColumn('estimated_duration', 'FORM.ESTIMATED-DURATION', 100),
    new PrimeNGTableColumn('course_user_available_count', 'CORE.AVAILABLE-USERS', 100),
    new PrimeNGTableColumn('course_enrollments_count', 'CORE.ENROLLED-USERS', 100)
  ];
  override title = 'MENU.USERS';
  override filterableFields = []
  override keys = {
    primary_key: 'el_course_id',
    label: 'name_'
  }
  override filename = 'MENU.USERS';
  override dataFormatter = this.utils.getCourseFormatter()

  formatData(data: any) {
    this.utils.applyFormat(data, this.dataFormatter)
  }

  override getList(): void {
    this.query(this.data.core.courses()).subscribe()
  }

  override handleRows(data: any): void {
    console.log(data);
    super.handleRows(data);
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.userDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  seeOnlyAvailableUsers(row: any) {
    super.handleItemSelected(row)

    this.utils.appDialog.seeCourseUserAvailableDialog(
      this.itemSelected[this.keys.primary_key], this.itemSelected[this.keys.label], true, {
      onSuccess: () => {
        this.getList()
      }
    })
  }

  seeEnrollments(row: any) {
    this.utils.appDialog.seeCourseEnrollmentsDialog(row[this.keys.primary_key])
  }
}
