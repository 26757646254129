import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-wilcard-filter',
  templateUrl: './wilcard-filter.component.html',
  styleUrls: ['./wilcard-filter.component.sass']
})
export class WilcardFilterComponent implements OnInit, OnChanges {

  wildcards = [];
  tempWildcards = [];
  tableSelect;
  fieldsTable = []
  fieldtable



  constructor(public utils: UtilsService, public data: GlobalDataService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('type' in changes) {
      console.log('entro');

    }
  }

  changeTable(data) {
    this.fieldsTable = data.fields

  }


  ngOnInit(): void {
    this.getWildcards();
  }

  getWildcards() {
    this.data.core.wildcards().subscribe(
      data => this.handleWildcards(data),

    );
  }

  searchWildcard(event: any) {
    const val = event.target.value.toLowerCase();
    this.wildcards = this.tempWildcards.filter(item => {
      return item.key_.toLowerCase().indexOf(val) !== -1 || !val;
    });
  }

  handleWildcards(data) {
    this.wildcards = data;
    this.tempWildcards = [...data];
  }

  copyWildcard(val: string) {
    Utils.copyToClipboard(`[var:${val}]`);
    this.utils.toast.info(this.utils.translate.instant('FORM.CLIPBOARD'), this.utils.translate.instant('ML.WILDCARD') + ' ' + this.utils.translate.instant('FORM.COPIED').toLowerCase());
  }

}
