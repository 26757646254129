import { Component } from '@angular/core';
import { BaseTagDirective } from 'src/app/directives/base-chip.directive';
import { AppTag } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-app-chip',
  templateUrl: './app-chip.component.html',
  styleUrls: ['./app-chip.component.sass']
})
export class AppChipComponent extends BaseTagDirective {
}

@Component({
  selector: 'app-enrollment-status-chip',
  templateUrl: './app-chip.component.html',
  styleUrls: ['./app-chip.component.sass']
})
export class EnrollmentStatusChipComponent extends BaseTagDirective {
  override statuses: { [key: string]: AppTag; } = this.globals.ENROLLMENT_STATUS;
}

@Component({
  selector: 'app-test-approval-required-chip',
  templateUrl: './app-chip.component.html',
  styleUrls: ['./app-chip.component.sass']
})
export class TestApprovalRequiredChipComponent extends BaseTagDirective {
  override statuses: { [key: string]: AppTag; } = this.globals.TEST_APPROVAL_REQUIRED_STATUS;
}
