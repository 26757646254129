<p-toast position="top-center"></p-toast>
<div class="flex flex-1 flex-column px-6" *ngIf="processing">
    <div class="flex justify-content-center mb-2">
      <img src="assets/images/logo.png" alt="Logo" style="max-width: 100%; max-height: 120px">
    </div>
    <div class="flex justify-content-center" style="height: 140px">
      <ng-lottie [options]="options"></ng-lottie>
    </div>
    <div class="flex justify-content-center">
      {{ 'AUTH.AUTHENTICATING' | translate }}
    </div>
  </div>
  <div class="flex flex-1 flex-column px-6" *ngIf="!processing">
    <div class="flex flex-1 flex-column">
      <div class="flex justify-content-center flex-1">
        <h3>{{ 'AUTH.RESTORE-PASSWORD' | translate }}</h3>
      </div>
      <div class="flex justify-content-center flex-1 mb-2">
        <span class="text-center p-text-secondary   ">{{ "WIDGET.RESTORE-PASSWORD-MESSAGE" | translate }}</span>
      </div>
      <form #dataForm="ngForm" [formGroup]="signInForm" (ngSubmit)="authenticate()">
        <div class="flex flex-column w-100 py-2">
          <label class="block mb-2" for="username">{{ 'AUTH.EMAIL' | translate }}</label>
          <input id="username" formControlName="username" type="text" class="w-full" pInputText
            placeholder="{{ 'AUTH.EMAIL' | translate }}" required />
        </div>
      </form>
      <div class="flex flex-1 mt-3">
        <button pButton label="{{ 'AUTH.SEND' | translate }}" class="flex flex-1" [disabled]="!signInForm.valid"
          (click)="dataForm.ngSubmit.emit()"></button>
      </div>
    </div>
   
  </div>
  