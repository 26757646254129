import { Component, Input } from '@angular/core';
import { BaseItemRestructDirective } from 'src/app/directives/restruct/base-item-restruct.directive';
import { AttemptableTest, AttemptableTestJSON } from 'src/app/models/AttemptableTestClasses';

@Component({
  selector: 'app-attemptable-test-detail',
  templateUrl: './attemptable-test-detail.component.html',
  styleUrls: ['./attemptable-test-detail.component.sass']
})
export class AttemptableTestDetailComponent extends BaseItemRestructDirective<AttemptableTest, AttemptableTestJSON> {
  @Input() takeId = 0
  @Input() showHeader = true
  @Input() type: 'V' | 'U' | 'C' = 'V'
  ItemModel = AttemptableTest;

  override getSource() {
    switch (this.type) {
      case 'V':
        this.itemService = this.data.core.testVersionUnique(this.itemId)
        break;
      case 'U':
        this.itemService = this.data.core.unitTestUnique(this.itemId)
        break
    }
    super.getSource()
  }
}
