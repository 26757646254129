import { Component } from '@angular/core';
import { Params } from '@angular/router';
import { TestDetailComponent } from 'src/app/components/shared/test-detail/test-detail.component';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';
import { AppMenuItem } from 'src/app/models/AppGenericClasses';

type WeightCalculationType = "DISTRIBUTE" | "CALCULATE" | "FREE"

interface WeightCalculation {
  label: string
  value: WeightCalculationType
  warningMsg: string
}

@Component({
  selector: 'app-test-question-form',
  templateUrl: './test-question-form.component.html',
  styleUrls: ['./test-question-form.component.sass']
})
export class TestQuestionFormComponent extends BaseFormDirective {
  form = {
    poll_id: 0,
    content: null,
    type_: 'SELECTION-SINGLE',
    weight: 0.0,
    complement: null,
    proof_required: false,
    answers: [],
  };
  answerForm = {
    content: null,
    is_correct: 0,
  };
  answerRelatedForm = {
    content: null,
    related_value: {
      content: null,
    },
  };

  answers: any[] = [];
  answersRelated: any[] = [];
  answerIdxSelected = 0;

  parentTestId = 0
  parentTest = null
  open = false
  typeMenuItems: AppMenuItem[] = []
  typeMenuItemActive = ''

  title = 'CORE.QUESTION'
  codeType = 1

  weightCalculations: WeightCalculation[] = [
    {
      label: "FORM.DISTRIBUTE-WEIGHTS-AUTOMATICALLY",
      value: "DISTRIBUTE",
      warningMsg: ""
    },
    {
      label: "FORM.ASSIGN-CALCULATE-WEIGHTS-AUTOMATICALLY",
      value: "CALCULATE",
      warningMsg: "WIDGET.CALCULATE-QUESTION-WEIGHT-WARNING"
    },
    {
      label: "FORM.ASSIGN-WEIGHT",
      value: "FREE",
      warningMsg: ""
    }
  ]
  selectedWeightCalculation: WeightCalculation = this.weightCalculations[0]

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('parentTestId' in params) {
      this.parentTestId = params['parentTestId']
    }
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.parentTestId = this.dialogConfig.data.pollId ?? this.parentTestId
    this.editId = this.dialogConfig.data.questionId ?? this.editId
  }

  override onLanguageInit() {
    super.onLanguageInit()
    if (this.parentTestId > 0) {
      this.utils.executeRequest(
        this.data.core.testUnique(this.parentTestId), {
        onSuccess: (data) => {
          console.log(data);
          this.parentTest = data
        }
      })
    }
    this.typeMenuItems = []
    this.typeMenuItems.push(
      new AppMenuItem(this.utils.translate.instant('FORM.SELECTION-SINGLE'), { key: 'SELECTION-SINGLE' }),
      new AppMenuItem(this.utils.translate.instant('FORM.SELECTION-MULTIPLE'), { key: 'SELECTION-MULTIPLE' }),
      new AppMenuItem(this.utils.translate.instant('FORM.RELATE'), { key: 'RELATION' }),
      new AppMenuItem(this.utils.translate.instant('FORM.OPEN-QUESTION'), { key: 'OPEN' })
    );
    this.typeMenuItemActive = this.typeMenuItems[0].key
  }

  seeParentTest() {
    this.dialog.open(TestDetailComponent, {
      data: {
        showEditBtn: false,
        itemId: this.parentTestId
      },
      dismissableMask: true
    })
  }

  onTypeMenuChange() {
    this.form.type_ = this.typeMenuItemActive
    this.onTypeChange()
  }

  // ANSWER HANDLING

  addAnswer() {
    this.answers.push({
      content: this.answerForm.content,
      is_correct: this.answerForm.is_correct
    });
    this.onAnswerAdd()
  }

  removeAnswer(idx) {
    this.answers.splice(idx, 1)
  }

  addRelatedAnswer() {
    this.answersRelated.push({
      content: this.answerRelatedForm.content,
      related_value: {
        content: this.answerRelatedForm.related_value.content
      }
    });
    this.onAnswerAdd()
  }

  removeRelatedAnswer(idx) {
    this.answersRelated.splice(idx, 1)
  }

  onAnswerAdd() {
    this.cleanAnswerForms()
  }

  // ANSWER TYPE HANDLING

  onOpenChange() {
    this.form.type_ = this.open ? 'OPEN' : this.typeMenuItemActive
    this.onTypeChange()
  }

  onTypeChange() {
    this.open = this.form.type_ === 'OPEN'
    if (!this.open) {
      this.typeMenuItemActive = this.typeMenuItems.find((e) => {
        return e.key === this.form.type_
      }).key
    }
  }

  // FORM FUNCTIONS

  override getItemEdit() {
    this.editService = this.data.core.testQuestionUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods() {
    this.postService = this.data.core.testQuestionCreate(this.formSanitized)
    this.putService = this.data.core.testQuestionUpdate(
      this.editId,
      this.formSanitized
    )
  }

  override onSubmit() {
    this.form.poll_id = this.parentTest.poll.poll_id
    switch (this.form.type_) {
      case 'SELECTION-SINGLE':
        this.setFormAnswersSelectionSimple()
        break;
      case 'SELECTION-MULTIPLE':
        this.setFormAnswersSelectionMultiple()
        break;
      case 'RELATION':
        this.setFormAnswersRelated()
        break
    }
    super.onSubmit()
  }

  override handleItemEditSuccess(data) {
    super.handleItemEditSuccess(data)
    this.form.type_ = data.type_
    this.onTypeChange();
    if (['SELECTION-SINGLE', 'SELECTION-MULTIPLE'].includes(this.form.type_)) {
      this.answers = data.answers_selection
      this.answerIdxSelected = this.answers.findIndex(
        (answer) => answer.is_correct
      )
    } else if (this.form.type_ === 'RELATION') {
      this.answersRelated = data.answers_related
    }
  }

  setFormAnswersSelectionSimple() {
    this.answers.forEach((answer, idx) => {
      answer.is_correct = 0
      if (this.answerIdxSelected === idx) {
        answer.is_correct = 1
      }
    });
    this.form.answers = this.answers
  }

  setFormAnswersSelectionMultiple() {
    this.form.answers = this.answers
  }

  setFormAnswersRelated() {
    this.form.answers = this.answersRelated
  }

  override cleanForm() {
    this.form = {
      poll_id: this.parentTestId,
      content: null,
      type_: this.typeMenuItemActive,
      weight: 0.0,
      complement: null,
      proof_required: false,
      answers: []
    }
    this.cleanAnswerForms()
  }

  cleanAnswerForms() {
    this.answerForm = {
      content: null,
      is_correct: 0
    }
    this.answerRelatedForm = {
      content: null,
      related_value: {
        content: null
      }
    }
  }

  seeWeights() {
    this.utils.appDialog.seeTestQuestionWeightDetailDialog(
      this.parentTest.poll.poll_id,
      this.editId,
      this.form.weight
    )
  }
}
