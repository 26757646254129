import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section-tags',
  templateUrl: './section-tags.component.html',
  styleUrls: ['./section-tags.component.sass']
})
export class SectionTagsComponent {
  @Input() unit: any
  @Input() section: any
}
