/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';

@Component({
  selector: 'app-course-test-summary',
  templateUrl: './course-test-summary.component.html',
  styleUrls: ['./course-test-summary.component.sass']
})
export class CourseTestSummaryComponent extends BaseItemDirective {
  refreshActionServices(): void {
  }
}
