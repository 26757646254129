import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.sass']
})
export class PasswordRecoverComponent {
  processing = false

  options: AnimationOptions = {
    path: '/assets/animations/loading.json',
  }

  signInForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)])
  })

  constructor(
    private auth: AuthService,
    private router: Router
  ) {
  }

  authenticate() {
    this.processing = true
    this.auth.auth(
      {
        username: this.signInForm.controls.username.value,
        password: this.signInForm.controls.password.value,
        environment: 'W',
        platform: 'W',
        fcm_token: ''
      }
    )
      .subscribe(
        {
          next: value => this.handleAPISuccess(value),
          error: err => this.handleError(err)
        }
      )
  }

  handleAPISuccess(data: any) {
    if (data.could_upload_files) {
      Auth.signIn({
        username: this.signInForm.controls.username.value ?? '',
        password: this.signInForm.controls.password.value ?? ''
      })
        .then(() => this.handleAmplifySuccess(data))
        .catch(error => this.awsSignUp(data, error))
    } else {
      this.handleAmplifySuccess(data)
    }
  }

  awsSignUp(data: any, error: any) {
    if (['UserNotConfirmedException'].includes(error.code)) {
      this.handleAmplifySignUpSuccess()
    } else {
      Auth.signUp(
        {
          username: this.signInForm.controls.username.value ?? '',
          password: this.signInForm.controls.password.value ?? ''
        }
      )
        .then(() => this.handleAmplifySignUpSuccess())
        .catch(error => this.handleError(error))
    }
  }

  handleAmplifySignUpSuccess() {
    this.router.navigateByUrl(`/auth/confirm-account/${this.signInForm.controls.username.value}`)
  }

  handleAmplifySuccess(data: any) {
    this.auth.handleSuccess(data, () => {
      this.processing = false
    })
  }

  handleError(error: any) {
    this.processing = false
    console.error(error)
    //this.dataError = 1
  }

}

