import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { SuportSectionComponent } from '../../suport-section/suport-section.component';

@Component({
  selector: 'app-support-section-item',
  templateUrl: './support-section-item.component.html',
  styleUrls: ['./support-section-item.component.sass']
})
export class SupportSectionItemComponent extends BaseItemDirective {
  @Input() showTags = true;
  @Input() presentation = 1;

  form = {
    parentId: null,
    childId: null
  }
  unitName = '';
  override title = 'CORE.COURSE';
  override keys = {
    primary_key: 'el_course_unit_section_id',
    label: 'el_course_unit_section_id',
  };
  items: MenuItem[] = [
    {
      id: 'INACTIVATE',
      label: this.utils.translate.instant('ENV.DELETE'),
      icon: 'pi pi-ban',
      styleClass: 'text-danger',
      command: () => this.inactivate(),
    },
  ];

  public timeMap = {
    '=1': '# hora',
    'other': '# horas'
  }

  override refreshActionServices(): void {

    this.form = {
      parentId: this.item[this.keys.primary_key],
      childId: this.item.content_id
    }


    this.inactivationService = this.data.core.sectionSupportContentDelete(
      this.form
    );
  }


  previewSupportContent(row) {
    console.log(row)
    if (row.content.type_ === 'URL') {
      window.open(row.content.content, '_blank')
      return;
    }

    const header = this.utils.translate.instant(
      'FORM.SUPPORT-CONTENT', {
      value: row.content.name_
    })
    this.dialog.open(SuportSectionComponent, {
      header,
      width: '80%',
      height: row.content.type_ !== 'AUDIO' ? '100%' : '20%',
      styleClass: 'p-overflow-hidden',
      contentStyle: { height: '100%', overflow: 'hidden', padding: '.3rem' },
      data: {
        data: row.content
      }
    })
  }
}
