import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-report-courses',
  templateUrl: './report-courses.component.html',
  styleUrls: ['./report-courses.component.sass']
})
export class ReportCoursesComponent extends BaseDirective {
  override title = 'MENU.COURSES';
}

