import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-specialty-form',
  templateUrl: './specialty-form.component.html',
  styleUrls: ['./specialty-form.component.sass']
})
export class SpecialtyFormComponent extends BaseFormDirective {
  override title = 'FORM.SPECIALTY'
  override form = {
    name_: null
  }
  path = 'specialty'

  override getItemEdit() {
    this.editService = this.data.core.specialtyUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.specialtyCreate(this.formSanitized)
    this.putService = this.data.core.specialtyUpdate(this.editId, this.formSanitized)
  }
}
