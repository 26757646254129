<div class="flex flex-column h-full" *ngIf="course">
  <h1 class="mb-2">
    <span class="text-primary" *ngIf="enrollment">
      {{ enrollment.user.fullname }}
    </span>
    | {{ course.name_ }}
  </h1>
  <div class="flex flex-grow-1">
    <p-divider layout="vertical"></p-divider>
    <div class="flex-grow-1 hidden lg:flex relative" style="width: 350px">
      <div class="absolute h-full w-full">
        <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
          <div class="card p-2">
            <p-accordion>
              <p-accordionTab header="Contenido del curso">
                <div class="course-progress">
                  <div class="flex gap-2 p-text-secondary font-bold">
                    <div class="flex">Progreso del curso:</div>
                    <div class="flex-grow-1 text-right">
                      {{ calculateCourseProgress / 100 | percent : "1.2-2" }}
                    </div>
                  </div>
                  <div class="my-2">
                    <p-progressBar
                      [value]="calculateCourseProgress"
                      [style]="{ height: '15px' }"
                    ></p-progressBar>
                  </div>
                </div>
                <div class="flex justify-content-between">
                  <div class="flex flex-column mb-4" style="text-align: start">
                    <strong style="color: rgb(92, 90, 90)"
                      >{{ estimatedDuration }} hora(s)</strong
                    >
                    Total
                  </div>
                  <div class="flex flex-column mb-4" style="text-align: end">
                    <strong style="color: rgb(92, 90, 90)"
                      >{{ course.validity_period }} días</strong
                    >
                    Validez de certificación
                  </div>
                </div>
                <div class="flex flex-wrap gap-2" *ngIf="course.tags">
                  <ng-container *ngFor="let tag of course.tags.split(',')">
                    <p-tag [value]="tag" class="mr-1"></p-tag>
                  </ng-container>
                </div>
                <div>
                  <h2 style="color: rgb(92, 90, 90)">Descripción</h2>
                  <p class="m-0">
                    {{ course.description_ }}
                  </p>
                </div>
                <div class="mb-1" *ngIf="course.objective">
                  <h2 style="color: rgb(92, 90, 90)">Objetivo</h2>
                  <p class="m-0" [innerHTML]="course.objective">
                    {{ course.objective }}
                  </p>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
          <div class="card p-2" *ngFor="let unit of units; index as unitIdx">
            <div class="box-elevation border-round-xl p-2">
              <h3 class="mb-3" style="color: rgb(92, 90, 90)">
                Unidad #{{ unitIdx + 1 }} | {{ unit.name_ }}
              </h3>
              <div
                class="mt-2 mb-3"
                *ngIf="unit.sections.length || unit.tests.length"
              >
                <div class="text-sm flex justify-content-between">
                  <div class="flex-grow">Avance de la unidad</div>
                  <div class="flex">{{ calculateUnitProgress(unit) }}%</div>
                </div>
                <p-progressBar
                  [value]="calculateUnitProgress(unit)"
                  [style]="{ height: '15px' }"
                ></p-progressBar>
              </div>
              <div *ngFor="let section of unit.sections; index as sectionIdx">
                <div
                  class="box-elevation border-round-md border-2 flex flex-column border-transparent shadow-0 my-2"
                  [ngClass]="{
                      'white-card': section.couldGoToSection,
                      'disabled-card': !section.couldGoToSection,
                      'green-card': section.isComplete,
                    }"
                >
                  <div
                    class="flex align-items-center justify-content-between w-full"
                  >
                    <p class="pl-3 flex align-items-center gap-2">
                      <app-course-unit-section-content-item
                        [item]="section.resource_content"
                        [fireActions]="false"
                        [showName]="false"
                      >
                      </app-course-unit-section-content-item>
                      Clase #{{ sectionIdx + 1 }} | {{ section.name_ }}
                    </p>
                    <i
                      class="pi pi-check-circle"
                      [ngClass]="{
                        'green-icon': section.isComplete,
                        'grey-icon': !section.isComplete
                      }"
                      [ngStyle]="{ 'font-size': '24px', padding: '8px' }"
                    >
                    </i>
                  </div>
                  <ng-container
                    *ngIf="
                      ['IMG', 'VIDEO', 'AUDIO', 'PDF'].includes(
                        section.resource_content.type_
                      )
                    "
                  >
                  </ng-container>
                  <div class="m-2">
                    <app-section-tags
                      [unit]="unit"
                      [section]="section"
                    ></app-section-tags>
                  </div>
                </div>
              </div>
              <p-divider></p-divider>
              <div *ngFor="let test of unit.tests; index as testIdx">
                <div
                  class="box-elevation border-round-md border-2 flex flex-column border-transparent shadow-0 my-2 white-card"
                  [ngClass]="{
                    'green-card': test.isComplete,
                    'disabled-card': !test.couldGoToTest,
                  }"
                >
                  <div
                    class="flex align-items-center justify-content-between w-full"
                  >
                    <p class="pl-3 flex align-items-center gap-2">
                      <span
                        class="material-symbols-outlined"
                        style="font-size: 2rem"
                      >
                        quiz
                      </span>
                      Evaluación #{{ testIdx + 1 }} |
                      {{ test.test?.poll?.title }}
                    </p>
                    <i
                      class="pi pi-check-circle"
                      [ngClass]="{
                        'green-icon': test.isComplete,
                        'grey-icon': !test.isComplete
                      }"
                      [ngStyle]="{ 'font-size': '24px', padding: '8px' }"
                    >
                    </i>
                  </div>
                  <div class="flex flex-column gap-2 pl-3 pr-2">
                    <div
                      class="flex align-items-center gap-2"
                      *ngIf="test.hasTakes"
                    >
                      <p class="text-md">
                        Calificación más alta:
                        {{ test.highestRating / 100 | percent : "1.2-2" }}
                      </p>
                      <p-tag
                        severity="success"
                        value="Aprobado"
                        *ngIf="test.approved"
                      ></p-tag>
                      <p-tag
                        severity="danger"
                        value="Reprobado"
                        *ngIf="!test.approved"
                      ></p-tag>
                    </div>
                    <span
                      class="text-sm text-right"
                      *ngIf="test.remainingAttempts"
                    >
                      Intentos restantes: {{ test.remainingAttempts }}
                    </span>
                  </div>
                  <p-divider styleClass="m-1"></p-divider>
                  <div class="flex justify-content-end m-2">
                    <ng-container *ngIf="!test.isComplete">
                      <p-tag
                        [severity]="'danger'"
                        [value]="'Requiere aprobación' | translate"
                        *ngIf="test.approval_required"
                      >
                      </p-tag>
                      <p-tag
                        [severity]="'success'"
                        [value]="'No requiere aprobación' | translate"
                        *ngIf="!test.approval_required"
                      >
                      </p-tag>
                    </ng-container>

                    <p-tag
                      [severity]="'success'"
                      [value]="'Completado' | translate"
                      *ngIf="test.isComplete"
                    >
                    </p-tag>
                  </div>
                  <app-user-test-takes
                    [parentId]="unit.el_course_unit_take_id"
                    [type]="'U'"
                  ></app-user-test-takes>
                </div>
              </div>
            </div>
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>

<app-content-trigger-button
  #contentTrigger
  style="display: none"
></app-content-trigger-button>
