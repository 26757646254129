<div class="flex flex-column h-full">
  <div class="flex" style="height: 60px" style="background-color: white">
    <div
      class="flex-initial align-items-center justify-content-center hidden md:flex"
      style="width: 200px"
    >
      <img
        src="assets/images/logo.png"
        style="max-width: 90%; max-height: 90%"
      />
    </div>
    <div
      class="flex-initial align-items-center justify-content-center flex md:hidden"
      style="width: 100px"
    >
      <a (click)="sidebarVisible = !sidebarVisible">
        <img
          src="assets/images/logo.png"
          style="max-width: 100%; max-height: 100%"
        />
      </a>
    </div>
    <div class="flex flex-1 justify-content-end">
      <div
        class="flex p-inputgroup w-6 md:w-6 lg:w-2 col-offset-0 md:col-offset-2 lg:col-offset-4 bg-transparent"
        *ngIf="utils.global.searchEnabled | async"
      >
        <input
          type="text"
          (input)="onInput($event)"
          [(ngModel)]="searchText"
          [ngModelOptions]="{ standalone: true }"
          pInputText
          placeholder="{{ 'ENV.SEARCH' | translate }}"
          class="bg-transparent text-right"
          autocomplete="false"
        />
        <span class="p-inputgroup-addon bg-transparent"
          ><i class="pi pi-search" style="line-height: 1.25"></i
        ></span>
      </div>
      <div class="flex">
        <button
          pButton
          pRipple
          class="p-button-text p-button-primary"
          icon="pi pi-bell"
          (click)="seeReminders()"
          pTooltip="{{ 'ENV.REMINDERS' | translate }}"
          tooltipPosition="bottom"
        ></button>
      </div>
      <div class="flex align-items-center pr-3">
        <p-button styleClass="p-button-text" (click)="menu.toggle($event)">
          <span class="material-symbols-outlined">person</span>
          <div
            class="flex-column justify-content-end app-navbar-user ml-2 hidden md:flex"
          >
            <span class="title text-right">{{ accountName }}</span>
          </div>
        </p-button>
        <p-menu
          #menu
          [model]="userMenu"
          [popup]="true"
          appendTo="body"
        ></p-menu>
      </div>
    </div>
  </div>
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '3px' }"
    *ngIf="utils.global.processing | async"
  ></p-progressBar>
  <div class="flex flex-grow-1">
    <div
      class="hidden md:flex h-full relative"
      style="width: 200px; min-width: 200px"
    >
      <div class="absolute h-full w-full">
        <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
          <ng-container *ngTemplateOutlet="sidebarMenu"></ng-container>
        </p-scrollPanel>
      </div>
    </div>
    <div class="flex flex-grow-1 relative">
      <div
        class="flex flex-column layout-content-wrapper h-full w-full px-3 py-2"
      >
        <router-outlet class="router-flex"></router-outlet>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="sidebarVisible" position="bottom" styleClass="h-full">
  <ng-container *ngTemplateOutlet="sidebarMenu"></ng-container>
</p-sidebar>

<ng-template #sidebarMenu>
  <ul class="app-sidebar">
    <ng-container *ngFor="let menu of menus" [ngSwitch]="menu.items.length">
      <li *ngIf="menu.visible">
        <a
          [routerLink]="menu.routerLink"
          class="flex"
          pTooltip="{{ menu.label | translate }}"
          (click)="sidebarVisible = false"
        >
          <div
            class="flex align-content-center flex-wrap justify-content-center"
            style="width: 48px"
          >
            <span class="material-symbols-outlined">{{ menu.icon }}</span>
          </div>
          <div
            class="flex flex-1 align-content-center flex-wrap justify-content-start"
          >
            {{ menu.label | translate }}
          </div>
        </a>
      </li>
    </ng-container>
  </ul>
</ng-template>

<p-sidebar
  [(visible)]="showReminders"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '100%', 'max-width': '20em' }"
>
  <ng-template pTemplate="content">
    <app-reminder-list
      (itemSelectedChange)="closeReminders()"
      [optionSidebar]="optionSidebar"
    ></app-reminder-list>
  </ng-template>
  <ng-template pTemplate="footer">
    <!--  <button
      pButton
      pRipple
      class="p-button-text p-button-primary w-full"
      icon="pi pi-times"
      label="{{ 'ENV.CLOSE' | translate }}"
      (click)="closeReminders()"
    ></button> -->
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-copy"
      class="btn p-button-text"
      [ngClass]="
        optionSidebar === 'test' ? 'p-button-primary font-bold' : 'inactive-tab'
      "
      (click)="optionSidebar = 'test'"
      tooltipPosition="top"
      tooltipZIndex="100000"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-bell"
      (click)="optionSidebar = 'notifications'"
      class="btn p-button-text"
      [ngClass]="
        optionSidebar === 'notifications'
          ? 'p-button-primary font-bold'
          : 'inactive-tab'
      "
      tooltipPosition="top"
      tooltipZIndex="100000"
    ></button>
  </ng-template>
</p-sidebar>

<p-toast position="top-center"></p-toast>
