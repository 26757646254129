<div class="flex flex-column h-full" *ngIf="library">
  <div class="flex" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h5 class="module-subtitle cursor-pointer" routerLink="/libraries">
        {{ "MENU.LIBRARIES" | translate | uppercase }}
      </h5>
      <h2 class="module-title">
        {{ library.name_ | translate }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color); margin-top: 20px"
      ></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h5 class="module-subtitle" style="color: white">a</h5>
      <h2 class="module-title">
        {{ "MENU.CONTENTS" | translate }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
      *ngIf="userType.could_manage_contents"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-success"
        icon="pi pi-plus"
        label="{{ 'ENV.NEW' | translate }} {{
          'CORE.CONTENT' | translate | lowercase
        }}"
        routerLink="/contents/create/{{ libraryId }}"
      ></button>
      <button
        pButton
        pRipple
        class="p-button-text p-button-primary"
        icon="pi pi-plus"
        label="{{ 'FORM.ADD-CONTENT-TO-LIBRARY' | translate }}"
        (click)="seeContents()"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <app-library-content-table
          #primeNgList
          [libraryId]="libraryId"
          (inactivationSuccess)="handleContentDelete($event)"
        ></app-library-content-table>
      </div>
    </div>
  </div>
</div>
