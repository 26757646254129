<div class="flex">
  <ng-container *ngFor="let item of items; index as i">
    <div class="flex flex-column justify-content-start">
      <h2
        class="module-title"
        [ngClass]="item.routerLink ? 'cursor-pointer' : ''"
        [routerLink]="item.routerLink"
      >
        {{ item.label | translate }}
      </h2>
    </div>
    <div class="flex mx-4 my-1" *ngIf="i < items.length - 1">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color)"
      ></i>
    </div>
  </ng-container>
</div>
