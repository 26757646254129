<div class="flex flex-column h-full">
  <div class="flex align-items-center" style="height: auto">
    <ng-container *ngIf="parentTestId > 0 && parentTest">
      <div class="flex flex-column justify-content-start">
        <h5 class="module-subtitle">
          {{ "CORE.TEST" | translate | uppercase }}
        </h5>
        <h2
          class="module-title cursor-pointer"
          (click)="seeParentTest()"
          pTooltip="{{ 'CORE.TEST' | translate }} | {{
            'ENV.SEE-DETAIL' | translate
          }}"
          tooltipPosition="bottom"
        >
          {{ parentTest.poll.title }}
        </h2>
      </div>
      <div class="flex mx-4">
        <i
          class="pi pi-angle-double-right"
          style="font-size: 2rem; color: var(--primary-color)"
        ></i>
      </div>
    </ng-container>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h5 class="module-subtitle">{{ title | translate | uppercase }}</h5>
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW-F") | translate }}
        {{ "CORE.QUESTION" | translate | lowercase }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form
          #dataForm="ngForm"
          (ngSubmit)="onSubmit()"
          class="px-4 py-3 h-full"
        >
          <div class="grid h-full">
            <div class="col-6">
              <div class="flex flex-column lg:flex-row mt-2 mb-4">
                <span class="p-float-label w-full">
                  <textarea
                    pInputTextarea
                    id="description"
                    name="description"
                    rows="5"
                    class="ng-dirty w-full"
                    [(ngModel)]="form.content"
                    required
                  ></textarea>
                  <label for="description">{{
                    "FORM.QUESTION-TEXT" | translate
                  }}</label>
                </span>
              </div>
              <div class="flex flex-column mb-4">
                <div class="flex flex-column gap-2">
                  <span class="p-float-label w-full">
                    <input
                      type="text"
                      pInputText
                      [(ngModel)]="form.weight"
                      class="w-full"
                      name="weight"
                      id="weight"
                    />
                    <label for="weight">{{ "FORM.WEIGHT" | translate }}</label>
                  </span>
                </div>
                <p-slider
                  [(ngModel)]="form.weight"
                  class="w-full"
                  name="weight"
                  id="weight_slider"
                ></p-slider>
              </div>
              <!-- <div class="flex flex-row align-items-center gap-2 mb-4">
                <p-checkbox
                  name="random_questions"
                  [binary]="true"
                  [(ngModel)]="!!form.proof_required"
                  inputId="random_questions"
                ></p-checkbox>
                <label for="random_questions">{{
                  "FORM.PROOF-REQUIRED" | translate
                }}</label>
              </div> -->
              <div class="flex flex-column gap-2">
                <label for="complement">{{
                  "FORM.COMPLEMENT" | translate
                }}</label>
                <div style="height: 300px">
                  <app-html-editor
                    name="complement"
                    [(ngModel)]="form.complement"
                  ></app-html-editor>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="flex flex-column box box-elevation p-3 mt-2">
                <div class="flex flex-wrap pb-2 w-full">
                  <app-custom-tab-selector
                    class="w-full"
                    [items]="typeMenuItems"
                    [(ngModel)]="typeMenuItemActive"
                    (ngModelChange)="onTypeMenuChange()"
                    name="type_menu"
                  ></app-custom-tab-selector>
                </div>
                <div class="flex-grow overflow-hidden">
                  <div
                    class="w-full h-100 flex flex-column overflow-hidden"
                    *ngIf="
                      typeMenuItemActive &&
                      typeMenuItemActive === 'SELECTION-SINGLE'
                    "
                  >
                    <form #dataAnswerForm="ngForm" class="flex flex-wrap">
                      <p>
                        {{ "FORM.MULTIPLE-CHOICE-SINGLE-ANSWER" | translate }}
                      </p>
                      <div class="flex mb-4 gap-2 w-full">
                        <div class="flex flex-column flex-grow w-full gap-2">
                          <span class="p-float-label w-full">
                            <textarea
                              pInputTextarea
                              id="answer_content"
                              name="answer_content"
                              class="w-full ng-dirty"
                              required
                              [(ngModel)]="answerForm.content"
                            >
                            </textarea>
                            <label for="answer_content">{{
                              "FORM.ANSWER-TEXT" | translate
                            }}</label>
                          </span>
                        </div>
                        <button
                          pButton
                          pRipple
                          type="button"
                          icon="pi pi-plus"
                          class="p-button-success ms-2 flex align-self-start"
                          pTooltip="{{ 'FORM.ADD' | translate }}"
                          style="margin-top: 1.7rem"
                          [ngClass]="
                            dataAnswerForm.valid ? '' : 'p-button-secondary'
                          "
                          [disabled]="!dataAnswerForm.valid"
                          (click)="addAnswer()"
                        ></button>
                      </div>
                      <p-message
                        class="text-center w-full mb-3"
                        severity="warn"
                        [text]="'WIDGET.SELECT-THE-CORRECT-ANSWER' | translate"
                      ></p-message>
                    </form>
                    <div
                      class="box box-elevation p-2 mx-2 mb-2 flex align-items-center justify-content-between"
                      *ngFor="let answer of answers; index as i"
                    >
                      <div class="field-radiobutton m-0 flex-grow">
                        <p-radioButton
                          [inputId]="'answer_is_correct' + i"
                          name="answer_is_correct"
                          [value]="i"
                          [(ngModel)]="answerIdxSelected"
                        >
                        </p-radioButton>
                        <label [for]="'answer_is_correct' + i">{{
                          answer.content
                        }}</label>
                      </div>
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-danger p-button-text flex ml-3"
                        (click)="removeAnswer(i)"
                      ></button>
                    </div>
                  </div>
                  <div
                    class="w-full h-100 flex flex-column overflow-hidden"
                    *ngIf="
                      typeMenuItemActive &&
                      typeMenuItemActive === 'SELECTION-MULTIPLE'
                    "
                  >
                    <form #dataAnswerForm="ngForm" class="flex flex-wrap">
                      <p>
                        {{ "FORM.MULTIPLE-CHOICE-MULTIPLE-ANSWER" | translate }}
                      </p>
                      <div class="flex mb-4 gap-2 w-full">
                        <div class="flex flex-column gap-2 w-full flex-grow">
                          <span class="p-float-label w-full">
                            <textarea
                              pInputTextarea
                              id="answer_multiple_content"
                              name="answer_multiple_content"
                              class="w-full ng-dirty"
                              required
                              [(ngModel)]="answerForm.content"
                            >
                            </textarea>
                            <label for="answer_content">{{
                              "FORM.ANSWER-TEXT" | translate
                            }}</label>
                          </span>
                        </div>
                        <button
                          pButton
                          pRipple
                          type="button"
                          icon="pi pi-plus"
                          class="p-button-success ms-2 flex align-self-start"
                          pTooltip="{{ 'FORM.ADD' | translate }}"
                          style="margin-top: 1.7rem"
                          [ngClass]="
                            dataAnswerForm.valid ? '' : 'p-button-secondary'
                          "
                          [disabled]="!dataAnswerForm.valid"
                          (click)="addAnswer()"
                        ></button>
                      </div>
                      <p-message
                        class="text-center w-full mb-3"
                        severity="warn"
                        [text]="'WIDGET.SELECT-THE-CORRECT-ANSWERS' | translate"
                      ></p-message>
                    </form>
                    <div
                      class="box box-elevation p-2 mb-2 flex align-items-center justify-content-between"
                      *ngFor="let answer of answers; index as i"
                    >
                      <div class="field-radiobutton m-0 flex-grow">
                        <p-checkbox
                          [(ngModel)]="answer.is_correct"
                          [binary]="true"
                          [trueValue]="1"
                          [falseValue]="0"
                          [inputId]="'answer_multiple_is_correct' + i"
                          [ngModelOptions]="{ standalone: true }"
                        >
                        </p-checkbox>
                        <label [for]="'answer_multiple_is_correct' + i">{{
                          answer.content
                        }}</label>
                      </div>
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-danger p-button-text flex ml-3"
                        (click)="removeAnswer(i)"
                      ></button>
                    </div>
                  </div>
                  <div
                    class="w-full h-100 flex flex-column overflow-hidden"
                    *ngIf="
                      typeMenuItemActive && typeMenuItemActive === 'RELATION'
                    "
                  >
                    <form
                      #dataAnswerRelatedForm="ngForm"
                      class="flex flex-wrap"
                    >
                      <p class="inline-block mr-3">
                        {{ "FORM.RELATED-ANSWERS" | translate }}
                      </p>
                      <div class="flex gap-2 w-full mb-4">
                        <div class="flex gap-2 flex-grow">
                          <div class="flex-grow flex flex-column gap-2">
                            <span class="p-float-label w-full">
                              <textarea
                                pInputTextarea
                                id="answer_content_related"
                                name="answer_content_related"
                                class="w-full ng-dirty"
                                [(ngModel)]="answerRelatedForm.content"
                                required
                              >
                              </textarea>
                              <label for="answer_content">
                                {{ "FORM.TEXT" | translate }} A
                              </label>
                            </span>
                          </div>
                          <div class="flex-grow flex flex-column gap-2">
                            <span class="p-float-label w-full">
                              <textarea
                                pInputTextarea
                                id="answer_content_related_value"
                                name="answer_content_related_value"
                                class="w-full ng-dirty"
                                [(ngModel)]="
                                  answerRelatedForm.related_value.content
                                "
                                required
                              >
                              </textarea>
                              <label for="answer_content">
                                {{ "FORM.TEXT" | translate }} B
                              </label>
                            </span>
                          </div>
                        </div>
                        <button
                          pButton
                          pRipple
                          type="button"
                          icon="pi pi-plus"
                          class="p-button-success ms-2 flex align-self-start"
                          pTooltip="{{ 'FORM.ADD' | translate }}"
                          style="margin-top: 1.7rem"
                          [ngClass]="
                            dataAnswerRelatedForm.valid
                              ? ''
                              : 'p-button-secondary'
                          "
                          [disabled]="!dataAnswerRelatedForm.valid"
                          (click)="addRelatedAnswer()"
                        ></button>
                      </div>
                    </form>
                    <div
                      class="box box-elevation p-2 mx-2 mb-2 flex align-items-center justify-content-between"
                      *ngFor="let answerRelated of answersRelated; index as i"
                    >
                      <div
                        class="flex-grow align-items-center gap-3 px-2 flex grid"
                      >
                        <div class="flex-grow">
                          {{ answerRelated.content }}
                        </div>
                        <div class="flex">
                          <span
                            class="material-symbols-outlined"
                            style="font-size: 2rem"
                          >
                            arrow_forward
                          </span>
                        </div>
                        <div class="flex-grow">
                          {{ answerRelated.related_value.content }}
                        </div>
                      </div>
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-danger p-button-text flex ml-3"
                        (click)="removeRelatedAnswer(i)"
                      ></button>
                    </div>
                  </div>
                  <div
                    class="w-full h-100 flex flex-column overflow-hidden"
                    *ngIf="typeMenuItemActive && typeMenuItemActive === 'OPEN'"
                  >
                    <p-message
                      class="text-center w-full mt-4"
                      severity="warn"
                      [text]="
                        'WIDGET.OPEN-TEXT-WILL-BE-REQUESTED-AS-RESPONSE'
                          | translate
                      "
                    ></p-message>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
