import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CourseUserPickerTableComponent } from 'src/app/components/controls/user-picker-table/user-picker-table.component';
import { BaseItemRestructDirective } from 'src/app/directives/restruct/base-item-restruct.directive';
import { DialogListData } from 'src/app/models/AppGenericClasses';
import { Course, CourseJSON } from 'src/app/models/CourseClasses';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
@Component({
  selector: 'app-course-item',
  templateUrl: './course-item.component.html',
  styleUrls: ['./course-item.component.sass'],
})
export class CourseItemComponent extends BaseItemRestructDirective<Course, CourseJSON> {
  @Input() showTags = true
  @Input() presentation = 1
  @Input() withUserEnrollment = false
  @Input() couldCloneCourse = false;
  showInacivationProhibition = false
  ItemModel = Course;

  override title = 'CORE.COURSE';

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
    private router: Router
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef)
  }

  registeredUsers() {
    this.router.navigateByUrl(`/courses/${this.item.id}/enrolled-users`)
  }

  prerrequisites() {
    this.router.navigateByUrl(`/courses/${this.item.id}/prerrequisites`)
  }

  viewCourse() {
    this.router.navigateByUrl(`/courses/preview/${this.item.id}`)
  }

  units(item: Course) {
    localStorage.setItem('course_name', item.name)
    this.router.navigateByUrl(`/courses/units/preview/${item.id}`)
  }

  seeOnlyAvailableUsers() {
    this.seeUsers(true)
  }

  seeEnrollments() {
    this.utils.appDialog.seeCourseEnrollmentsDialog(this.item.id)
  }

  seePendingUsers() {
    this.utils.appDialog.seePendingCourseUsersDialog(this.item.id)
  }

  seeUsers(onlyAvailable = false) {
    this.utils.appDialog.seeCourseUserAvailableDialog(this.item.id, this.item.name, onlyAvailable, {
      onSuccess: () => {
        this.requestReload.emit()
      }
    })
  }

  override refreshActionServices(): void {
    this.canInactivate = this.item.enrollmentsCount <= 0
    this.inactivationService = this.data.core.courseDelete(this.item.id)

    this.canReactivate = !this.item.active
    this.reactivationService = this.data.core.courseActivate(this.item.id)
  }

  handleInactivationProhibition() {
    this.showInacivationProhibition = true
  }
}
