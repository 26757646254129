import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';


@Component({
  selector: 'app-pending-user-table',
  templateUrl: './pending-user-table.component.html',
  styleUrls: ['./pending-user-table.component.sass']
})
export class PendingUserTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('first_name,last_name', 'FORM.NAME', 150, { frozen: true }),
    new PrimeNGTableColumn('dni', 'FORM.DNI', 100),
    new PrimeNGTableColumn('email', 'AUTH.EMAIL', 100),
    new PrimeNGTableColumn('credentials.type.name_', 'CORE.ROLE', 150),
    new PrimeNGTableColumn('group.name_', 'MENU.GROUP', 100),
    new PrimeNGTableColumn('post.name_', 'FORM.SPECIALTY', 150)

  ];
  override title = 'MENU.PENDING-USERS';
  override filterableFields = []
  override keys = {
    primary_key: 'user_id',
    label: ''
  }
  override filename = 'MENU.PENDING-USERS';

  override getList(): void {
    this.query(this.data.core.pendingUsers()).subscribe()
  }

  override handleRows(data: any): void {
    console.log(data);
    super.handleRows(data);
  }

  approveUser(row) {
    this.utils.executeRequest(this.data.core.userEnable({ id: row.user_id }),
      {
        onSuccess: (data) => {
          console.log(data)
          this.utils.toast.saved(this.title)
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
  }

  rejectUser(row) {
    this.utils.executeRequest(this.data.core.userReject({ id: row.user_id }),
      {
        onSuccess: (data) => {
          console.log(data)
          this.utils.toast.saved(this.title)
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
  }

  refreshActionServices() {
  }
}
