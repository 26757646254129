import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sortable-list',
  templateUrl: './sortable-list.component.html',
  styleUrls: ['./sortable-list.component.sass']
})
export class SortableListComponent {
  @Input() data: any[] = []
  @Input() labelKey = ''
  @Output() dataSorted = new EventEmitter<any>()


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    this.dataSorted.emit()
  }
}
