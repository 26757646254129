import { AppMenuItem } from 'src/app/models/AppGenericClasses';
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-tab-selector',
  templateUrl: './custom-tab-selector.component.html',
  styleUrls: ['./custom-tab-selector.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomTabSelectorComponent,
      multi: true,
    },
  ]
})
export class CustomTabSelectorComponent implements ControlValueAccessor {
  @Input() items: AppMenuItem[];

  get value(): string {
    return this.tabSelected;
  }

  set value(v: string) {
    if (v !== this.tabSelected) {
      this.tabSelected = v;
      this.onChange(v);
    }
  }
  tabSelected: string;

  constructor() { }

  onChange = (_) => { };
  onTouched = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    if (obj === null) {
      this.tabSelected = '';
    } else if (obj !== this.tabSelected) {
      this.tabSelected = obj;
    }
  }

  onTabSelected(key: string) {
    this.tabSelected = key;
    this.onChange(this.tabSelected);
  }
}
