<div
  class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3"
  *ngIf="item"
>
  <div class="flex flex-column w-12 md:w-8 lg:w-12 px-4 py-3 mb-3">
    <a class="card-item-title cursor-pointer" (click)="handleItemSelected()">{{
      item.name_
    }}</a>
    <p class="p-text-secondary" *ngIf="showDescription">
      <app-text-wrapper [text]="item.description"></app-text-wrapper>
    </p>
    <div class="flex flex-row pt-2" *ngIf="item.color">
      <app-color-chip
        [content]="source.color.name_"
        [color]="source.color"
        class="mr-2"
      ></app-color-chip>
    </div>
    <div class="flex flex-column md:flex-row">
      <div
        class="flex flex-grow-1 justify-content-center md:justify-content-end mt-2 md:mt-0 card-item-buttons"
        *ngIf="showActions && userType.could_manage_topics"
      >
        <button
          pButton
          pRipple
          icon="pi pi-trash"
          pTooltip="{{ 'ENV.DELETE' | translate }}"
          (click)="inactivate()"
          class="p-button-text p-button-raised p-button-danger"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-pencil"
          pTooltip="{{ 'ENV.EDIT' | translate }}"
          routerLink="/topics/edit/{{ item.id }}"
          class="p-button-text p-button-raised"
        ></button>
        <button
          pButton
          pRipple
          pTooltip="{{ 'ENV.NEW' | translate }} {{
            'CORE.COURSE' | translate | lowercase
          }}"
          label="{{ 'ENV.NEW' | translate }} {{
            'CORE.COURSE' | translate | lowercase
          }}"
          routerLink="/courses/create/{{ item.id }}"
          class="p-button-text p-button-raised"
        ></button>
      </div>
    </div>
  </div>
</div>
