/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { Message } from 'primeng/api';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-course-preview',
  templateUrl: './course-preview.component.html',
  styleUrls: ['./course-preview.component.sass']
})
export class CoursePreviewComponent extends BaseItemDirective {
  @Input() showSummarizedInfo = true
  timeLimitInMinutes = null
  messages: Message[] | undefined;

  override getItem() {
    this.itemService = this.data.core.fullCourse(this.itemId)
    super.getItem()
  }

  refreshActionServices(): void {
  }

  handleGetItemSuccess(data: any): void {
    super.handleGetItemSuccess(data)
    this.messages = [{ severity: 'info', detail: 'Hacen falta los siguientes cursos: curso 1, test, curso 2' }];
    this.item.tests.forEach(test => {
      test.test.time_limit = test.test.time_limit / 60;
    });
    this.item.units.forEach(unit => {
      unit.sections.forEach(section => {
        const duration = Utils.sumArrayByKey(
          [...section.support_contents, ...[section.resource_content]],
          'min_viewing_time'
        )
        section.duration = (duration / 3600).toFixed(1)
      })
    })
  }
}
