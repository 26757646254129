<div class="flex flex-column h-full">
  <div class="flex flex-grow-1">
    <div class="flex w-12 lg:flex relative">
      <div class="absolute h-full w-full">
        <p-scrollPanel
          [style]="{ width: '100%', height: '100%' }"
          *ngIf="!loading; else loadingTemplate"
        >
          <div
            class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3"
          >
            <div
              class="flex flex-column w-12 md:w-12 lg:w-12 px-4 py-3 mb-3 justify-content-between"
            >
              <div class="flex" style="height: auto">
                <div
                  class="flex flex-grow-1 align-items-center justify-content-center"
                >
                  <h2 class="module-title">
                    {{ testTake.test.poll.title | uppercase }}
                  </h2>
                </div>
              </div>
              <div>
                <div
                  class="flex w-12 md:flex lg:flex card-item-tags justify-content-end"
                >
                  <div
                    tooltipPosition="left"
                    class="flex flex-column align-items-center"
                    [pTooltip]="'FORM.MIN-APPROVAL-PERCENTAGE' | translate"
                  >
                    <p-knob
                      [(ngModel)]="testTake.result"
                      [readonly]="true"
                      valueColor="#689F38"
                      rangeColor="#D32F2F"
                      valueTemplate="{value}"
                      [strokeWidth]="5"
                    ></p-knob>
                  </div>
                </div>
              </div>
              <div>
                <div
                  class="flex w-12 md:flex lg:flex card-item-tags justify-content-between align-items-center"
                >
                  <div>
                    <p class="p-text-secondary font-bold">
                      {{ "FORM.MIN-APPROVAL-PERCENTAGE" | translate }}:
                    </p>
                    <p class="p-text-secondary">
                      {{ "80%" }}
                    </p>
                  </div>
                  <p-tag
                    styleClass="align-self-center p-text-secondary text-lg"
                    severity="success"
                    value="Aprobado"
                  >
                  </p-tag>
                </div>
              </div>
              <div>
                <div
                  class="flex w-12 md:flex lg:flex card-item-tags justify-content-between"
                >
                  <div>
                    <p class="p-text-secondary font-bold">
                      {{ "FORM.TIME-FROM" | translate }}:
                    </p>
                    <p class="p-text-secondary flex align-items-center">
                      <span class="material-symbols-outlined">schedule</span>
                      {{ testTake.time_from }}
                    </p>
                  </div>
                  <div>
                    <p class="p-text-secondary font-bold">
                      {{ "FORM.TIME-LIMIT" | translate }}:
                    </p>
                    <p class="p-text-secondary flex align-items-center">
                      <span class="material-symbols-outlined">schedule</span>
                      {{ "06:00" }}
                    </p>
                  </div>
                  <div>
                    <p class="p-text-secondary font-bold">
                      {{ "FORM.TIME-TO" | translate }}:
                    </p>
                    <p class="p-text-secondary flex align-items-center">
                      <span class="material-symbols-outlined">schedule</span>
                      {{ testTake.time_to }}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div class="flex w-12 md:flex lg:flex card-item-tags row">
                  <div class="card-item-title col-6">
                    {{ "CORE.QUESTION" | translate }}
                  </div>
                  <div class="card-item-title col-3">
                    {{ "FORM.TYPE" | translate }}
                  </div>
                  <div class="card-item-title col-3">
                    {{ "Resultado" }}
                  </div>
                </div>
              </div>
              <div>
                <div
                  *ngFor="let t of testTake.test.poll.questions"
                  class="flex w-12 md:flex lg:flex card-item-tags row"
                >
                  <div class="p-text-secondary col-6">
                    {{ t.content }}
                  </div>
                  <div class="p-text-secondary col-3">
                    {{ "FORM." + t.type_ | translate }}
                  </div>
                  <div class="p-text-secondary col-3 text-end">
                    <p-tag
                      styleClass="align-self-center p-text-secondary text-lg"
                      severity="success"
                    >
                      <span
                        class="material-symbols-outlined"
                        style="font-size: 2rem"
                      >
                        done
                      </span>
                    </p-tag>
                    <p-tag
                      styleClass="align-self-center p-text-secondary text-lg"
                      severity="danger"
                    >
                      <span
                        class="material-symbols-outlined"
                        style="font-size: 2rem"
                      >
                        close
                      </span>
                    </p-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
