import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { AWSS3Service } from 'src/app/services/data/aws-s3.service';
import { GenericResponse } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-section-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['./section-item.component.sass']
})
export class SectionItemComponent extends BaseItemDirective {
  @Input() showTags = true;
  @Input() presentation = 1;
  @Input() idx = 0;
  override title = 'CORE.COURSE';
  override keys = {
    primary_key: 'el_course_unit_section_id',
    label: 'name_',
  };
  items: MenuItem[] = [
    {
      label: this.utils.translate.instant('FORM.ASSOCIATE-MAIN-CONTENT'),
      icon: 'pi pi-file-edit',
      command: () => {
        this.openAssociateMainContentDialog()
      }
    },
    {
      label: this.utils.translate.instant('FORM.CREATE-AND-ASSOCIATE-MAIN-CONTENT'),
      icon: 'pi pi-file-import',
      command: () => {
        this.openCreateMainContentDialog()
      }
    },
    {
      label: this.utils.translate.instant('FORM.ASSOCIATE-SUPPORT-CONTENT'),
      icon: 'pi pi-file-edit',
      command: () => {
        this.openAssociateSupportContentDialog()
      }
    },
    {
      label: this.utils.translate.instant('FORM.CREATE-AND-ASSOCIATE-SUPPORT-CONTENT'),
      icon: 'pi pi-file-import',
      command: () => {
        this.openCreateSupportContentDialog()
      }
    },
    {
      id: 'INACTIVATE',
      label: this.utils.translate.instant('ENV.INACTIVATE'),
      icon: 'pi pi-ban',
      styleClass: 'text-danger',
      command: () => this.inactivate(),
    }
  ];

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
    private router: Router,
    protected s3Service: AWSS3Service,
    protected messageService: MessageService

  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef);
  }

  supportContents() {
    localStorage.setItem('sectionName', this.item.name_)
    this.router.navigateByUrl(
      `/courses/units/sections/support-content/${this.item.el_course_unit_section_id}`
    );
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.sectionDelete(
      this.item[this.keys.primary_key]
    );
  }

  openAssociateSupportContentDialog() {
    this.utils.appDialog.seeContentPicker(this.utils.translate.instant("FORM.SECTION"), {
      onSuccess: (res: GenericResponse) => {
        const selectedItems = res.complement.data
        const objects = selectedItems.map(item => {
          return {
            el_course_unit_section_id: this.item.el_course_unit_section_id,
            content_id: item.content_id
          }
        })
        this.utils.executeRequest(
          this.data.core.supportSectionContentBatch({ objects }), {
          onSuccess: () => {
            this.utils.toast.saved(this.title)
            this.requestReload.emit()
          }
        })
      }
    }, {
      showSelectOneButton: false,
      idsSelected: this.item.support_contents.map(content => content.content_id),
      multiple: true
    })
  }

  openCreateSupportContentDialog() {
    this.utils.appDialog.seeContentFormDialog({
      onSuccess: () => {
        this.requestReload.emit()
      }
    }, {
      parentUnitSectionId: this.item.el_course_unit_section_id,
      parentTargetType: "SUPPORT"
    })
  }

  openAssociateMainContentDialog() {
    this.utils.appDialog.seeContentPicker(this.utils.translate.instant("FORM.SECTION"), {
      onSuccess: (res: GenericResponse) => {
        this.utils.executeRequest(
          this.data.core.sectionUpdate(this.item.el_course_unit_section_id, { content_id: res.complement.data[0].content_id }), {
          onSuccess: () => {
            this.utils.toast.saved(this.title)
            this.requestReload.emit()
          }
        })
      }
    }, {
      showSelectOneButton: true,
      idsSelected: this.item.support_contents.map(content => content.content_id),
      multiple: false
    })
  }

  openCreateMainContentDialog() {
    this.utils.appDialog.seeContentFormDialog({
      onSuccess: () => {
        this.requestReload.emit()
      }
    }, {
      parentUnitSectionId: this.item.el_course_unit_section_id,
      parentTargetType: "MAIN"
    })
  }
}
