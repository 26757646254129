import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';

@Component({
  selector: 'app-unit-item',
  templateUrl: './unit-item.component.html',
  styleUrls: ['./unit-item.component.sass'],
})
export class UnitItemComponent extends BaseItemDirective {
  @Input() courseId = 0;
  @Input() idx = 0;
  unitName = '';
  override title = 'CORE.COURSE';
  override keys = {
    primary_key: 'el_course_unit_id',
    label: 'name_',
  };
  items: MenuItem[] = [
    {
      id: 'SECTIONS',
      label: this.utils.translate.instant('FORM.SECTIONS'),
      icon: 'pi pi-server',
      command: () => this.sections()
    },
    {
      id: 'TESTS',
      label: this.utils.translate.instant('MENU.TESTS'),
      icon: 'pi pi-check-square',
      command: () => this.tests(),
    },
    {
      id: 'CREATE-TEST',
      label: this.utils.translate.instant('FORM.CREATE-AND-ASSOCIATE-TEST'),
      icon: 'pi pi-check-square',
      command: () => this.seeCreateAndAssociateTestDialog(),
    },
    {
      id: 'ASSOCIATE-TETS',
      label: this.utils.translate.instant('FORM.ASSOCIATE-TEST'),
      icon: 'pi pi-check-square',
      command: () => this.seeAssociateTestDialog(),
    },
    {
      separator: true,
    },
    {
      id: 'INACTIVATE',
      label: this.utils.translate.instant('ENV.INACTIVATE'),
      icon: 'pi pi-ban',
      styleClass: 'text-danger',
      command: () => this.inactivate(),
    },
  ];
  currentTestIds: number[] = []

  tests() {
    localStorage.setItem('unit_name', this.item.name_)
    this.utils.router.navigateByUrl(
      `/courses/units/tests/${this.item.el_course_unit_id}`
    );
  }

  sections() {
    localStorage.setItem('unit_name', this.item.name_)
    this.utils.router.navigateByUrl(
      `/courses/units/sections/${this.courseId}/${this.item.el_course_unit_id}`
    );
  }

  seeCreateAndAssociateTestDialog() {
    this.utils.appDialog.seeCourseUnitTestFormDialog(this.item.el_course_unit_id, {
      onSuccess: () => {
        this.requestReload.emit()
      }
    })
  }

  seeAssociateTestDialog() {
    this.utils.appDialog.seeUnitTestPickerTableDialog(this.item.el_course_unit_id, {
      onSuccess: () => {
        this.requestReload.emit()
      }
    }, {
      idsExcluded: this.currentTestIds
    })
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.unitDelete(
      this.item[this.keys.primary_key]
    );
  }

  override onItemReady(): void {
    super.onItemReady()
    this.currentTestIds = this.item.tests.map(item => item.el_test_id)
  }
}
