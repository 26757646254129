<div class="flex flex-column" [ngClass]="containerStyleClass">
  <div class="flex flex-grow-1 h-full" *ngIf="!horizontal">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <ng-container>
          <div
            *ngFor="let row of rows"
            class="box-elevation border-round-md border-2 flex flex-column border-transparent shadow-0 my-2 white-card cursor-pointer"
            (click)="handleItemSelected(row)"
            routerLink="libraries/{{ row.library_id }}/contents"
          >
            <p class="font-bold mt-0">
              {{ row.name_ }}
            </p>
            <p class="mt-0">
              {{ row.description_ }}
            </p>
            {{ row.tags.replace(",", ", ") }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="flex flex-grow-1 h-full" *ngIf="horizontal">
    <div class="flex w-12">
      <div class="h-full w-full" *ngIf="!loading; else loadingTemplate">
        <p-carousel
          [value]="rows"
          [numVisible]="rows.length <= 4 ? rows.length : 4"
          [numScroll]="1"
          [circular]="false"
        >
          <ng-template let-row pTemplate="item">
            <div
              class="box-elevation border-round-md border-2 flex flex-column border-transparent mx-2 shadow-0 my-2 white-card cursor-pointer p-3"
              routerLink="libraries/{{ row.library_id }}/contents"
            >
              <p class="font-bold mt-0">
                {{ row.name_ }}
              </p>
              <p class="mt-0">
                <app-text-wrapper
                  [ellipsisLinesNum]="2"
                  [text]="row.description_"
                ></app-text-wrapper>
              </p>
              <div class="flex gap-2">
                <p-tag *ngFor="let tag of row.tags.split(',')">
                  {{ tag }}
                </p-tag>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <div class="loading-container">
      <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
    </div>
  </ng-template>
</div>
