import { DatePipe } from "@angular/common";
import { Utils } from "./utils";

export class DateUtils {

  private static getDate(value) {
    return this.dateParts(value).date
  }

  static dateParts(date: string): { date: string, time: string } {
    return {
      date: date.split(' ')[0],
      time: date.split(' ')[1]
    }
  }

  static strToDate(value: string) {
    return new Date(`${this.getDate(value)} 00:00:00`);
  }

  static strToDateTime(value: string) {
    let time = '0000:00:00'
    const currentTime = this.dateParts(value).time
    if (currentTime) {
      time = currentTime
    }
    return new Date(`${this.getDate(value)} ${time}`);
  }

  static printDateFormatted(value: string | null) {
    if (value) {
      if (value !== '' && value !== '0000-00-00') {
        try {
          return this.strDateToFormat(value);
        } catch (e) {
          Utils.error(e)
        }
      }
    }
    return '--';
  }

  static printDateFormattedWithTime(value: string | null) {
    return `${this.printDateFormatted(value.split(' ')[0])} ${value.split(' ')[1]
      }`;
  }

  static getDateTimeFormatted(date: string) {
    const dateParts = DateUtils.dateParts(date)
    if (dateParts.time) {
      return DateUtils.printDateFormattedWithTime(date);
    } else {
      return DateUtils.printDateFormatted(date);
    }
  }

  static dateToFormat(date: Date, format = 'dd MMM yyy') {
    const datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }

  static strDateToFormat(value: string, format = 'dd MMM yyy') {
    return this.dateToFormat(this.strToDateTime(value), format);
  }

  static isDateBetween(checkDate: Date, startDate: Date, endDate: Date): boolean {
    return checkDate >= startDate && checkDate <= endDate;
  }
}
