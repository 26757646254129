<div class="flex flex-column h-full">
  <div class="flex justify-content-end mb-2">
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-file-pdf"
      class="p-button-danger me-3 position-relative mr-2"
      [label]="'FORM.MASS-CERTIFICATE' | translate"
      (click)="generateSimplePDF('', true)"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-file-export"
      class="p-button-danger me-3 position-relative mr-2"
      (click)="generateTablePdf()"
    ></button>

    <app-prime-ng-export-table [primeNgList]="this"></app-prime-ng-export-table>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-table"
      label="{{ 'FORM.SHOW-HIDE-COLUMNS' | translate }}"
      class="p-button-success me-3 position-relative"
      (click)="openColumnSidebar()"
    ></button>
  </div>
  <div class="flex-grow overflow-hidden h-full">
    <p-table
      id="data-table-list"
      [columns]="columns"
      [dataKey]="keys.primary_key"
      [value]="rows"
      [loading]="loading"
      [paginator]="true"
      [rows]="rowsPerPage"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="rowsPerPageOptions"
      styleClass="p-datatable-gridlines"
      [(selection)]="selectedRows"
      [scrollable]="true"
      scrollHeight="flex"
      [resizableColumns]="true"
      [currentPageReportTemplate]="
        'GENERAL.SHOWING-X-TO-Y-OF-Z-ROWS'
          | translate
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      "
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3rem" pFrozenColumn>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let col of columns">
            <th
              [pSortableColumn]="col.sortable"
              pFrozenColumn
              [frozen]="col.frozen"
              pResizableColumn
              [pResizableColumnDisabled]="!col.resizable"
              [width]="col.width"
              [style.minWidth.px]="col.resizable ? 0 : col.width"
              *ngIf="col.visible"
            >
              <div class="flex align-items-center gap-2">
                {{ col.header | translate }}
                <p-sortIcon
                  *ngIf="col.sortable"
                  [field]="col.sortable"
                ></p-sortIcon>
                <div [ngSwitch]="col.field" *ngIf="col.filterable">
                  <div *ngSwitchCase="'enrolled_at'">
                    <p-columnFilter type="date" field="enrolled_at" display="menu" />
                  </div>
                  <div *ngSwitchCase="'enrollment_status'">
                    <p-columnFilter field="enrollment_status" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                      <ng-template pTemplate="header">
                        <div class="px-3 pt-3 pb-0">
                          <span class="font-bold">Agent Picker</span>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect 
                          [(ngModel)]="value2" 
                          [options]="enrollmentStatus" 
                          optionLabel="name"
                          optionValue="value"
                          (onChange)="filter($event.value)">
                        </p-multiSelect>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                  <div *ngSwitchDefault>
                    <p-columnFilter
                      *ngIf="col.filterable"
                      type="text"
                      [field]="col.sortable"
                      display="menu"
                    ></p-columnFilter>
                  </div>
                </div>
              </div>
            </th>
          </ng-container>
          <th
            style="min-width: 125px"
            [width]="125"
            alignFrozen="right"
            pFrozenColumn
          ></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr>
          <td pFrozenColumn>
            <p-tableCheckbox [value]="row"></p-tableCheckbox>
          </td>
          <ng-container *ngFor="let col of columns">
            <td
              pFrozenColumn
              [frozen]="col.frozen"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <ng-container [ngSwitch]="col.field">
                <div
                  *ngSwitchCase="'enrollment_status'"
                  class="text-center"
                  style="white-space: pre-line"
                >
                  <app-enrollment-status-chip
                    [status]="row.enrollment_status"
                  ></app-enrollment-status-chip>
                </div>

                <div
                  *ngSwitchCase="'percentage'"
                  class="text-center"
                  style="white-space: pre-line"
                >
                  <div
                    class="flex flex-column gap-1 cursor-pointer"
                    routerLink="/courses/user/preview/{{ row.el_course_id }}/{{
                      row.user.user_id
                    }}"
                  >
                    <div class="text-sm text-right">
                      {{ row.percentage | percent : "1.2-2" }}
                    </div>
                    <p-progressBar
                      [value]="row.percentage * 100"
                      [style]="{ height: '15px' }"
                    >
                    </p-progressBar>
                  </div>
                </div>

                <div *ngSwitchDefault style="white-space: pre-line">
                  {{ col.field | ngCellValue : row }}
                </div>
              </ng-container>
            </td>
          </ng-container>
          <td
            style="min-width: 125px"
            [width]="125"
            class="p-2"
            alignFrozen="right"
            pFrozenColumn
          >
            <div
              class="flex flex-grow-1 justify-content-center md:justify-content-center mt-2 md:mt-0 gap-2"
            >
              <button
                pButton
                pRipple
                icon="pi pi-ban"
                [pTooltip]="
                  row.enrollment_status === 'F' || row.enrollment_status === 'A'
                    ? ('FORM.UNAVAILABLE' | translate)
                    : ('FORM.UNENROLL' | translate)
                "
                [disabled]="
                  row.enrollment_status === 'F' || row.enrollment_status === 'A'
                "
                (click)="cancelInscription(row)"
                class="p-button-text p-button-raised p-button-danger"
              ></button>
              <button
                pButton
                pRipple
                icon="pi pi-user-plus"
                [disabled]="
                  row.enrollment_status !== 'A'
                "
                (click)="RestoreInscription(row)"
                class="p-button-text p-button-raised p-button-success"
              ></button>
              <button
                pButton
                pRipple
                icon="pi pi-file-pdf"
                (click)="generateSimplePDF(row)"
                [disabled]="row.enrollment_status !== 'F'"
                class="p-button-text p-button-raised p-button-danger"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ "WIDGET.DT-EMPTY" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-sidebar
  [(visible)]="isColumnToggleVisible"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '100%', 'max-width': '20em' }"
>
  <app-prime-list-columns-toggle
    [(columns)]="columns"
  ></app-prime-list-columns-toggle>
</p-sidebar>

<p-dialog
  header="{{ 'MENU.REPORTS' | translate }}"
  [(visible)]="isPdfDialogVisible"
  [modal]="true"
  [style]="{ width: '95%', height: '95%' }"
  [draggable]="false"
  [resizable]="false"
  [contentStyle]="{ height: '100%' }"
>
  <app-modal-simple-pdf
    *ngIf="isPdfDialogVisible"
    [itemSelection]="itemSelected"
    [courseDetail]="courseDetail"
    [batchMode]="batchMode"
    [selectedDataTable]="rows"
  >
  </app-modal-simple-pdf>
</p-dialog>
<p-dialog
  header="{{ 'MENU.REPORTS' | translate }}"
  [(visible)]="isPdfTableDialogVisible"
  [modal]="true"
  [style]="{ width: '95%', height: '95%' }"
  [draggable]="false"
  [resizable]="false"
  [contentStyle]="{ height: '100%' }"
>
  <app-modal-table-pdf
    *ngIf="isPdfTableDialogVisible"
    [itemSelection]="itemSelected"
    [courseDetail]="courseDetail"
    [selectedDataColumns]="columns"
    [selectedDataTable]="rows"
  >
  </app-modal-table-pdf>
</p-dialog>
