import { PercentPipe } from "@angular/common"
import { UtilsService } from "../services/utils/utils.service"
import { DateUtils } from "../shared/utils/date-utils"
import { AppFormattedList, AppJSONSanitizer } from "./AppGenericClasses"
import { AppSortableItem, AppSelectableItem } from "./AppModels"
import { TestTake, TestTakeJSON } from "./TestTakeClasses"


export class TestTakeAttemptJSON {
  attempt = 0
  take: TestTakeJSON = new TestTakeJSON()
}

export class TestTakeAttempt implements AppSortableItem, AppSelectableItem<TestTakeAttempt> {
  id_: number
  name_: string
  value: TestTakeAttempt
  attempt: number

  formatter: TestTakeAttemptFormatter

  testTake: TestTake

  constructor(source: TestTakeAttemptJSON, utils: UtilsService) {
    source = new AppJSONSanitizer<TestTakeAttemptJSON>(source, TestTakeAttemptJSON).result
    this.attempt = source.attempt
    this.testTake = new TestTake(source.take)
    this.formatter = new TestTakeAttemptFormatter(source, utils)
  }

  get sortValue(): string {
    return this.name
  }

  get name(): string {
    return this.name_
  }
  set name(value: string) {
    this.name_ = value
  }

  get id(): number {
    return this.id_
  }
  set id(value: number) {
    this.id_ = value
  }
}

export class TestTakeAttemptFormatter {
  utils: UtilsService
  timeFrom_: string
  timeTo_: string
  result_: number
  take: TestTakeJSON

  constructor(source: TestTakeAttemptJSON, utils: UtilsService) {
    this.utils = utils
    this.take = source.take
    this.timeFrom_ = source.take.time_from
    this.timeTo_ = source.take.time_to
    this.result_ = source.take.result
  }

  get timeFrom(): string {
    return DateUtils.getDateTimeFormatted(this.timeFrom_)
  }

  get timeTo() {
    return DateUtils.getDateTimeFormatted(this.timeTo_)
  }

  get result() {
    return new PercentPipe('en-US').transform((this.result_) / 100, '1.2')
  }
}

export class TestTakeAttemptList extends AppFormattedList<TestTakeAttempt, TestTakeAttemptJSON> {

  constructor(utils: UtilsService) {
    super(utils);
    this.ItemModel = TestTakeAttempt
  }
}
