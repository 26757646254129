import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-wrapper',
  templateUrl: './text-wrapper.component.html',
  styleUrls: ['./text-wrapper.component.sass']
})
export class TextWrapperComponent {
  @Input() text: string = null
  @Input() ellipsisLinesNum = 3
  @Input() onStringLength = 150
  seeMore = false

  toggle() {
    event.stopPropagation()
    this.seeMore = !this.seeMore
  }
}
