import { Component, Input, ViewChild } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { BaseListDirective } from 'src/app/directives/base-list.directive';

@Component({
  selector: 'app-course-item-enrolled-users',
  templateUrl: './course-item-enrolled-users.component.html',
  styleUrls: ['./course-item-enrolled-users.component.sass']
})
export class CourseItemEnrolledUsersComponent extends BaseItemDirective {
  @ViewChild('primeNgList') list: BaseListDirective
  @Input() itemId = 0
  userIds: number[] = []
  title = 'CORE.ENROLLED-USERS'

  override handleDialogConfigData() {
    super.handleDialogConfigData()
    this.itemId = this.dialogConfig.data.courseId ?? this.itemId;
  }

  override getItem() {
    this.itemService = this.data.core.courseUnique(this.itemId)
    super.getItem()
  }

  override onItemReady() {
    super.onItemReady()
    this.userIds = this.item?.course_prerequisites?.map(course => course.el_course_enrollment_id) || []
  }

  refreshActionServices(): void {
    throw new Error('Method not implemented.');
  }
}
