<div
  class="flex align-items-center mb-1 gap-2"
  [ngClass]="fireActions ? 'cursor-pointer' : ''"
  (click)="viewSupportContent(content)"
  *ngIf="content"
>
  <span
    class="material-symbols-outlined"
    style="font-size: 2rem"
    [pTooltip]="globals.CONTENT_TYPES[content.type].label | translate"
  >
    {{ globals.CONTENT_TYPES[content.type].materialIcon }}
  </span>
  <span *ngIf="showName">{{ content.name }}</span>
</div>

<app-content-trigger-button
  #contentTrigger
  style="display: none"
></app-content-trigger-button>
