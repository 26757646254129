<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h5
        class="module-subtitle cursor-pointer"
        routerLink="/courses/units/preview/{{ courseId }}"
      >
        {{ "MENU.UNITS" | translate | uppercase }}
      </h5>
      <h2 class="module-title ellipsis-1">
        {{ unitName | translate }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color); margin-top: 20px"
      ></i>
    </div>
    <div class="flex flex-grow-1 align-items-end justify-content-start">
      <h2 class="module-title">
        {{ "FORM.SECTIONS" | translate | uppercase }}
      </h2>
    </div>
    <div
      class="flex flex-wrap gap-2 flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-primary"
        icon="pi pi-sort-amount-up-alt"
        label="{{ 'ENV.SORT' | translate }}"
        (click)="seeSortableItems()"
      ></button>
      <button
        pButton
        pRipple
        class="p-button-text p-button-success"
        icon="pi pi-plus"
        label="{{ 'ENV.NEW-F' | translate }} {{
          'FORM.SECTION' | translate | lowercase
        }}"
        routerLink="/courses/units/sections/create/{{ unitId }}"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 hidden lg:flex relative">
    <div class="flex w-12 lg:flex relative">
      <div class="absolute h-full w-full">
        <p-scrollPanel
          [style]="{ width: '100%', height: '100%' }"
          *ngIf="!loading; else loadingTemplate"
        >
          <app-section-item
            *ngFor="let row of rows; index as i"
            [item]="row"
            [idx]="i"
            (itemDisabled)="getList()"
            (requestReload)="getList()"
          >
          </app-section-item>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="showSortableItems" position="right" appendTo="body">
  <ng-template pTemplate="header">
    <h3>{{ "ENV.SORT" | translate }} | {{ "FORM.SECTIONS" | translate }}</h3>
  </ng-template>
  <app-sortable-list
    [data]="rows"
    labelKey="name_"
    (dataSorted)="onReorder()"
  ></app-sortable-list>
</p-sidebar>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
