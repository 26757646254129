import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';


@Component({
  selector: 'app-city-form',
  templateUrl: './city-form.component.html',
  styleUrls: ['./city-form.component.sass']
})
export class CityFormComponent extends BaseFormDirective {
  override title = 'MENU.CITIES'
  override form = {
    name_: null
  }
  path = 'city'

  override getItemEdit() {
    this.editService = this.data.core.cityUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.cityCreate(this.formSanitized)
    this.putService = this.data.core.cityUpdate(this.editId, this.formSanitized)
  }
}
