<div class="flex flex-column h-full">
    <div class="flex" style="height: auto">
      <div class="flex flex-grow-1 align-items-center justify-content-start">
        <h2 class="module-title">{{ title | translate | uppercase }}</h2>
      </div>
      <div
        class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
      >
        <button
          pButton
          pRipple
          class="p-button-text p-button-success"
          icon="pi pi-plus"
          label="{{ 'ENV.NEW' | translate }} {{
            'CORE.GENDER' | translate | lowercase
          }}"
          routerLink="/configuration/gender/create"
        ></button>
      </div>
    </div>
    <p-divider styleClass="divider-slim"></p-divider>
    <div class="flex flex-grow-1">
      <div class="flex w-12 relative">
        <div class="absolute h-full w-full">
          <app-gender-table></app-gender-table>
        </div>
      </div>
    </div>
  </div>