import { VideoUrlPlayerComponent } from './../../components/controls/video-url-player/video-url-player.component';
import { AppPrimeNGDialogConfig } from './../../models/AppGenericClasses';
import { MediaPlayerConfig } from 'src/app/models/AppGenericClasses';
import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { AwsAudioPlayerComponent } from 'src/app/components/controls/aws-audio-player/aws-audio-player.component';
import { AwsImageViewerComponent } from 'src/app/components/controls/aws-image-viewer/aws-image-viewer.component';
import { AwsHtmlViewerComponent } from 'src/app/components/controls/aws-html-viewer/aws-html-viewer.component';
import { AwsVideoPlayerComponent } from 'src/app/components/controls/aws-video-player/aws-video-player.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public search: BehaviorSubject<string> = new BehaviorSubject<string>('')
  public searchEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public processing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public isDarkActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(localStorage.getItem('app-theme') == 'dark')

  config: any = []
  user: { [key: string]: any } = {}
  userType: { [key: string]: any } = {}

  contentTypeDialogConfig: {
    [key: string]: {
      dialogComponent: Type<any>,
      mediaPlayerConfig?: MediaPlayerConfig,
      dialogConfig?: AppPrimeNGDialogConfig,
      rawData?: object
    }
  } = {
      AUDIO: {
        dialogComponent: AwsAudioPlayerComponent,
        mediaPlayerConfig: {
          autoPlay: true
        },
        dialogConfig: {
          maximizable: false,
          height: 'auto'
        }
      },
      VIDEO: {
        dialogComponent: AwsVideoPlayerComponent,
        mediaPlayerConfig: {
          autoPlay: true
        }
      },
      IMG: {
        dialogComponent: AwsImageViewerComponent,
        rawData: {
          gallery: false
        }
      },
      PLAIN: {
        dialogComponent: AwsHtmlViewerComponent
      },
      'VIDEO-URL': {
        dialogComponent: VideoUrlPlayerComponent,
        mediaPlayerConfig: {
          autoPlay: true
        },
        dialogConfig: {
          height: '70vh'
        }
      }
    }

  themeColor(color: { [key: string]: any }) {
    if (color) {
      return this.isDarkActive.value ? {
        surface: `#${color.surface_light}`,
        textColor: `#${color.text_color_light}`
      } : {
        surface: `#${color.surface_dark}`,
        textColor: `#${color.text_color_dark}`
      }
    }
    return {
      surface: '',
      textColor: ''
    }
  }
}
