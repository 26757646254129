<div class="flex flex-column" [ngClass]="containerStyleClass">
  <div class="flex flex-grow-1 h-full">
    <div class="flex w-12 relative">
      <div
        class="absolute h-full w-full"
        *ngIf="!loading; else loadingTemplate"
      >
        <ng-container>
          <div
            *ngFor="let row of rows"
            class="box-elevation border-round-md border-2 flex flex-column border-transparent shadow-0 my-2 white-card cursor-pointer"
            (click)="handleItemSelected(row)"
          >
            <div class="flex align-items-center justify-content-between w-full">
              <p class="pl-3 flex align-items-center gap-2 font-bold">
                <span class="material-symbols-outlined">local_library</span>
                {{ row.title | translate }}
              </p>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                (click)="deleteRow(row)"
                class="btn p-button-text p-button-danger"
              ></button>
            </div>
            <div class="flex flex-column gap-2 pl-3 pr-2">
              <span class="mb-2 text-sm font-italic">
                {{ row.message }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <div class="loading-container">
      <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
    </div>
  </ng-template>
</div>
