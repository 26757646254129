import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { ComponentDataSource } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-test-take',
  templateUrl: './test-take.component.html',
  styleUrls: ['./test-take.component.sass']
})
export class TestTakeComponent extends BaseListDirective {

  override filterableFields = ['name_', 'description_', 'tags']
  value = 50;
  testTake = null;
  @Input() testTakeId = 0
  override componentDataSource: ComponentDataSource = 'INPUT'

  override getList() {
    this.query(this.data.core.testTake(this.testTakeId)).subscribe()
  }

  override handleRouteParams(params: Params) {
    super.handleRouteParams(params)
    if ('id' in params) {
      this.testTakeId = params['id']
    }
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  handleRows(data: any): void {
    this.testTake = data;
    this.loading = false;
    console.log(this.testTake);
  }
}
