import { Component, ViewChild } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';
import { BaseListDirective } from 'src/app/directives/base-list.directive';

@Component({
  selector: 'app-test-version',
  templateUrl: './test-version.component.html',
  styleUrls: ['./test-version.component.sass']
})
export class TestVersionComponent extends BaseDirective {
  override title = 'MENU.TESTS';
  @ViewChild('primeNgList') list: BaseListDirective

  seeTestVersionFormTableDialog() {
    this.utils.appDialog.seeTestVersionFormTableDialog({
      onSuccess: () => {
        this.list.getList()
      }
    })
  }
}
