<div *ngFor="let column of columns" class="mb-3 mx-3">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-eye"
    label="{{ column.header }}"
    class="p-button-raised p-button-success p-button-text"
    [style]="{ width: '100%' }"
    (click)="onColumnToggle(column)"
    *ngIf="column.visible"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-eye-slash"
    label="{{ column.header }}"
    class="p-button-danger p-button-text"
    [style]="{ width: '100%' }"
    (click)="onColumnToggle(column)"
    *ngIf="!column.visible"
  ></button>
</div>
