<form #dataForm="ngForm" class="h-full w-full">
  <div class="flex mb-2 justify-content-between">
    <p-dropdown #dd id="test" name="test" appendTo="body" placeholder="{{ 'FORM.SELECT-A' | translate }} {{
        ('FORM.SUPPORT-CONTENT' | translate).toLowerCase()
      }}" [options]="rows" [(ngModel)]="supportContent" (onChange)="appendUser(dd.selectedOption)" optionLabel="name_"
      optionValue="content_id" [filter]="true" aria-describedby="id_user-help" class="w-full ng-dirty">
      <ng-template let-item pTemplate="selectedItem">
        <div class="text-capitalize">{{ item.name_ }}</div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div class="text-capitalize">{{ item.name_ }}</div>
      </ng-template>
    </p-dropdown>
    <button pButton pRipple type="button" (click)="save()" label="{{ 'ENV.SAVE' | translate }}"
      styleClass="p-button-success"></button>
  </div>
  <table class="w-full">
    <thead>
      <tr>
        <th style="width: 70px; max-width: 70px">
          {{ "FORM.SUPPORT-CONTENT" | translate }}
        </th>
        <th style="width: 70px; max-width: 70px">
          {{ "FORM.TYPE" | translate }}
        </th>
        <th style="width: 80px; max-width: 80px">
          {{ "ENV.DELETE" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let t of supportContents">
        <td class="text-capitalize text-center" style="width: 70px">
          {{ t.name_ }}
        </td>
        <td class="text-center" style="width: 70px">
          <span class="material-symbols-outlined" style="font-size: 2rem"
            [pTooltip]="globals.CONTENT_TYPES[t.type_].label | translate">
            {{ globals.CONTENT_TYPES[t.type_].materialIcon }}
          </span>
        </td>

        <td class="text-center" style="width: 80px">
          <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-text p-button-danger"
            (click)="removeUser(t.content_id)"></button>
        </td>
      </tr>
      <tr>
        <td colspan="3"></td>
      </tr>
    </tbody>
  </table>
</form>
