<div class="flex flex-column h-full" *ngIf="itemList">
  <div class="flex gap-2">
    <div class="flex-grow-1">
      <div class="flex gap-2 mb-2">
        <div class="flex p-inputgroup bg-transparent" style="max-width: 300px">
          <input
            type="text"
            pInputText
            [(ngModel)]="searchText"
            placeholder="{{ 'ENV.SEARCH' | translate }}"
            class="bg-transparent text-right"
            autocomplete="false"
          />
          <span class="p-inputgroup-addon bg-transparent"
            ><i class="pi pi-search" style="line-height: 1.25"></i
          ></span>
        </div>
        <button
          pButton
          pTooltip="Resetear filtros"
          tooltipPosition="bottom"
          icon="pi pi-filter-slash"
          class="p-button-warning ml-2 mr-4"
          (click)="resetDTFilters()"
        ></button>
      </div>
    </div>

    <div class="flex justify-content-end mb-2 mx-3">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-plus"
        label="{{ 'FORM.ADD-SELECTED' | translate }}"
        class="p-button-success me-3 position-relative"
        (click)="onItemSelectedInBatch()"
        [disabled]="!(itemList.selectedRows.length > 0)"
        *ngIf="multiple"
      ></button>
    </div>
  </div>
  <div class="flex-grow overflow-hidden h-full">
    <p-table
      [columns]="columns"
      dataKey="id"
      [value]="itemList.rows"
      [loading]="loading"
      [paginator]="true"
      [rows]="rowsPerPage"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="rowsPerPageOptions"
      styleClass="p-datatable-gridlines"
      [(selection)]="itemList.selectedRows"
      [scrollable]="true"
      scrollHeight="flex"
      [resizableColumns]="true"
      [currentPageReportTemplate]="
        'GENERAL.SHOWING-X-TO-Y-OF-Z-ROWS'
          | translate
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      "
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3rem" pFrozenColumn *ngIf="multiple">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let col of columns">
            <th
              [pSortableColumn]="col.sortable"
              pFrozenColumn
              [frozen]="col.frozen"
              pResizableColumn
              [pResizableColumnDisabled]="!col.resizable"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <div
                class="flex align-items-center justify-content-between gap-2"
                [style.width]="col.width + 'px'"
              >
                <div class="flex-grow overflow-hidden">
                  <span
                    class="ellipsis block"
                    pTooltip="{{ col.header | translate }}"
                    tooltipPosition="bottom"
                  >
                    {{ col.header | translate }}
                  </span>
                </div>
                <div class="flex align-items-center">
                  <p-sortIcon
                    [field]="col.sortable"
                    *ngIf="col.sortable !== ''"
                  ></p-sortIcon>
                  <p-columnFilter
                    *ngIf="col.filterable"
                    type="text"
                    [field]="col.sortable"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </div>
            </th>
          </ng-container>
          <th [width]="150" alignFrozen="right" pFrozenColumn></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr>
          <td pFrozenColumn *ngIf="multiple">
            <p-tableCheckbox [value]="row"></p-tableCheckbox>
          </td>
          <ng-container *ngFor="let col of columns">
            <td
              pFrozenColumn
              [frozen]="col.frozen"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <ng-container [ngSwitch]="col.field">
                <div *ngSwitchDefault style="white-space: pre-line">
                  {{ col.field | ngCellValue : row }}
                </div>
              </ng-container>
            </td>
          </ng-container>
          <td [width]="150" class="p-2" alignFrozen="right" pFrozenColumn>
            <div
              class="flex flex-grow-1 justify-content-center md:justify-content-end mt-2 md:mt-0 gap-2"
            >
              <button
                pButton
                pRipple
                icon="pi pi-plus"
                pTooltip="{{ 'FORM.ADD' | translate }}"
                class="p-button-text p-button-raised p-button-success"
                (click)="onItemSelected(row)"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ "WIDGET.DT-EMPTY" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-sidebar
  [(visible)]="isColumnToggleVisible"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '100%', 'max-width': '20em' }"
>
  <app-prime-list-columns-toggle
    [(columns)]="columns"
  ></app-prime-list-columns-toggle>
</p-sidebar>
