import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';
@Component({
  selector: 'app-pending-user',
  templateUrl: './pending-user.component.html',
  styleUrls: ['./pending-user.component.sass']
})
export class PendingUserComponent extends BaseDirective {
  override title = 'MENU.PENDING-USERS';
}
