import { MenuItem } from 'primeng/api';
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Globals } from "../globals"
import { UtilsService } from "../services/utils/utils.service"
import { TestFormatter, TestQuestionFormatter } from "./AppFormatters"
import { AppJSONSanitizer, ContentType } from "./AppGenericClasses"
import { PollParser } from "./AppParsers"
import { ColorJSON, ContentJSON, TestJSON, TestQuestionJSON, TopicJSON } from "./AppSources"
import { ResourceDataItem } from '../components/shared/content-trigger-button/content-trigger-button.component';
import { CourseUnitTest } from './CourseUnitTestClasses';
import { Utils } from '../shared/utils/utils';

export interface AppItem {
  id_: number
  name_: string

  get name(): string
  set name(value: string)
  get id(): number
  set id(value: number)
}

export interface AppSelectableItem<Value> {
  value: Value
  name: string
}

export interface AppSortableItem extends AppItem {
  get sortValue(): string
  set sortValue(value: string)
}

export class Color implements AppItem, AppSelectableItem<Color> {
  id_: number;
  name_: string;
  value_: Color;

  surfaceLight: string
  textColorLight: string

  surfaceDark: string
  textColorDark: string

  source: ColorJSON

  constructor(source: ColorJSON) {
    this.source = new AppJSONSanitizer<ColorJSON>(source, ColorJSON).result
    this.id = this.source.color_id
    this.name = this.source.name_
    this.surfaceLight = this.source.surface_light
    this.textColorLight = this.source.text_color_light
    this.surfaceDark = this.source.surface_dark
    this.textColorDark = this.source.text_color_dark
  }

  get id(): number {
    return this.id_
  }
  set id(value: number) {
    this.id_ = value
  }

  get name(): string {
    return this.name_
  }
  set name(value: string) {
    this.name_ = value
  }

  get value(): Color {
    return this
  }
}

export class Topic implements AppSortableItem, AppSelectableItem<Topic> {
  id_: number;
  name_: string;
  value_: Topic;
  description: string

  color: Color

  source: TopicJSON

  constructor(source: TopicJSON, utils: UtilsService) {
    this.source = new AppJSONSanitizer<TopicJSON>(source, TopicJSON).result
    this.id = this.source.el_topic_id
    this.name = this.source.name_
    this.description = this.source.description_
    this.color = new Color(this.source.color)
  }

  get id(): number {
    return this.id_
  }
  set id(value: number) {
    this.id_ = value
  }

  get name(): string {
    return this.name_
  }
  set name(value: string) {
    this.name_ = value
  }

  get sortValue(): string {
    return this.name_
  }

  get value(): Topic {
    return this
  }
}

export class Content implements AppItem {
  id_: number
  name_: string
  type: string
  content: string
  minViewingTime: number

  source: ContentJSON

  constructor(source: ContentJSON) {
    this.source = new AppJSONSanitizer<ContentJSON>(source, ContentJSON).result
    this.name = this.source.name_
    this.type = this.source.type_
    this.content = this.source.content
    this.minViewingTime = this.source.min_viewing_time

  }

  get id(): number {
    return this.id_
  }
  set id(value: number) {
    this.id_ = value
  }

  get name(): string {
    return this.name_
  }
  set name(value: string) {
    this.name_ = value
  }

  get realTime(){
    return Utils.convertSecondsToHMS(this.minViewingTime)
  }
}

export class Poll {
  title: string
  review: string
  openQuestionsCount: number
}

export class Test implements AppSortableItem, AppSelectableItem<Test> {
  id_: number
  name_: string
  timeLimit: number
  randomQuestions: number
  minApprovalPercentage: number

  poll: Poll

  formatter: TestFormatter

  source: TestJSON

  constructor(source: TestJSON, utils: UtilsService) {
    this.source = new AppJSONSanitizer<TestJSON>(source, TestJSON).result
    this.id = this.source.el_test_id
    this.timeLimit = this.source.time_limit
    this.randomQuestions = this.source.random_questions
    this.minApprovalPercentage = this.source.min_approval_percentage
    this.poll = new PollParser(this.source.poll).result
    this.formatter = new TestFormatter(this.source, utils)
  }

  get id(): number {
    return this.id_
  }

  set id(value: number) {
    this.id_ = value
  }

  get name() {
    return this.poll.title
  }

  set name(value: string) {
    this.name_ = value
  }

  get sortValue(): string {
    return this.name
  }

  get value(): Test {
    return this
  }
}

export class TestQuestion implements AppSortableItem, AppSelectableItem<TestQuestion> {
  id_: number
  name_: string
  content: string
  weight_: string
  newWeight: string
  type: string
  answersSelection: any[]
  answersRelated: any[]
  order: number

  resourceContent: Content
  resourceContentMenuItems: MenuItem[]

  formatter: TestQuestionFormatter

  constructor(source: TestQuestionJSON, utils: UtilsService) {
    source = new AppJSONSanitizer<TestQuestionJSON>(source, TestQuestionJSON).result
    this.id = source.poll_question_id
    this.content = source.content
    this.weight = source.weight
    this.newWeight = source.new_weight
    this.type = source.type_
    this.answersSelection = source.answers_selection
    this.answersRelated = source.answers_related
    this.order = source.order_
    this.resourceContent = new Content(source.resource_content)
    this.formatter = new TestQuestionFormatter(source, utils)
  }

  get id(): number {
    return this.id_
  }
  set id(value: number) {
    this.id_ = value
  }

  get name(): string {
    return this.content
  }
  set name(value: string) {
    this.content = value
  }

  get sortValue(): string {
    return this.order.toString()
  }
  set sortValue(value: string) {
    this.order = +value
  }

  get value(): TestQuestion {
    return this
  }

  get weight() {
    return this.weight_
  }
  set weight(value: string) {
    this.weight_ = value
  }

  get answersNum() {
    let result = 0
    switch (this.type) {
      case "OPEN":
        result = 0
        break
      case "SELECTION-SINGLE":
      case "SELECTION-MULTIPLE":
        result = this.answersSelection.length
        break
      case "RELATION":
        result = this.answersRelated.length
    }
    return result
  }

  get resourceContentData(): ResourceDataItem {
    return {
      type_: this.resourceContent.type as ContentType,
      content: this.resourceContent.content,
      resourceData: {
        path: 'content',
        id: this.resourceContent.id,
        field: this.resourceContent.type,
        ext: Globals.CONTENT_TYPES[this.resourceContent.type].ext,
        resourceContent: ['PLAIN', 'URL', 'VIDEO-URL'].includes(this.resourceContent.type) ? this.resourceContent.content : ''
      }
    }
  }
}
