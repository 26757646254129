<ng-container *ngIf="!downloading; else loadingTemplate">
  <ng-container *ngIf="resourceList.length; else noFilesUploaded">
    <div
      class="row single-file box box-elevation p-2 h-100 mb-2"
      *ngFor="let resource of resourceList; let i = index"
    >
      <div class="text-center">
        <ng-container [ngSwitch]="resource.ext">
          <div style="height: 150px" *ngSwitchCase="'mp4'">
            <app-aws-video-viewer [resourceUrl]="resource.src">
            </app-aws-video-viewer>
          </div>
          <div *ngSwitchCase="'mp3'">
            <app-aws-audio-player [resourceUrl]="resource.src">
            </app-aws-audio-player>
          </div>
          <div
            style="height: 300px"
            class="cursor-pointer"
            *ngSwitchCase="'png'"
          >
            <app-aws-image-viewer [resourceUrl]="resource.src">
            </app-aws-image-viewer>
          </div>
          <div class="block cursor-pointer" *ngSwitchDefault>
            <a
              appBaseHtmlElementResource
              [resourceUrl]="resource.src"
              target="_blank"
              style="color: inherit; text-decoration: none"
            >
              <span class="material-symbols-outlined" style="font-size: 2.5rem"
                >description</span
              >
            </a>
          </div>
        </ng-container>
      </div>
      <div class="flex align-items-center gap-3">
        <div class="flex-grow-1 overflow-hidden">
          <p class="font-bold w-full ellipsis my-1">
            {{ resource.name }}
          </p>
          <span class="p-text-secondary" *ngIf="resource.fileData.size">
            {{ resource.fileData.size | formatBytes }}
          </span>
        </div>
        <div class="flex" *ngIf="showDeleteButton">
          <a
            class="cursor-pointer"
            (click)="deleteUploadedFile(resource.key ?? '', i)"
          >
            <span
              class="material-symbols-outlined text-danger"
              style="font-size: 35px"
              >delete</span
            >
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noFilesUploaded>
  <div
    style="height: 100px"
    class="w-full flex text-center align-items-center justify-content-center"
  >
    <h3>{{ "WIDGET.NO-FILES-UPLOADED" | translate }}</h3>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
