<div
  class="flex flex-grow-1 align-items-center justify-content-center md:justify-content-end mt-2 md:mt-0 card-item-buttons gap-1"
  *ngIf="item && userType.could_manage_course"
>
  <button
    pButton
    pRipple
    icon="pi pi-pencil"
    pTooltip="{{ 'ENV.EDIT' | translate }}"
    routerLink="/courses/edit/{{ item.id }}"
    class="p-button-text p-button-raised"
  ></button>
  <button
    pButton
    pRipple
    icon="pi pi-list"
    (click)="units(item)"
    class="p-button-text p-button-raised p-button-secondary"
    pTooltip="{{ 'CORE.UNITS' | translate }}"
  ></button>
  <button
    pButton
    pRipple
    icon="pi pi-ellipsis-h"
    class="p-button-text p-button-raised p-button-info"
    (click)="menu.toggle($event)"
  ></button>
  <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
</div>
