<div class="flex flex-column h-full">
  <div class="flex align-items-center" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h2 class="module-title cursor-pointer" routerLink="/admin/test">
        {{ title | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color)"
      ></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW-F") | translate }}
        {{ "CORE.TEST" | translate | lowercase }}
      </h2>
    </div>

    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
            <span class="p-float-label">
              <input
                pInputText
                id="title"
                name="title"
                class="ng-dirty w-full"
                aria-describedby="title-help"
                [(ngModel)]="form.poll.title"
                required
              />
              <label for="title">{{ "FORM.TITLE" | translate }}</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 mb-4">
            <span class="p-float-label">
              <textarea
                pInputTextarea
                id="description"
                name="description"
                rows="5"
                class="ng-dirty w-full"
                [(ngModel)]="form.poll.review"
              ></textarea>
              <label for="description">{{
                "FORM.DESCRIPTION" | translate
              }}</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 mb-4">
            <span class="p-float-label">
              <p-inputNumber
                [showButtons]="true"
                inputId="time_limit"
                name="time_limit"
                [(ngModel)]="timeLimit"
                class="ng-dirty w-full"
                [min]="0"
                [required]="true"
              ></p-inputNumber>
              <label for="time_limit">
                {{ "FORM.TIME-LIMIT" | translate }} ({{
                  globals.EL_TEST_VISUALIZATION.TIME_LIMIT_UNIT.label
                    | translate
                }})
              </label>
            </span>
          </div>
          <div class="flex flex-row align-items-center gap-2 mb-4">
            <p-checkbox
              name="random_questions"
              [binary]="true"
              [(ngModel)]="!!form.random_questions"
              inputId="random_questions"
            ></p-checkbox>
            <label for="random_questions">{{
              "FORM.QUESTIONS-IN-RANDOM-ORDER" | translate
            }}</label>
          </div>
          <div class="flex flex-column mb-4">
            <div class="flex flex-column gap-2">
              <span class="p-float-label">
                <input
                  type="text"
                  pInputText
                  [(ngModel)]="form.min_approval_percentage"
                  class="w-full"
                  name="min_approval_percentage"
                  id="min_approval_percentage"
                />
                <label for="min_approval_percentage">{{
                  "FORM.MIN-APPROVAL-PERCENTAGE" | translate
                }}</label>
              </span>
            </div>
            <p-slider
              [(ngModel)]="form.min_approval_percentage"
              class="w-full"
              name="min_approval_percentage"
              id="min_approval_percentage_slider"
            ></p-slider>
          </div>
          <div class="flex flex-column gap-2 mb-4">
            <label for="tags">{{ "FORM.TAGS" | translate }}</label>
            <p-chips
              inputId="tags"
              name="tags"
              class="w-full"
              [separator]="globals.REG_EXP.chipFieldSeparator"
              [placeholder]="'WIDGET.CHIP-FIELD-EXAMPLE' | translate"
              [(ngModel)]="tags"
            ></p-chips>
          </div>
          <div class="flex flex-column gap-2 mb-4">
            <span class="p-float-label">
              <p-inputNumber
                [showButtons]="true"
                inputId="allowed_attempts"
                name="allowed_attempts"
                class="ng-dirty w-full"
                [min]="1"
                [required]="true"
                [(ngModel)]="testVersionForm.allowed_attempts"
              ></p-inputNumber>
              <label for="allowed_attempts">{{
                "FORM.ALLOWED-ATTEMPTS" | translate
              }}</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 mb-4" *ngIf="!editId">
            <label for="test_version_range_dates">{{
              "FORM.PLEASE-INDICATE-RANGE-DATES-AND-TIME-IN-WHICH-EVALUATION-WILL-BE-AVAILABLE"
                | translate
            }}</label>
            <div
              class="flex w-full align-items-center justify-content-center gap-3"
            >
              <div class="flex-grow">
                <p-calendar
                  id="version_date_from"
                  name="version_date_from"
                  [(ngModel)]="versionDateFrom"
                  [showTime]="true"
                  dateFormat="yy-mm-dd"
                  class="w-full"
                  inputStyleClass="w-full"
                  appendTo="body"
                  (ngModelChange)="onVersionDateFromChange()"
                ></p-calendar>
              </div>
              <span class="flex">Hasta</span>
              <div class="flex-grow">
                <p-calendar
                  id="version_date_to"
                  name="version_date_to"
                  [(ngModel)]="versionDateTo"
                  [showTime]="true"
                  dateFormat="yy-mm-dd"
                  class="w-full"
                  inputStyleClass="w-full"
                  appendTo="body"
                  (ngModelChange)="onVersionDateToChange()"
                ></p-calendar>
              </div>
            </div>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
