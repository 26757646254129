import { BaseDirective } from './../../../directives/base.directive';
import { AppMenuItem } from './../../../models/AppGenericClasses';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-module-menu',
  templateUrl: './module-menu.component.html',
  styleUrls: ['./module-menu.component.sass']
})
export class ModuleMenuComponent extends BaseDirective {
  @Input() menuItems: AppMenuItem[] = []


  override onInit(): void {
    this.menuItems = this.utils.translateAndSort(this.menuItems, 'label')


    super.onInit()
  }
}
