<ng-container *ngIf="item">
  <app-test-detail
    [source]="item.el_test"
    [showActions]="false"
    [componentDataSource]="'INPUT'"
  ></app-test-detail>
  <div class="box box-elevation p-3 flex flex-column w-12">
    <h2 class="module-title">
      {{ "CORE.QUESTION" | translate }} |
      {{ "FORM." + item.type_ | translate }}
    </h2>
    <p class="p-text-secondary">
      {{ item.content }}
    </p>
  </div>
</ng-container>
