import { BaseDirective } from './../../../../directives/base.directive';
import { Component } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.sass']
})
export class TestComponent extends BaseDirective {
  override title = 'MENU.TESTS';
}
