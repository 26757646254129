import { ResourceDataItem } from "../components/shared/content-trigger-button/content-trigger-button.component"
import { AWSResourceData, ContentType } from "./AppGenericClasses"

export class ColorJSON {
  color_id = 0
  name_ = ""
  surface_light = ""
  text_color_light = ""
  surface_dark = ""
  text_color_dark = ""
}

export class TopicJSON {
  el_topic_id = 0
  name_ = ""
  description_ = ""

  color: ColorJSON = new ColorJSON()
}

export class ContentJSON implements ResourceDataItem {
  resourceData: AWSResourceData = new AWSResourceData()
  content_id = 0
  name_ = ""
  type_: ContentType = "IMG"
  content = ""
  min_viewing_time = 0
}

export class PollJSON {
  title = ""
  review = ""
  questions_count = 0
}

export class TestJSON {
  el_test_id = 0
  time_limit = 0
  random_questions = 0
  min_approval_percentage = 0

  poll: PollJSON = new PollJSON()
}



export class TestQuestionJSON {
  poll_question_id = 0
  content = ""
  weight = ""
  new_weight = ""
  type_ = ""
  answers_selection = []
  answers_related = []
  order_ = 0

  resource_content = new ContentJSON()
}
