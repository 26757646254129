<div class="flex flex-column h-full">
  <div class="flex justify-content-end mb-2">
    <app-prime-ng-export-table [primeNgList]="this"></app-prime-ng-export-table>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-table"
      label="{{ 'FORM.SHOW-HIDE-COLUMNS' | translate }}"
      class="p-button-success me-3 position-relative"
      (click)="openColumnSidebar()"
    ></button>
  </div>
  <div
    class="flex justify-content-start align-items-center p-3 sticky top-0"
    style="z-index: 1000; background: var(--surface-a)"
  >
    <div class="flex-grow-1">
      <b>Peso total: {{ totalWeight * 10 | percent }}</b>
    </div>
    <div class="flex gap-2 align-items-center">
      <div class="flex flex-row align-items-center gap-2">
        <p-checkbox
          name="random_questions"
          [binary]="true"
          [(ngModel)]="distributeWeights"
          (ngModelChange)="onDistributeWeights()"
          inputId="random_questions"
        ></p-checkbox>
        <label for="random_questions">{{
          "FORM.DISTRIBUTE-WEIGHTS-AUTOMATICALLY" | translate
        }}</label>
      </div>
      <button
        pButton
        icon="pi pi-cloud"
        pTooltip="{{ 'ENV.SAVE' | translate }}"
        tooltipPosition="bottom"
        tooltipZIndex="10000"
        appendTo="body"
        (click)="updateRowsInBatch()"
        *ngIf="distributeWeights"
      ></button>
    </div>
  </div>
  <div class="flex-grow overflow-hidden h-full">
    <p-table
      [columns]="columns"
      dataKey="id"
      [value]="itemList.rows"
      [loading]="loading"
      [paginator]="true"
      [rows]="rowsPerPage"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="rowsPerPageOptions"
      styleClass="p-datatable-gridlines"
      [(selection)]="itemList.selectedRows"
      [scrollable]="true"
      scrollHeight="flex"
      [resizableColumns]="true"
      [currentPageReportTemplate]="
        'GENERAL.SHOWING-X-TO-Y-OF-Z-ROWS'
          | translate
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      "
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3rem" pFrozenColumn>
            <span class="pi pi-arrows-alt" pReorderableRowHandle></span>
          </th>
          <th style="width: 3rem" pFrozenColumn>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let col of columns">
            <th
              [pSortableColumn]="col.sortable"
              pFrozenColumn
              [frozen]="col.frozen"
              pResizableColumn
              [pResizableColumnDisabled]="!col.resizable"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <div
                class="flex align-items-center justify-content-between gap-2"
                [style.width]="col.width + 'px'"
              >
                <div
                  class="flex gap-1 align-items-center flex-grow overflow-hidden"
                >
                  <span
                    class="ellipsis block"
                    pTooltip="{{ col.header | translate }}"
                    tooltipPosition="bottom"
                  >
                    {{ col.header | translate }}
                  </span>
                  <span
                    class="material-symbols-outlined"
                    *ngIf="col.editable"
                    pTooltip="{{
                      'WIDGET.CAN-BE-EDITED-FROM-CELL' | translate
                    }}"
                    tooltipPosition="bottom"
                  >
                    edit_square
                  </span>
                </div>
                <div class="flex align-items-center">
                  <p-sortIcon
                    [field]="col.sortable"
                    *ngIf="col.sortable !== ''"
                  ></p-sortIcon>
                  <p-columnFilter
                    *ngIf="col.filterable"
                    type="text"
                    [field]="col.sortable"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </div>
            </th>
          </ng-container>
          <th [width]="150" alignFrozen="right" pFrozenColumn></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-row
        let-columns="columns"
        let-index="rowIndex"
      >
        <tr [pReorderableRow]="index">
          <td style="width: 3rem" pFrozenColumn>
            <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>
          <td pFrozenColumn>
            <p-tableCheckbox [value]="row"></p-tableCheckbox>
          </td>
          <ng-container *ngFor="let col of columns">
            <td
              pFrozenColumn
              [frozen]="col.frozen"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <ng-container [ngSwitch]="col.field">
                <div *ngSwitchDefault style="white-space: pre-line">
                  <ng-container *ngIf="col.editable">
                    <div
                      [pEditableColumn]="col.field | ngCellValue : row"
                      [pEditableColumnField]="col.field"
                    >
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <ng-container [ngSwitch]="col.type">
                            <input
                              *ngSwitchCase="'TEXT-LINE'"
                              pInputText
                              type="text"
                              [(ngModel)]="row[col.field]"
                              class="w-full"
                              (keydown.enter)="onEditComplete(row)"
                            />
                            <textarea
                              *ngSwitchCase="'MULTI-TEXT-LINE'"
                              pInputText
                              type="text"
                              [(ngModel)]="row[col.field]"
                              class="w-full"
                              (keydown.enter)="onEditComplete(row)"
                            ></textarea>
                            <p-inputNumber
                              *ngSwitchCase="'NUMBER'"
                              [(ngModel)]="row[col.field]"
                              class="w-full"
                              styleClass="w-full"
                              inputStyleClass="w-full"
                              mode="decimal"
                              [minFractionDigits]="0"
                              [maxFractionDigits]="1"
                              (keydown.enter)="onEditComplete(row)"
                            ></p-inputNumber>
                          </ng-container>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{ row[col.field] }}
                        </ng-template>
                      </p-cellEditor>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!col.editable">
                    {{ col.field | ngCellValue : row }}
                  </ng-container>
                </div>
              </ng-container>
            </td>
          </ng-container>
          <td [width]="150" class="p-2" alignFrozen="right" pFrozenColumn>
            <div
              class="flex flex-grow-1 justify-content-center md:justify-content-end mt-2 md:mt-0 gap-2"
            >
              <p-menu
                #menu
                [model]="row.resourceContentMenuItems"
                appendTo="body"
                [popup]="true"
              ></p-menu>
              <button
                pButton
                type="button"
                (click)="menu.toggle($event)"
                icon="pi pi-file"
                pTooltip="{{ 'ENV.ASSOCIATED-CONTENT' | translate }}"
                class="p-button-text p-button-raised p-button-info"
              ></button>
              <button
                pButton
                pRipple
                icon="pi pi-pencil"
                pTooltip="{{ 'ENV.EDIT' | translate }}"
                routerLink="/admin/test/{{ pollId }}/question/edit/{{ row.id }}"
                class="p-button-text p-button-raised"
              ></button>
              <button
                pButton
                pRipple
                icon="pi pi-trash"
                pTooltip="{{ 'ENV.DELETE' | translate }}"
                (click)="inactivate(row)"
                class="p-button-text p-button-raised p-button-danger"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ "WIDGET.DT-EMPTY" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-content-trigger-button
  #contentTrigger
  style="display: none"
></app-content-trigger-button>

<p-sidebar
  [(visible)]="isColumnToggleVisible"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '100%', 'max-width': '20em' }"
>
  <app-prime-list-columns-toggle
    [(columns)]="columns"
  ></app-prime-list-columns-toggle>
</p-sidebar>
