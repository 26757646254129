import { Utils } from 'src/app/shared/utils/utils';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BaseDirective } from './base.directive';
import { HttpClient } from "@angular/common/http";
import { Directive, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from "primeng/dynamicdialog";
import { UtilsService } from "../services/utils/utils.service";
import { GlobalDataService } from '../services/data/global-data.service';
import { ActivatedRoute } from '@angular/router';
import { Storage } from 'aws-amplify';
import { ResourceNotFoundComponent } from '../components/shared/resource-not-found/resource-not-found.component';

@Directive({
  selector: '[appBaseResource]'
})
export class BaseResourceDirective extends BaseDirective {
  resource = ''
  downloading = false
  available = false
  downloadOptions: any = {
    level: 'public',
    cacheControl: 'no-cache',
    validateObjectExistence: true
  }
  showResource = false
  ref!: DynamicDialogRef
  @Input() path = ''
  @Input() editId = 0
  @Input() field = ''
  @Input() ext = ''
  @Input() resourceUrl = ''
  @Input() resourcePath = ''
  @Input() resourceContent = ''
  @Output() resourceLoaded = new EventEmitter()

  constructor(
    utils: UtilsService,
    data: GlobalDataService,
    route: ActivatedRoute,
    dialog: DialogService,
    dialogConfig: DynamicDialogConfig,
    dialogRef: DynamicDialogRef,
    protected http: HttpClient
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef);
  }

  override onInit(): void {
    super.onInit()
    if (!this.resourceUrl && !this.resourceContent) {
      this.loadResource()
    } else {
      this.resource = this.resourceUrl
    }
  }

  override handleDialogConfigData() {
    super.handleDialogConfigData()
    this.resourceUrl = this.dialogConfig.data.resourceUrl ?? this.resourceUrl;
    this.resourceContent = this.dialogConfig.data.resourceContent ?? this.resourceContent;
    this.path = this.dialogConfig.data.path ?? this.path;
    this.editId = this.dialogConfig.data.id ?? this.editId;
    this.field = this.dialogConfig.data.field ?? this.field;
    this.ext = this.dialogConfig.data.ext ?? this.ext;
  }

  override manageChanges(changes: SimpleChanges) {
    super.manageChanges(changes);
    if ('editId' in changes) {
      this.loadResource()
    }
    if ('resourceUrl' in changes && this.resourceUrl) {
      if (this.resourceUrl) {
        this.resource = this.resourceUrl
      }
      this.onLoadResourceContentComplete()
    }
  }

  beforeGetResource() {
    this.resourcePath = `${this.path}/${this.editId}/${this.field}/resource.${this.ext}`
  }

  loadResource() {
    this.downloading = true
    this.beforeGetResource()
    Storage.get(this.resourcePath, this.downloadOptions)
      .then((result) => {
        this.onLoadResourceSuccess(result)
      })
      .catch((err) => {
        this.onLoadResourceError(err)
      })
      .finally(() => {
        this.onLoadResourceComplete()
      });
  }

  loadResourceContent(url) {
    this.downloading = true;
    if (this.ext == 'txt') {
      this.http.get(url, { responseType: 'text' }).subscribe({
        next: (content) => {
          this.onLoadResourceContentSuccess(content)
        },
        error: (error) => {
          this.onLoadResourceContentError(error)
        },
        complete: () => {
          this.onLoadResourceContentComplete()
        },
      });
    } else {
      this.onLoadResourceContentComplete()
    }
  }

  onLoadResourceSuccess(result: any) {
    this.available = true
    this.resource = result
    this.loadResourceContent(result)
  }

  onLoadResourceError(error: any) {
    this.available = false
  }

  onLoadResourceComplete() {
    this.downloading = false
    this.resourceLoaded.emit()
  }

  onLoadResourceContentSuccess(content: any) {
    this.resourceContent = content
  }

  onLoadResourceContentError(error: any) {
    Utils.error(error)
  }

  onLoadResourceContentComplete() {
    this.downloading = false
    this.available = true
  }

  showNotFoundDialog() {
    this.ref = this.dialog.open(ResourceNotFoundComponent, {
      width: '50vw',
      dismissableMask: true
    });
  }

  seeResource() {
    if (this.available || this.resourceUrl) {
      this.showResource = true
    } else {
      this.showNotFoundDialog()
    }
  }
}
