import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-specialty',
  templateUrl: './specialty.component.html',
  styleUrls: ['./specialty.component.sass']
})
export class SpecialtyComponent extends BaseDirective {
  override title = 'FORM.SPECIALTY';
}
