<ng-container [ngSwitch]="platform">
  <div *ngSwitchCase="'youtube'" class="h-full">
    <youtube-player #youtubePlayer class="youtube-player" [videoId]="getYoutubeVideoID(url)"
      [playerVars]="youtubeEmbedParams" (stateChange)="onYoutubePlayerStateChange($event)" (ready)="onYoutubePlayerReady($event)"></youtube-player>
  </div>
  <div *ngSwitchCase="'server'" class="h-full">
    <app-aws-video-player [resourceUrl]="url"></app-aws-video-player>
  </div>
  <div *ngSwitchDefault>
    <p>{{ "ERROR.INVALID-URL" | translate }}</p>
  </div>
</ng-container>