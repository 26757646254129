import { FileUtils } from 'src/app/shared/utils/file-utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {

  transform(bytes: number): string {
    return FileUtils.formatBytes(bytes, 2);
  }

}
