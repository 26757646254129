<form #dataForm="ngForm" class="h-full w-full">
  <div class="flex">
    <p-dropdown
      #dd
      id="test"
      name="test"
      appendTo="body"
      placeholder="{{ 'FORM.SELECT-A-F' | translate }} {{
        ('CORE.TEST' | translate).toLowerCase()
      }}"
      [options]="rows"
      [(ngModel)]="evaluationSelected"
      (onChange)="appendUser(dd.selectedOption)"
      optionLabel="test.poll.title"
      optionValue="el_test_id"
      [filter]="true"
      aria-describedby="id_user-help"
      class="w-full ng-dirty"
    >
      <ng-template let-item pTemplate="selectedItem">
        <div class="text-capitalize">{{ item.test.poll.title }}</div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div class="text-capitalize">{{ item.test.poll.title }}</div>
      </ng-template>
    </p-dropdown>

    <button
      pButton
      pRipple
      type="button"
      (click)="save()"
      label="{{ 'ENV.SAVE' | translate }}"
      styleClass="p-button-success"
    ></button>
  </div>
  <table class="w-full">
    <thead>
      <tr>
        <th style="width: 70px">
          {{ "CORE.TEST" | translate }}
        </th>
        <th style="width: 70px">
          {{ "FORM.ATTEMPTS-ALLOWED" | translate }}
        </th>
        <th style="width: 150px">
          {{ "FORM.REQUIRED-F" | translate }}
        </th>
        <th style="width: 80px">
          {{ "ENV.DELETE" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let t of evaluations">
        <td class="text-capitalize text-center" style="width: 70px">
          {{ t.title }}
        </td>
        <td class="py-1 text-center">
          <p-inputNumber
            styleClass="w-100 text-center"
            [id]="'visitsFee' + t.el_course_unit_test_id"
            [name]="'visitsFee' + t.el_course_unit_test_id"
            [(ngModel)]="t.allowed_attempts"
            [showButtons]="true"
            buttonLayout="horizontal"
            decrementButtonClass="p-button-secondary"
            incrementButtonClass="p-button-secondary"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            [min]="1"
            required
          >
          </p-inputNumber>
        </td>

        <td class="text-center" style="width: 150px">
          <p-radioButton
            name="private"
            value="1"
            [(ngModel)]="t.approval_required"
            inputId="private1"
            class="ng-dirty"
            [required]="true"
          ></p-radioButton>
          <label for="private1" class="ml-2 mr-2">{{
            "GENERAL.YES" | translate
          }}</label>

          <p-radioButton
            name="private"
            value="0"
            [(ngModel)]="t.approval_required"
            inputId="private2"
            class="ng-dirty"
            [required]="true"
          ></p-radioButton>
          <label for="private2" class="ml-2">{{
            "GENERAL.NO" | translate
          }}</label>
        </td>

        <td class="text-center" style="width: 80px">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            class="p-button-text p-button-danger"
            (click)="removeUser(t.el_test_id)"
          ></button>
        </td>
      </tr>

      <tr>
        <td colspan="3"></td>
      </tr>
    </tbody>
  </table>
</form>
