import { Component } from '@angular/core';
import { TestTableComponent } from '../../admin/test/test-table/test-table.component';

@Component({
  selector: 'app-grading-table',
  templateUrl: './grading-table.component.html',
  styleUrls: ['./grading-table.component.sass']
})
export class GradingTableComponent extends TestTableComponent {
  override getList() {
    this.query(this.data.core.testsWithOpenQuestions()).subscribe();
  }
}
