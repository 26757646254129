import { FormattableObjectProperty } from './../../../../../models/AppGenericClasses';
import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-pending-graded-test-table',
  templateUrl: './pending-graded-test-table.component.html',
  styleUrls: ['./pending-graded-test-table.component.sass']
})
export class PendingGradedTestTableComponent extends BaseListDirective {
  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('poll_take.user.first_name,poll_take.user.last_name', 'FORM.MADE-BY-F', 300, { frozen: true }),
    new PrimeNGTableColumn('poll_take.poll.title', 'CORE.TEST', 300),
    new PrimeNGTableColumn('time_to', 'FORM.DATE-TIME', 300, { type: 'DATE-TIME' }),
    new PrimeNGTableColumn('result', 'FORM.RESULT', 150, { type: 'NUMBER' })
  ];
  override title = 'Evaluaciones calificadas';
  override filterableFields = ['poll_take.user.first_name,poll_take.user.last_name', 'poll_take.poll.title']
  override keys = {
    primary_key: 'el_test_id',
    label: 'poll.title'
  }
  override filename = 'MENU.TESTS';
  override dataFormatterProps: FormattableObjectProperty[] = [
    {
      field: 'time_to',
      formats: [
        'DATE-FORMATTED'
      ]
    },
    {
      field: 'result',
      formats: [
        'DECIMAL'
      ]
    }
  ];

  @Input() testId = 0

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    this.testId = params['id'] ?? this.testId
  }

  override getList() {
    this.query(this.data.core.pendingGradedTestTakes(this.testId)).subscribe();
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.testDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }
}
