import { Component, Input } from "@angular/core";
import { BaseDirective } from "src/app/directives/base.directive";


@Component({
  selector: 'app-report-course-user',
  templateUrl: './report-course-user.component.html',
  styleUrls: ['./report-course-user.component.sass']
})
export class ReportCourseUserComponent extends BaseDirective {
  @Input() courseId: number = 0
  override title = 'MENU.PENDING-USERS';

  onInit(): void {
    this.courseId = +this.route.snapshot.paramMap.get('id')
    super.onInit();
  }

  override handleDialogConfigData() {
    super.handleDialogConfigData()
    this.courseId = this.dialogConfig.data.courseId ?? this.courseId
  }
}
