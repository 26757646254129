import { Component } from '@angular/core';

@Component({
  selector: 'app-data-treatment',
  templateUrl: './data-treatment.component.html',
  styleUrls: ['./data-treatment.component.sass']
})
export class DataTreatmentComponent {

}
