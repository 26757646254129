import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass']
})

export class NotificationsComponent extends BaseListDirective {


  refreshActionServices(): void {

  }

  override title = 'MENU.USERS';
  override filterableFields = [];
  override keys = {
    primary_key: 'el_course_id',
    label: 'name_',
  };




  override getList(): void {
    this.query(this.data.core.userNotifications()).subscribe();
  }


  deleteRow(data) {
    this.data.core.userNotificationDelete(data.user_notification_id).subscribe(data => {
      this.utils.toast.deleted('ENV.NOTIFICATIONS');
      this.getList();
    })
  }



}





