import { UtilsService } from "../services/utils/utils.service"
import { AppJSONSanitizer } from "./AppGenericClasses"
import { Test } from "./AppModels"
import { TestJSON } from "./AppSources"

export class AttemptableTestJSON extends TestJSON {
  allowed_attempts = 0

  takes: any[]
}

export class AttemptableTest extends Test {
  allowedAttempts: number
  takesCount_: number
  elTestId: number

  override source: AttemptableTestJSON

  constructor(source: AttemptableTestJSON, utils: UtilsService) {
    super(source, utils)
    this.source = new AppJSONSanitizer<AttemptableTestJSON>(source, AttemptableTestJSON).result
    this.allowedAttempts = this.source.allowed_attempts
    this.elTestId = this.source.el_test_id
    this.takesCount = this.source.takes.length
  }

  set takesCount(value: number) {
    this.takesCount_ = value
  }
  get takesCount() {
    if (this.takesCount_ > this.allowedAttempts) {
      return this.allowedAttempts
    }
    return this.takesCount_
  }

  get takesProgress() {
    if (this.takesCount > this.allowedAttempts) {
      return 100
    }
    return (this.takesCount / this.allowedAttempts) * 100
  }
}
