import { Globals } from 'src/app/globals';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Injectable } from '@angular/core';
import { TopicItemComponent } from 'src/app/components/master/topic/topic-item/topic-item.component';
import { TranslateService } from '@ngx-translate/core';
import { TestPickerTableComponent, TestVersionFormTableComponent, UnitTestPickerTableComponent } from 'src/app/components/controls/test-picker-table/test-picker-table.component';
import { DialogFormData, DialogListData, GenericResponse } from 'src/app/models/AppGenericClasses';
import { TestVersionFormComponent } from 'src/app/components/master/test-version/test-version-form/test-version-form.component';
import { UnitTestFormComponent } from 'src/app/components/master/units/unit-test-form/unit-test-form.component';
import { UnitTestAddDataFormComponent } from 'src/app/components/master/units/unit-test-add-data-form/unit-test-add-data-form.component';
import { CourseUserPickerTableComponent, TestVersionUserPickerTableComponent } from 'src/app/components/controls/user-picker-table/user-picker-table.component';
import { ContentPickerTableComponent } from 'src/app/components/controls/content-picker-table/content-picker-table.component';
import { ContentFormComponent } from 'src/app/components/master/content/content-form/content-form.component';
import { TestQuestionWeightDetailComponent } from 'src/app/components/shared/test-question-weight-detail/test-question-weight-detail.component';
import { CourseItemEnrolledUsersComponent } from 'src/app/components/master/course/course-item/course-item-enrolled-users/course-item-enrolled-users.component';
import { ReportCourseUserComponent } from 'src/app/components/master/report/report-courses/report-course-table/report-course-user/report-course-user.component';

@Injectable({
  providedIn: 'root'
})
export class AppDialogService {

  constructor(
    private dialog: DialogService,
    private translate: TranslateService,
  ) { }

  open(
    ref: DynamicDialogRef,
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {}
  ) {
    ref.onClose.subscribe((res: GenericResponse) => {
      console.log(res);

      if (res?.success) {
        console.log(res);
        optional.onSuccess?.(res);
      } else {
        optional.onError?.(res);
      }
      optional.onComplete?.();
    })
  }

  seeTopic(topicId: number) {
    this.dialog.open(TopicItemComponent, {
      header: this.translate.instant('CORE.TOPIC'),
      data: {
        showActions: false,
        itemId: topicId
      },
      dismissableMask: true
    })
  }

  seeTestPickerTableDialog(
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    extraData: DialogListData = {}
  ) {
    this.open(
      this.dialog.open(TestPickerTableComponent, {
        data: {
          multiple: true,
          ...extraData
        } as DialogListData,
        ...Globals.DIALOG_CONFIG.CONTAINER
      }), optional
    )
  }

  seeTestVersionFormTableDialog(
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    extraData: DialogListData = {}
  ) {
    this.open(
      this.dialog.open(TestVersionFormTableComponent, {
        data: {
          multiple: false,
          ...extraData
        } as DialogListData,
        ...Globals.DIALOG_CONFIG.CONTAINER
      }), optional
    )
  }

  seeTestVersionFormDialog(
    elTestId: number,
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    config: DynamicDialogConfig = {},
    extraData: DialogFormData = {}
  ) {
    this.open(
      this.dialog.open(TestVersionFormComponent, {
        data: {
          el_test_id: elTestId,
          redirect_on_save: false,
          ...extraData
        } as DialogFormData,
        ...Globals.DIALOG_CONFIG.CONTAINER,
        ...config
      }), optional
    )
  }

  seeUnitTestPickerTableDialog(
    unitId: number,
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    extraData: DialogListData = {}
  ) {
    this.open(
      this.dialog.open(UnitTestPickerTableComponent, {
        data: {
          el_course_unit_id: unitId,
          multiple: false,
          ...extraData
        } as DialogListData,
        ...Globals.DIALOG_CONFIG.CONTAINER
      }), optional
    )
  }

  seeUnitTestAddDataFormDialog(
    elTestId: number,
    unitId: number,
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    config: DynamicDialogConfig = {},
    extraData: DialogFormData = {}
  ) {
    this.open(
      this.dialog.open(UnitTestAddDataFormComponent, {
        data: {
          el_test_id: elTestId,
          el_course_unit_id: unitId,
          redirect_on_save: false,
          ...extraData
        } as DialogFormData,
        ...Globals.DIALOG_CONFIG.CONTAINER,
        ...config
      }), optional
    )
  }

  seeCourseUnitTestFormDialog(
    unitId: number,
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    extraData: DialogFormData = {}
  ) {
    this.open(
      this.dialog.open(UnitTestFormComponent, {
        data: {
          el_course_unit_id: unitId,
          redirect_on_save: false,
          ...extraData
        } as DialogFormData,
        ...Globals.DIALOG_CONFIG.CONTAINER
      }), optional
    )
  }

  seeTestQuestionWeightDetailDialog(
    pollId: number,
    questionId: number,
    currentWeight: number,
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    extraData: DialogListData = {}
  ) {
    this.open(
      this.dialog.open(TestQuestionWeightDetailComponent, {
        data: {
          pollId,
          questionId,
          currentWeight,
          ...extraData
        } as DialogListData,
        ...Globals.DIALOG_CONFIG.CONTAINER
      }), optional
    )
  }

  seeContentFormDialog(
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    extraData: DialogFormData = {}
  ) {
    this.open(
      this.dialog.open(ContentFormComponent, {
        data: {
          redirect_on_save: false,
          ...extraData
        },
        ...Globals.DIALOG_CONFIG.CONTAINER,
        maximizable: true
      }), optional
    )
  }

  seeCourseEnrollmentsDialog(courseId: number) {
    this.open(
      this.dialog.open(CourseItemEnrolledUsersComponent, {
        ...Globals.DIALOG_CONFIG.CONTAINER,
        data: {
          courseId
        }
      })
    )
  }

  seePendingCourseUsersDialog(courseId: number) {
    this.open(
      this.dialog.open(ReportCourseUserComponent, {
        ...Globals.DIALOG_CONFIG.CONTAINER,
        data: {
          courseId
        }
      })
    )
  }

  ///// USER PICKERS

  seeTestVersionUserPickerTableDialog(
    pollId: number,
    pollTitle: string,
    optional?: {
      onSuccess?: (data: any) => void;
      onError?: (error: any) => void;
      onComplete?: () => void;
    }
  ) {
    const header = this.translate.instant(
      'FORM.AVAILABLE-USERS-IN-X', {
      value: pollTitle
    })
    this.open(
      this.dialog.open(TestVersionUserPickerTableComponent, {
        header,
        data: {
          parentId: pollId
        },
        ...Globals.DIALOG_CONFIG.CONTAINER
      }), optional)
  }

  seeCourseUserAvailableDialog(courseId: number, courseTitle: string, onlyAvailable = false, optional?: {
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    onComplete?: () => void;
  }) {
    const header = this.translate.instant(
      'FORM.AVAILABLE-USERS-IN-X', {
      value: courseTitle
    })
    this.open(
      this.dialog.open(CourseUserPickerTableComponent, {
        header,
        width: '80%',
        height: '100%',
        styleClass: 'p-overflow-hidden',
        contentStyle: { height: '100%', overflow: 'hidden', padding: '.3rem' },
        data: {
          parentId: courseId,
          onlyAvailable
        }
      }), optional
    )
  }

  ///// CONTENT PICKERS

  seeContentPicker(
    titleComplement: string,
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {},
    extraData: DialogListData = {}
  ) {
    this.open(
      this.dialog.open(ContentPickerTableComponent, {
        header: this.translate.instant(
          'FORM.ADD-CONTENT-IN-X',
          { value: titleComplement }
        ),
        ...Globals.DIALOG_CONFIG.CONTAINER,
        data: {
          ...extraData
        } as DialogListData
      }), optional)
  }
}
