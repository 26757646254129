import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-document-type-table',
  templateUrl: './document-type-table.component.html',
  styleUrls: ['./document-type-table.component.sass']
})
export class DocumentTypeTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('abbreviation', 'FORM.ABBREVIATION', 500),
    new PrimeNGTableColumn('name_', 'FORM.TITLE', 500, { frozen: true })

  ];
  override title = 'MENU.DOCUMENTS-TYPES';
  override filterableFields = ['name_', 'abbreviation']
  override keys = {
    primary_key: 'dni_type_id',
    label: 'name_'
  }
  override filename = 'MENU.DOCUMENTS-TYPES';

  override getList(): void {
    this.query(this.data.core.DNITypes()).subscribe()
  }

  override handleRows(data: any): void {
    super.handleRows(data);
    console.log(data);
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.DNITypeDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }
}
