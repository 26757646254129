import { Component, Input } from '@angular/core';
import { TestVersionTableComponent } from '../../master/test-version/test-version-table/test-version-table.component';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-available-test-version-list',
  templateUrl: './available-test-version-list.component.html',
  styleUrls: ['./available-test-version-list.component.sass']
})
export class AvailableTestVersionListComponent extends TestVersionTableComponent {

  @Input() containerStyleClass = "h-full"
  @Input() horizontal = false

  override getList() {
    this.query(this.data.core.testsVersionsOfUser()).subscribe();
  }

  handleRows(data: any[]) {
    console.log(data);
    data = data.filter(row => !(row.course_unit_tests_count > 0))
    data.forEach(test => {
      const testTakes = test.takes.map(tTake => {
        tTake.result = Utils.floatStringToNumber(tTake.take.result)
        return tTake
      })

      test.hasTakes = !!testTakes.length
      const testMinApprovalPerc = Utils.floatStringToNumber(test.test.min_approval_percentage)
      test.highestRating = Utils.sortByProperty(testTakes, 'result', 'desc')[0]?.result
      test.approved = test.highestRating >= testMinApprovalPerc
      test.remainingAttempts = test.allowed_attempts - testTakes.length
      test.isComplete = !test.approval_required || (testTakes.length && test.approved)

      const takesProgress = () => {
        if (test.takes.length > test.allowed_attempts) {
          return 100
        }
        return (test.takes.length / test.allowed_attempts) * 100
      }

      test.takesProgress = takesProgress()
    })
    super.handleRows(data)
  }
}
