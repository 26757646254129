/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { ObjectFormatter } from 'src/app/models/ObjectFormatter';

@Component({
  selector: 'app-test-take-detail',
  templateUrl: './test-take-detail.component.html',
  styleUrls: ['./test-take-detail.component.sass']
})
export class TestTakeDetailComponent extends BaseItemDirective {
  @Input() showResult = true
  override showActions = false
  override itemFormatter: ObjectFormatter = this.utils.getTestTakeFormatter();
  pollTake: any = null

  override getItem() {
    this.itemService = this.data.core.viewTakeDetail(this.itemId)
    super.getItem()
  }

  override onItemReady(): void {
    this.pollTake = this.item.poll_take
  }

  override refreshActionServices(): void {
  }
}
