import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-city-table',
  templateUrl: './city-table.component.html',
  styleUrls: ['./city-table.component.sass']
})
export class CityTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'CORE.CITY', 1000, { frozen: true }),
  ];
  override title = 'MENU.CITIES';
  override filterableFields = ['name_']
  override keys = {
    primary_key: 'city_id',
    label: 'name_'
  }
  override filename = 'MENU.CITIES';

  override getList(): void {
    this.query(this.data.core.cities()).subscribe()
  }

  override handleRows(data: any): void {
    super.handleRows(data);
    console.log(data);
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.cityDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }
}
