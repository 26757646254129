import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from "../../services/auth/token.service";

export const authGuard = () => {
  const tokenService = inject(TokenService);
  const router = inject(Router);
  if (tokenService.loggedIn.value) {
    return true
  }
  return router.parseUrl('/auth/sign-in');
}

export const unAuthGuard = () => {
  const tokenService = inject(TokenService);
  const router = inject(Router)
  if (!tokenService.loggedIn.value) {
    return true
  }
  return router.parseUrl('/');
}
