<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">{{ title | translate | uppercase }}</h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-success"
        icon="pi pi-plus"
        label="{{ 'ENV.NEW' | translate }} {{
          'CORE.TOPIC' | translate | lowercase
        }}"
        routerLink="/topics/create"
        *ngIf="userType.could_manage_topics"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-wrap gap-2 align-items-center mb-2">
    <button
      pButton
      pRipple
      class="p-button-text p-button-primary"
      icon="pi pi-filter"
      label="{{ 'ENV.FILTER' | translate }}"
      (click)="seeCustomFilters()"
    ></button>
    <span>
      {{ "ENV.APPLIED-FILTERS" | translate }}:
      {{ itemList.filter.appliedFilters }}
    </span>
  </div>
  <div class="flex flex-grow-1">
    <div class="flex w-12 lg:w-9 relative">
      <div class="absolute h-full w-full">
        <p-scrollPanel
          [style]="{ width: '100%', height: '100%' }"
          *ngIf="!loading; else loadingTemplate"
        >
          <app-topic-item
            [source]="row.source"
            (itemSelected)="handleItemSelected($event)"
            (itemDisabled)="getList()"
            *ngFor="let row of itemList.rows"
          ></app-topic-item>
        </p-scrollPanel>
      </div>
    </div>
    <p-divider
      class="hidden lg:flex"
      layout="vertical"
      type="dashed"
    ></p-divider>
    <div
      class="flex-grow-1 hidden lg:flex relative"
      *ngIf="itemList.itemSelected"
    >
      <div class="flex flex-column h-full absolute">
        <div class="flex flex-column">
          <h4 class="my-0">{{ "MENU.COURSES" | translate }}</h4>
          <h3 class="mb-0">{{ itemList.itemSelected.name | translate }}</h3>
          <p-divider styleClass="divider-slim"></p-divider>
        </div>
        <div class="h-full w-full overflow-hidden">
          <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
            <app-topic-courses
              *ngIf="itemList.itemSelected.id"
              [topicId]="itemList.itemSelected.id"
            ></app-topic-courses>
          </p-scrollPanel>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="showCustomFilters" appendTo="body">
  <ng-template pTemplate="header">
    <h3>{{ "ENV.FILTER" | translate }} | {{ "MENU.TOPICS" | translate }}</h3>
  </ng-template>
  <ng-container>
    <div
      class="flex flex-column gap-2 mb-3"
      *ngIf="itemList.filter.colorFilter"
    >
      <span class="p-float-label">
        <p-multiSelect
          id="color"
          name="color"
          [options]="itemList.filter.colorFilter.options"
          [(ngModel)]="itemList.filter.colorFilter.value"
          [filter]="true"
          optionValue="value"
          optionLabel="name"
          (ngModelChange)="onCustomFilter()"
          appendTo="body"
        >
          <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
              <app-color-chip
                [color]="option.value.source"
                [content]="option.value.name_"
              ></app-color-chip>
            </div>
          </ng-template>
          <ng-template let-selected pTemplate="selectedItems">
            <div
              class="flex flex-wrap align-items-center gap-2 px-1"
              style="min-height: 20px"
            >
              <app-color-chip
                *ngFor="let item of selected"
                [color]="item.source"
                [content]="item.name_"
              ></app-color-chip>
            </div>
          </ng-template>
        </p-multiSelect>
        <label for="color">{{ "MENU.COLORS" | translate }}</label>
      </span>
    </div>
  </ng-container>
  <ng-template pTemplate="footer">
    <button pButton (click)="cleanCustomFilters()">Limpiar filtros</button>
  </ng-template>
</p-sidebar>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
