import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
  selector: 'app-drag-drop[required="true"]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DragDropValidatorDirective,
      multi: true
    }
  ]
})
export class DragDropValidatorDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    const files = control.value
    if (!files || files.length <= 0) {
      return {
        required: 'Please select a file'
      }
    }
    return null
  }

}
