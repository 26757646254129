<div class="box box-elevation border-round-xl px-3 pb-2 mb-3">
  <div class="flex align-items-center">
    <div class="flex-grow-1">
      <h3 class="pt-2">Unidad #{{ idx + 1 }} | {{ item.name_ }}</h3>
    </div>
    <div class="flex" *ngIf="showActions">
      <button
        pButton
        pRipple
        icon="pi pi-pencil"
        pTooltip="{{ 'ENV.EDIT' | translate }}"
        routerLink="/courses/units/edit/{{ item.el_course_unit_id }}"
        class="p-button-text p-button-raised mr-2"
      ></button>
      <button
        pButton
        pRipple
        icon="pi pi-ellipsis-h"
        class="p-button-text p-button-raised p-button-info"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
    </div>
  </div>
  <div class="flex justify-content-between mb-2">
    <span>
      {{ item.description_ }}
    </span>
    <div class="font-bold p-text-secondary font-italic">
      {{ "FORM.ESTIMATED-DURATION" | translate }}:
      <span *ngIf="item?.duration?.hours > 0">
        {{ item?.duration?.hours }}
        {{ "FORM.HOURS" | translate | lowercase }}
      </span>
      <span *ngIf="item?.duration?.minutes > 0">
        {{ item?.duration?.minutes }}
        {{ "FORM.MINUTES" | translate | lowercase }}
      </span>

    </div>
  </div>

  <p-accordion
    *ngFor="let section of item.sections; index as sectionIdx"
    styleClass="mb-1"
  >
    <p-accordionTab contentStyleClass="pt-0">
      <ng-template pTemplate="header">
        <span class="flex align-items-center gap-2 w-full">
          <app-course-unit-section-content-item
            [item]="section.resource_content"
            [fireActions]="fireActions"
            [showName]="false"
          ></app-course-unit-section-content-item>
          <span class="font-bold white-space-nowrap">{{
            "Clase #" + (sectionIdx + 1) + " | " + section.name_
          }}</span>
        </span>
      </ng-template>
      <div class="ml-2">
        <div class="mb-4">
          <div [innerHTML]="section.content"></div>
          <app-course-unit-section-contents
            [item]="section"
            [fireActions]="fireActions"
          ></app-course-unit-section-contents>
        </div>
        <div class="font-bold p-text-secondary font-italic">
          {{ "FORM.ESTIMATED-DURATION" | translate }}:
          <span *ngIf="section?.duration?.hours > 0">
            {{ section?.duration?.hours }}
            {{ "FORM.HOURS" | translate | lowercase }}
          </span>
          <span *ngIf="section?.duration?.minutes > 0">
            {{ section?.duration?.minutes }}
            {{ "FORM.MINUTES" | translate | lowercase }}
          </span>
          <span *ngIf="section?.duration?.minutes <= 0 && section?.duration?.hours <= 0">
            {{ section?.duration?.seconds }}
            {{ "FORM.SECONDS" | translate | lowercase }}
          </span>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
  <p-accordion
    *ngFor="let unitTest of item.tests; index as testIdx"
    styleClass="mb-1"
  >
    <p-accordionTab contentStyleClass="pt-0">
      <ng-template pTemplate="header">
        <span class="flex align-items-center gap-2 w-full">
          <span class="material-symbols-outlined" style="font-size: 2rem">
            quiz
          </span>
          <span class="font-bold white-space-nowrap">{{
            "Evaluación #" + (testIdx + 1) + " | " + unitTest.test?.poll?.title
          }}</span>
        </span>
      </ng-template>
      <app-course-test-summary
        [showName]="false"
        [item]="unitTest"
      ></app-course-test-summary>
    </p-accordionTab>
  </p-accordion>
</div>
