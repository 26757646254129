<div class="flex flex-column h-full" *ngIf="course">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">
        {{ title | translate | uppercase }} |
        {{ course.name_ | translate }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-primary"
        icon="pi pi-plus"
        label="{{ 'FORM.ADD-TO-COURSE' | translate }}"
        (click)="seeCourses()"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <app-course-prerrequisite-table
          #primeNgList
          [courseId]="courseId"
          (inactivationSuccess)="handlePrerequisiteDelete($event)"
        ></app-course-prerrequisite-table>
      </div>
    </div>
  </div>
</div>
