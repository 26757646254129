<vg-player style="width: fit-content" class="mx-auto" (onPlayerReady)="onPlayerReady($event)">
  <vg-overlay-play></vg-overlay-play>
  <vg-buffering></vg-buffering>

  <vg-scrub-bar>
    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
  </vg-scrub-bar>

  <vg-controls>
    <vg-play-pause></vg-play-pause>
    <vg-playback-button></vg-playback-button>

    <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

    <vg-scrub-bar style="pointer-events: none"></vg-scrub-bar>

    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

    <vg-mute></vg-mute>
    <vg-volume></vg-volume>

    <vg-fullscreen></vg-fullscreen>
  </vg-controls>
  <video #mediaPlayer [vgMedia]="mediaPlayer" [src]="resource" preload="auto" (play)="onPlayerPlay($event)"
    (pause)="onPlayerPause($event)"></video>
</vg-player>