import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-color-table',
  templateUrl: './color-table.component.html',
  styleUrls: ['./color-table.component.sass']
})
export class ColorTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.COLOR', 150, { frozen: true }),
    new PrimeNGTableColumn('surface_dark', 'FORM.COLOR-SURFACE-DARK', 150),
    new PrimeNGTableColumn('text_color_dark', 'FORM.COLOR-SURFACE-LIGHT', 150),
    new PrimeNGTableColumn('surface_light', 'FORM.COLOR-TEXT-DARK', 150),
    new PrimeNGTableColumn('text_color_light', 'FORM.COLOR-TEXT-LIGHT', 150)
  ];
  override title = 'MENU.COLORS';
  override filterableFields = ['name_']
  override keys = {
    primary_key: 'color_id',
    label: 'name_'
  }
  override filename = 'MENU.COLORS';

  override getList(): void {
    this.query(this.data.core.colors()).subscribe()
  }

  override handleRows(data: any): void {
    super.handleRows(data);
    console.log(data);
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.colorDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }
}
