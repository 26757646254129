<div class="flex flex-1 flex-column px-6" *ngIf="validating">
  <div class="flex justify-content-center mb-2">
    <img src="assets/images/logo.png" alt="Logo" style="max-width: 100%; max-height: 120px">
  </div>
  <div class="flex justify-content-center" style="height: 140px">
    <ng-lottie [options]="options"></ng-lottie>
  </div>
  <div class="flex justify-content-center">
    {{ 'AUTH.AUTHENTICATING' | translate }}
  </div>
</div>
<div class="flex flex-1 flex-column px-6" *ngIf="!validating">
  <div class="flex flex-1 flex-column">
    <div class="flex justify-content-center flex-1">
      <h3>{{ 'AUTH.VERIFY-ACCOUNT' | translate }}</h3>
    </div>
    <form #loginForm=ngForm (ngSubmit)="onSubmit()">
      <div *ngIf="dataError <= 0">
        <div class="field text-center">
          <input type="text" pInputText [(ngModel)]="code" required class="text-center w-100" id="code"
                 name="code" placeholder="">
        </div>
        <button
          pButton
          type="submit"
          label="{{ 'AUTH.VERIFY' | translate }}"
          class="flex flex-1 w-full"
          [disabled]="!loginForm.valid"
          *ngIf="!validating">
        </button>
      </div>
      <p-messages severity="error" *ngIf="dataError===-1">
        <ng-template pTemplate>
          <div class="w-100 text-center">{{ 'ERROR.AWS-CODE' | translate }}</div>
        </ng-template>
      </p-messages>
      <div *ngIf="dataError===1">
        <p-messages severity="success">
          <ng-template pTemplate>
            <div class="w-100 text-center">{{ 'AUTH.VERIFY-ACCOUNT-DONE' | translate }}</div>
          </ng-template>
        </p-messages>
        <div class="w-100 text-center">
          <a routerLink="/" class="p-button p-button-link px-0">{{ 'AUTH.GO-TO-SIGN-IN' | translate }}</a>
        </div>
      </div>
      <p-messages severity="error" *ngIf="dataError===-2">
        <ng-template pTemplate>
          <div class="w-100 text-center">{{ 'ERROR.SERVER' | translate }}</div>
        </ng-template>
      </p-messages>
    </form>
    <div class="w-100 p-2 flex resend-frame mt-2" *ngIf="dataError <= 0">
      <div class="flex-auto">
        <button
          pButton
          label="{{ 'AUTH.RESEND-CODE' | translate }}"
          class="flex flex-1 p-button-text"
          [disabled]="!couldResendCode"
          *ngIf="!validating"
          (click)="resendCode()">
        </button>
      </div>
      <div class="flex flex-wrap align-items-center justify-content-center">
        <span class="vertical-align-middle">00:{{ printSeconds() }}</span>
      </div>
    </div>
    <div class="row" *ngIf="validating">
      <div class="col">
        <div class="form-group text-center pt-1">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-vmo" role="progressbar"
                 aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
