/* eslint-disable @typescript-eslint/no-empty-function */
import { AppFormatter } from './../models/AppGenericClasses';
import { Observable } from 'rxjs';
import { BaseDirective } from './base.directive';
import { Directive, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Utils } from '../shared/utils/utils';
import { ObjectFormatter } from '../models/ObjectFormatter';
import { Params } from '@angular/router';

@Directive({
  selector: '[appBaseItem]',
})
export abstract class BaseItemDirective<T = any> extends BaseDirective {
  @Input() itemId = 0
  @Input() item: T = null;
  @Input() showName = true;
  @Input() showDescription = true;
  @Input() showActions = true;
  @Input() fireActions = true;
  @Input() presentation = 1;
  @Output() requestReload: EventEmitter<any> = new EventEmitter();
  @Output() itemDisabled: EventEmitter<boolean> = new EventEmitter(true);
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  canInactivate = true
  keys = {
    primary_key: '',
    label: ''
  }
  inactivationService: Observable<any> | undefined;
  itemService: Observable<any> | undefined;
  itemFormatter: ObjectFormatter

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    this.itemId = params["id"] ?? this.itemId
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.itemId = this.dialogConfig.data.itemId ?? this.itemId
    this.item = this.dialogConfig.data.item ?? this.item
    this.showActions = this.dialogConfig.data.showActions ?? this.showActions
  }

  override onLanguageInit(): void {
    super.onLanguageInit()
    if (this.itemId > 0) {
      this.getItem()
    } else {
      this.item = this.getItemFormatted(this.item)
    }
  }

  getItem() {
    if (this.itemService) {
      this.utils.executeRequest(
        this.itemService, {
        onSuccess: (data) => {
          this.handleGetItemSuccess(data)
        }
      })
    }
  }

  getItemFormatted(data: T) {
    if (this.itemFormatter) {
      return new AppFormatter<T>([data], this.utils, (data: T[]) => {
        this.utils.applyFormat(data, this.itemFormatter)
      }).dataFormatted[0]
    } else {
      return data
    }
  }

  inactivate(msgComplement = ""): void {
    if (this.canInactivate) {
      let message = Utils.nestedValue(this.keys.label, this.item)

      if (msgComplement) {
        message += " " + this.utils.translate.instant(msgComplement)
      }

      this.utils.fireConfirmation({
        message: this.utils.translate.instant('QUESTION.INACTIVATE', { value: message })
      },
        () => {
          this.utils.showLoad();
          this.inactivationService?.subscribe({
            next: () => this.handleInactivationSuccess(),
            error: (err) => this.handleChangeStatusError(err),
            complete: () => this.handleChangeStatusComplete()
          });
        });
    } else {
      this.handleInactivationProhibition()
    }
  }

  handleInactivationProhibition() { }

  handleInactivationSuccess(): void {
    this.utils.toast.deleted(this.title);
    this.itemDisabled.emit(false);
  }

  handleChangeStatusError(error: any): void {
    Utils.error(error);
    this.utils.toast.error();
  }

  handleChangeStatusComplete(): void {
    this.utils.hideLoad();
  }

  handleItemSelected(): void {
    this.itemSelected.emit(this.item);
  }

  handleGetItemSuccess(data: any) {
    this.item = this.getItemFormatted(data)
    this.onItemReady()
  }

  abstract refreshActionServices(): void

  override manageChanges(changes: SimpleChanges): void {
    super.manageChanges(changes);
    if ('item' in changes) {
      this.item = this.getItemFormatted(this.item)
      this.onItemReady()
      this.refreshActionServices();
    }
  }

  onItemReady() { }
}
