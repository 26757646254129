<div class="flex flex-column h-full">
  <div class="flex mb-2">
    En base al peso <span class="font-bold ml-1">{{ this.currentWeight }}</span>
    , estos son los nuevos pesos para todas las preguntas:
  </div>
  <div class="flex-grow overflow-hidden h-full">
    <p-table
      [columns]="columns"
      [value]="itemList.rows"
      [loading]="loading"
      [rows]="rowsPerPage"
      styleClass="p-datatable-gridlines"
      [scrollable]="true"
      scrollHeight="flex"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th
              [pSortableColumn]="col.sortable"
              pFrozenColumn
              [frozen]="col.frozen"
              pResizableColumn
              [pResizableColumnDisabled]="!col.resizable"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <div
                class="flex align-items-center justify-content-between gap-2"
                [style.width]="col.width + 'px'"
              >
                <div class="flex-grow overflow-hidden">
                  <span
                    class="ellipsis block"
                    pTooltip="{{ col.header | translate }}"
                    tooltipPosition="bottom"
                  >
                    {{ col.header | translate }}
                  </span>
                </div>
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr>
          <ng-container *ngFor="let col of columns">
            <td
              pFrozenColumn
              [frozen]="col.frozen"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <ng-container [ngSwitch]="col.field">
                <div *ngSwitchCase="'poll.review'">
                  <app-text-wrapper [text]="row.poll.review"></app-text-wrapper>
                </div>
                <div *ngSwitchDefault style="white-space: pre-line">
                  {{ col.field | ngCellValue : row }}
                </div>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ "WIDGET.DT-EMPTY" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
