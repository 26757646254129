import { Component } from '@angular/core';
import { Params } from '@angular/router';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-course-preview-wrapper',
  templateUrl: './course-preview-wrapper.component.html',
  styleUrls: ['./course-preview-wrapper.component.sass']
})
export class CoursePreviewWrapperComponent extends BaseDirective {
  courseId = 0;
  isStudying = 0;
  userLogged = this.utils.global.user.user_id
  // 
  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('id' in params) {
      this.courseId = +params['id']
    }
    this.utils.executeRequest(this.data.core.enrolledUserinCourse(
      this.courseId, this.userLogged
    ),
      {
        onSuccess: (data) => {
          this.isStudying = data
        },
      })

  }
}
