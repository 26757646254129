import { Component } from '@angular/core';
import { BaseItemListDirective } from 'src/app/directives/restruct/base-item-list.directive';
import { TopicList } from 'src/app/models/AppLists';
import { Topic } from 'src/app/models/AppModels';
import { TopicJSON } from 'src/app/models/AppSources';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.sass'],
})
export class TopicComponent extends BaseItemListDirective<Topic, TopicJSON, TopicList> {
  override title = 'MENU.TOPICS'
  override ListModel = TopicList;

  override getList() {
    this.query(this.data.core.topics()).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.topicDelete(
      this.itemList.itemSelected.id
    )
  }
}
