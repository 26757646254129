import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-unit-test-add-data-form',
  templateUrl: './unit-test-add-data-form.component.html',
  styleUrls: ['./unit-test-add-data-form.component.sass']
})
export class UnitTestAddDataFormComponent extends BaseFormDirective {

  override title = 'MENU.TESTS'

  override form = {
    el_test_id: 0,
    el_course_unit_id: 0,
    allowed_attempts: null,
    approval_required: 0
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.form.el_test_id = this.dialogConfig.data.el_test_id ?? this.form.el_test_id;
    this.form.el_course_unit_id = this.dialogConfig.data.el_course_unit_id ?? this.form.el_course_unit_id;
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.unitTestCreate(this.formSanitized)
  }
}
