import { AwsUploadedFileListComponent } from './../../../shared/aws-uploaded-file-list/aws-uploaded-file-list.component';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ViewChild } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';
import { Params } from '@angular/router';
import { ImageUploadComponent } from 'src/app/components/controls/image-upload/image-upload.component';
import { BaseFileUploaderDirective } from 'src/app/directives/controls/base-file-uploader.directive';
import { AppChronometer, AppTimer, ContentType } from 'src/app/models/AppGenericClasses';
import { Utils } from 'src/app/shared/utils/utils';
import { LibraryDetailComponent } from 'src/app/components/shared/library-detail/library-detail.component';
import { TestQuestionDetailComponent } from 'src/app/components/shared/test-question-detail/test-question-detail.component';

@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
  styleUrls: ['./content-form.component.sass']
})
export class ContentFormComponent extends BaseFormDirective {
  @ViewChild('iuUploadedFileList') iuUploadedFileList!: AwsUploadedFileListComponent
  @ViewChild('iuCover') iuCover!: ImageUploadComponent
  @ViewChild(BaseFileUploaderDirective) iuFileUploader!: BaseFileUploaderDirective
  override title = 'MENU.CONTENTS'

  override form = {
    name_: null,
    description_: null,
    type_: '' as ContentType,
    content: '',
    transcription: null,
    tags: '',
    module: 'EL',
    min_viewing_time: 0,
    private: 0,
    last_cover_update: null,
    last_resource_update: null,
    parent_library_id: null,
    parent_test_question_id: null,
    parent_unit_section_id: null,
    parent_target_type: null
  }
  chronometer: AppChronometer = new AppChronometer({ hours: 0, minutes: 0, seconds: 0 })

  tags: never[] = []
  resourceId = 0
  currentResource = null
  contentPlain = ''
  contentURL = ''
  contentVideoURL = ''
  showContentPlain = false

  path = 'content'
  files: File[] = []

  parentLibraryId = 0
  parentLibrary = null

  parentTestQuestionId = 0
  parentTestQuestion = null

  parentUnitSectionId = 0
  parentUnitSection = null

  parentTargetType: string = null

  contentTypeOptions: any[] = [
    { label: 'FORM.AUDIO', value: 'AUDIO' },
    { label: 'FORM.VIDEO', value: 'VIDEO' },
    { label: 'FORM.PDF', value: 'PDF' },
    { label: 'FORM.IMG', value: 'IMG' },
    { label: 'FORM.CONTENT', value: 'PLAIN' },
    { label: 'FORM.URL', value: 'URL' },
    { label: 'FORM.EXTERNAL-VIDEO', value: 'VIDEO-URL' }
  ];

  seeLibrary() {
    this.dialog.open(LibraryDetailComponent, {
      header: this.utils.translate.instant('CORE.LIBRARY'),
      data: {
        item: this.parentLibrary
      },
      dismissableMask: true
    })
  }

  seeTestQuestion() {
    this.dialog.open(TestQuestionDetailComponent, {
      data: {
        itemId: this.parentTestQuestionId
      },
      dismissableMask: true
    })
  }

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params)
    if ('parentLibraryId' in params) {
      this.parentLibraryId = params['parentLibraryId']
    }
    if ('parentTestQuestionId' in params) {
      this.parentTestQuestionId = params['parentTestQuestionId']
    }
    if ('parentUnitSectionId' in params) {
      this.parentUnitSectionId = params['parentUnitSectionId']
    }
    if ('parentTargetType' in params) {
      this.parentTargetType = params['parentTargetType']
    }
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.parentLibraryId = this.dialogConfig.data.parentLibraryId ?? this.parentLibraryId
    this.parentTestQuestionId = this.dialogConfig.data.parentTestQuestionId ?? this.parentTestQuestionId
    this.parentUnitSectionId = this.dialogConfig.data.parentUnitSectionId ?? this.parentUnitSectionId
    this.parentTargetType = this.dialogConfig.data.parentTargetType ?? this.parentTargetType
  }

  override onLanguageInit(): void {
    super.onLanguageInit()
    if (this.parentLibraryId > 0) {
      this.utils.executeRequest(
        this.data.core.libraryUniqueBasic(this.parentLibraryId), {
        onSuccess: (data) => {
          this.parentLibrary = data
        }
      })
    }
    if (this.parentTestQuestionId > 0) {
      this.utils.executeRequest(
        this.data.core.testQuestionUnique(this.parentTestQuestionId), {
        onSuccess: (data) => {
          this.parentTestQuestion = data
        }
      })
    }
    if (this.parentUnitSectionId > 0) {
      this.utils.executeRequest(
        this.data.core.sectionUnique(this.parentUnitSectionId), {
        onSuccess: (data) => {
          this.parentUnitSection = data
        }
      })
    }
  }

  override getItemEdit() {
    this.editService = this.data.core.contentUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.contentCreate(this.formSanitized)
    this.putService = this.data.core.contentUpdate(this.editId, this.formSanitized)
  }

  override handleItemEditSuccess(data: any) {
    super.handleItemEditSuccess(data)
    const tags = data['tags']
    this.tags = tags ? tags.split(',') : []
    switch (this.form.type_) {
      case 'PLAIN':
        this.contentPlain = this.form.content
        break;
      case 'URL':
        this.contentURL = this.form.content
        break;
      case 'VIDEO-URL':
        this.contentVideoURL = this.form.content
        break;
    }
    const timerModel = Utils.convertSecondsToHMS(this.form.min_viewing_time);
    this.chronometer = new AppChronometer(timerModel)

    console.log(this.form);

  }

  onContentTypeChange() {

    switch (this.form.type_ as ContentType) {
      case 'AUDIO':
      case 'VIDEO':
      case 'PDF':
      case 'IMG':
        if (this.iuUploadedFileList && this.editId > 0) {
          this.iuUploadedFileList.field = this.form.type_
          this.iuUploadedFileList.loadResources()
        }
        break;
      case 'URL':
      case 'VIDEO-URL':
        this.form.min_viewing_time = 0;
        this.chronometer.reset()
        break;
      default:
        break;
    }
    this.files = []
  }

  override onSubmit() {

    this.form.tags = this.tags.join(',')
    switch (this.form.type_ as ContentType) {
      case 'PLAIN':
        this.form.content = this.contentPlain
        break;
      case 'URL':
        this.form.content = this.contentURL
        break;
      case 'VIDEO-URL':
        this.form.content = this.contentVideoURL
    }
    if (this.iuCover.files.length > 0) {
      this.form.last_cover_update = new Date()
    }

    this.form.last_resource_update = new Date()

    if (this.parentLibrary) {
      this.form.parent_library_id = this.parentLibraryId
    }
    if (this.parentTestQuestion) {
      this.form.parent_test_question_id = this.parentTestQuestionId
    }
    if (this.parentUnitSectionId) {
      this.form.parent_unit_section_id = this.parentUnitSectionId
    }
    if (this.parentTargetType) {
      this.form.parent_target_type = this.parentTargetType
    }
    this.form.min_viewing_time = Utils.getTimerSeconds(this.chronometer)

    super.onSubmit()
  }

  override cleanForm() {
    super.cleanForm()
    this.tags = []
    this.files = []
    this.iuCover.image = null
  }

  override handleSave(data: any): void {
    if (this.iuCover) {
      this.iuCover.upload(data)
        .then(() => {
          if (this.iuFileUploader) {
            this.iuFileUploader.upload(data)
            this.iuFileUploader.uploadState.subscribe(state => {
              if (state.success) {
                this.dialogRef.close({ success: true })
              }
            })
          } else {
            super.handleSave()
          }
        })
        .catch(err => {
          Utils.error(err)
        })
    } else {
      super.handleSave()
    }
  }

  handleFilesUploadState(state: any) {
    if (state.success) {
      this.files = []
      if (this.editId > 0) {
        this.iuUploadedFileList.loadResources()
      }
      super.handleSave()
    } else {
      super.handleError()
    }
  }
}
