import { Directive, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { GlobalDataService } from '../services/data/global-data.service';

@Directive({
  selector: '[appBaseGenericFilter]'
})
export class BaseGenericFilterDirective extends GlobalDataService implements OnInit{
  @Input() field: string;
  @Input() matchModeOptions: SelectItem[];
  sourceKey = '';

  ngOnInit() {
    this.load(this.sourceKey);
  }
}
