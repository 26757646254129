import { Component, Input } from '@angular/core';
import { BaseConfirmationDialogDirective } from 'src/app/directives/base-confirmation-dialog.directive';

@Component({
  selector: 'app-replace-files-warning',
  templateUrl: './replace-files-warning.component.html',
  styleUrls: ['./replace-files-warning.component.sass']
})
export class ReplaceFilesWarningComponent extends BaseConfirmationDialogDirective {
  @Input() repeatingFilenames: string[] = []

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    if (!this.repeatingFilenames.length) {
      this.repeatingFilenames = this.dialogConfig.data.repeatingFilenames ?? this.repeatingFilenames;
    }
  }
}
