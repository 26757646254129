<div class="flex flex-column h-100" *ngIf="item">
  <div class="flex flex-grow-1">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <app-course-summarized-info
          [item]="item"
          *ngIf="showSummarizedInfo"
        ></app-course-summarized-info>
        <app-course-banner
          [item]="item"
          [showName]="showName"
        ></app-course-banner>
        <app-course-detail [item]="item"></app-course-detail>
      </div>
    </div>
  </div>
</div>
