import { AWSS3Service } from 'src/app/services/data/aws-s3.service';
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.sass'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: HtmlEditorComponent, multi: true }
  ]
})
export class HtmlEditorComponent {

  get value(): string {
    return this.html
  }

  set value(v: string) {
    if (v !== this.html) {
      this.html = v
      this.onChange(v)
    }
  }

  html = ''

  constructor(private s3Service: AWSS3Service) { }

  seeHtml() {
    this.s3Service.seeDialogResource('PLAIN', {
      resourceContent: this.html
    })
  }

  onHtmlChange(ev) {
    this.onChange(ev)
  }

  onChange = (_: string) => { }

  onTouched = () => { }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  writeValue(obj: string): void {
    if (obj === null) {
      this.html = ''
    } else if (obj !== this.html) {
      this.html = obj
    }
  }
}

