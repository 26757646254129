import { Directive, ElementRef, Input, ViewChild } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { BaseResourceDirective } from './base-resource.directive';

@Directive({
  selector: '[appBaseMediaPlayer]',
})
export class BaseMediaPlayerDirective extends BaseResourceDirective {
  @ViewChild('mediaPlayer')
  mediaPlayer!: ElementRef;
  @Input() autoPlay = false;

  override handleDialogConfigData(): void {
    this.autoPlay = this.dialogConfig.data.autoPlay ?? this.autoPlay;
    super.handleDialogConfigData()
  }

  onPlayerReady(ev: VgApiService) {
    ev.fsAPI.medias.forEach((media) => {
      media.subscriptions.canPlay.subscribe(() => {
        if (this.autoPlay) {
          media.play();
        }
      });
    });
  }

  onPlayerPlay(ev: any) {
    console.log("Play", ev);
    
  }

  onPlayerPause(ev: any) {
    console.log("Pause", ev);
    
  }
}
