<div class="flex flex-column h-full">
  <div class="flex-grow overflow-hidden h-full">
    <p-table
      [columns]="columns"
      dataKey="id"
      [value]="itemList.rows"
      [loading]="loading"
      [paginator]="true"
      [rows]="rowsPerPage"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="rowsPerPageOptions"
      styleClass="p-datatable-gridlines"
      [(selection)]="itemList.selectedRows"
      [scrollable]="true"
      scrollHeight="flex"
      [resizableColumns]="true"
      [currentPageReportTemplate]="
        'GENERAL.SHOWING-X-TO-Y-OF-Z-ROWS'
          | translate
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      "
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3rem" pFrozenColumn>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let col of columns">
            <th
              [pSortableColumn]="col.sortable"
              pFrozenColumn
              [frozen]="col.frozen"
              pResizableColumn
              [pResizableColumnDisabled]="!col.resizable"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <div
                class="flex align-items-center justify-content-between gap-2"
                [style.width]="col.width + 'px'"
              >
                <div class="flex-grow overflow-hidden">
                  <span
                    class="ellipsis block"
                    pTooltip="{{ col.header | translate }}"
                    tooltipPosition="bottom"
                  >
                    {{ col.header | translate }}
                  </span>
                </div>
                <div class="flex align-items-center">
                  <p-sortIcon
                    [field]="col.sortable"
                    *ngIf="col.sortable !== ''"
                  ></p-sortIcon>
                  <p-columnFilter
                    *ngIf="col.filterable"
                    type="text"
                    [field]="col.sortable"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </div>
            </th>
          </ng-container>
          <th [width]="100" alignFrozen="right" pFrozenColumn></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr>
          <td pFrozenColumn>
            <p-tableCheckbox [value]="row"></p-tableCheckbox>
          </td>
          <ng-container *ngFor="let col of columns">
            <td
              pFrozenColumn
              [frozen]="col.frozen"
              [width]="col.width"
              *ngIf="col.visible"
            >
              <ng-container [ngSwitch]="col.field">
                <div
                  *ngSwitchCase="'formatter.timeTo'"
                  style="white-space: pre-line"
                >
                  <div *ngIf="row.testTake.timeTo">
                    {{ row.formatter.timeTo }}
                  </div>
                  <div class="text-center">
                    <p-tag
                      *ngIf="!row.testTake.timeTo"
                      severity="warn"
                      value="¡Intento en progreso!"
                    ></p-tag>
                  </div>
                </div>
                <div
                  *ngSwitchCase="'type_'"
                  class="text-center"
                  style="white-space: pre-line"
                >
                  <span
                    class="material-symbols-outlined"
                    style="font-size: 2rem"
                    [pTooltip]="
                      globals.CONTENT_TYPES[row.type_].label | translate
                    "
                  >
                    {{ globals.CONTENT_TYPES[row.type_].materialIcon }}
                  </span>
                </div>
                <div *ngSwitchDefault style="white-space: pre-line">
                  {{ col.field | ngCellValue : row }}
                </div>
              </ng-container>
            </td>
          </ng-container>
          <td [width]="100" class="p-2" alignFrozen="right" pFrozenColumn>
            <div
              class="flex flex-grow-1 justify-content-center md:justify-content-end mt-2 md:mt-0 gap-2"
            >
              <button
                pButton
                pRipple
                icon="pi pi-check-square"
                pTooltip="{{ 'Calificar' | translate }}"
                tooltipPosition="left"
                routerLink="/grading/rate/pending/{{ row.testTake.id }}"
                class="p-button-text p-button-raised p-button-success"
                *ngIf="
                  userType.could_grade_tests &&
                  row.testTake.timeTo &&
                  type === 'V'
                "
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ "WIDGET.DT-EMPTY" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
