import { Component, Input } from '@angular/core';
import { ImageViewerWrapperDirective } from "../../../directives/controls/image-viewer-wrapper.directive";

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.sass']
})
export class ImageViewerComponent extends ImageViewerWrapperDirective {
  @Input() defaultImage = ''

  override onInit() {
    if (this.defaultImage != '') {
      this.image = this.defaultImage
    }
    super.onInit()
  }
}
