/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-video-url-field',
  templateUrl: './video-url-field.component.html',
  styleUrls: ['./video-url-field.component.sass'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: VideoUrlFieldComponent, multi: true }
  ]
})
export class VideoUrlFieldComponent {

  get value(): string {
    return this.url
  }

  set value(v: string) {
    if (v !== this.url) {
      this.url = v
      this.onChange(v)
    }
  }

  url = ''

  onUrlChange(ev) {
    this.onChange(ev)
  }

  onChange = (_: string) => { }

  onTouched = () => { }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  writeValue(obj: string): void {
    if (obj === null) {
      this.url = ''
    } else if (obj !== this.url) {
      this.url = obj
    }
  }
}
