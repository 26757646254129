import { BaseDirective } from './../../../directives/base.directive';
import { Component } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.sass']
})
export class ContentComponent extends BaseDirective {
  override title = 'MENU.CONTENTS';
}
