<div class="sidebar-container" *ngIf="item">
  <div class="p-3 align-content-center">
    <section class="p-2 align-items-center">
      <p class="container-info">
        <i class="pi pi-video pr-2"></i>
        {{
          course.videoTimeSum % 1 === 0
            ? course.videoTimeSum.toFixed(0)
            : (course.videoTimeSum | number : "1.1-1")
        }}
        hora(s) de video
      </p>
      <p class="container-info">
        <i class="pi pi-list pr-2"></i> {{ course.unitCount }} unidad(es)
      </p>
      <p class="container-info">
        <i class="pi pi-check-square pr-2"></i>
        {{ course.sectionCount }} clase(s)
      </p>
      <p class="container-info">
        <i class="pi pi-images pr-2"></i>
        {{ course.supportContentCount }} contenido(s) de soporte
      </p>
      <p class="container-info">
        <i class="pi pi-bookmark pr-2"></i>
        {{ course.validityPeriod }} día(s) de certificación
      </p>
      <p class="container-info">
        <i class="pi pi-question pr-2"></i>
        {{ course.testCount }} evaluacion(es)
      </p>
      <p-divider type="solid"></p-divider>
    </section>
    <button
      *ngIf="userType.could_enroll_in_course"
      pButton
      pRipple
      label="Inscribirse al curso"
      type="button"
      class="p-button-raised w-full"
      (click)="joinCourse()"
    ></button>
    <div
      class="flex flex-grow-1 justify-content-center md:justify-content-end mt-2 md:mt-0 card-item-buttons"
    >
      <app-course-action-buttons
        [source]="item"
        (itemDisabled)="itemDisabled.emit(true)"
      ></app-course-action-buttons>
    </div>
  </div>
</div>
