import { AppJSONSanitizer } from "./AppGenericClasses"
import { AppItem } from "./AppModels"

export class TestTakeJSON {
  el_test_take_id = 0
  el_test_id = 0
  poll_take_id = 0
  time_from = ''
  time_to = ''
  result = 0
}

export class TestTake implements AppItem {
  id_: number
  name_: string
  timeFrom: string
  timeTo: string
  result: number
  testId: number

  source: TestTakeJSON

  constructor(source: TestTakeJSON) {
    this.source = new AppJSONSanitizer<TestTakeJSON>(source, TestTakeJSON).result
    this.id = this.source.el_test_take_id
    this.timeFrom = this.source.time_from
    this.timeTo = this.source.time_to
    this.result = this.source.result
    this.testId = this.source.el_test_id
  }

  get id(): number {
    return this.id_
  }
  set id(value: number) {
    this.id_ = value
  }

  get name(): string {
    throw new Error("Method not implemented.")
  }
  set name(value: string) {
    throw new Error("Method not implemented.")
  }
}
