<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h2 class="module-title cursor-pointer" routerLink="/libraries">
        {{ "MENU.LIBRARIES" | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i class="pi pi-angle-double-right" style="font-size: 2rem; color: var(--primary-color)"></i>
    </div>
    <div class="flex flex-grow-1  flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW-F") | translate }}
        {{ "CORE.LIBRARY" | translate | lowercase }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
            <span class="p-float-label">
              <input
                pInputText
                id="title"
                name="title"
                class="ng-dirty w-full"
                aria-describedby="title-help"
                [(ngModel)]="form.name_"
                required
              />
              <label for="title">{{ "FORM.TITLE" | translate }}*</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 mb-4">
            <span class="p-float-label">
              <textarea
                pInputTextarea
                id="description"
                name="description"
                rows="5"
                class="w-full"
                [(ngModel)]="form.description_"
              ></textarea>
              <label for="description">{{
                "FORM.DESCRIPTION" | translate
              }}</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label for="tags">{{ "FORM.TAGS" | translate }}</label>
            <p-chips
              inputId="tags"
              name="tags"
              class="w-full"
              [separator]="globals.REG_EXP.chipFieldSeparator"
              [placeholder]="'WIDGET.CHIP-FIELD-EXAMPLE' | translate"
              [(ngModel)]="tags"
            ></p-chips>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
