import { TestQuestionFormComponent } from './components/master/admin/test/test-question/test-question-form/test-question-form.component';
import { TestDetailWrapperComponent } from './components/master/admin/test/test-detail-wrapper/test-detail-wrapper.component';
import { TestFormComponent } from './components/master/admin/test/test-form/test-form.component';
import { AdminComponent } from './components/master/admin/admin.component';
import { LibraryFormComponent } from './components/master/library/library-form/library-form.component';
import { ContentFormComponent } from './components/master/content/content-form/content-form.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { PasswordRecoverComponent } from './components/auth/password-recover/password-recover.component';
import { PasswordResetComponent } from './components/auth/password-reset/password-reset.component';
import { AuthContainerComponent } from './components/auth/auth-container/auth-container.component';
import { MasterContainerComponent } from './components/master/master-container/master-container.component';
import { CourseComponent } from './components/master/course/course.component';
import { CourseFormComponent } from './components/master/course/course-form/course-form.component';
import { TopicComponent } from './components/master/topic/topic.component';
import { authGuard, unAuthGuard } from './entities/auth/auth.guard';
import { ContentComponent } from './components/master/content/content.component';
import { ConfigurationComponent } from './components/master/configuration/configuration.component';
import { ColorComponent } from './components/master/configuration/color/color.component';
import { ColorFormComponent } from './components/master/configuration/color/color-form/color-form.component';
import { DocumentTypeComponent } from './components/master/configuration/document-type/document-type.component';
import { DocumentTypeFormComponent } from './components/master/configuration/document-type/document-type-form/document-type-form.component';
import { GenderComponent } from './components/master/configuration/gender/gender.component';
import { GenderFormComponent } from './components/master/configuration/gender/gender-form/gender-form.component';
import { CityComponent } from './components/master/configuration/city/city.component';
import { CityFormComponent } from './components/master/configuration/city/city-form/city-form.component';
import { UserFormComponent } from './components/master/configuration/user/user-form/user-form.component';
import { UserComponent } from './components/master/configuration/user/user.component';
import { LibraryComponent } from './components/master/library/library.component';
import { LibraryContentComponent } from './components/master/library/library-content/library-content.component';
import { TestComponent } from './components/master/admin/test/test.component';
import { TestQuestionComponent } from './components/master/admin/test/test-question/test-question.component';
import { CourseItemPrerequisiteComponent } from './components/master/course/course-item/course-item-prerequisite/course-item-prerequisite.component';
import { CourseItemEnrolledUsersComponent } from './components/master/course/course-item/course-item-enrolled-users/course-item-enrolled-users.component';
import { TestVersionComponent } from './components/master/test-version/test-version.component';
import { CoursePreviewWrapperComponent } from './components/master/course/course-preview-wrapper/course-preview-wrapper.component';
import { UnitsComponent } from './components/master/units/units.component';
import { UnitFormComponent } from './components/master/units/unit-form/unit-form.component';
import { EvaluationsComponent } from './components/master/units/evaluations/evaluations.component';
import { SectionsComponent } from './components/master/units/sections/sections.component';
import { SectionFormComponent } from './components/master/units/sections/section-form/section-form.component';
import { SupportSectionContentComponent } from './components/master/units/sections/support-section-content/support-section-content.component';
import { TestVersionAllComponent } from './components/master/test-version/test-version-table/test-version-all/test-version-all.component';
import { GroupComponent } from './components/master/configuration/group/group.component';
import { GroupFormComponent } from './components/master/configuration/group/group-form/group-form.component';
import { SpecialtyComponent } from './components/master/configuration/specialty/specialty.component';
import { SpecialtyFormComponent } from './components/master/configuration/specialty/specialty-form/specialty-form.component';
import { PendingUserComponent } from './components/master/configuration/pending-user/pending-user.component';
import { TestTakeFormComponent } from './components/shared/test-take-form/test-take-form.component';
import { GradingComponent } from './components/master/grading/grading.component';
import { GradedTestComponent } from './components/master/grading/graded-test/graded-test.component';
import { PendingGradedTestComponent } from './components/master/grading/pending-graded-test/pending-graded-test.component';
import { TopicFormWrapperComponent } from './components/master/topic/topic-form-wrapper/topic-form-wrapper.component';
import { DashboardComponent } from './components/master/dashboard/dashboard.component';
import { TestVersionTestFormComponent } from './components/master/test-version/test-version-test-form/test-version-test-form.component';
import { UnitTestFormComponent } from './components/master/units/unit-test-form/unit-test-form.component';
import { RatePendingTestFormComponent } from './components/master/grading/rate-pending-test-form/rate-pending-test-form.component';
import { TestTakeDetailWrapperComponent } from './components/master/admin/test/test-take/test-take-detail-wrapper/test-take-detail-wrapper.component';
import { ReportComponent } from './components/master/report/report.component';
import { ReportCoursesComponent } from './components/master/report/report-courses/report-courses.component';
import { ReportCourseUserComponent } from './components/master/report/report-courses/report-course-table/report-course-user/report-course-user.component';
import { UserTypeComponent } from './components/master/configuration/user-type/user-type.component';
import { UserTypeFormComponent } from './components/master/configuration/user-type/user-type-form/user-type-form.component';
import { CourseUserDetailComponent } from './components/shared/course-user-detail/course-user-detail.component';
import { ConfirmAccountComponent } from "./components/auth/confirm-account/confirm-account.component";
import { ReportTakeEvaluationComponent } from './components/master/report/report-take-evaluation/report-take-evaluation.component';
import { ReportTakeUserComponent } from './components/master/report/report-take-user/report-take-user.component';
import { TemplateComponent } from './components/master/configuration/template/template.component';
import { TemplateFormComponent } from './components/master/configuration/template/template-form/template-form.component';
import { WildcardsComponent } from './components/master/configuration/wildcards/wildcards.component';
import { WildCardFormComponent } from './components/master/configuration/wildcards/wild-card-form/wild-card-form.component';
import { DataTreatmentComponent } from './components/shared/data-treatment/data-treatment.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [unAuthGuard],
    component: AuthContainerComponent,
    children: [
      {
        path: 'sign-in',
        component: SignInComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
      {
        path: 'password-recover',
        component: PasswordRecoverComponent,
      },
      {
        path: 'password-reset',
        component: PasswordResetComponent,
      },
      {
        path: 'confirm-account/:username',
        component: ConfirmAccountComponent,
      },
      {
        path: 'data-treatment',
        component: DataTreatmentComponent,
      },
    ],
  },
  {
    path: '',
    canActivate: [authGuard],
    component: MasterContainerComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            component: ReportComponent,
          },
          {
            path: 'courses',
            component: ReportCoursesComponent
          },
          {
            path: 'courses/:id',
            component: ReportCourseUserComponent
          },
          {
            path: 'take-evaluation',
            component: ReportTakeEvaluationComponent
          },
          {
            path: 'user-takes',
            component: ReportTakeUserComponent
          },

        ]

      },
      {
        path: 'data-treatment',
        component: DataTreatmentComponent,
      },
      {
        path: 'courses',
        children: [
          {
            path: '',
            component: CourseComponent,
          },
          {
            path: 'create',
            component: CourseFormComponent,
          },
          {
            path: 'edit/:id',
            component: CourseFormComponent,
          },
          {
            path: 'create/:defaultTopic',
            component: CourseFormComponent,
          },
          {
            path: ':id/prerrequisites',
            component: CourseItemPrerequisiteComponent
          },
          {
            path: ':id/enrolled-users',
            component: CourseItemEnrolledUsersComponent
          },
          {
            path: 'preview/:id',
            component: CoursePreviewWrapperComponent
          },
          {
            path: 'user/preview/:id/:userId',
            component: CourseUserDetailComponent
          },
          {
            path: 'units',
            children: [
              {
                path: 'preview/:id',
                component: UnitsComponent
              },
              {
                path: 'edit/:id',
                component: UnitFormComponent
              },
              {
                path: 'sections',
                children: [
                  {
                    path: 'create/:unitId',
                    component: SectionFormComponent
                  },
                  {
                    path: 'edit/:id',
                    component: SectionFormComponent
                  },
                  {
                    path: 'support-content/:sectionId',
                    component: SupportSectionContentComponent
                  },
                  {
                    path: ':courseId/:id',
                    component: SectionsComponent
                  }
                ]
              },
              {
                path: 'tests',
                children: [
                  {
                    path: ':id',
                    component: EvaluationsComponent,
                  },
                  {
                    path: 'edit/:id',
                    component: UnitTestFormComponent,
                  }
                ]
              },
              {
                path: 'create/:courseId',
                component: UnitFormComponent
              },
            ]
          },
        ],
      },
      {
        path: 'topics',
        children: [
          {
            path: '',
            component: TopicComponent,
          },
          {
            path: 'create',
            component: TopicFormWrapperComponent,
          },
          {
            path: 'edit/:id',
            component: TopicFormWrapperComponent,
          }
        ],
      },
      {
        path: 'contents',
        children: [
          {
            path: '',
            component: ContentComponent,
          },
          {
            path: 'create',
            component: ContentFormComponent,
          },
          {
            path: 'create/:parentLibraryId',
            component: ContentFormComponent,
          },
          {
            path: 'edit/:id',
            component: ContentFormComponent,
          }
        ],
      },
      {
        path: 'libraries',
        children: [
          {
            path: '',
            component: LibraryComponent,
          },
          {
            path: 'create',
            component: LibraryFormComponent,
          },
          {
            path: 'edit/:id',
            component: LibraryFormComponent,
          },
          {
            path: ':id/contents',
            component: LibraryContentComponent,
          }
        ],
      },
      {
        path: 'tests',
        children: [
          {
            path: '',
            component: TestVersionComponent,
          },
          {
            path: 'versions/:id',
            component: TestVersionAllComponent,
          },
          {
            path: 'create',
            component: LibraryFormComponent,
          },
          {
            path: 'edit/:id',
            component: LibraryFormComponent,
          },
          {
            path: 'take',
            children: [
              {
                path: ':id/:type/:parentId/:testId',
                component: TestTakeFormComponent
              },
              {
                path: 'detail/:id',
                component: TestTakeDetailWrapperComponent
              }
            ]
          }
        ],
      },
      {
        path: 'configuration',
        children: [
          {
            path: '',
            component: ConfigurationComponent
          },
          {
            path: 'color',
            children: [
              {
                path: '',
                component: ColorComponent
              },
              {
                path: 'create',
                component: ColorFormComponent
              },
              {
                path: 'edit/:id',
                component: ColorFormComponent
              }
            ]

          },
          {
            path: 'document-type',
            children: [
              {
                path: '',
                component: DocumentTypeComponent
              },
              {
                path: 'create',
                component: DocumentTypeFormComponent
              },
              {
                path: 'edit/:id',
                component: DocumentTypeFormComponent
              }
            ]

          },
          {
            path: 'gender',
            children: [
              {
                path: '',
                component: GenderComponent
              },
              {
                path: 'create',
                component: GenderFormComponent
              },
              {
                path: 'edit/:id',
                component: GenderFormComponent
              }
            ]
          },
          {
            path: 'city',
            children: [
              {
                path: '',
                component: CityComponent
              },
              {
                path: 'create',
                component: CityFormComponent
              },
              {
                path: 'edit/:id',
                component: CityFormComponent
              }
            ]
          },
          {
            path: 'user',
            children: [
              {
                path: '',
                component: UserComponent
              },
              {
                path: 'create',
                component: UserFormComponent
              },
              {
                path: 'edit/:id',
                component: UserFormComponent
              }
            ]
          },
          {
            path: 'user-type',
            children: [
              {
                path: '',
                component: UserTypeComponent
              },
              {
                path: 'create',
                component: UserTypeFormComponent
              },
              {
                path: 'edit/:id',
                component: UserTypeFormComponent
              }
            ]
          },
          {
            path: 'user-group',
            children: [
              {
                path: '',
                component: GroupComponent
              },
              {
                path: 'create',
                component: GroupFormComponent
              },
              {
                path: 'edit/:id',
                component: GroupFormComponent
              }
            ]
          },
          {
            path: 'specialty',
            children: [
              {
                path: '',
                component: SpecialtyComponent
              },
              {
                path: 'create',
                component: SpecialtyFormComponent
              },
              {
                path: 'edit/:id',
                component: SpecialtyFormComponent
              }
            ]
          },
          {
            path: 'pending-users',
            children: [
              {
                path: '',
                component: PendingUserComponent
              },
            ]
          },
          {
            path: 'templates',
            children: [
              {
                path: '',
                component: TemplateComponent
              },
              {
                path: 'create',
                component: TemplateFormComponent
              },
              {
                path: 'edit/:id',
                component: TemplateFormComponent
              }
            ]
          },
          {
            path: 'wildcards',
            children: [
              {
                path: '',
                component: WildcardsComponent
              },
              {
                path: 'create',
                component: WildCardFormComponent
              },
              {
                path: 'edit/:id',
                component: WildCardFormComponent
              }
            ]
          },
        ]
      },
      {
        path: 'grading',
        children: [
          {
            path: '',
            component: GradingComponent
          },
          {
            path: 'graded/:id',
            component: GradedTestComponent
          },
          {
            path: 'pending/:id',
            component: PendingGradedTestComponent
          },
          {
            path: 'rate/pending/:id',
            component: RatePendingTestFormComponent
          }
        ]
      },
      {
        path: 'admin',
        children: [
          {
            path: '',
            component: AdminComponent
          },
          {
            path: 'test',
            children: [
              {
                path: '',
                component: TestComponent,
              },
              {
                path: 'create',
                component: TestFormComponent,
              },
              {
                path: 'create/:instantiatedIn',
                component: TestFormComponent,
              },
              {
                path: 'edit/:id',
                component: TestFormComponent,
              },
              {
                path: 'detail/:id',
                component: TestDetailWrapperComponent,
              },
              {
                path: ':parentTestId/question',
                children: [
                  {
                    path: '',
                    component: TestQuestionComponent,
                  },
                  {
                    path: 'create',
                    component: TestQuestionFormComponent,
                  },
                  {
                    path: 'edit/:id',
                    component: TestQuestionFormComponent,
                  }
                ],
              },
              {
                path: 'test-version',
                children: [
                  {
                    path: 'create',
                    component: TestVersionTestFormComponent,
                  },
                  {
                    path: 'edit/:id',
                    component: TestVersionTestFormComponent,
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
