import { Component } from '@angular/core';
import { BaseListDirective } from "../../../directives/base-list.directive";

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.sass']
})
export class CourseComponent extends BaseListDirective {

  override filterableFields = ['name_', 'description_', 'tags']

  override getList() {
    this.query(this.data.core.courses()).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  handleRows(data: any): void {
    console.log(data);
    super.handleRows(data)

  }
}

@Component({
  selector: 'app-dashboard-courses',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.sass']
})
export class DashboardCoursesComponent extends CourseComponent {
  override getList() {
    this.query(this.data.core.courses()).subscribe()
  }
}