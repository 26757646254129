/* eslint-disable @typescript-eslint/no-empty-function */
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { Component } from '@angular/core';
import { Course, CourseParser } from 'src/app/models/CourseClasses';

@Component({
  selector: 'app-course-summarized-info',
  templateUrl: './course-summarized-info.component.html',
  styleUrls: ['./course-summarized-info.component.sass']
})
export class CourseSummarizedInfoComponent extends BaseItemDirective {
  userLogged = this.utils.global.user.user_id
  keys = {
    primary_key: '',
    label: 'name_'
  }

  course!: Course

  refreshActionServices(): void { }

  override onItemReady(): void {
    super.onItemReady()
    this.course = new CourseParser(this.item, this.utils).result
  }

  joinCourse() {
    this.utils.executeRequest(this.data.core.courseEnrollmentPOST({
      el_course_id: this.itemId,
      user_id: this.userLogged
    }));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
