import { Component } from '@angular/core';
import { AppMenuItem } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.sass']
})
export class ReportComponent {
  title = 'MENU.SETTINGS'
  menuItems: AppMenuItem[] = [
    new AppMenuItem('MENU.COURSES', { routerLink: '/reports/courses', description_: 'MENU.DESC-COURSES' }),
    new AppMenuItem('MENU.TAKE-EVALUATIONS-BY-UNIT-COURSE', { routerLink: '/reports/take-evaluation', description_: '' }),
    new AppMenuItem('MENU.USER-TAKES', { routerLink: '/reports/user-takes', description_: '' }),

  ]
}
