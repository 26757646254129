import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-topic-courses',
  templateUrl: './topic-courses.component.html',
  styleUrls: ['./topic-courses.component.sass'],
})
export class TopicCoursesComponent extends BaseListDirective {
  @Input() topicId = 0
  override filterableFields = ['name_', 'description_']

  override getList() {
    this.query(this.data.core.coursesByTopic(this.topicId)).subscribe()
  }

  override manageChanges(changes: SimpleChanges): void {
    if ('topicId' in changes) {
      this.getList();
    }
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }
}
