import { UtilsService } from "../services/utils/utils.service";
import { AppFormattedList, AppJSONSanitizer } from "./AppGenericClasses";
import { AppSortableItem, AppSelectableItem, Test } from "./AppModels";
import { TestJSON } from "./AppSources";

export class CourseUnitTestJSON {
  el_course_unit_id = 0
  el_test_id = 0
  allowed_attempts = 0
  approval_required = 0
  test: TestJSON = new TestJSON()
}

export class CourseUnitTest implements AppSortableItem, AppSelectableItem<CourseUnitTest> {
  id_: number;
  name_: string;
  allowedAttempts: number
  approvalRequired: number
  value: CourseUnitTest
  testId: number
  test: Test

  source: CourseUnitTestJSON

  constructor(source: CourseUnitTestJSON, utils: UtilsService) {
    this.source = new AppJSONSanitizer<CourseUnitTestJSON>(source, CourseUnitTestJSON).result
    this.id = this.source.el_course_unit_id
    this.allowedAttempts = this.source.allowed_attempts
    this.approvalRequired = this.source.approval_required
    this.testId = this.source.el_test_id
    this.test = new Test(this.source.test, utils)
    this.name = this.source.test.poll.title
  }

  get id(): number {
    return this.id_
  }
  set id(value: number) {
    this.id_ = value
  }

  get name(): string {
    return this.name_
  }
  set name(value: string) {
    this.name_ = value
  }

  get sortValue(): string {
    return this.name
  }
}

export class CourseUnitTestList extends AppFormattedList<CourseUnitTest, CourseUnitTestJSON> {

  constructor(utils: UtilsService) {
    super(utils);
    this.ItemModel = CourseUnitTest
  }
}
