import { AppDialogService } from './app-dialog.service';
import { ELTest, TextCase } from './../../models/AppGenericClasses';
/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "./toast.service";
import { GlobalService } from "./global.service";
import { ConfirmationDialogComponent } from 'src/app/components/controls/confimation-dialog/confirmation-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FileSaverService } from 'ngx-filesaver';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/shared/utils/utils';
import { AppMenuItem } from 'src/app/models/AppGenericClasses';
import { Globals } from 'src/app/globals';
import { ObjectFormatter, ObjectFormatterConfig } from 'src/app/models/ObjectFormatter';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private globals = Globals

  constructor(
    public translate: TranslateService,
    public toast: ToastService,
    public global: GlobalService,
    public dialog: DialogService,
    public fileSaverService: FileSaverService,
    public router: Router,
    public appDialog: AppDialogService
  ) {
  }

  showLoad() {
    setTimeout(() => {
      this.global.processing.next(true)
    }, 100)
  }

  hideLoad() {
    setTimeout(() => {
      this.global.processing.next(false)
    }, 100)
  }

  fireConfirmation(data: any, callback: () => void) {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      header: this.translate.instant('ENV.CONFIRMATION'),
      baseZIndex: 10000,
      data: data,
      style: {
        maxWidth: '70%'
      }
    });
    ref.onClose.subscribe((data: any) => {
      if (data && data.confirmed) {
        callback();
      }
    });
  }

  translateAndSort(arr: AppMenuItem[], prop: string) {
    arr.forEach(item => {
      item[prop] = this.translate.instant(item[prop])
    })
    return Utils.sortByProperty(arr, prop)
  }

  translateObjectFormatterConfig(objFormatter: ObjectFormatter) {
    const timeFromUnit = objFormatter.formatConfig.TIME.fromUnit
    const timeToUnit = objFormatter.formatConfig.TIME.toUnit
    timeFromUnit.label = this.translate.instant(timeFromUnit.label)
    timeToUnit.label = this.translate.instant(timeToUnit.label)

    Object.assign(objFormatter.formatConfig, {
      TIME: {
        ...objFormatter.formatConfig.TIME, ...{
          fromUnit: timeFromUnit,
          toUnit: timeToUnit
        }
      },
      FLAG: {
        ...objFormatter.formatConfig.FLAG, ...{
          onTrueText: this.translate.instant('GENERAL.YES'),
          onFalseText: this.translate.instant('GENERAL.NO')
        }
      }
    })
    return objFormatter
  }

  getELTestFormatter() {
    const objFormatter = new ObjectFormatter()

    objFormatter.props = [
      {
        field: 'time_limit',
        formats: ['TIME', 'LOWER-CASE']
      },
      {
        field: 'min_approval_percentage',
        formats: ['PERCENTAGE']
      },
      {
        field: 'random_questions',
        formats: ['FLAG']
      }
    ]
    Object.assign(objFormatter.formatConfig, {
      TIME: {
        ...objFormatter.formatConfig.TIME, ...{
          fromUnit: this.globals.TIME_OPTIONS.SECONDS,
          toUnit: this.globals.EL_TEST_VISUALIZATION.TIME_LIMIT_UNIT,
          fractionDigits: 1
        }
      },
      PERCENTAGE: {
        ...objFormatter.formatConfig.PERCENTAGE, ...{
          divider: 100,
          digitsInfo: '1.2'
        }
      }
    } as ObjectFormatterConfig)
    return objFormatter
  }

  getContentFormatter() {
    const objFormatter = new ObjectFormatter()

    objFormatter.props = [
      {
        field: 'min_viewing_time',
        formats: ['TIME-FORMATTED', 'LOWER-CASE']
      },
      {
        field: 'tags',
        formats: ['COMMA-SEPARATED']
      }
    ]
    Object.assign(objFormatter.formatConfig, {
      TIME: {
        ...objFormatter.formatConfig.TIME, ...{
          fromUnit: this.globals.TIME_OPTIONS.SECONDS,
          toUnit: this.globals.CONTENT_VISUALIZATION.TIME_LIMIT_UNIT,
          fractionDigits: 1
        }
      }
    } as ObjectFormatterConfig)
    return objFormatter
  }


  getCourseFormatter() {
    const objFormatter = new ObjectFormatter()

    objFormatter.props = [
      {
        field: 'estimated_duration',
        formats: ['TIME', 'LOWER-CASE']
      }
    ]
    Object.assign(objFormatter.formatConfig, {
      TIME: {
        ...objFormatter.formatConfig.TIME, ...{
          fromUnit: this.globals.TIME_OPTIONS.HOURS,
          toUnit: this.globals.COURSE_VISUALIZATION.TIME_LIMIT_UNIT,
          fractionDigits: 1
        }
      }
    } as ObjectFormatterConfig)
    return objFormatter
  }

  getLibraryFormatter() {
    const objFormatter = new ObjectFormatter()

    objFormatter.props = [
      {
        field: 'tags',
        formats: ['COMMA-SEPARATED']
      }
    ]
    return objFormatter
  }

  getTestTakeFormatter() {
    const objFormatter = new ObjectFormatter()

    objFormatter.props = [
      {
        field: 'time_from',
        formats: ['DATE-FORMATTED']
      },
      {
        field: 'time_to',
        formats: ['DATE-FORMATTED']
      }
    ]
    return objFormatter
  }

  applyContentFormat(data: any[]) {
    this.applyFormat(data, this.getContentFormatter())
  }

  applyCourseFormat(data: any[]) {
    this.applyFormat(data, this.getContentFormatter())
  }

  applyELTestFormat(data: ELTest[]) {
    this.applyFormat(data, this.getELTestFormatter())
  }

  applyLibraryFormat(data: ELTest[]) {
    this.applyFormat(data, this.getLibraryFormatter())
  }

  applyFormat(data: any[], objFormatter: ObjectFormatter = new ObjectFormatter()) {
    this.translateObjectFormatterConfig(objFormatter).applyFormat(data)
  }

  executeRequest(
    request: Observable<any>,
    optional: {
      onSuccess?: (data: any) => void,
      onError?: (error: any) => void,
      onComplete?: () => void
    } = {}
  ) {
    this.showLoad()
    request?.subscribe({
      next: (data) => {
        this.hideLoad();
        optional.onSuccess?.(data);
      },
      error: (error) => {
        this.hideLoad();
        Utils.error(error);
        if (optional.onError) {
          optional.onError(error)
        } else {
          this.toast.error()
        }
      },
      complete: () => {
        this.hideLoad();
        optional.onComplete?.();
      }
    });
  }

  closeAllDialogs() {
    this.dialog.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }

  back() {
    history.back()
  }

  translateParts(parts: string[], textCase: TextCase = "DEFAULT") {
    return Utils.applyTextCase(
      parts.map(part => this.translate.instant(part)).join(" "),
      textCase
    )
  }

  getFlagText(val: number | boolean) {
    return val ?
      this.translate.instant('GENERAL.YES') :
      this.translate.instant('GENERAL.NO')
  }

  zero(value) {
    if (value < 10) {
      return '0' + value;
    }
    return value.toString();
  }
}
