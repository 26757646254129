<div
  cdkDropList
  [cdkDropListData]="data"
  (cdkDropListDropped)="drop($event)"
  class="drag-list"
>
  <ng-container *ngFor="let item of data">
    <div class="draggable-box" cdkDrag>
      {{ item[labelKey] }}
    </div>
  </ng-container>
</div>
