<ng-container *ngIf="item">
  <app-test-detail
    [source]="item.test"
    [showActions]="showActions"
    [componentDataSource]="'INPUT'"
  ></app-test-detail>
  <div class="flex my-2" style="height: auto">
    <div class="box box-elevation px-3 pt-2 w-full">
      <div class="grid">
        <div class="col-12">
          <div class="flex align-items-center justify-content-between w-full">
            <div class="flex-grow py-2">
              <b class="text-lg">
                {{ "ENV.TAKE-DETAIL" | translate }}
              </b>
            </div>
          </div>
          <hr />
        </div>
        <div class="col-12">
          <p class="text-sm mt-0">
            {{ "FORM.MADE-BY" | translate }}
          </p>
          <div>
            {{ item.poll_take.user.first_name }}
            {{ item.poll_take.user.last_name }}
          </div>
        </div>
        <div class="col">
          <p class="text-sm mt-0">
            {{ "FORM.STARTED-AT" | translate }}
          </p>
          <div>{{ item.time_from }}</div>
        </div>
        <div class="col">
          <p class="text-sm mt-0">
            {{ "FORM.FINISHED-AT" | translate }}
          </p>
          <div>{{ item.time_to }}</div>
        </div>
        <div class="col" *ngIf="showResult">
          <p class="text-sm mt-0">
            {{ "FORM.RESULT" | translate }}
          </p>
          <div class="text-center w-min">
            <p-knob
              [(ngModel)]="result"
              [readonly]="true"
              valueColor="#689F38"
              rangeColor="#D32F2F"
              valueTemplate="{value}"
              [strokeWidth]="5"
            ></p-knob>
            <p-tag
              styleClass="align-self-center p-text-secondary text-lg"
              severity="success"
              value="Aprobado"
              *ngIf="item.result >= +item.test.min_approval_percentage"
            >
            </p-tag>
            <p-tag
              styleClass="align-self-center p-text-secondary text-lg"
              severity="danger"
              value="Reprobado"
              *ngIf="item.result < +item.test.min_approval_percentage"
            >
            </p-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
