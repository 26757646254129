<p-tabView class="col-6" *ngIf="course">
  <p-tabPanel header="Descripción general">
    <div class="flex pl-3">
      <div class="flex flex-column mt-4 mr-4">
        <strong style="color: black"
          >{{ course.estimatedDuration }}
          {{
            globals.COURSE_VISUALIZATION.TIME_LIMIT_UNIT.label
              | translate
              | lowercase
          }}
        </strong>
        Duración estimada
      </div>
      <div class="flex flex-column ml-2 mt-4 mr-4">
        <strong style="color: rgb(49, 31, 31)"
          >{{ course.validityPeriod }} días</strong
        >
        Validez de certificación
      </div>
    </div>
    <div class="pl-3 mb-1">
      <h2 style="color: black">Descripción</h2>
      <p class="m-0">
        {{ course.description }}
      </p>
    </div>
    <div class="mb-1 pl-3" *ngIf="course.objective">
      <h2 style="color: black">Objetivo</h2>
      <p class="m-0" [innerHTML]="course.objective">
        {{ course.objective }}
      </p>
    </div>
    <div
      class="mb-1 pl-3"
      *ngIf="course.courseTopics && course.courseTopics.length"
    >
      <h2 style="color: black">Temas</h2>
      <ul>
        <div *ngFor="let cTopic of course.courseTopics">
          <li class="m-0">
            {{ cTopic.topic.name }}
          </li>
        </div>
      </ul>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Unidades" *ngIf="course.units && course.units.length">
    <ng-container *ngFor="let unit of course.units; index as unitIdx">
      <div class="box-elevation border-round-xl p-2 mb-3">
        <div class="ml-4">
          <h3 class="pt-2">Unidad #{{ unitIdx + 1 }} | {{ unit.name }}</h3>
          <p>
            {{ unit.description }}
          </p>
        </div>
        <p-accordion
          *ngFor="let section of unit.sections; index as sectionIdx"
          styleClass="mb-1"
        >
          <p-accordionTab contentStyleClass="pt-0">
            <ng-template pTemplate="header">
              <span class="flex align-items-center gap-2 w-full">
                <app-course-unit-section-content-item
                  [item]="
                    item.units[unitIdx].sections[sectionIdx].resource_content
                  "
                  [fireActions]="false"
                  [showName]="false"
                ></app-course-unit-section-content-item>
                <span class="font-bold white-space-nowrap">{{
                  "Clase #" + (sectionIdx + 1) + " | " + section.name
                }}</span>
              </span>
            </ng-template>
            <div class="ml-2">
              <div class="mb-4">
                <div [innerHTML]="section.content"></div>
                <app-course-unit-section-contents
                  [item]="item.units[unitIdx].sections[sectionIdx]"
                  [fireActions]="false"
                ></app-course-unit-section-contents>
              </div>
              <div class="font-bold p-text-secondary font-italic">
                {{ "FORM.ESTIMATED-DURATION" | translate }}:
                <span *ngIf="section.resourceContent.realTime.hours > 0" class="font-bold p-text-secondary font-italic">
                  {{ section.resourceContent.realTime.hours }}
                  {{ "FORM.HOURS" | translate | lowercase }}
                </span>
                <span *ngIf="section.resourceContent.realTime.minutes > 0" class="font-bold p-text-secondary font-italic">
                  {{ section.resourceContent.realTime.minutes }}
                  {{ "FORM.MINUTES" | translate | lowercase }}
                </span>
                <span *ngIf="section.resourceContent.realTime.hours <  0 && section.resourceContent.realTime.minutes <  0" >
                  {{ section.resourceContent.realTime.seconds }}
                  {{ "FORM.SECONDS" | translate | lowercase }}
                </span>
              </div>
              
            </div>
          </p-accordionTab>
        </p-accordion>
        <p-accordion
          *ngFor="let unitTest of unit.tests; index as testIdx"
          styleClass="mb-1"
        >
          <p-accordionTab contentStyleClass="pt-0">
            <ng-template pTemplate="header">
              <span class="flex align-items-center gap-2 w-full">
                <span class="material-symbols-outlined" style="font-size: 2rem">
                  quiz
                </span>
                <span class="font-bold white-space-nowrap">{{
                  "Evaluación #" +
                    (testIdx + 1) +
                    " | " +
                    unitTest.test?.poll?.title
                }}</span>
              </span>
            </ng-template>
            <app-course-test-summary
              [showName]="false"
              [item]="item.units[unitIdx].tests[testIdx]"
            ></app-course-test-summary>
          </p-accordionTab>
        </p-accordion>
      </div>
    </ng-container>
  </p-tabPanel>
</p-tabView>
