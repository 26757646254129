import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-prime-list-columns-toggle',
  templateUrl: './prime-list-columns-toggle.component.html',
  styleUrls: ['./prime-list-columns-toggle.component.sass']
})
export class PrimeListColumnsToggleComponent {
  @Input() columns: PrimeNGTableColumn[] = []
  @Output() columnsChange = new EventEmitter<PrimeNGTableColumn[]>()
  @Output() columnToggle = new EventEmitter<PrimeNGTableColumn>()

  onColumnToggle(column: PrimeNGTableColumn) {
    column.visible = !column.visible
    this.columnsChange.emit(this.columns)
  }
}
