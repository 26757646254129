import { UtilsService } from "../services/utils/utils.service"
import { AppJSONSanitizer } from "./AppGenericClasses"
import { CourseUnitSection, CourseUnitSectionJSON } from "./CourseUnitSectionClasses"
import { CourseUnitTest, CourseUnitTestJSON } from "./CourseUnitTestClasses"

export class CourseUnitJSON {
  el_course_unit_id = 0
  name_ = ""
  description_ = ""
  status_ = 1

  sections: CourseUnitSectionJSON[]
  tests: CourseUnitTestJSON[]
}

export class CourseUnit {
  id: number
  name: string
  description: string
  active: boolean

  sections: CourseUnitSection[]
  tests: CourseUnitTest[]

  source: CourseUnitJSON

  constructor(source: CourseUnitJSON, utils: UtilsService) {
    this.source = new AppJSONSanitizer<CourseUnitJSON>(source, CourseUnitJSON).result
    this.id = this.source.el_course_unit_id
    this.name = this.source.name_
    this.description = this.source.description_
    this.sections = this.source.sections.map(section => new CourseUnitSection(section))
    this.active = this.source.status_ != 0 ? true : false
    this.tests = this.source.tests.map(test => new CourseUnitTest(test, utils))
  }
}
