/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input } from '@angular/core';
import { Storage } from 'aws-amplify';
import { S3ProviderListOutputItem } from '@aws-amplify/storage';
import { BaseResourceDirective } from './base-resource.directive';
import { AWSUploadedFile } from '../models/AppGenericClasses';
import { Utils } from '../shared/utils/utils';

@Directive({
  selector: '[appBaseResourceList]',
})
export class BaseResourceListDirective extends BaseResourceDirective {
  @Input() latest = false
  resourceList: AWSUploadedFile[] = []
  awsResourceList: S3ProviderListOutputItem[] = []
  currentIdx = 0;

  override onInit() {
    if (this.editId > 0) {
      this.loadResources()
    }
  }

  beforeGetResourceList() {
    this.resourceList = []
    this.downloading = true
    this.resourcePath = `${this.path}/${this.editId}/${this.field}`
  }

  loadResources() {
    this.beforeGetResourceList();
    Storage.list(`${this.resourcePath}/`, {
      level: 'public'
    })
      .then((rs) => this.onLoadResourceListSuccess(rs.results))
      .catch((err) => this.onLoadResourceListError(err))
      .finally(() => this.onLoadResourceListComplete())
  }

  override loadResource() {
    this.downloading = true;
    Storage.get(this.resourcePath, this.downloadOptions)
      .then((result) => {
        this.onLoadResourceSuccess(result)
      })
      .catch((err) => {
        this.onLoadResourceError(err)
      })
      .finally(() => {
        this.onLoadResourceComplete()
      });
  }

  loadNextChildResource() {
    const currentItem = this.awsResourceList[this.currentIdx]
    if (currentItem && currentItem.key) {
      this.resourcePath = currentItem.key
      this.ext = currentItem.key.split('.').pop() ?? ''
      this.loadResource()
    } else {
      this.onLoadNextChildResourceComplete()
    }
  }

  onLoadNextChildResourceComplete() {
    this.downloading = false
  }

  onLoadResourceListSuccess(rs: S3ProviderListOutputItem[]) {
    this.awsResourceList = rs

    if (!this.latest) {
      this.awsResourceList = [
        Utils.sortByProperty(
          rs,
          'lastModified',
          'desc'
        )[0]
      ]
    }
    this.loadNextChildResource()
  }

  onLoadResourceListError(err) {
    Utils.error(err)
  }

  onLoadResourceListComplete() { }

  override onLoadResourceContentComplete(): void {
    this.currentIdx = 0
    super.onLoadResourceContentComplete()
    console.log(this.resourceList);
    
  }

  override async onLoadResourceSuccess(result: any) {
    const fileData = this.awsResourceList[this.currentIdx]
    this.currentIdx++
    if (this.currentIdx < this.awsResourceList.length) {
      this.resourceList.push(new AWSUploadedFile(fileData, result))
      this.loadNextChildResource()
    } else {
      this.resourceList.push(new AWSUploadedFile(fileData, result))
      this.onLoadResourceContentComplete()
    }
  }
}
