import { AppMenuItem } from './../../../models/AppGenericClasses';
import { Input } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-module-menu-wrapper',
  templateUrl: './module-menu-wrapper.component.html',
  styleUrls: ['./module-menu-wrapper.component.sass']
})
export class ModuleMenuWrapperComponent {
  @Input() title = '';
  @Input() menuItems: AppMenuItem[] = []
}
