<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="flex flex-column gap-2 mb-4">
    <span class="p-float-label">
      <p-inputNumber
        [showButtons]="true"
        inputId="allowed_attempts"
        name="allowed_attempts"
        class="ng-dirty w-full"
        [min]="1"
        [required]="true"
        [(ngModel)]="form.allowed_attempts"
      ></p-inputNumber>
      <label for="allowed_attempts">{{
        "FORM.ALLOWED-ATTEMPTS" | translate
      }}</label>
    </span>
  </div>
  <div class="flex flex-column gap-2 mb-4">
    <label for="test_version_range_dates">{{
      "FORM.PLEASE-INDICATE-RANGE-DATES-AND-TIME-IN-WHICH-EVALUATION-WILL-BE-AVAILABLE"
        | translate
    }}</label>
    <div class="flex w-full align-items-center justify-content-center gap-3">
      <div class="flex-grow">
        <p-calendar
          id="version_date_from"
          name="version_date_from"
          [(ngModel)]="versionDateFrom"
          [showTime]="true"
          dateFormat="yy-mm-dd"
          class="w-full"
          inputStyleClass="w-full"
          appendTo="body"
        ></p-calendar>
      </div>
      <span class="flex">Hasta</span>
      <div class="flex-grow">
        <p-calendar
          id="version_date_to"
          name="version_date_to"
          [(ngModel)]="versionDateTo"
          [showTime]="true"
          dateFormat="yy-mm-dd"
          class="w-full"
          inputStyleClass="w-full"
          [minDate]="versionDateFrom"
          appendTo="body"
          (ngModelChange)="onVersionDateToChange()"
        ></p-calendar>
      </div>
    </div>
  </div>
  <div class="flex justify-content-end">
    <button
      pButton
      class="p-button-primary"
      label="{{ 'ENV.SAVE' | translate }}"
      [disabled]="!dataForm.valid"
      (click)="dataForm.ngSubmit.emit()"
      *ngIf="!processing"
    ></button>
  </div>
</form>
