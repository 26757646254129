/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { ELTestVersion, FormattableObjectProperty, PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { DateUtils } from 'src/app/shared/utils/date-utils';

@Component({
  selector: 'app-test-version-table',
  templateUrl: './test-version-table.component.html',
  styleUrls: ['./test-version-table.component.sass']
})
export class TestVersionTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('test.poll.title', 'FORM.TITLE', 250, { frozen: true }),
    new PrimeNGTableColumn('test.poll.review', 'FORM.TEST-DESCRIPTION', 300),
    new PrimeNGTableColumn('available_from', 'FORM.START', 200, { type: 'DATE-TIME' }),
    new PrimeNGTableColumn('available_to', 'FORM.END', 200, { type: 'DATE-TIME' }),
    new PrimeNGTableColumn('allowed_attempts', 'FORM.ATTEMPTS-ALLOWED', 200)
  ];
  override title = 'MENU.TESTS';
  override filterableFields = ['poll.title']
  override keys = {
    primary_key: 'el_test_version_id',
    label: 'test.poll.title'
  }
  override filename = 'MENU.TESTS';
  override dataFormatterProps: FormattableObjectProperty[] = [
    {
      field: 'available_from',
      formats: ['DATE-FORMATTED']
    },
    {
      field: 'available_to',
      formats: ['DATE-FORMATTED']
    }
  ];

  seeUsers(row: any) {
    this.utils.appDialog.seeTestVersionUserPickerTableDialog(
      row.el_test_version_id,
      row.test.poll.title,
      {
        onSuccess: () => {
          this.utils.closeAllDialogs()
        }
      }
    )
  }

  override getList() {
    this.query(this.data.core.testsVersions()).subscribe();
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.testVersionDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  versions(data: ELTestVersion) {
    localStorage.setItem('testName', data.test.poll.title)
    this.utils.router.navigateByUrl(
      `/tests/versions/${data.el_test_id}`
    );
  }

  override handleRows(data: any): void {
    data.forEach(row => {
      const currentDate = new Date()

      currentDate.setHours(0, 0, 0, 0)

      row.testTakeAvailable = DateUtils.isDateBetween(
        currentDate,
        DateUtils.strToDate(row.available_from),
        DateUtils.strToDate(row.available_to)
      ) &&
        row.users_available?.filter(ua => ua.user_id === this.user.user_id).length > 0 &&
        !(row.course_unit_tests_count > 0)

      row.menuItems = [
        {
          id: 'NEW-VERSION',
          label: `${this.utils.translate.instant('ENV.NEW-F')} ${this.utils.translate.instant('ENV.VERSION')}`,
          icon: 'pi pi-plus',
          command: () => {
            this.utils.appDialog.seeTestVersionFormDialog(row.el_test_id, {
              onSuccess: () => {
                this.getList()
              }
            })
          }
        },
        {
          id: 'SEE-VERSIONS',
          label: this.utils.translate.instant('ENV.SEE-VERSIONS'),
          icon: 'pi pi-eye',
          command: () => {
            this.versions(row)
          }
        },
        {
          id: 'AVAILABLE-USERS',
          label: this.utils.translate.instant('CORE.AVAILABLE-USERS'),
          icon: 'pi pi-users',
          command: () => {
            this.seeUsers(row)
          }
        },
        {
          id: 'NOTIFY',
          label: this.utils.translate.instant('ENV.NOTIFY'),
          icon: 'pi pi-bell',
          command: () => {
            this.utils.executeRequest(this.data.core.notifyTestVersion(row.el_test_version_id))
          }
        },
        {
          separator: true,
        },
        {
          id: 'INACTIVATE',
          label: this.utils.translate.instant('ENV.INACTIVATE'),
          icon: 'pi pi-ban',
          styleClass: 'text-danger',
          command: () => this.inactivate(row)
        }
      ] as MenuItem[]
    });
    super.handleRows(data)
  }

  onGoToEdit(row: any) {
    if (row.course_unit_tests_count > 0) {
      this.utils.fireConfirmation({
        message: this.utils.translate.instant('WIDGET.ON-EDIT-COURSE-TEST-WARNING'),
      },
        () => {
          this.goToEdit(row)
        });
    } else {
      this.goToEdit(row)
    }
  }

  goToEdit(row: any) {
    this.utils.router.navigateByUrl(`/admin/test/test-version/edit/${row.el_test_version_id}`)
  }

  onGoToQuestions(row: any) {
    if (row.course_unit_tests_count > 0) {
      this.utils.fireConfirmation({
        message: this.utils.translate.instant('WIDGET.ON-EDIT-COURSE-TEST-QUESTIONS-WARNING'),
      },
        () => {
          this.goToQuestions(row)
        });
    } else {
      this.goToQuestions(row)
    }
  }

  goToQuestions(row: any) {
    this.utils.router.navigateByUrl(`/admin/test/detail/${row.test.el_test_id}`)
  }
}
