import { Globals } from 'src/app/globals';
import { OptionalPrimeTableColumnParams, YouTubeEmbedParams, MediaPlayerConfig, AppPrimeNGDialogConfig } from 'src/app/models/AppGenericClasses';
export class GlobalUtils {

  static getYoutubeEmbedParams(obj?: YouTubeEmbedParams) {
    return { ...Globals.DEFAULT_YOUTUBE_EMBED_PARAMS, ...obj ?? {} }
  }

  static getOptionalPrimeTableColumnParams(obj?: OptionalPrimeTableColumnParams) {
    return { ...Globals.DEFAULT_OPTIONAL_PRIME_TABLE_COLUMN_PARAMS, ...obj ?? {} }
  }

  static getMediaPlayerConfig(obj?: MediaPlayerConfig) {
    return { ...Globals.DEFAULT_MEDIA_PLAYER_CONFIG, ...obj ?? {} }
  }

  static getPrimeDialogConfig(obj?: AppPrimeNGDialogConfig) {
    return { ...Globals.DEFAULT_PRIME_DIALOG_CONFIG, ...obj ?? {} }
  }
}
