/* eslint-disable @typescript-eslint/no-unused-vars */
import { UtilsService } from '../services/utils/utils.service';
import { Utils } from '../shared/utils/utils';
import {
  AppFormattedList,
  AppItemListFilter,
  AppJSONSanitizer,
  AppObjectFilterItem,
} from './AppGenericClasses';
import { AppSelectableItem, AppSortableItem } from './AppModels';
import { AppParser, CourseTopicParser } from './AppParsers';
import {
  CourseEnrollment,
  CourseEnrollmentJSON,
} from './CourseEnrollmentClasses';
import { CourseTopic, CourseTopicJSON } from './CourseTopicClasses';
import { CourseUnit, CourseUnitJSON } from './CourseUnitClasses';
import { CourseUnitSection } from './CourseUnitSectionClasses';
import { CourseUnitTest } from './CourseUnitTestClasses';

export class CourseJSON {
  el_course_id = 0;
  name_ = '';
  description_ = '';
  tags = '';
  estimated_duration = '';
  validity_period = '';
  objective = '';
  status_ = 0;
  real_duration = 0;

  course_topics: CourseTopicJSON[] = [];
  units: CourseUnitJSON[] = [];

  course_enrollments_count = 0;
  course_user_available_count = 0;
  course_current_user_enrollment: CourseEnrollmentJSON =
    new CourseEnrollmentJSON();
  course_enrollments: CourseEnrollmentJSON[] = [];
  all_courses_enrollments: CourseEnrollmentJSON[] = [];
}

export class CourseParser extends AppParser<Course, CourseJSON> {
  constructor(data: CourseJSON, utils: UtilsService) {
    super(data, CourseJSON);
    this.result = Object.setPrototypeOf(
      {
        id: this.data.el_course_id,
        name: this.data.name_,
        description: this.data.description_,
        tags: this.data.tags,
        estimatedDuration: this.data.estimated_duration,
        validityPeriod: this.data.validity_period,
        objective: this.data.objective,
        courseTopics: this.data.course_topics?.map((cTopic) => {
          return new CourseTopicParser(cTopic).result;
        }),
        real_duration: 233,
        units: this.data.units.map((unit) => {
          return {
            name: unit.name_,
            description: unit.description_,
            sections: unit.sections.map((section) => {
              return new CourseUnitSection(section);
            }),
            tests: unit.tests.map((uTest) => {
              return new CourseUnitTest(uTest, utils);
            }),
          };
        }),
      } as Course,
      Course.prototype
    );
  }
}

export class Course implements AppSortableItem, AppSelectableItem<Course> {
  id_: number;
  name_: string;
  description: string;
  tags: string;
  estimatedDuration: string;
  validityPeriod: string;
  objective: string;
  enrollmentsCount: number;
  userAvailableCount: number;
  active: boolean;
  enrrollments: CourseEnrollment[];
  Courseenrrollments: CourseEnrollment[];
  courseTopics: CourseTopic[];
  units: CourseUnit[];
  currentUserEnrollment: CourseEnrollment;
  real_duration: number;
  source: CourseJSON;

  constructor(source: CourseJSON, utils: UtilsService) {
    this.source = new AppJSONSanitizer<CourseJSON>(source, CourseJSON).result;
    this.id = this.source.el_course_id;
    this.name = this.source.name_;
    this.description = this.source.description_;
    this.tags = this.source.tags;
    this.estimatedDuration = this.source.estimated_duration;
    this.validityPeriod = this.source.validity_period;
    this.objective = this.source.objective;
    this.enrollmentsCount = this.source.course_enrollments_count;
    this.userAvailableCount = this.source.course_user_available_count;
    this.active = this.source.status_ != 0 ? true : false;
    this.courseTopics = this.source.course_topics.map(
      (courseTopic) => new CourseTopic(courseTopic, utils)
    );
    this.currentUserEnrollment = new CourseEnrollment(
      this.source.course_current_user_enrollment,
      utils
    );
    this.enrrollments = this.source.course_enrollments.map(
      (c) => new CourseEnrollment(c, utils)
    );
    this.Courseenrrollments = this.source.all_courses_enrollments.map(
      (c) => new CourseEnrollment(c, utils)
    );
    this.units = this.source.units.map((u) => new CourseUnit(u, utils));
  }

  get id(): number {
    return this.id_;
  }
  set id(value: number) {
    this.id_ = value;
  }

  get name(): string {
    return this.name_;
  }
  set name(value: string) {
    this.name_ = value;
  }

  get sortValue(): string {
    return this.name;
  }

  get value(): Course {
    return this;
  }

  get unitCount() {
    return this.units.length;
  }

  get sectionCount() {
    return Utils.sumArrayByKey(this.units, 'sections.length');
  }

  get pendingEnrollmentsCount() {
    const value = this.userAvailableCount - this.enrollmentsCount;
    return value < 0 ? 0 : value;
  }

  get timeDuration() {
    return Utils.sumTimeCourse(this.units);
  }

  get supportContentCount() {
    return this.units
      .flatMap((unit) => unit.sections)
      .reduce(
        (count, section) => count + (section.supportContents.length || 0),
        0
      );
  }

  get testCount() {
    return this.units.flatMap((unit) => unit.tests).length;
  }

  get videoTimeSum() {
    return (
      this.units
        .flatMap((unit: CourseUnit) => unit.sections)
        .reduce(
          (sum: number, section: CourseUnitSection) =>
            sum +
            (section.resourceContent.type === 'VIDEO'
              ? section.resourceContent.minViewingTime || 0
              : 0),
          0
        ) / 3600
    );
  }

  get tagsArr() {
    return this.tags.split(',').map((tag) => tag.trim());
  }
}

export class CourseList extends AppFormattedList<Course, CourseJSON> {
  override filter: CourseListFilter;

  constructor(utils: UtilsService) {
    super(utils);
    this.ItemModel = Course;
  }

  override setFilterInstance(): void {
    this.filter = new CourseListFilter();
  }
}

export class CourseListFilter extends AppItemListFilter<Course> {
  tagFilter!: { options: string[]; value: string[] };
  courseFilter!: { options: string[]; value: string[] };
  courseTopicFilter!: AppObjectFilterItem<
    AppSelectableItem<CourseTopic>,
    CourseTopic[]
  >;
  ProgressCourseFilter!: { options: any[]; value: any };

  initFilters(): void {
    super.initFilters();
    this.tagFilter = {
      options: Utils.uniq(this.baseItems.map((item) => item.tagsArr).flat()),
      value: [],
    };
    this.courseFilter = {
      options: Utils.uniq(
        this.baseItems.map((item) => {
          return {
            id: item.id_,
            name_: item.name_,
          };
        })
      ),
      value: [],
    };
    this.courseTopicFilter = {
      options: Utils.removeDuplicatesById(
        this.baseItems.map((item) => item.courseTopics).flat()
      ),
      value: [],
    };
    this.ProgressCourseFilter = {
      options: [
        {
          label: 'TODOS',
          value: 'ALL',
        },
        {
          label: 'Aprobados',
          value: 'F',
        },
        {
          label: 'En curso',
          value: 'C',
        },
        {
          label: 'Reprobados',
          value: 'A',
        },
      ],
      value: [],
    };
    this.filterManager.filters = [
      this.tagFilter,
      this.courseTopicFilter,
      this.ProgressCourseFilter,
    ];
  }

  filterData() {
    return this.filteredItems.filter((item) => {
      return (
        (!this.tagFilter.value.length ||
          item.tagsArr.some((item) => this.tagFilter.value.includes(item))) &&
        (!this.courseTopicFilter.value.length ||
          item.courseTopics.some((item) =>
            this.courseTopicFilter.value.find((slItem) => slItem.id === item.id)
          ))
      );
    });
  }
}
