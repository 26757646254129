import { Component } from '@angular/core';
import { BaseMediaPlayerDirective } from 'src/app/directives/base-media-player.directive';

@Component({
  selector: 'app-aws-html-viewer',
  templateUrl: './aws-html-viewer.component.html',
  styleUrls: ['./aws-html-viewer.component.sass'],
})
export class AwsHtmlViewerComponent extends BaseMediaPlayerDirective {
}
