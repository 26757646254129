/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { AppExportableList, AppFormattedList, PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { Utils } from 'src/app/shared/utils/utils';
import { BaseDirective } from '../base.directive';
import { Table } from 'primeng/table';
import autoTable from 'jspdf-autotable';
import { AppSelectableItem, AppSortableItem } from 'src/app/models/AppModels';
import { UtilsService } from 'src/app/services/utils/utils.service';
import html2canvas from 'html2canvas';


@Directive({
  selector: '[appBaseItemList]',
})
export abstract class BaseItemListDirective<
  Item extends AppSortableItem & AppSelectableItem<Item>,
  Source,
  List extends AppFormattedList<Item, Source>
> extends BaseDirective implements AppExportableList {
  @ViewChild(Table) dt!: Table
  @Input() source: Source[]
  @Input() showActions = true
  @Input() showDescription = true
  @Input() fireActions = true
  @Input() presentation = 1
  @Input() rowsPerPageOptions = [10, 25, 50]
  @Input() idsExcluded: number[] = []
  @Input() idsSelected: number[] = []
  @Input() horizontal = false
  @Input() itemSelection;
  wildCards = [];
  itemSelected: { [key: string]: any } = {};
  @Output() inactivationSuccess = new EventEmitter<any>()
  override searchEnabled = true
  rowsPerPage = this.rowsPerPageOptions[0]
  loading = false
  multiple = true

  filename = '';
  availableColumns: PrimeNGTableColumn[] = []
  columns: PrimeNGTableColumn[] = []
  isColumnToggleVisible = false

  inactivationService: Observable<any> | undefined
  activationService: Observable<any> | undefined

  showSortableItems = false
  showCustomFilters = false
  showCustomCourses = false
  searchText_: string

  updateItemService: Observable<any> = null
  updateItemBatchService: Observable<any> = null
  updateOrderService: Observable<any> = null

  ListModel: new (utils: UtilsService) => List
  itemList!: List
  editedItem: Item

  private editRoute_: string = ""
  protected localUserFiltersKey

  assignLocalUserFilters() {
    if (this.localUserFiltersKey) {
      localStorage.setItem(this.localUserFiltersKey, JSON.stringify(this.dt.filters))
    }
  }

  resetDTFilters() {
    this.dt.clear()
    this.assignLocalUserFilters()
    this.utils.global.search.next("")
    this.searchText = ""
  }

  trackByFn = (item: Item) => {
    return item.id;
  };

  override onAfterViewInit() {
    super.onAfterViewInit()
    if (this.dt) {
      this.dt.onRowReorder.subscribe(_ => this.onReorder())

      if (this.localUserFiltersKey) {
        const localUserFiltersJSONString = localStorage.getItem(this.localUserFiltersKey)

        if (localUserFiltersJSONString) {
          this.dt.filters = JSON.parse(localUserFiltersJSONString)
        }
      }
      this.dt.onFilter.subscribe(_ => {
        this.assignLocalUserFilters()
      })
    }
  }

  initItemListConfig() {
    this.itemList.idsExcluded = this.idsExcluded
    this.itemList.idsSelected = this.idsSelected
  }

  override onLanguageInit() {
    this.itemList = new this.ListModel(this.utils)
    super.onLanguageInit();
    this.initComponent()
  }

  initComponent() {
    this.manageColumns()
    this.getList();
  }

  override manageChanges(changes: SimpleChanges): void {
    super.manageChanges(changes)
    if ('source' in changes) {
      this.handleRows(this.source)
    }
  }

  override handleDialogConfigData() {
    super.handleDialogConfigData()
    if (!this.idsExcluded.length) {
      this.idsExcluded = this.dialogConfig.data.idsExcluded ?? this.idsExcluded;
    }
    if (!this.idsSelected.length) {
      this.idsSelected = this.dialogConfig.data.idsSelected ?? this.idsSelected;
    }
    this.multiple = this.dialogConfig.data.multiple ?? this.multiple;
    this.initItemListConfig()
  }

  override setGlobalBehaviors() {
    super.setGlobalBehaviors()
    this.utils.global.search.next('');
  }

  getList(): void {
    this.query(this.getQuery()).subscribe()
  }

  getQuery(): Observable<any> {
    return
  }

  query(fn: Observable<any>): Observable<any> {
    this.loading = true;
    return new Observable<any>((subscriber) => {
      fn.subscribe({
        next: (data) => {
          this.handleRows(data);
          subscriber.next();
          subscriber.complete();
        },
        error: (error) => {
          Utils.error(error);
          this.loading = false;
          subscriber.error(error);
          subscriber.complete();
        },
      });
    });
  }

  handleRows(data: Source[]) {
    this.source = data
    this.loading = false
    this.itemList.source = data
    console.log(this.itemList);
  }

  inactivate(row: Item) {
    this.handleItemSelected(row);
    this.utils.fireConfirmation({
      message: this.utils.translate.instant('QUESTION.INACTIVATE', {
        value: row.name
      }),
    },
      () => {
        this.utils.executeRequest(this.inactivationService, {
          onSuccess: () => this.handleInactivationSuccess()
        })
      });
  }

  handleInactivationSuccess(): void {
    this.utils.toast.deleted(this.title);
    this.inactivationSuccess.emit(this.itemList.itemSelected)
    this.getList();
  }

  handleItemSelected(row: Item) {
    this.itemList.itemSelected = row;
    this.refreshActionServices();
  }

  abstract refreshActionServices(): void

  manageColumns() {
    this.columns = this.availableColumns.filter((item) => item.visible);
    this.columns.forEach((item) => {
      item.header = this.utils.translate.instant(item.header);
    });
  }

  isColumnVisible(field: string): boolean {
    return this.columns.filter((item) => item.field === field).length > 0;
  }

  openColumnSidebar() {
    this.isColumnToggleVisible = true;
  }

  getColumn(field) {
    return this.columns.filter((column) => column.field === field)[0];
  }

  exportCSV(filter = false) {
    this.dt.exportCSV({ selectionOnly: filter });
  }

  exportExcel(selected = false) {
    this.exportDataFormatted();
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        this.exportDataFormatted(selected)
      );
      worksheet['!cols'] = this.columns
        .filter((it) => it.visible)
        .map((col) => {
          return { wpx: col.width / 1.5 };
        });
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(
        excelBuffer,
        this.utils.translate.instant(this.filename)
      );
    });
  }

  exportPdf(selected = false) {
    const filename =
      this.utils.translate.instant(this.filename).toLowerCase() +
      '_export_' +
      new Date().getTime();
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const cols = this.exportPDFColumns();
        const doc = new jsPDF.default('l', 'mm', [cols.length * 60, 210]);
        autoTable(doc, {
          body: this.exportDataPDFFormatted(selected),
          columns: cols,
        });
        doc.save(`${filename}.pdf`);
      });
    });
  }

  exportPDFColumns() {
    const finalColumns: string[] = [];
    this.columns
      .filter((it) => it.visible)
      .forEach((c) => {
        if ('complementaryHeader' in c && c.complementaryHeader) {
          finalColumns.push(c.header + ' - ' + c.complementaryHeader);
        } else {
          finalColumns.push(c.header);
        }
      });
    return finalColumns;
  }

  exportFinalRows(selected: boolean) {
    let finalRows: any[];
    if (selected) {
      finalRows = this.itemList.selectedRows;
    } else {
      if (this.dt.filteredValue) {
        finalRows = this.dt.filteredValue;
      } else {
        finalRows = this.itemList.rows;
      }
    }
    return finalRows;
  }

  exportDataPDFFormatted(selected: boolean) {
    const finalRows = this.exportFinalRows(selected);
    const data: string[][] = [];
    finalRows.forEach((r) => {
      const row: string[] = [];
      this.columns
        .filter((it) => it.visible)
        .forEach((c) => {
          row.push(this.exportValue(c, r));
        });
      data.push(row);
    });
    return data;
  }

  exportDataFormatted(selected = false) {
    const finalRows = this.exportFinalRows(selected);
    const data: string[][] = [];
    finalRows.forEach((r) => {
      const row: string[] = [];
      const columnsCopy = [
        {
          field: 'id',
          header: 'ID',
          visible: true
        },
        ...this.columns,
      ];
      columnsCopy
        .filter((it) => it.visible)
        .forEach((c) => {
          if ('complementaryHeader' in c && c.complementaryHeader) {
            row[c.header + ' - ' + c.complementaryHeader] = this.exportValue(
              c,
              r
            );
          } else {
            row[c.header] = this.exportValue(c, r);
          }
        });
      data.push(row);
    });
    return data;
  }

  exportValue(column, row): string {
    if (column.capitalize) {
      return Utils.capitalizeEachWord(Utils.ngCellValue(column.field, row));
    }
    return Utils.ngCellValue(column.field, row);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    this.utils.fileSaverService.save(
      data,
      fileName.toLowerCase() +
      '_export_' +
      new Date().getTime() +
      EXCEL_EXTENSION
    );
  }

  setReorderMethod() {
  }

  onReorder() {
    this.beforeReorder();
    this.setReorderMethod();
    this.updateOrder();
  }

  beforeReorder() {
    this.itemList.rows.forEach((row, idx) => {
      row.sortValue = idx.toString();
    });
  }

  updateOrder() {
    this.utils.executeRequest(this.updateOrderService)
  }

  seeSortableItems() {
    this.showSortableItems = true
  }

  seeCustomFilters() {
    this.showCustomFilters = true
  }

  seeCustomCourses() {
    this.showCustomCourses = true
  }

  onCustomFilter() {
    this.itemList.rows = this.itemList.filter.filterData()
  }

  cleanCustomFilters() {
    this.itemList.rows = this.itemList.filter.clean()
  }

  cloneCourse() {
    this.updateRowsInBatch();
    this.cleanCustomFilters();

  }


  setEditMethod() { }

  setUpdateItemMethod(data: Partial<Item>) {
    throw "Method not implemented."
  }

  setUpdateItemBatchMethod() {
    throw "Method not implemented."
  }

  onEditComplete(row: Item) {
    this.editedItem = row

    if (row) {
      const data = {}

      Object.keys(row).forEach(field => {
        const editableFields = this.columns.filter(column => column.editable).map(column => column.field)

        if (editableFields.includes(field)) { data[field] = row[field] }
      })

      this.setUpdateItemMethod(data)

      if (this.updateItemService) {
        this.utils.executeRequest(this.updateItemService, {
          onSuccess: () => {
            this.utils.toast.saved(this.title)
          }
        })
      }
    }
  }


  generateTablePdf() {

    this.isPdfTableDialogVisible = true;
  }


  generateTablePDF(type, data) {

    let tempRows = [];

    let text = type.html;
    let match = /\[var:[^\]]+\]/g;

    let patrones = text.match(match);


    if (patrones) {
      patrones.forEach(patron => {
        text = this.replaceWildcard(text, patron);
      });
    }






    const div = document.createElement('div');
    div.id = 'container-pdf'








    let tabla = document.createElement("table");
    tabla.id = "miTabla";

    let thead = document.createElement("thead");

    let encabezado = "<tr>";

    this.selectedDataColumns
      .filter((it) => it.visible)
      .forEach(c => {
        encabezado += ` <th>${c.header}</th>`
      });

    encabezado += "</tr>";




    thead.innerHTML = encabezado;
    tabla.appendChild(thead);

    var tbody = document.createElement("tbody");













    this.selectedDataTable.forEach((r) => {
      const row: string[] = [];
      this.selectedDataColumns
        .filter((it) => it.visible)
        .forEach((c) => {
          row.push(this.exportValue(c, r));
        });
      tempRows.push(row);
    });


    tempRows.forEach(r => {
      let fila = "<tr>";
      r.forEach(value => {

        fila += "<td>" + value + "</td>";

      });

      fila += "</tr>";
      tbody.innerHTML += fila;

    })

    tabla.appendChild(tbody);


    div.innerHTML = text + tabla.outerHTML;
    document.body.appendChild(div);
    this.onPrint('container-pdf')


    /*     const filename =
          this.utils.translate.instant(this.filename).toLowerCase() +
          '_export_' +
          new Date().getTime();


        html2canvas(div).then(canvas => {

          import('jspdf').then((jsPDF) => {

            const cols = this.selectedDataColumns;
            const doc = new jsPDF.default('l', 'px');

            const pageWidth = doc.internal.pageSize.getWidth();



            import('jspdf-autotable').then(() => {

              let imgWidth = pageWidth;
              let imgHeight = canvas.height * imgWidth / canvas.width;

              const contentDataURL = canvas.toDataURL('image/png')
              let position = 20;
              doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

              autoTable(doc, {
                body: tempRows,
                columns: cols,
                startY: imgHeight + 30,
                theme: 'grid'
              });
              doc.save(`${filename}.pdf`);
              div.remove();

            })


          });

        }) */


  }

  onPrint(divName) {
    let printContents;
    let popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html lang="es">
      <head>
        <link id="theme-link" rel="stylesheet" type="text/css" href="styles.css">
        <style>
        @media print {
          ::ng-deep {
            .p-sidebar-header
              display: none
          }

          .table-container{
            margin: 1rem 0;
            padding: 0 1rem;
            height: auto !important;
          }

          tr, th {
            border-color: var(--surface-300);
            border-bottom: 1px solid black;
            border-top: 1px solid black;
            border-collapse: collapse;
            padding: .5rem;
          }

          td {
            padding: .5rem;
          }

          .table-container, .p-sidebar-content, .order-summary-container {
            overflow: unset
          }

          .btn-pdf {
            display: none
          }

                  .styled-table {
            border-collapse: collapse;
            margin: 25px 0;
            font-size: 18px;
            text-align: left;
        }
        .styled-table th, .styled-table td {
            padding: 12px 15px;
        }
        .styled-table thead tr {
            background-color: #009879;
            color: #ffffff;
            text-align: left;
        }
        .styled-table tbody tr:nth-of-type(even) {
            background-color: #f3f3f3;
        }
        .styled-table tbody tr:hover {
            background-color: #f1f1f1;
        }
        }
        </style>
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>`);
    popupWin.document.close();
    document.getElementById(divName).remove();
  }


  getHtmlHeight(element) {
    const clone = element.cloneNode(true);
    clone.style.visibility = 'hidden';
    document.body.appendChild(clone);
    const height = clone.offsetHeight;
    const htmlWidth = clone.offsetWidth;
    document.body.removeChild(clone);
    return { height, htmlWidth };
  }


  replaceWildcard(texto, variable) {

    const regex = /\[var:(\w+)\]/;

    let wCard = this.wildCards.find(w => w.key_ === variable.match(regex)[1]);
    let patron = new RegExp(variable.replace(/[\[\]]/g, '\\$&'), 'g');

    if (wCard.type_ === 'TEXT') {
      return texto.replace(patron, wCard.content);
    } else {
      /* let content = texto.replace(patron, wCard.content); */
      let parts = wCard.content.split('.')





      switch (parts[0]) {
        case 'el_course':

          if (parts[1] === 'final_note') {
            if ('unitTestsAverageScore' in this.itemSelection) {
              return texto.replace(patron, this.itemSelection.unitTestsAverageScore)
            } else {
              return texto.replace(patron, 'Sin nota');
            }
          }

          if (this.courseDetail !== null) {
            return texto.replace(patron, this.courseDetail[parts[1]])
          } else {
            if (this.itemSelection) {
              //! todo change
              return texto.replace(patron, '')

            } else {
              return texto.replace(patron, 'Sin curso')
            }
          }
        case 'el_course_unit':
          if (this.UnitDetail !== null) {
            return texto.replace(patron, this.UnitDetail[parts[1]])
          } else {
            if ('course_units' in this.itemSelection) {
              return texto.replace(patron, this.itemSelection.course_units.map(u => u[parts[1]]).join(', '))
            } else {
              return texto.replace(patron, 'Sin unidades')
            }

          }

        case 'el_test':


          if (parts[1] === 'name_') {
            if (this.testDetail !== null) {
              return texto.replace(patron, this.testDetail.poll.title)
            } else {
              return texto.replace(patron, 'Sin Evaluaciones')
            }
          }

          if (this.testDetail !== null) {
            return texto.replace(patron, this.testDetail[parts[1]])
          } else {
            if ('tests' in this.itemSelection) {
              return texto.replace(patron, this.itemSelected.tests.map(u => u.name_).join(', '))
            } else {
              if ('course_units' in this.itemSelection) {
                if ('tests' in this.itemSelection.course_units[0]) {
                  return texto.replace(patron, this.itemSelection.course_units.map(cu => cu.tests[0].test.title).join(', '))
                }
              }
              return texto.replace(patron, 'Sin Evaluaciones')
            }

          }

        case 'user_':
          if (parts[1] === 'user_post') {
            return texto.replace(patron, this.itemSelection.user.post.name_);
          }
          if (parts[1] === 'enrolled_at') {
            return texto.replace(patron, this.itemSelection[parts[1]])
          }
          if (this.userDetail !== null) {
            return texto.replace(patron, this.userDetail[parts[1]])
          } else {
            if ('user' in this.itemSelection) {
              return texto.replace(patron, this.itemSelection.user[parts[1]])
            } else {
              return texto.replace(patron, 'Sin Usuario')
            }
          }



        default:
          return texto
          break;
      }

    }



  }


  updateRowsInBatch() {
    this.setUpdateItemBatchMethod()

    if (this.updateItemBatchService) {
      this.utils.executeRequest(this.updateItemBatchService, {
        onSuccess: () => {
          this.utils.toast.saved(this.title)
        }
      })
    }
  }

  edit(row: any, newPage: boolean = false) {
    this.handleItemSelected(row)
    this.onEdit(newPage)
  }

  onEdit(newPage: boolean = false) {
    if (newPage) {
      const url = this.utils.router.serializeUrl(
        this.utils.router.createUrlTree([this.editRoute])
      );

      window.open(url, '_blank');
    } else {
      this.utils.router.navigate([this.editRoute])
    }
  }

  set searchText(value: string) {
    this.searchText_ = value
    this.itemList.applyFilter(value)
  }
  get searchText() {
    return this.searchText_
  }

  get editRoute() {
    return this.editRoute_
  }
}
