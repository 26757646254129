<div class="flex flex-column h-full">
  <div class="flex align-items-center px-2" style="height: auto">
    <ng-container *ngIf="parentLibraryId > 0 && parentLibrary">
      <div class="flex flex-column justify-content-start">
        <h5 class="module-subtitle cursor-pointer" routerLink="/libraries">
          {{ "MENU.LIBRARIES" | translate | uppercase }}
        </h5>
        <h2 class="module-title">
          {{ parentLibrary.name_ }}
        </h2>
      </div>
      <div class="flex mx-4">
        <i
          class="pi pi-angle-double-right"
          style="font-size: 2rem; color: var(--primary-color)"
        ></i>
      </div>
    </ng-container>
    <ng-container *ngIf="parentTestQuestionId > 0 && parentTestQuestion">
      <div class="flex flex-column justify-content-start">
        <h5 class="module-subtitle cursor-pointer" (click)="back()">
          {{ "MENU.QUESTIONS" | translate | uppercase }}
        </h5>
        <h2
          class="module-title cursor-pointer"
          (click)="seeTestQuestion()"
          pTooltip="{{ 'CORE.QUESTION' | translate }} | {{
            'ENV.SEE-DETAIL' | translate
          }}"
          tooltipPosition="bottom"
        >
          {{ parentTestQuestion.el_test.poll.title }} |
          {{ "CORE.QUESTION" | translate }}
        </h2>
      </div>
      <div class="flex mx-4">
        <i
          class="pi pi-angle-double-right"
          style="font-size: 2rem; color: var(--primary-color)"
        ></i>
      </div>
    </ng-container>
    <ng-container *ngIf="parentUnitSectionId > 0 && parentUnitSection">
      <div class="flex flex-column justify-content-start">
        <h5 class="module-subtitle cursor-pointer" (click)="back()">
          {{ "FORM.SECTIONS" | translate | uppercase }}
        </h5>
        <h2 class="module-title cursor-pointer">
          {{ parentUnitSection.name_ }} |
          {{ "FORM.SECTION" | translate }}
        </h2>
      </div>
      <div class="flex mx-4">
        <i
          class="pi pi-angle-double-right"
          style="font-size: 2rem; color: var(--primary-color)"
        ></i>
      </div>
    </ng-container>
    <div class="flex flex-column justify-content-start">
      <h2 class="module-title cursor-pointer" routerLink="/contents">
        {{ "MENU.CONTENTS" | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color)"
      ></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW") | translate }}
        {{ "CORE.CONTENT" | translate | lowercase }}
      </h2>
    </div>

    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="
          !dataForm.valid ||
          (iuUploadedFileList &&
            !iuUploadedFileList?.resourceList.length &&
            !files.length) ||
          (iuCover && !iuCover?.files.length && !iuCover?.image)
        "
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column lg:flex-row mb-4">
            <div class="flex w-12 lg:w-4 pr-0 lg:pr-4">
              <app-image-upload
                #iuCover
                class="w-full"
                style="max-height: 215px"
                [path]="'content'"
                [id]="editId"
                [field]="'cover'"
                [required]="true"
              >
              </app-image-upload>
            </div>
            <div class="flex flex-column flex-grow-1">
              <div class="flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
                <span class="p-float-label">
                  <input
                    pInputText
                    id="title"
                    name="title"
                    class="ng-dirty w-full"
                    aria-describedby="title-help"
                    [(ngModel)]="form.name_"
                    required
                  />
                  <label for="title">{{ "FORM.TITLE" | translate }}*</label>
                </span>
              </div>
              <div class="flex flex-column gap-2 mt-2">
                <span class="p-float-label">
                  <textarea
                    pInputTextarea
                    id="description"
                    name="description"
                    rows="5"
                    class="ng-dirty w-full"
                    [(ngModel)]="form.description_"
                    required
                  ></textarea>
                  <label for="description"
                    >{{ "FORM.DESCRIPTION" | translate }}*</label
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-column gap-2 mb-2">
            <label for="private">
              {{ "FORM.MIN-VIEWING-TIME" | translate }}*
            </label>
            <div class="grid">
              <div class="col-fixed" [style]="{ width: '100%' }">
                <app-chronometer
                  [(ngModel)]="chronometer"
                  name="chronometer"
                  [required]="true"
                ></app-chronometer>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap gap-3 mb-4">
            <div class="block w-12">
              <label for="private"
                >{{ "QUESTION.PRIVATE-CONTENT" | translate }}*</label
              >
            </div>
            <div class="flex align-items-center">
              <p-radioButton
                name="private"
                value="1"
                [(ngModel)]="form.private"
                inputId="private1"
                class="ng-dirty"
                [required]="true"
              ></p-radioButton>
              <label for="private1" class="ml-2">{{
                "GENERAL.YES" | translate
              }}</label>
            </div>
            <div class="flex align-items-center">
              <p-radioButton
                name="private"
                value="0"
                [(ngModel)]="form.private"
                inputId="private2"
                class="ng-dirty"
                [required]="true"
              ></p-radioButton>
              <label for="private2" class="ml-2">{{
                "GENERAL.NO" | translate
              }}</label>
            </div>
          </div>
          <div class="flex flex-column gap-2 my-4">
            <label for="tags">{{ "FORM.TAGS" | translate }}</label>
            <p-chips
              inputId="tags"
              name="tags"
              class="w-full"
              [separator]="globals.REG_EXP.chipFieldSeparator"
              [placeholder]="'WIDGET.CHIP-FIELD-EXAMPLE' | translate"
              [(ngModel)]="tags"
            ></p-chips>
          </div>
          <div class="flex flex-column gap-2 mb-4">
            <label for="type">{{ "FORM.CONTENT-TYPE" | translate }}*</label>
            <p class="text-color-secondary mt-0">
              {{ "WIDGET.UPLOAD-FILE-OR-SELECT-CONTENT-OPTION" | translate }}
            </p>
            <p-message
              severity="warn"
              text="
                Los PDFs no pueden pesar mas de 5MB y los archivos de mas de
                2MB o que contengan mas de 100 paginas pueden afectar el
                funcionamiento de los aplicativos móviles"
            >
            </p-message>
            <div class="text-center mb-3">
              <p-selectButton
                [options]="contentTypeOptions"
                [(ngModel)]="form.type_"
                id="content-type"
                name="content-type"
                optionLabel="label"
                optionValue="value"
                class="ng-dirty"
                [required]="true"
                [disabled]="iuUploadedFileList?.downloading"
                (onChange)="onContentTypeChange()"
              >
                <ng-template let-item pTemplate>
                  {{ item.label | translate }}
                </ng-template>
              </p-selectButton>
            </div>
            <div *ngIf="['AUDIO', 'VIDEO', 'PDF', 'IMG'].includes(form.type_)">
              <div class="grid">
                <div class="col flex flex-column">
                  <h3 class="mt-0">{{ "FORM.NEW-FILE" | translate }}</h3>
                  <app-drag-drop
                    appBaseFileUploader
                    [multiple]="false"
                    [accepted]="globals.CONTENT_TYPES[form.type_].fileType"
                    [(ngModel)]="files"
                    [required]="editId <= 0"
                    [path]="path"
                    [field]="form.type_"
                    [files]="files"
                    (uploadState)="handleFilesUploadState($event)"
                    id="resources"
                    name="resources"
                  ></app-drag-drop>
                </div>
                <div class="lg:col md:col-7 col-12" *ngIf="editId > 0">
                  <h3 class="mt-0">{{ "FORM.UPLOADED-FILE" | translate }}</h3>
                  <app-aws-uploaded-file-list
                    #iuUploadedFileList
                    [path]="path"
                    [field]="form.type_"
                    [latest]="false"
                    [ext]="globals.CONTENT_TYPES[form.type_].ext"
                    [editId]="editId"
                    [showDeleteButton]="false"
                  ></app-aws-uploaded-file-list>
                </div>
              </div>
            </div>
            <div *ngIf="form.type_ === 'PLAIN'">
              <div style="height: 300px">
                <app-html-editor
                  name="content_plain"
                  [(ngModel)]="contentPlain"
                  [required]="true"
                ></app-html-editor>
              </div>
            </div>
            <div class="flex flex-column gap-2" *ngIf="form.type_ === 'URL'">
              <label for="url">URL</label>
              <input
                pInputText
                type="text"
                [(ngModel)]="contentURL"
                required
                class="ng-dirty"
                id="url"
                name="url"
                placeholder="https://example.com"
              />
            </div>
            <ng-container *ngIf="form.type_ === 'VIDEO-URL'">
              <app-video-url-field
                id="video_url"
                name="video_url"
                [(ngModel)]="contentVideoURL"
                [required]="true"
              ></app-video-url-field>
            </ng-container>
          </div>
          <div
            class="flex flex-column gap-2 my-4"
            *ngIf="['VIDEO', 'VIDEO-URL'].includes(form.type_)"
          >
            <label for="tags">{{ "FORM.TRANSCRIPTION" | translate }}</label>
            <app-html-editor
              name="transcription"
              [(ngModel)]="form.transcription"
              [style]="{ height: '300px' }"
            ></app-html-editor>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
