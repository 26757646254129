<div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h2 class="module-title cursor-pointer" routerLink="/configuration/user">
        {{ "MENU.USERS" | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-3">
      <i
        class="pi pi-angle-double-right"
        style="font-size: 2rem; color: var(--primary-color)"
      ></i>
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? "ENV.EDIT" : "ENV.NEW") | translate }}
        {{ "CORE.USER" | translate | lowercase }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column lg:flex-row mb-4">
            <div class="flex flex-column flex-grow-1">
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <input
                  pInputText
                  id="name"
                  name="name"
                  class="ng-dirty"
                  aria-describedby="title-help"
                  [(ngModel)]="form.first_name"
                  maxlength="100"
                  required
                />
                <label for="name">{{ "FORM.NAME" | translate }}</label>
              </div>
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <input
                  pInputText
                  id="surname"
                  name="surname"
                  class="ng-dirty"
                  aria-describedby="title-help"
                  [(ngModel)]="form.last_name"
                  maxlength="100"
                  required
                />
                <label for="surname">{{ "FORM.SURNAME" | translate }}</label>
              </div>
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="dni-type"
                  name="dni-type"
                  [options]="data.sources['dni-type'].data"
                  [(ngModel)]="form.dni_type_id"
                  [filter]="true"
                  appendTo="body"
                  [optionValue]="data.sources['dni-type'].key"
                  [optionLabel]="data.sources['dni-type'].label"
                >
                </p-dropdown>
                <label for="dni-type">{{
                  "CORE.DOCUMENT-TYPE" | translate
                }}</label>
              </div>
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <input
                  pInputText
                  id="dni"
                  name="dni"
                  class="ng-dirty"
                  aria-describedby="title-help"
                  [(ngModel)]="form.dni"
                  maxlength="100"
                  required
                />
                <label for="dni">{{ "FORM.DNI" | translate }}</label>
              </div>
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <input
                  pInputText
                  id="email"
                  name="email"
                  class="ng-dirty"
                  aria-describedby="title-help"
                  [(ngModel)]="form.email"
                  maxlength="100"
                  required
                />
                <label for="dni">{{ "AUTH.EMAIL" | translate }}</label>
              </div>
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="city"
                  name="city"
                  [options]="data.sources['city'].data"
                  [(ngModel)]="form.city_id"
                  [filter]="true"
                  appendTo="body"
                  [optionValue]="data.sources['city'].key"
                  [optionLabel]="data.sources['city'].label"
                >
                </p-dropdown>
                <label for="city">{{ "CORE.CITY" | translate }}</label>
              </div>
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="group"
                  name="group"
                  [options]="data.sources['group'].data"
                  [(ngModel)]="form.user_group_id"
                  [filter]="true"
                  appendTo="body"
                  [optionValue]="data.sources['group'].key"
                  [optionLabel]="data.sources['group'].label"
                >
                </p-dropdown>
                <label for="group">{{ "MENU.GROUP" | translate }}</label>
              </div>
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="role"
                  name="role"
                  [options]="data.sources['user-type'].data"
                  [(ngModel)]="form.user_type_id"
                  [filter]="true"
                  appendTo="body"
                  [optionValue]="data.sources['user-type'].key"
                  [optionLabel]="data.sources['user-type'].label"
                >
                </p-dropdown>
                <label for="role">{{ "CORE.ROLE" | translate }}</label>
              </div>
              <div
                class="p-float-label flex flex-column gap-2 mt-4 lg:mt-0 mb-4"
              >
                <p-dropdown
                  id="specialty"
                  name="specialty"
                  [options]="data.sources['specialty'].data"
                  [(ngModel)]="form.user_post_id"
                  [filter]="true"
                  appendTo="body"
                  [optionValue]="data.sources['specialty'].key"
                  [optionLabel]="data.sources['specialty'].label"
                >
                </p-dropdown>
                <label for="specialty">{{
                  "FORM.SPECIALTY" | translate
                }}</label>
              </div>
            </div>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>
