<button
  pButton
  pRipple
  class="p-button-text mx-2"
  [ngClass]="
    tabSelected !== item.key
      ? 'p-button-secondary'
      : 'p-button-raised p-button-success'
  "
  *ngFor="let item of items"
  type="button"
  (click)="onTabSelected(item.key)"
  pTooltip="{{ item.tooltip | translate }}"
>
  <span
    class="material-symbols-rounded"
    [ngClass]="item.label ? 'mr-2' : ''"
    *ngIf="item.icon"
    >{{ item.icon }}</span
  >
  <span class="capitalize" *ngIf="item.label">{{
    (item.label | translate).toLowerCase()
  }}</span>
</button>
