/* eslint-disable @typescript-eslint/no-empty-function */
import { BaseResourceListDirective } from './../../../directives/base-resource-list.directive';
import { Component, Input } from '@angular/core';
import { Storage } from 'aws-amplify';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-aws-uploaded-file-list',
  templateUrl: './aws-uploaded-file-list.component.html',
  styleUrls: ['./aws-uploaded-file-list.component.sass']
})
export class AwsUploadedFileListComponent extends BaseResourceListDirective {

  @Input() showDeleteButton = true

  deleteUploadedFile(key: string, idx: number) {
    this.utils.showLoad()
    Storage.remove(key, {
      level: 'public'
    })
      .then((rs) => this.onDeleteUploadedFileSuccess(rs, idx))
      .catch((err) => this.onDeleteUploadedFileError(err))
      .finally(() => this.onDeleteUploadedFileComplete())
  }

  onDeleteUploadedFileSuccess(data: any, idx: number) {
    this.utils.hideLoad()
    this.utils.toast.deleted('GENERAL.FILES');
    this.resourceList.splice(idx, 1)
  }

  onDeleteUploadedFileError(error: any) {
    this.utils.toast.error();
    Utils.error(error)
  }

  onDeleteUploadedFileComplete() { }
}
