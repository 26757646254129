<div class="flex flex-column h-full" *ngIf="item">
  <div class="flex" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">
        {{ title | translate | uppercase }} |
        {{ item.name_ | translate }}
      </h2>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <app-course-enrolled-users-table
          [courseId]="itemId"
          [userIds]="userIds"
          [courseDetail]="item"
          #primeNgList
        ></app-course-enrolled-users-table>
      </div>
    </div>
  </div>
</div>
