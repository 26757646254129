/* eslint-disable @typescript-eslint/no-empty-function */
import { Globals } from './../../globals';
import { FileType, GenericResponse } from "src/app/models/AppGenericClasses"

export class FileUtils {
  static acceptedFiles = Globals.ACCEPTED_FILE_EXTENSIONS

  static supported(accepted: FileType[]) {
    const supported: string[] = []
    accepted.forEach((ext) => {
      if (ext in this.acceptedFiles) {
        const matches = /\(([^)]+)\)/.exec(this.acceptedFiles[ext])
        if (matches) {
          supported.push(
            matches[1]
              .replace(/\\/g, '')
              .split('.')
              .join('')
              .split('|')
              .join(', ')
          )
        }
      }
    })
    return supported.join(' | ')
  }

  static checkFileExtension(
    file: File,
    accepted: FileType[],
    onValid: (item: File) => void,
    onInvalid: () => void = () => { }
  ) {
    let valid = false
    accepted.forEach((ext) => {
      if (FileUtils.validateExtension(file.name, this.acceptedFiles[ext])) {
        valid = true
      }
    });
    if (valid) {
      onValid(file)
    } else {
      onInvalid()
    }
  }

  static checkFileExtensionBatch(
    files: File[],
    accepted: FileType[],
    onValid: (item: File) => void,
    onInvalid: () => void = () => { }
  ) {
    for (const item of files) {
      this.checkFileExtension(item, accepted, onValid, onInvalid)
    }
  }


  static checkSize(
    file: File,
    onValid: (item: File) => void,
    onInvalid: (res: GenericResponse) => void = () => { }
  ) {
    if (this.validateExtension(file.name, Globals.ACCEPTED_FILE_EXTENSIONS['application/pdf'])) {
      if (file.size > 5000000) {
        onInvalid({
          success: false,
          complement: {
            code: -1,
            message: ''
          }
        })
        return
      }
    }
    onValid(file)
  }

  static validateExtension(fileName, regex) {
    return regex.exec(fileName)
  }

  static imageFromBlob(imageObject): Promise<any> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result)
        },
        false
      )
      if (imageObject) {
        reader.readAsDataURL(imageObject)
      }
    })
  }

  static getExtFromPath(path) {
    return path.split('.').pop();
  }

  static getFileNameFromPath(path) {
    const lastSlashIndex = path.lastIndexOf('/');
    if (lastSlashIndex === -1) {
      return path;
    } else {
      return path.substring(lastSlashIndex + 1);
    }
  }

  static getFileNameWithoutExtension(filename) {
    const lastDotIndex = filename.lastIndexOf('.')
    if (lastDotIndex !== -1) {
      return filename.substring(0, lastDotIndex)
    }
    return filename
  }

  static formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
