import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-report-take-evaluation',
  templateUrl: './report-take-evaluation.component.html',
  styleUrls: ['./report-take-evaluation.component.sass']
})
export class ReportTakeEvaluationComponent extends BaseDirective {
  override title = 'MENU.TAKE-EVALUATIONS-BY-UNIT-COURSE';
}

