<div
  class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3"
  *ngIf="presentation === 1"
>
  <div class="flex w-12 md:w-4 lg:w-2">
    <a class="flex" routerLink="/courses/preview/{{ item.id }}">
      <app-aws-image-viewer
        defaultImage="assets/images/cover-example.jpg"
        [path]="'course'"
        [editId]="item.id"
        field="cover"
        ext="png"
      ></app-aws-image-viewer>
    </a>
  </div>
  <div class="flex flex-column w-12 md:w-8 lg:w-10 px-4 py-3 mb-3">
    <a class="card-item-title cursor-pointer" (click)="viewCourse()">{{
      item.name
    }}</a>
    <div class="flex flex-row pt-2">
      <ng-container *ngFor="let courseTopic of item.courseTopics">
        <app-color-chip
          [content]="courseTopic.source.topic.name_"
          [color]="courseTopic.source.topic.color"
          class="mr-2 cursor-pointer"
          (click)="op.toggle($event)"
        ></app-color-chip>
        <p-overlayPanel #op>
          <app-topic-item
            [itemId]="courseTopic.source.topic.el_topic_id"
            [showActions]="false"
          ></app-topic-item>
        </p-overlayPanel>
      </ng-container>
    </div>
    <p class="p-text-secondary" *ngIf="showDescription">
      <app-text-wrapper [text]="item.description"></app-text-wrapper>
    </p>
    <div class="flex flex-column md:flex-row w-full">
      <div class="flex flex-column w-full">
        <p class="p-text-secondary font-bold w-full text-sm">
          {{ "FORM.ESTIMATED-DURATION" | translate }}:
          <span *ngIf="item.timeDuration.hours > 0">
            {{ item.timeDuration.hours }}
            h
          </span>
          <span *ngIf="item.timeDuration.minutes > 0">
            {{ item.timeDuration.minutes }}
            min
          </span>
        
        </p>
        <div
          class="flex flex-wrap w-12 md:w-8 lg:w-9 card-item-tags"
          *ngIf="item.tags.length"
        >
          <p class="p-text-secondary font-bold w-full">
            {{ "ENV.TAGS" | translate }}:
          </p>
          <div class="flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of item.tagsArr">
              <p-tag [value]="tag"></p-tag>
            </ng-container>
          </div>
        </div>
      </div>
      <app-course-action-buttons
        [source]="item.source"
        (itemDisabled)="itemDisabled.emit(true)"
        (itemEnabled)="itemEnabled.emit(true)"
        *ngIf="showActions"
      ></app-course-action-buttons>
    </div>
  </div>
</div>

<div class="card pb-4" *ngIf="presentation === 2">
  <div
    class="box-elevation border-round-md flex flex-column align-items-center shadow-2 p-3"
  >
    <a class="flex w-full h-7 mb-1" routerLink="/courses/preview/{{ item.id }}">
      <app-image-viewer
        [defaultImage]="'assets/images/cover-example.jpg'"
        [path]="'course'"
        [id]="item.id"
        [field]="'cover'"
      ></app-image-viewer>
    </a>
    <p-tag routerLink="/courses/preview/{{ item.id }}">
      <div class="text-center">{{ item.name_ }}</div>
    </p-tag>
  </div>
</div>

<div
  class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3"
  *ngIf="presentation === 3"
>
  <div class="flex w-12 md:w-4 lg:w-2">
    <a class="flex" routerLink="/courses/preview/{{ item.id }}">
      <app-aws-image-viewer
        defaultImage="assets/images/cover-example.jpg"
        [path]="'course'"
        [editId]="item.id"
        field="cover"
        ext="png"
      ></app-aws-image-viewer>
    </a>
  </div>
  <div class="flex flex-column w-12 md:w-8 lg:w-10 px-4 py-3 mb-3">
    <a class="card-item-title cursor-pointer" (click)="viewCourse()">{{
      item.name_
    }}</a>
    <div class="flex flex-row pt-2">
      <app-color-chip
        *ngFor="let courseTopic of item.courseTopics"
        [content]="courseTopic.source.topic.name_"
        [color]="courseTopic.source.topic.color"
        class="mr-2"
      ></app-color-chip>
    </div>
    <p class="p-text-secondary" *ngIf="showDescription">
      {{ item.description }}
    </p>
    <div class="flex flex-column md:flex-row mb-3">
      <div class="flex flex-column w-full">
        <p class="p-text-secondary font-bold w-full text-sm">
          {{ "FORM.ESTIMATED-DURATION" | translate }}:
          {{ item.estimatedDuration }}
          {{
            globals.COURSE_VISUALIZATION.TIME_LIMIT_UNIT.label
              | translate
              | lowercase
          }}
        </p>
        <div
          class="flex flex-wrap w-12 md:w-8 lg:w-9 card-item-tags"
          *ngIf="item.tags.length"
        >
          <p class="p-text-secondary font-bold w-full">
            {{ "ENV.TAGS" | translate }}:
          </p>
          <div class="flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of item.tagsArr">
              <p-tag [value]="tag"></p-tag>
            </ng-container>
          </div>
        </div>
      </div>
      <app-course-action-buttons
        [source]="item.source"
        (itemDisabled)="itemDisabled.emit(true)"
        (itemEnabled)="itemEnabled.emit(true)"
        *ngIf="showActions"
      ></app-course-action-buttons>
    </div>
    <span class="p-text-secondary">
      Usuarios inscritos: {{ item.currentUserEnrollment }}
    </span>
  </div>
</div>

<div
  class="card-list-item flex flex-column flex-row flex-grow-1 mb-3"
  *ngIf="presentation === 4"
>
  <div class="flex w-12 justify-content-center">
    <a class="flex" routerLink="/courses/preview/{{ item.id }}">
      <app-aws-image-viewer
        defaultImage="assets/images/cover-example.jpg"
        [path]="'course'"
        [editId]="item.id"
        field="cover"
        ext="png"
        style="height: 150px"
      ></app-aws-image-viewer>
    </a>
  </div>
  <div class="flex flex-column w-12 md:w-8 lg:w-10 px-4 py-3 mb-3">
    <a class="card-item-title cursor-pointer" (click)="viewCourse()">{{
      item.name
    }}</a>
    <div class="flex flex-row pt-2">
      <ng-container *ngFor="let courseTopic of item.courseTopics">
        <app-color-chip
          [content]="courseTopic.source.topic.name_"
          [color]="courseTopic.source.topic.color"
          class="mr-2 cursor-pointer"
          (click)="op.toggle($event)"
        ></app-color-chip>
        <p-overlayPanel #op>
          <app-topic-item
            [itemId]="courseTopic.source.topic.el_topic_id"
            [showActions]="false"
          ></app-topic-item>
        </p-overlayPanel>
      </ng-container>
    </div>
    <p class="p-text-secondary" *ngIf="showDescription">
      <app-text-wrapper [text]="item.description"></app-text-wrapper>
    </p>
    <div class="flex flex-column md:flex-row w-full">
      <div class="flex flex-column w-full">
        <p class="p-text-secondary font-bold w-full text-sm">
          {{ "FORM.ESTIMATED-DURATION" | translate }}:
          {{ item.estimatedDuration }}
          {{
            globals.COURSE_VISUALIZATION.TIME_LIMIT_UNIT.label
              | translate
              | lowercase
          }}
        </p>
        <div
          class="flex flex-wrap w-12 md:w-8 lg:w-9 card-item-tags"
          *ngIf="item.tags.length"
        >
          <p class="p-text-secondary font-bold w-full">
            {{ "ENV.TAGS" | translate }}:
          </p>
          <div class="flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of item.tagsArr">
              <p-tag [value]="tag"></p-tag>
            </ng-container>
          </div>
        </div>
      </div>
      <app-course-action-buttons
        [source]="item.source"
        (itemDisabled)="itemDisabled.emit(true)"
        (itemEnabled)="itemEnabled.emit(true)"
        *ngIf="showActions"
      ></app-course-action-buttons>
    </div>
  </div>
</div>

<div
  class="card-list-item flex flex-column flex-row flex-grow-1 mb-3"
  *ngIf="presentation === 5"
>
  <div class="flex w-12 justify-content-center">
    <a class="flex" routerLink="/courses/preview/{{ item.id }}">
      <app-aws-image-viewer
        defaultImage="assets/images/cover-example.jpg"
        [path]="'course'"
        [editId]="item.id"
        field="cover"
        ext="png"
        style="height: 150px"
      ></app-aws-image-viewer>
    </a>
  </div>
  <div class="flex flex-column w-12 px-4 py-3 mb-3">
    <a class="card-item-title cursor-pointer" (click)="viewCourse()">{{
      item.name
    }}</a>
    <div class="flex flex-column gap-1" *ngIf="withUserEnrollment">
      <div class="text-sm text-right">
        {{ item.currentUserEnrollment.percentage | percent : "1.2-2" }}
      </div>
      <p-progressBar
        [value]="item.currentUserEnrollment.percentage * 100"
        [style]="{ height: '15px' }"
      >
      </p-progressBar>
    </div>
    <div class="flex flex-column md:flex-row w-full">
      <app-course-action-buttons
        [source]="item.source"
        (itemDisabled)="itemDisabled.emit(true)"
        (itemEnabled)="itemEnabled.emit(true)"
        *ngIf="showActions"
      ></app-course-action-buttons>
    </div>
  </div>
</div>

<div
  class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3"
  *ngIf="presentation === 6"
>
  <div class="flex w-12 md:w-4 lg:w-6">
    <a class="flex" routerLink="/courses/preview/{{ item.id }}">
      <app-aws-image-viewer
        defaultImage="assets/images/cover-example.jpg"
        [path]="'course'"
        [editId]="item.id"
        field="cover"
        ext="png"
      ></app-aws-image-viewer>
    </a>
  </div>
  <div class="flex flex-column w-12 md:w-8 lg:w-10 px-4 py-3 mb-3">
    <a
      class="card-item-title cursor-pointer text-xl text-center"
      (click)="viewCourse()"
      >{{ item.name_ }}</a
    >
    <div class="flex flex-column md:flex-row mb-3">
      <div class="flex w-full justify-content-center gap-3 p-3">
        <div
          class="box box-elevation p-3 flex flex-column gap-2 align-items-center p-text-secondary text-center cursor-pointer"
          style="max-width: 200px"
          (click)="seeOnlyAvailableUsers()"
        >
          <h1 class="my-0">
            {{ item.userAvailableCount }}
          </h1>
          <div class="text-lg">Usuarios habilitados</div>
        </div>
        <div
          class="box box-elevation p-3 flex flex-column gap-2 align-items-center p-text-secondary text-center cursor-pointer"
          style="max-width: 200px"
          (click)="seePendingUsers()"
        >
          <h1 class="my-0">
            {{ item.pendingEnrollmentsCount }}
          </h1>
          <div class="text-lg">
            Usuarios habilitados pendientes por inscribirse
          </div>
        </div>
        <div
          class="box box-elevation p-3 flex flex-column gap-2 align-items-center p-text-secondary text-center cursor-pointer"
          style="max-width: 200px"
          (click)="seeEnrollments()"
        >
          <h1 class="my-0">
            {{ item.enrollmentsCount }}
          </h1>
          <div class="text-lg">Usuarios inscritos</div>
        </div>
      </div>
    </div>
    <div class="flex w-full justify-content-end">
      <app-course-action-buttons
        [source]="item.source"
        (itemDisabled)="itemDisabled.emit(true)"
        (itemEnabled)="itemEnabled.emit(true)"
        (requestReload)="requestReload.emit()"
        *ngIf="showActions"
      ></app-course-action-buttons>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="showInacivationProhibition"
  [modal]="true"
  [draggable]="false"
>
  <p>No se puede eliminar el curso porque cuenta con usuarios inscritos.</p>
</p-dialog>
