<div class="flex flex-column">
  <div class="flex-grow pb-4" [innerHTML]="message"></div>
  <div class="flex justify-content-end gap-2">
    <button
      pButton
      pRipple
      type="button"
      class="p-button-raised p-button-danger"
      label="{{ 'GENERAL.NO' | translate }}"
      (click)="reject()"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      class="p-button-raised p-button-success"
      label="{{ 'GENERAL.YES' | translate }}"
      (click)="confirm()"
    ></button>
  </div>
</div>
