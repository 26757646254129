import { Component } from '@angular/core';

@Component({
  selector: 'app-pending-graded-test',
  templateUrl: './pending-graded-test.component.html',
  styleUrls: ['./pending-graded-test.component.sass']
})
export class PendingGradedTestComponent {
  title = 'MENU.PENDING-GRADED';
}
