<ng-container *ngIf="underConstruction">
  <app-under-construction></app-under-construction>
</ng-container>
<div class="flex flex-column h-full" *ngIf="!underConstruction">
  <div class="flex flex-grow-1 h-full" *ngIf="!userType.could_manage_course">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <p-scrollPanel styleClass="w-full h-full">
          <h2>Cursos en desarrollo</h2>
          <p-divider></p-divider>
          <app-course-list
            class="h-full"
            [presentation]="5"
            [showActions]="false"
            [horizontal]="true"
            [showDescription]="false"
            [containerStyleClass]="''"
            [withUserEnrollment]="true"
            [customListQuery]="coursesWithUserEnrollmentQuery"
          ></app-course-list>
          <h2>Cursos disponibles</h2>
          <p-divider></p-divider>
          <app-course-list
            class="h-full"
            [presentation]="4"
            [showActions]="false"
            [horizontal]="true"
            [showDescription]="false"
            [containerStyleClass]="''"
            [customListQuery]="availableUserCoursesQuery"
          ></app-course-list>
          <h2>Evaluaciones libres disponibles</h2>
          <p-divider></p-divider>
          <app-available-test-version-list
            [showActions]="false"
            [horizontal]="true"
            [containerStyleClass]="''"
          ></app-available-test-version-list>
          <h2>Bibliotecas disponibles</h2>
          <p-divider></p-divider>
          <app-available-libraries-list
            class="h-full"
            [presentation]="4"
            [showActions]="false"
            [horizontal]="true"
            [containerStyleClass]="''"
          ></app-available-libraries-list>
        </p-scrollPanel>
      </div>
    </div>
  </div>
  <ng-container *ngIf="userType.could_manage_course">
    <h2 class="mb-0">Cursos</h2>
    <p-divider></p-divider>
    <app-course-list
      class="h-full"
      [couldShowInactives]="false"
      [presentation]="6"
      [showActions]="true"
      [showDescription]="false"
    ></app-course-list>
  </ng-container>
</div>
