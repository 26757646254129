import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Component, Input } from '@angular/core';
import { AWSResourceData, ContentType } from 'src/app/models/AppGenericClasses';
import { AWSS3Service } from 'src/app/services/data/aws-s3.service';
import { Utils } from 'src/app/shared/utils/utils';

export type ResourceDataItem = {
  type_: ContentType
  content: string
  resourceData: AWSResourceData
}

@Component({
  selector: 'app-content-trigger-button',
  templateUrl: './content-trigger-button.component.html',
  styleUrls: ['./content-trigger-button.component.sass']
})
export class ContentTriggerButtonComponent {

  @Input() item: ResourceDataItem = null

  constructor(
    protected utils: UtilsService,
    protected dialog: DialogService,
    protected s3Service: AWSS3Service,
    protected messageService: MessageService
  ) { }

  async seeResource() {
    switch (this.item.resourceData.field) {
      case 'PDF':
        await this.handlePDF()
        break;
      case 'URL':
        this.handleURL()
        break;
      case 'VIDEO-URL':
        break;
      default:
        break;
    }
    this.s3Service.seeDialogResource(this.item.type_, this.item.resourceData)
  }

  private async handlePDF() {
    this.utils.toast.loading()
    const asyncResourceUrl = await AWSS3Service.getResourceURL(this.item.resourceData)
    switch (asyncResourceUrl.status) {
      case 1:
        window.open(asyncResourceUrl.data, '_blank')
        break;
      case 2:
        Utils.error(asyncResourceUrl.data)
        this.utils.toast.error()
        break
      default:
        break;
    }
    this.messageService.clear()
  }

  private handleURL() {
    window.open(this.item.content, '_blank')
  }
}
