import { Component } from '@angular/core';
import { BaseDirective } from 'src/app/directives/base.directive';

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.sass']
})

export class ColorComponent extends BaseDirective {
  override title = 'MENU.COLORS';
}