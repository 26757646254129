import { Component } from '@angular/core';

@Component({
  selector: 'app-graded-test',
  templateUrl: './graded-test.component.html',
  styleUrls: ['./graded-test.component.sass']
})
export class GradedTestComponent {
  title = 'Evaluaciones calificadas';
}
