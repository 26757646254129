import { Component } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { Globals } from 'src/app/globals';
import { FormattableObjectProperty, PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-user-type-table',
  templateUrl: './user-type-table.component.html',
  styleUrls: ['./user-type-table.component.sass']
})
export class UserTypeTableComponent extends BaseListDirective {

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.NAME', 150, { frozen: true })
  ];
  override title = 'MENU.USERS';
  override filterableFields = []
  override keys = {
    primary_key: 'user_id',
    label: 'name_'
  }
  override filename = 'MENU.USERS';

  override getList(): void {
    this.query(this.data.core.userTypes()).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.userDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  override generateColumns() {
    super.generateColumns()
    Object.keys(Globals.USER_TYPE_PERMISSIONS).forEach(key => {
      const permissionGroup = Globals.USER_TYPE_PERMISSIONS[key]
      permissionGroup.permissions.forEach(permission => {
        this.columns.push(
          new PrimeNGTableColumn(permission.key, permission.label, 300)
        )
        this.dataFormatterProps.push({
          field: permission.key,
          formats: ['FLAG']
        })
      })
    })
    this.translateColumns()
  }
}
