import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalService } from "../../../services/utils/global.service";

@Component({
  selector: 'app-color-chip',
  templateUrl: './color-chip.component.html',
  styleUrls: ['./color-chip.component.sass']
})
export class ColorChipComponent implements OnInit, OnChanges {
  @Input() content = ''
  @Input() color: { [key: string]: any } | undefined = {}

  surface = 'var(--primary-color)'
  textColor = 'var(--primary-color-text)'

  constructor(
    private global: GlobalService
  ) {
  }

  ngOnInit() {
    if (this.color) {
      this.handleColor()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('color' in changes) {
      this.handleColor()
    }
  }

  handleColor() {
    const color = this.global.themeColor(this.color)
    this.surface = color.surface
    this.textColor = color.textColor
  }
}
