import { FileUtils } from 'src/app/shared/utils/file-utils';
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { FileType } from 'src/app/models/AppGenericClasses';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.sass'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DragDropComponent, multi: true },
  ]
})
export class DragDropComponent {
  @Input() required = false
  @Input() accepted: FileType[] = []
  @Input() multiple = false

  get value(): File[] {
    return this.files
  }

  set value(v: File[]) {
    if (v !== this.files) {
      this.files = v
      this.onChange(v)
    }
  }

  files: File[] = []

  fileBrowseHandler(target) {
    this.prepareFilesList(target.files)
  }

  prepareFilesList(files: Array<any>) {
    FileUtils.checkFileExtensionBatch(files, this.accepted, (validItem: any) => {
      FileUtils.checkSize(validItem, () => {
        validItem.progress = 0
        this.files.push(validItem)
        this.onChange(files)
      }, () => {

      })
    })
  }

  supported() {
    return FileUtils.supported(this.accepted)
  }

  onFileDropped($event) {
    this.prepareFilesList($event)
  }

  deleteFile(index: number) {
    this.files.splice(index, 1)
    this.onChange(this.files)
  }

  onChange = (_: File[]) => { }

  onTouched = () => { }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  writeValue(obj: File[]): void {
    if (obj === null) {
      this.files = []
    } else if (obj !== this.files) {
      this.files = obj
    }
  }
}
