<div class="grid row justify-content-center" *ngIf="chronometer">
  <div class="col text-center" style="max-width: 150px">
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-plus"
      class="p-button-text w-100"
      (click)="plusHour()"
    ></button>
    <h2 pTooltip="{{ 'FORM.HOURS' | translate }}">
      <p-inputNumber
        styleClass="w-full"
        class="w-full"
        inputStyleClass="w-full"
        [(ngModel)]="chronometer.hours"
        (ngModelChange)="onChange(chronometer)"
      ></p-inputNumber>
    </h2>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-minus"
      class="p-button-text w-100"
      (click)="subtractHour()"
    ></button>
  </div>
  <div
    class="col-fixed"
    style="display: flex; align-items: center"
    [style]="{ width: '20px' }"
  >
    <h2>:</h2>
  </div>
  <div class="col text-center" style="max-width: 150px">
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-plus"
      class="p-button-text w-100"
      (click)="plusMinute()"
    ></button>
    <h2 pTooltip="{{ 'FORM.MINUTES' | translate }}">
      <p-inputNumber
        min="0"
        max="60"
        [(ngModel)]="chronometer.minutes"
        styleClass="w-full"
        class="w-full"
        inputStyleClass="w-full"
        (ngModelChange)="onChange(chronometer)"
      ></p-inputNumber>
    </h2>

    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-minus"
      class="p-button-text w-100"
      (click)="subtractMinute()"
    ></button>
  </div>
  <div
    class="col-fixed vertical-center"
    style="display: flex; align-items: center"
    [style]="{ width: '20px' }"
  >
    <h2>:</h2>
  </div>
  <div class="col text-center" style="max-width: 150px">
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-plus"
      class="p-button-text w-100"
      (click)="plusSecond()"
    ></button>
    <h2 pTooltip="{{ 'FORM.SECONDS' | translate }}">
      <p-inputNumber
        min="0"
        max="60"
        [(ngModel)]="chronometer.seconds"
        styleClass="w-full"
        class="w-full"
        inputStyleClass="w-full"
        (ngModelChange)="onChange(chronometer)"
      ></p-inputNumber>
    </h2>

    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-minus"
      class="p-button-text w-100"
      (click)="subtractSecond()"
    ></button>
  </div>
</div>
