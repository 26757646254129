import { FileUtils } from 'src/app/shared/utils/file-utils';
import { Component, Input } from '@angular/core';
import { BaseResourceDirective } from 'src/app/directives/base-resource.directive';

@Component({
  selector: 'app-aws-image-viewer',
  templateUrl: './aws-image-viewer.component.html',
  styleUrls: ['./aws-image-viewer.component.sass'],
})
export class AwsImageViewerComponent extends BaseResourceDirective {

  @Input() gallery = true
  @Input() defaultImage = ''

  override downloadOptions = {
    level: 'public',
    download: true,
    cacheControl: 'no-cache',
    validateObjectExistence: true,
  };

  override handleDialogConfigData() {
    super.handleDialogConfigData()
    this.gallery = this.dialogConfig.data.gallery ?? this.gallery;
  }

  override onLoadResourceSuccess(result) {
    FileUtils.imageFromBlob(result.Body).then((image) => {
      this.resource = image
      this.downloading = false
    });
    super.onLoadResourceSuccess(result)
  }
}
