<div
  class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3"
  *ngIf="item"
>
  <div class="flex flex-column w-12 px-4 py-3 mb-3">
    <p class="card-item-title">{{ item.name_ }}</p>
    <p class="p-text-secondary mt-0" *ngIf="showDescription">
      {{ item.description_ }}
    </p>
    <div class="flex flex-column md:flex-row">
      <div class="flex w-12 md:w-8 lg:w-9 card-item-tags">
        <span class="p-text-secondary font-bold">{{
          "ENV.TAGS" | translate
        }}</span
        >: <span class="font-italic">{{ item.tags }}</span>
      </div>
    </div>
  </div>
</div>
