import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-report-course-user-table',
  templateUrl: './report-course-user-table.component.html',
  styleUrls: ['./report-course-user-table.component.sass']
})
export class ReportCourseUserTableComponent extends BaseListDirective {

  @Input() courseId = 0;

  override title = 'MENU.USERS';
  override filename = 'MENU.USERS';
  override filterableFields = [];

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('first_name,last_name', 'FORM.NAME', 150, { frozen: true }),
    new PrimeNGTableColumn('dni_type.abbreviation', 'CORE.DOCUMENT-TYPE', 100),
    new PrimeNGTableColumn('dni', 'FORM.DNI', 100),
    new PrimeNGTableColumn('city.name_', 'CORE.CITY', 100),
    new PrimeNGTableColumn('email', 'AUTH.EMAIL', 100),
    new PrimeNGTableColumn('credentials.type.name_', 'CORE.ROLE', 150),
    new PrimeNGTableColumn('group.name_', 'MENU.GROUP', 100),
    new PrimeNGTableColumn('post.name_', 'FORM.SPECIALTY', 150)

  ];


  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef)
  }

  override getList() {
    this.query(this.data.core.pendingUserCourses(this.courseId)).subscribe()
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemSelected[this.keys.primary_key]
    );
  }

  setReorderMethod() {
    this.updateOrderService = this.data.core.unitUpdateInBatch({
      objects: this.rows
    });
    super.setReorderMethod();
  }
}


