/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Course, CourseJSON } from 'src/app/models/CourseClasses';
import { CourseUserPickerTableComponent } from '../../controls/user-picker-table/user-picker-table.component';
import { BaseItemRestructDirective } from 'src/app/directives/restruct/base-item-restruct.directive';


@Component({
  selector: 'app-course-action-buttons',
  templateUrl: './course-action-buttons.component.html',
  styleUrls: ['./course-action-buttons.component.sass']
})
export class CourseActionButtonsComponent extends BaseItemRestructDirective<Course, CourseJSON> {
  ItemModel = Course
  title = "MENU.COURSES"

  refreshActionServices(): void {
    this.canInactivate = this.item.enrollmentsCount <= 0
    this.inactivationService = this.data.core.courseDelete(this.item.id)

    this.canReactivate = !this.item.active
    this.reactivationService = this.data.core.courseActivate(this.item.id)
  }

  units(course: Course) {
    localStorage.setItem('course_name', course.name)
    this.utils.router.navigateByUrl(`/courses/units/preview/${course.id}`)
  }

  items: MenuItem[] = []

  onItemReady(): void {
    super.onItemReady()
    this.items = [
      {
        id: 'PRE-REQUISITES',
        label: this.utils.translate.instant('CORE.PRE-REQUISITES'),
        icon: 'pi pi-history',
        command: () => this.prerrequisites()
      },
      {
        id: 'AVAILABLE-USERS',
        label: this.utils.translate.instant('CORE.AVAILABLE-USERS'),
        icon: 'pi pi-users',
        command: () => this.seeUsers()
      },
      {
        id: 'ENROLLED-USERS',
        label: this.utils.translate.instant('CORE.ENROLLED-USERS'),
        icon: 'pi pi-id-card',
        command: () => this.registeredUsers()
      },
      {
        id: 'NOTIFY',
        label: this.utils.translate.instant('ENV.NOTIFY'),
        icon: 'pi pi-bell',
        command: () => {
          this.utils.executeRequest(this.data.core.notifyCourse(this.item.id))
        },
        visible: this.item.active
      },
      {
        separator: true
      },
      {
        id: 'INACTIVATE',
        label: this.utils.translate.instant('ENV.INACTIVATE'),
        icon: 'pi pi-ban',
        styleClass: 'text-danger',
        command: () => this.inactivate(),
        visible: this.item.active
      },
      {
        id: 'REACTIVATE',
        label: this.utils.translate.instant('ENV.REACTIVATE'),
        icon: 'pi pi-upload',
        styleClass: 'text-green-300',
        command: () => this.reactivate(),
        visible: !this.item.active
      }
    ]
  }

  prerrequisites() {
    this.utils.router.navigateByUrl(`/courses/${this.item.id}/prerrequisites`)
  }

  registeredUsers() {
    this.utils.router.navigateByUrl(`/courses/${this.item.id}/enrolled-users`)
  }

  seeUsers() {
    this.utils.appDialog.seeCourseUserAvailableDialog(this.item.id, this.item.name, false, {
      onSuccess: () => {
        this.requestReload.emit()
      }
    })
  }
}
