import { Component, Input } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { FormattableObjectProperty, PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { CourseEnrollment } from 'src/app/models/CourseEnrollmentClasses';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-course-enrolled-users-table',
  templateUrl: './course-enrolled-users-table.component.html',
  styleUrls: ['./course-enrolled-users-table.component.sass']
})
export class CourseEnrolledUsersTableComponent extends BaseListDirective {
  
  enrollmentStatus = [
    {name: 'En curso', value: 'C'},
    {name: 'Finalizado', value: 'F'},
    {name: 'Anulado', value: 'A'},
  ]
  value2: any[] = [];

  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('user.first_name,user.last_name', 'FORM.NAME', 300, { frozen: true }),
    new PrimeNGTableColumn('user.dni', 'FORM.DNI', 200),
    new PrimeNGTableColumn('enrolled_at', 'FORM.INSCRIPTION-DATE', 200),
    new PrimeNGTableColumn('enrollment_status', 'FORM.INSCRIPTION-STATUS', 200, { filterable: true }),
    new PrimeNGTableColumn('percentage', 'FORM.COURSE-PROGRESS', 200),
    new PrimeNGTableColumn('unitTestsAverageScore', 'FORM.COURSE-CALIFICATION', 200),
  ];
  override title = 'CORE.ENROLLED-USERS';
  override filename = 'CORE.ENROLLED-USERS';
  override keys = {
    primary_key: 'el_course_enrollment_id',
    label: 'user.first_name,user.last_name'
  }
  baseUtils = Utils
  @Input() courseId = 0
  @Input() userIds = [];
  @Input() courseDetail = null;

  override dataFormatterProps: FormattableObjectProperty[] = [
    {
      field: 'enrolled_at',
      formats: ['DATE-FORMATTED']
    }
  ];

  override handleDialogConfigData() {
    super.handleDialogConfigData()
    this.courseId = this.dialogConfig.data.courseId ?? this.courseId;
  }

  override getList(): void {
    this.query(this.data.core.courseLatestEnrollments(this.courseId)).subscribe()
  }

  refreshActionServices(): void {
    console.log(this.itemSelected)
    this.inactivationService = this.data.core.courseEnrollmentAnnul(
      {
        id: this.itemSelected[this.keys.primary_key]
      }
    )
    this.reactivationService = this.data.core.courseEnrollmentRestore(
      {
        id: this.itemSelected[this.keys.primary_key]
      }
    )
  }

  cancelInscription(row: any) {
    this.handleItemSelected(row);
    this.utils.fireConfirmation({
      message: this.utils.translate.instant('QUESTION.CANCEL-INSCRIPTION', {
        value: Utils.ngCellValue(this.keys.label, this.itemSelected)
      }),
    },
      () => {
        this.inactivationService?.subscribe({
          next: () => this.handleInactivationSuccess(),
          error: (err) => this.handleGenericError(err),
          complete: () => this.handleChangeStatusComplete()
        });
      });
  }

  RestoreInscription(row: any) {
    this.handleItemSelected(row);
    this.utils.fireConfirmation({
      message: this.utils.translate.instant('QUESTION.RESTORE-INSCRIPTION', {
        value: Utils.ngCellValue(this.keys.label, this.itemSelected)
      }),
    },
      () => {
        this.reactivationService?.subscribe({
          next: () => this.handleInactivationSuccess(),
          error: (err) => this.handleGenericError(err),
          complete: () => this.handleChangeStatusComplete()
        });
      });
  }


  handleRows(data: any): void {
    data.forEach((item) => {
      const enrollment = new CourseEnrollment(item, this.utils)
      item.percentage = enrollment.percentage
      item.unitTestsAverageScore = enrollment.unitTestsAverageScore
    })
    super.handleRows(data)
  }

  override exportValue(column, row): string {
    switch (column.field) {
      case 'percentage':
        return (row.percentage * 100).toFixed(2) + '%';
      case 'enrollment_status':
        if (row.enrollment_status === 'C') {
          return 'En curso'
        }

        if (row.enrollment_status === 'F') {
          return 'Finalizado'
        }
        return 'Reprobado'

      default:
        return super.exportValue(column, row);

    }

  }




}
