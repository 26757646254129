import { Component } from '@angular/core';
import { TestFormComponent } from '../../admin/test/test-form/test-form.component';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-unit-test-form',
  templateUrl: './unit-test-form.component.html',
  styleUrls: ['./unit-test-form.component.sass']
})
export class UnitTestFormComponent extends TestFormComponent {
  courseUnitTestForm = {
    el_test_id: 0,
    el_course_unit_id: 0,
    allowed_attempts: null,
    approval_required: 0
  }

  override onInit(): void {
    this.form.min_approval_percentage = 0
    super.onInit()
  }

  validateInteger(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const regex = /^\d*$/; 
    

    if (!regex.test(inputElement.value)) {
      inputElement.value = this.form.min_approval_percentage !== null ? this.form.min_approval_percentage : 0;
    } else {
      this.form.min_approval_percentage = inputElement.value !== '' ? parseInt(inputElement.value, 10) : 0;
    }
  }

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.courseUnitTestForm.el_course_unit_id = this.dialogConfig.data.el_course_unit_id ?? this.courseUnitTestForm.el_course_unit_id;
  }

  override setItemEditMethod(): void {
    this.editService = this.data.core.unitTestUnique(this.editId)
  }

  override setSubmitMethods(): void {
    super.setSubmitMethods()
    this.putService = this.data.core.unitTestUpdate(this.editId, this.formSanitized)
  }

  override handleItemEditData(data) {
    console.log(data);
    
    const tags = data.test.poll.tag
    this.tags = tags ? tags.split(',') : []
    this.timeLimit = data.test.time_limit / this.globals.EL_TEST_VISUALIZATION.TIME_LIMIT_UNIT.value
    Utils.fillObj(this.form, data.test);
    Utils.fillObj(this.courseUnitTestForm, data);
    console.log(this.form.min_approval_percentage)

    this.form.min_approval_percentage =  parseInt(this.form.min_approval_percentage, 10)
  }

  override onSubmit() {
    this.form.course_unit_test_data = this.courseUnitTestForm
    super.onSubmit()
  }

  onApprovalRequiredChange() {
    if (!this.courseUnitTestForm.approval_required) {
      this.form.min_approval_percentage = 0
    }
  }
}
