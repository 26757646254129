import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-toggle-button',
  templateUrl: './custom-toggle-button.component.html',
  styleUrls: ['./custom-toggle-button.component.sass'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomToggleButtonComponent, multi: true },
  ]
})
export class CustomToggleButtonComponent implements OnInit, ControlValueAccessor {
  @Input() labelOn = '';
  @Input() labelOff = '';
  @Input() showTooltip = false;

  get value(): boolean {
    return this.data;
  }

  set value(v: boolean) {
    if (v !== this.data) {
      this.data = v;
      this.onChange(v);
    }
  }

  data = false;

  constructor() {
  }

  ngOnInit(): void {
    if (this.labelOff === '') {
      this.labelOff = this.labelOn;
    }
  }

  onChange = (_) => {
  }
  onTouched = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: boolean): void {
    if (!obj) {
      this.data = false
    } else {
      this.data = obj
    }
  }

  toggle() {
    this.data = !this.data;
    this.onChange(this.data);
  }
}
