import { HttpClient } from "@angular/common/http";
import { Directive, ElementRef, Host, Optional, Self } from "@angular/core";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { UtilsService } from "../services/utils/utils.service";
import { GlobalDataService } from '../services/data/global-data.service';
import { ActivatedRoute } from '@angular/router';
import { BaseResourceDirective } from './base-resource.directive';

@Directive({
  selector: '[appBaseHtmlElementResource]',
})
export class BaseHtmlElementResourceDirective extends BaseResourceDirective {

  constructor(
    utils: UtilsService,
    data: GlobalDataService,
    route: ActivatedRoute,
    dialog: DialogService,
    dialogConfig: DynamicDialogConfig,
    dialogRef: DynamicDialogRef,
    http: HttpClient,
    @Host() @Self() @Optional() public hostElementRef: ElementRef
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef, http);
  }

  onInit(): void {
    if (!this.resourceUrl) {
      this.loadResource();
    } else {
      if (this.hostElementRef.nativeElement.tagName.toLowerCase() === 'a') {
        this.hostElementRef.nativeElement.setAttribute('href', this.resourceUrl);
      }
    }
  }

  override onLoadResourceSuccess(result) {
    if (this.hostElementRef.nativeElement.tagName.toLowerCase() === 'a') {
      this.hostElementRef.nativeElement.setAttribute('href', result);
    }
    if (this.hostElementRef.nativeElement.tagName.toLowerCase() === 'img') {
      this.hostElementRef.nativeElement.setAttribute('src', result);
    }
    if (this.hostElementRef.nativeElement.tagName.toLowerCase() === 'iframe') {
      this.hostElementRef.nativeElement.setAttribute('src', result);
    }
    super.onLoadResourceSuccess(result);
  }

  override onLoadResourceError(error: any) {
    if (this.hostElementRef.nativeElement.tagName.toLowerCase() === 'a') {
      this.hostElementRef.nativeElement.addEventListener('click', (ev: MouseEvent) => {
        ev.preventDefault();
        this.showNotFoundDialog();
      });
    }
    super.onLoadResourceError(error)
  }
}
