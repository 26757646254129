<p-editor
  name="html"
  [(ngModel)]="html"
  [style]="{ height: '100%' }"
  (ngModelChange)="onHtmlChange($event)"
  styleClass="h-full mb-2"
  required
></p-editor>
<button
  pButton
  pRipple
  type="button"
  class="p-button-raised"
  icon="pi pi-eye"
  label="{{ 'ENV.PREVIEW' | translate }}"
  (click)="seeHtml()"
></button>
