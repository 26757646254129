<div class="container-fluid h-100 p-0 editor-wrapper">
  <div class="d-flex flex-column h-100">
    <div class="component-header text-end p-0">
      <a
        class="btn btn-link text-app-custom text-decoration-none"
        (click)="frameActive = frameActive == 1 ? 2 : 1"
        *ngIf="showToggle"
      >
        <span class="material-symbols-outlined" *ngIf="frameActive == 1">
          code
        </span>
        <span class="material-symbols-outlined" *ngIf="frameActive == 2">
          view_column
        </span>

        <span *ngIf="frameActive == 1"
          >&nbsp;{{ "FORM.CODE" | translate }}</span
        >
        <span *ngIf="frameActive == 2"
          >&nbsp;{{ "ENV.PREVIEW" | translate }}</span
        >
      </a>
    </div>
    <div class="d-flex flex-column flex-grow position-relative">
      <div
        class="position-absolute w-100 h-100 overflow-auto"
        *ngIf="frameActive == 1"
        [innerHTML]="renderHTML()"
      ></div>
      <div
        class="position-absolute w-100 h-100"
        (resize)="reInitMonacoEditor()"
        style="height: 200px"
        *ngIf="frameActive == 2 && !editorRefreshing"
      >
        <ngx-monaco-editor
          [options]="editorOptions"
          [(ngModel)]="code"
          class="h-100"
          style="height: 100%"
          (ngModelChange)="onCodeChanged()"
        ></ngx-monaco-editor>
      </div>
    </div>
  </div>
</div>
