<div class="card-list-item flex flex-column md:flex-row flex-grow-1 mb-3">
  <div class="flex flex-column w-12 md:w-12 lg:w-12 px-4 py-3 mb-3 justify-content-between">
    <div>
      <div class="flex w-12 md:flex lg:flex card-item-tags align-items-center justify-content-between">
        <a class="card-item-title">{{ item.content.name_ }}</a>
        <div>
          <button pButton pRipple icon="pi pi-ellipsis-h" class="p-button-text p-button-raised p-button-info"
            (click)="menu.toggle($event)"></button>
          <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
        </div>
      </div>
    </div>
    <div class="flex flex-column justify-content-center text-center cursor-pointer"
      [pTooltip]="globals.CONTENT_TYPES[item.content.type_].label | translate"
      (click)="previewSupportContent(item); $event.stopPropagation()">
      <p-tag class="mx-1 my-1 d-inline-flex" [severity]="globals.CONTENT_TYPES[item.content.type_].severity">
        <span class="material-symbols-outlined" style="font-size: 100px">{{
          globals.CONTENT_TYPES[item.content.type_].materialIcon }}
        </span>
      </p-tag>
    </div>

    <div style="height: 50px" class="flex flex-column justify-content-end mb-2" *ngIf="item.content.tags.length">
      <div class="flex w-12 md:w-8 lg:w-9 card-item-tags align-items-center"
        *ngIf="item.content.tags.split(',').length > 1">
        <span class="p-text-secondary font-bold">{{
          "ENV.TAGS" | translate
          }}</span>:
        <p-tag *ngFor="let t of item.content.tags.split(',')" class="mx-1 my-1 d-inline-flex" severity="primary">
          {{ t }}
        </p-tag>
      </div>
    </div>
  </div>
</div>
