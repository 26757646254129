import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-reminder-list',
  templateUrl: './reminder-list.component.html',
  styleUrls: ['./reminder-list.component.sass']
})
export class ReminderListComponent {
  @Output() itemSelectedChange = new EventEmitter<any>()
  @Input() optionSidebar: string = ''
}
