<p-columnFilter
  [field]="field"
  [matchModeOptions]="matchModeOptions"
  [matchMode]="'unique-by-key'"
  display="menu"
  [showMatchModes]="false"
  [showOperator]="false"
  [showAddButton]="false"
>
  <ng-template pTemplate="header">
    <div class="px-3 pt-3 pb-0">
      <span class="p-text-bold">{{ "FORM.TYPES" | translate }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <p-multiSelect
      [ngModel]="value"
      [options]="options"
      placeholder="{{ 'FORM.SELECT-A' | translate }} {{
        ('FORM.TYPE' | translate).toLowerCase()
      }}"
      (onChange)="filter($event.value)"
      selectedItemsLabel="{0} {{ ('FORM.TYPES' | translate).toLowerCase() }} {{
        ('FORM.SELECTED-M' | translate).toLowerCase()
      }}"
      [maxSelectedLabels]="1"
      [optionLabel]="'label'"
      appendTo="body"
    >
      <ng-template let-items pTemplate="selectedItems">
        <div
          class="flex align-items-center gap-2 px-1 mt-2"
          *ngFor="let option of items"
        >
          <p-tag>
            <div class="flex">
              <span
                class="material-symbols-outlined"
                style="font-size: 2rem"
                [pTooltip]="
                  globals.CONTENT_TYPES[option.value].label | translate
                "
              >
                {{ globals.CONTENT_TYPES[option.value].materialIcon }}
              </span>
            </div>
            <div class="flex-grow-1">
              {{ globals.CONTENT_TYPES[option.value].label | translate }}
            </div>
          </p-tag>
        </div>
        <div *ngIf="!value || value.length === 0">
          {{ "FORM.SELECT-A" | translate }}
          {{ ("FORM.TYPE" | translate).toLowerCase() }}
        </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div class="flex">
            <span
              class="material-symbols-outlined"
              style="font-size: 2rem"
              [pTooltip]="globals.CONTENT_TYPES[option.value].label | translate"
            >
              {{ globals.CONTENT_TYPES[option.value].materialIcon }}
            </span>
          </div>
          <div class="flex-grow-1">
            {{ globals.CONTENT_TYPES[option.value].label | translate }}
          </div>
        </div>
      </ng-template>
    </p-multiSelect>
  </ng-template>
</p-columnFilter>
