import { Component } from '@angular/core';

@Component({
  selector: 'app-resource-not-found',
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.sass']
})
export class ResourceNotFoundComponent {

}
