import { AppJSONSanitizer } from "./AppGenericClasses"

export class CourseUnitSectionTakeJSON {
  el_course_unit_section_take_id = 0
  el_course_unit_section_id = 0
  el_course_unit_take_id = 0
  start_date = ""
  completion_date = ""
  progress = 0
  status_ = 1
}

export class CourseUnitSectionTake {
  id: number
  courseUnitSectionId: number
  courseUnitTakeId: number
  startDate: string
  completionDate: string
  progress: number
  active_: boolean

  source: CourseUnitSectionTakeJSON

  constructor(source: CourseUnitSectionTakeJSON) {
    this.source = new AppJSONSanitizer<CourseUnitSectionTakeJSON>(source, CourseUnitSectionTakeJSON).result
    this.id = this.source.el_course_unit_section_take_id
    this.courseUnitSectionId = this.source.el_course_unit_section_id
    this.courseUnitTakeId = this.source.el_course_unit_take_id
    this.startDate = this.source.start_date
    this.completionDate = this.source.completion_date
    this.progress = this.source.progress
    this.active = this.source.status_ != 0 ? true : false
  }

  set active(value: boolean) {
    this.active_ = value
  }
  get status() {
    return this.active_
  }
}
