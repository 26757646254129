/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { BaseItemRestructDirective } from 'src/app/directives/restruct/base-item-restruct.directive';
import { Test } from 'src/app/models/AppModels';
import { TestJSON } from 'src/app/models/AppSources';

@Component({
  selector: 'app-test-detail',
  templateUrl: './test-detail.component.html',
  styleUrls: ['./test-detail.component.sass']
})
export class TestDetailComponent extends BaseItemRestructDirective<Test, TestJSON> {
  @Input() showHeader = true
  @Input() type: 'V' | 'U' | 'C' = 'V'
  ItemModel = Test;

  override getSource() {
    switch (this.type) {
      case 'V':
        this.itemService = this.data.core.testUnique(this.itemId)
        break;
      case 'U':
        this.itemService = this.data.core.testUniqueOfUnit(this.itemId)
        break
    }
    super.getSource()
  }

  onEdit() {
    this.utils.closeAllDialogs()
  }
}
