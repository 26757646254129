import { Injectable } from '@angular/core';
import { CoreDataService } from "./core-data.service";
import { ConfigDataService } from "./config-data.service";
import { QuillModules, defaultModules } from 'ngx-quill';


export interface BaseFormSourceInterface {
  onHandleLoadFinished(key: string, data: any): void
  onHandleNullResponse?(key: string): void
}

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  sources: { [key: string]: any } = {
    topics: {
      key: 'el_topic_id',
      label: 'name_',
      data: [],
      source: this.core.topics()
    },
    'colors-to-select': {
      key: 'color_id',
      label: 'name_',
      data: [],
      source: this.core.colorsToSelect()
    },
    'user-type': {
      key: 'user_type_id',
      label: 'name_',
      data: [],
      source: this.core.userType()
    },
    'group': {
      key: 'user_group_id',
      label: 'name_',
      data: [],
      source: this.core.groups()
    },
    'city': {
      key: 'city_id',
      label: 'name_',
      data: [],
      source: this.core.cities()
    },
    'dni-type': {
      key: 'dni_type_id',
      label: 'name_',
      data: [],
      source: this.core.DNITypes()
    },
    'specialty': {
      key: 'user_post_id',
      label: 'name_',
      data: [],
      source: this.core.specialties()
    },
    'gender': {
      key: 'gender_id',
      label: 'name_',
      data: [],
      source: this.core.genders()
    }
  }



  quillModules: QuillModules = {
    toolbar: {
      container: defaultModules.toolbar,
      handlers: {
        image: this.imageHandler,
      },
    },
  };


  imageHandler(this: any) {
    const tooltip = this.quill.theme.tooltip;
    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;
    tooltip.save = function (this: any) {
      const range = this.quill.getSelection(true);
      const value = this.textbox.value;
      if (value) {
        this.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    };
    // Called on hide and save.
    tooltip.hide = function (this: any) {
      tooltip.save = originalSave;
      tooltip.hide = originalHide;
      tooltip.hide();
    };
    tooltip.edit('image');
    tooltip.textbox.placeholder = 'URL';
  }




  listener: BaseFormSourceInterface | undefined

  constructor(
    public core: CoreDataService,
    public config: ConfigDataService
  ) { }

  load(...sources: string[]) {
    sources.forEach(source => {
      if (this.sources.hasOwnProperty(source)) {
        if (!this.sources[source].data.length) {
          this.sources[source].source.subscribe((data: any) =>
            this.handleLoadSuccess(source, data)
          )
        }
      }
    })
  }

  handleLoadSuccess(key: string, data: any): void {
    if (data == null) {
      if (typeof this.listener?.onHandleNullResponse === 'function') {
        this.listener?.onHandleNullResponse(key)
      }
      return
    }
    this.sources[key].data = data
    this.listener?.onHandleLoadFinished(key, data)
  }

  setListener(listener: BaseFormSourceInterface) {
    this.listener = listener
  }

}
