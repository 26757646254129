import { AWSS3Service } from 'src/app/services/data/aws-s3.service';
import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MediaUploadItem } from 'src/app/models/AppGenericClasses';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Storage } from 'aws-amplify';
import { Globals } from 'src/app/globals';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-media-uploader',
  templateUrl: './media-uploader.component.html',
  styleUrls: ['./media-uploader.component.sass']
})
export class MediaUploaderComponent implements OnInit {
  @Input() mediaItems: MediaUploadItem[] = [];
  progressFinish = 100;
  doDismiss = false;
  ACCEPTED_FILE_EXTENSIONS = Globals.ACCEPTED_FILE_EXTENSIONS;

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.mediaItems = this.config.data;
    this.upload(0);
  }

  upload(i) {
    if (i >= this.mediaItems.length) {
      this.finish();
      return;
    }
    const item = this.mediaItems[i];
    switch (item.type) {
      case 'canvas':
      case 'habeas-data':
        item.status = 1;
        this.uploadBase64(
          item,
          () => {
            item.status = 2;
            this.upload(i + 1);
          },
          err => {
            Utils.error(err);
          }
        );
        break;
      case 'image':
      case 'file':
        item.status = 1;
        AWSS3Service.uploadFile(item)
          .then(() => {
            item.status = 2
            this.upload(i + 1)
          }).catch(err => {
            Utils.error(err);
          })
        break
    }
  }

  uploadBase64(item: MediaUploadItem, success: () => void, error: (err: any) => void) {
    Storage.put(`${item.data.table}/${item.data.id}/${item.data.field}/resource.jpg`,
      this.b64toBlob(item.base64, 'image/jpeg'),
      {
        level: 'public',
        contentType: 'image/jpeg'
      })
      .then(() => success())
      .catch(err => error(err));
  }

  finish() {
    this.doDismiss = true;
    setInterval(() => {
      if (this.progressFinish <= 0) {
        this.ref.close(true);
      }
      this.progressFinish = 0;
    }, 1000);
  }
}
