import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.sass'],
})
export class UnitsComponent extends BaseListDirective {
  course_name = '';
  courseId = null;
  override filterableFields = ['name_', 'description_', 'tags'];
  override orderKey = 'order_';

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef);
  }

  onInit(): void {
    this.courseId = this.route.snapshot.paramMap.get('id');
    this.course_name = localStorage.getItem('course_name') ?? '';
    super.onInit();
  }

  override getList() {
    this.query(this.data.core.units(this.courseId)).subscribe();
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemSelected[this.keys.primary_key]
    );
  }

  handleRows(data): void {
    data = data.map(r => {
      let dh = 0;
      let dm = 0;
      let ss = 0;
      return {
        ...r,
        sections: r.sections.map(s => {
          let dur = Utils.convertSecondsToHMS(s.resource_content.min_viewing_time)
          dh += dur.hours
          dm += dur.minutes
          ss += dur.seconds
          return {
            ...s,
            duration:dur
          }
        }),
        duration:  Utils.convertSecondsToHMS(Utils.convertHHMMToSeconds(dh,dm,ss))
      }
    });
    console.log(data);
    
    super.handleRows(data);
  }

  setReorderMethod() {
    console.log(this.rows);

    this.updateOrderService = this.data.core.unitUpdateInBatch({
      objects: this.rows,
    });
    super.setReorderMethod();
  }
}
