import { TextCase } from './../../models/AppGenericClasses';
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-prototype-builtins */
import { DatePipe } from "@angular/common"
import { isDevMode } from "@angular/core"
import { AppTimer, AppTimerModel, SortType } from "src/app/models/AppGenericClasses"
import { AppItem } from 'src/app/models/AppModels';



export class Utils {

  static log(data: any) {
    if (isDevMode()) {
      console.log(data)
    }
  }

  static error(data: string) {
    if (isDevMode()) {
      console.error(data)
    }
  }

  static ngCellValue(key: string, row: any, separator = ' ') {
    const parts = key.split(',')
    const values: string[] = []
    parts.forEach((item) => {
      values.push(this.nestedValue(item, row))
    })
    return values.join(separator)
  }

  static nestedValue(key: string, item: any) {
    if (key?.includes('.')) {
      const objKeys = key.split('.')
      let tmpObject = item
      objKeys.forEach((k) => {
        if (tmpObject) {
          tmpObject = tmpObject[k]
        }
      });
      return tmpObject
    }
    return item[key]
  }

  static capitalizeEachWord(sentence) {
    if (sentence) {
      if (sentence.length > 1) {
        let words = sentence.toLowerCase().trim().split(' ');
        words = words.filter((it) => it !== '');
        return words
          .map((word) => {
            return word[0].toUpperCase() + word.substring(1);
          })
          .join(' ');
      } else {
        return sentence.toUpperCase();
      }
    }
    return '';
  }

  static searchByAttr(arrObjs, key, target, sensitive = false) {
    let val = '';
    return arrObjs.filter((obj) => {
      val = this.nestedValue(key, obj);
      if (typeof val === 'string' && !sensitive && typeof target === 'string') {
        val = val.toLowerCase();
        target = target.toLowerCase();
      }
      return val == target;
    });
  }

  static basicSort(arr: any[], orderType: 'asc' | 'desc' = 'asc') {
    const sorted = arr.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    if (orderType == 'desc') {
      return sorted.reverse();
    }
    return sorted;
  }

  static sortByProperty<Type = any>(arrObj: Type[], property: string, orderType: SortType = 'asc') {
    const sorted = arrObj.sort((a, b) => {
      let valueA = this.nestedValue(property, a);
      let valueB = this.nestedValue(property, b);
      if (typeof valueA == 'string') {
        valueA = this.ngCellValue(property, a, '.').toLowerCase();
      }
      if (typeof valueB == 'string') {
        valueB = this.ngCellValue(property, b, '.').toLowerCase();
      }
      if (valueA < valueB || !valueA) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    if (orderType == 'desc' || orderType == -1) {
      return sorted.reverse();
    }
    return sorted;
  }

  static cleanObj(obj: object) {
    for (const key of Object.keys(obj)) {
      obj[key] = null
    }
  }

  static fillObj(obj: object, data: object) {
    Object.entries(obj).forEach(([key]) => {
      obj[key] = data[key]
    })
  }

  static titleCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  static applyTextCase(text: string, textCase: TextCase) {
    switch (textCase) {
      case 'TITLE-CASE':
        return this.titleCase(text)
      case 'UPPER-CASE':
        return text.toUpperCase()
      case 'LOWER-CASE':
        return text.toLowerCase()
      default:
        return text;
    }
  }

  static getFlagYNValue(field, obj) {
    const valueField = this.ngCellValue(field, obj);
    if (valueField) {
      return valueField == 'Y' || valueField == '1'
    } else {
      return false;
    }
  }

  static convertTimeUnit(value: number, fromUnit: number, toUnit: number): number {
    return (value * fromUnit) / toUnit;
  }

  static dateToFormat(date: Date, format: string = 'dd MMM yyy') {
    const datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }

  static deepCopy<T>(source: T): T {
    if (source === null || typeof source !== 'object') {
      return source;
    }

    if (source instanceof Date) {
      return new Date(source.getTime()) as any;
    }

    if (source instanceof Array) {
      const newArray = [];
      for (let i = 0; i < source.length; i++) {
        newArray[i] = this.deepCopy(source[i]);
      }
      return newArray as any;
    }

    if (source instanceof Object) {
      const newObject = {} as T;
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          newObject[key] = this.deepCopy(source[key]);
        }
      }
      return newObject;
    }

    throw new Error('Unable to copy object. Unsupported type.');
  }

  static shuffleArray<T>(array: T[]): T[] {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }

  static sumArray(arr: number[]) {
    let sum = 0;
    arr.forEach((x) => {
      sum += x;
    });
    return sum;
  }

  static sumArrayByKey(arr: object[], key: string) {
    let sum = 0;
    arr.forEach((x) => {
      sum += +this.nestedValue(key, x);
    });
    return sum;
  }

  static sumTimeCourse(arr: any[]) {
    let sum = 0;
    arr.forEach((u) => {
      u.sections.forEach(s => {
        sum += s.resourceContent.minViewingTime
      });
    });
    return this.convertSecondsToHMS(sum);
  }


  static convertHHMMToSeconds(hh, mm, ss) {
    return hh * 3600 + mm * 60 + ss;
  }

  static convertSecondsToHMS(seconds: number): AppTimerModel {
    if (seconds < 0) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return {
      hours: hours,
      minutes: minutes,
      seconds: remainingSeconds,
    };
  }

  static getTimerSeconds(timer: AppTimer) {
    return (timer.hours * 3600) + (timer.minutes * 60) + timer.seconds
  }

  static secondsBetweenDates(dateFrom: Date, dateTo: Date): number {
    const diffInMilliseconds = dateTo.getTime() - dateFrom.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

    return diffInSeconds;
  }

  static zero(num: number) {
    return num < 10 ? `0${num}` : num.toString();
  }

  static uniq(a: any[]) {
    return [...new Set(a)];
  }

  static uniqByKey(array: any[], key: string) {
    const uniqueValues = new Set();
    const result = [];

    array.forEach(obj => {
      const value = key ? this.nestedValue(key, obj) : obj
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value)
        result.push(obj)
      }
    });

    return result;
  }

  static toPrimengDropdown(arr: any[], labelKey = null) {
    const data = [];
    arr.forEach((item) => {
      let label = item
      if (labelKey) {
        label = this.nestedValue(labelKey, item)
      }
      data.push({ label, value: item });
    });
    return data;
  }

  static uniqueByKeyToPrimengDropdown(arr: any[], key: string, label: string = null) {
    const options = arr.flatMap(item => {
      const value = Utils.nestedValue(key, item);

      if (typeof value === 'string') {
        return value.split(',').map(x => x.trim());
      }
      if (Array.isArray(value)) {
        return value;
      }
      if (typeof value === 'object' && !Array.isArray(value)) {
        return [value];
      }
    });

    const uniqueOptions = Utils.uniqByKey(options.filter(x => x), label);
    const sortedOptions = Utils.sortByProperty(uniqueOptions, label, 'asc');
    const primengDropdown = Utils.toPrimengDropdown(sortedOptions, label);

    return primengDropdown.filter(x => x.label);
  }

  static removeDuplicatesById<T extends AppItem>(array: T[]): T[] {
    const uniqueMap: Map<number, T> = new Map();

    array.forEach(object => {
      uniqueMap.set(object.id, object);
    });

    return Array.from(uniqueMap.values());
  }

  static objFilter(arr: any[], filters: { [key: string]: string[] }) {
    if (!arr.length) return [];

    return arr.filter(field => {
      return Object.keys(filters).every(key => {
        return this.arrayContains(filters[key], field[key]);
      });
    });
  }

  static arrayContains(arr: any, target: any) {
    if (!arr || !target) return false;

    if (typeof target === 'number') {
      target = target.toString();
    } else if (typeof target === 'string') {
      target = target.trim().toLowerCase();
      return arr.some(element => element.trim().toLowerCase() === target);
    } else if (Array.isArray(target)) {
      const strArr = arr.map(element => String(element).trim().toLowerCase());
      const strTarget = target.map(element => String(element).trim().toLowerCase());
      return strArr.some(val => strTarget.includes(val));
    }
    return false;
  }

  static toStrArr(arr) {
    return arr.map(val => typeof val === 'string' || typeof val === 'number' ? val.toString() : val);
  }

  static floatStringToNumber(value: string) {
    return value ? parseFloat((+value as number).toFixed(2)) : 0
  }

  static copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
