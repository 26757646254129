/* eslint-disable @typescript-eslint/no-empty-function */
import { AppJSONSanitizer } from "./AppGenericClasses";
import { Poll, Test, Topic } from "./AppModels"
import { TopicJSON, PollJSON, TestJSON } from "./AppSources";
import { CourseTopic, CourseTopicJSON } from "./CourseTopicClasses";

export class AppParser<T, Y> {
  result: T
  protected data: Y

  constructor(data: Y, Model: new () => Y) {
    this.sanitize(data, Model)
  }

  private sanitize(data: Y, Model: new () => Y) {
    this.data = new AppJSONSanitizer<Y>(data, Model).result
  }
}

export class TopicParser extends AppParser<Topic, TopicJSON> {

  constructor(data: TopicJSON) {
    super(data, TopicJSON);
    this.result = Object.setPrototypeOf({
      name: this.data.name_
    } as Topic, Topic.prototype);
  }
}

export class PollParser extends AppParser<Poll, PollJSON> {

  constructor(data: PollJSON) {
    super(data, PollJSON);
    this.result = Object.setPrototypeOf({
      title: this.data.title,
      review: this.data.review,
      openQuestionsCount: this.data.questions_count
    } as Poll, Poll.prototype);
  }
}

export class TestParser extends AppParser<Test, TestJSON> {

  constructor(data: TestJSON) {
    super(data, TestJSON);
    this.result = Object.setPrototypeOf({
      id: this.data.el_test_id,
      timeLimit: this.data.time_limit,
      randomQuestions: this.data.random_questions,
      minApprovalPercentage: this.data.min_approval_percentage,
      poll: new PollParser(this.data.poll).result
    } as Test, Test.prototype);
  }
}

export class CourseTopicParser extends AppParser<CourseTopic, CourseTopicJSON> {

  constructor(data: CourseTopicJSON) {
    super(data, CourseTopicJSON);
    this.result = Object.setPrototypeOf({
      topic: new TopicParser(this.data.topic).result
    } as CourseTopic, CourseTopic.prototype);
  }
}
