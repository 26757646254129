<div class="flex flex-column h-full overflow-hidden">
  <div class="flex">
    <h3 *ngIf="optionSidebar === 'test'" class="my-0">
      {{ "ENV.AVAILABLE-TEST-VERSIONS" | translate }}
    </h3>
    <h3 *ngIf="optionSidebar === 'notifications'" class="my-0">
      {{ "ENV.NOTIFICATIONS" | translate }}
    </h3>
  </div>
  <p-divider></p-divider>
  <div class="flex-grow-1 overflow-hidden">
    <p-scrollPanel styleClass="w-full h-full">
      <app-available-test-version-list
        *ngIf="optionSidebar === 'test'"
        (itemSelectedChange)="itemSelectedChange.emit()"
      ></app-available-test-version-list>
      <app-notifications
        *ngIf="optionSidebar === 'notifications'"
      ></app-notifications>
    </p-scrollPanel>
  </div>
  <ng-template #loadingTemplate>
    <div class="loading-container">
      <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
    </div>
  </ng-template>
</div>
