import { Component } from '@angular/core';
import { BaseFormDirective } from 'src/app/directives/base-form.directive';

@Component({
  selector: 'app-color-form',
  templateUrl: './color-form.component.html',
  styleUrls: ['./color-form.component.sass']
})
export class ColorFormComponent extends BaseFormDirective {
  override title = 'MENU.COLORS'
  override form = {
    name_: null,
    surface_light: null,
    text_color_light: null,
    surface_dark: null,
    text_color_dark: null
  }
  path = 'colors'
  showColorPicker = false;

  toggleColorPicker() {
    this.showColorPicker = !this.showColorPicker;
  }

  updateInputBackground() {
    const darkSpan = document.getElementById('dark-span');
    const lightSpan = document.getElementById('light-span');
    const darkTextSpan = document.getElementById('dark-text-span');
    const lightTextSpan = document.getElementById('light-text-span');

    if (darkSpan) {
      darkSpan.style.background = this.form.surface_dark;
    }

    if (lightSpan) {
      lightSpan.style.background = this.form.surface_light;
    }

    if (darkTextSpan) {
      darkTextSpan.style.background = this.form.text_color_dark;
    }

    if (lightTextSpan) {
      lightTextSpan.style.background = this.form.text_color_light;
    }
  }

  override getItemEdit() {
    this.editService = this.data.core.colorUnique(this.editId)
    super.getItemEdit()
  }

  override setSubmitMethods(): void {
    this.postService = this.data.core.colorCreate(this.formSanitized)
    this.putService = this.data.core.colorUpdate(this.editId, this.formSanitized)
  }

  override onSubmit() {
    this.form.surface_dark = this.form.surface_dark.replace('#', '');
    this.form.surface_light = this.form.surface_light.replace('#', '');
    this.form.text_color_dark = this.form.text_color_dark.replace('#', '');
    this.form.text_color_light = this.form.text_color_light.replace('#', '');
    console.log('Form Data:', this.form);
    super.onSubmit()
  }
}
