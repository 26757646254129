<ng-container *ngIf="item">
  <div class="flex align-items-center">
    <button
      pButton
      pRipple
      icon="pi pi-eye"
      class="p-button-text p-button-raised p-button-info"
      pTooltip="{{ 'GENERAL.SEE' | translate }} {{
        'CORE.CONTENT' | translate
      }}"
      tooltipPosition="left"
      (click)="seeResource()"
    ></button>
  </div>
</ng-container>
