/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { AWSUtils } from 'src/app/shared/utils/aws-utils';
import { Utils } from 'src/app/shared/utils/utils';
import { ContentTriggerButtonComponent } from '../content-trigger-button/content-trigger-button.component';

@Component({
  selector: 'app-course-user-detail',
  templateUrl: './course-user-detail.component.html',
  styleUrls: ['./course-user-detail.component.sass']
})
export class CourseUserDetailComponent extends BaseItemDirective {
  @ViewChild('contentTrigger') contentTrigger!: ContentTriggerButtonComponent

  @Input() userId = 0
  @Input() courseId = 0
  course = null
  enrollment = null
  courseEnrollmentId = 0
  estimatedDuration = null

  units: any[] = []
  unitTakes: any[] = []
  sequentialUnits = 0

  override getItem() {
    this.itemService = this.data.core.fullCourse(this.courseId);
    super.getItem();
  }

  override handleGetItemSuccess(data: any) {
    this.utils.executeRequest(this.data.core.courseEnrollmentCurrent({
      user_id: this.userId,
      el_course_id: this.courseId
    }), {
      onSuccess: (currentEnrollment: any) => {
        console.log(currentEnrollment);
        this.enrollment = currentEnrollment
        this.enrollment.user.fullname = `${this.enrollment.user?.first_name} ${this.enrollment.user?.last_name}`
        this.courseEnrollmentId = currentEnrollment.el_course_enrollment_id
        this.utils.executeRequest(this.data.core.courseEnrollmentUnitTakes({
          el_course_id: this.courseId,
          el_course_enrollment_id: this.courseEnrollmentId
        }), {
          onSuccess: (unitTakes) => {
            this.unitTakes = unitTakes
          },
          onError: () => {
            super.handleGetItemSuccess(data)
          },
          onComplete: () => {
            super.handleGetItemSuccess(data)
          }
        })
      }
    })
  }

  override onItemReady() {
    this.course = this.item;
    this.sequentialUnits = this.course.sequential_units
    this.units = this.course.units
    this.units = this.units.filter((unit: any) => unit.status_)
    this.units = Utils.sortByProperty(this.units, 'order_', 'asc')

    this.units.forEach((unit: any, uIndex) => {
      unit.el_course_unit_take_id = 0
      unit.sections = unit.sections.filter(section => section.status_);
      unit.sections = Utils.sortByProperty(unit.sections, 'order_', 'asc')
      unit.couldGoToUnit = this.couldGoToUnit(uIndex)

      unit.sections.forEach((section: any, sIndex: number) => {
        section.el_course_unit_section_take_id = 0
        section.isComplete = false
        section.couldGoToSection = this.couldGoToSection(sIndex, uIndex)
      });

      unit.tests.forEach(uTest => {
        uTest.isComplete = false
        uTest.hasTakes = false
        uTest.remainingAttempts = uTest.test.allowed_attempts
        uTest.couldGoToTest = this.couldGoToTest(uIndex)
      })

      this.initUnitTakeData(unit)

      unit.isComplete = [
        !unit.sections.length || unit.sections.every(section => section.isComplete),
        !unit.tests.length || unit.tests.every(test => test.isComplete)
      ].every(Boolean);
    });

    this.estimatedDuration = this.course.estimated_duration.split(' ')[0];
    this.updateCourseModel()
  }

  initUnitTakeData(unit: any) {
    const unitTake = this.unitTakes.find(uTake => {
      return uTake.el_course_unit_id === unit.el_course_unit_id
    })

    if (unitTake) {
      unit.el_course_unit_take_id = unitTake.el_course_unit_take_id
      unit.sections.forEach((section: any) => {
        const sectionTake = unitTake.sections.find(sTake => sTake.el_course_unit_section_id === section.el_course_unit_section_id)
        if (sectionTake) {
          section.el_course_unit_section_take_id = sectionTake.el_course_unit_section_take_id
          section.isComplete = sectionTake.completion_date;
        }
      });
      unit.tests.forEach(uTest => {
        const testTakes = unitTake.takes.filter(tTake => tTake.take.el_test_id === uTest.el_test_id)
          .map(tTake => {
            tTake.result = Utils.floatStringToNumber(tTake.take.result)
            return tTake
          })
        uTest.hasTakes = !!testTakes.length
        const testMinApprovalPerc = Utils.floatStringToNumber(uTest.test.min_approval_percentage)
        uTest.highestRating = Utils.sortByProperty(testTakes, 'result', 'desc')[0]?.result
        uTest.approved = uTest.highestRating >= testMinApprovalPerc
        uTest.remainingAttempts = uTest.allowed_attempts - testTakes.length
        uTest.isComplete = !uTest.approval_required || (testTakes.length && uTest.approved)
      })
    }
  }

  updateCourseModel() {
    this.units.forEach((unit: any, uIndex: number) => {
      unit.couldGoToUnit = this.couldGoToUnit(uIndex)
      unit.sections.forEach((section: any, sIndex: number) => {
        section.couldGoToSection = this.couldGoToSection(sIndex, uIndex)
      });
      unit.tests.forEach((test: any) => {
        console.log(this.units[uIndex]);

        test.couldGoToTest = this.couldGoToTest(uIndex)
      });
      unit.isComplete = [
        !unit.sections.length || unit.sections.every(section => section.isComplete),
        !unit.tests.length || unit.tests.every(test => test.isComplete)
      ].every(Boolean);
    });
    console.log(this.units);
  }

  refreshActionServices(): void { }

  previousUnitsCompleted(unitIdx: any) {
    const prevUnits = this.units.slice(0, unitIdx)
    return !prevUnits.length || prevUnits.every((unit: any) => unit.isComplete);
  }

  previousSections(sectionIdx: number, unitIdx: number) {
    return this.units[unitIdx].sections.slice(0, sectionIdx)
  }

  previousSectionsCompleted(sectionIdx: number, unitIdx: number) {
    const prevSections = this.previousSections(sectionIdx, unitIdx)
    return !prevSections.length || prevSections.every((section: any) => section.isComplete || (section.couldGoToSection && !section.visualize_required));
  }

  couldGoToSection(sectionIdx: number, unitIdx: number) {
    const unit = this.units[unitIdx]
    let result = true

    if (this.sequentialUnits) {
      result = this.previousUnitsCompleted(unitIdx)
    }
    if (result && unit.sequential_sections) {
      result = this.previousSectionsCompleted(sectionIdx, unitIdx)
    }
    return result
  }

  couldGoToTest(unitIdx: number) {
    return this.units[unitIdx].sections.every(section => section.isComplete)
  }

  couldGoToUnit(unitIdx: number) {
    return !this.sequentialUnits ||
      (this.sequentialUnits && this.previousUnitsCompleted(unitIdx))
  }

  override handleRouteParams(params: Params): void {
    super.handleRouteParams(params);
    if ('id' in params) {
      this.courseId = +params['id'];
      this.userId = +params['userId'];
      this.itemId = this.courseId;
    }
  }

  calculateUnitProgress(unit: any): number {
    const completedSections = unit.sections.filter(section => section.isComplete && section.visualize_required).length;
    const totalSections = unit.sections.filter(section => section.status_ === 1 && section.visualize_required).length;
    const completedTests = unit.tests.filter(test => test.isComplete && test.approval_required).length;
    const totalTests = unit.tests.filter(test => test.approval_required).length;

    return ((completedSections + completedTests) / (totalSections + totalTests)) * 100;
  }

  get calculateCourseProgress(): number {
    const sections = this.units.map(unit => unit.sections).flat()
    const completedSections = sections.filter(section => section.status_ === 1 && section.isComplete).length;
    const totalSections = sections.filter(unit => unit.status_ === 1).length;

    const tests = this.units.map(unit => unit.tests).flat()
    const completedTests = tests.filter(test => test.isComplete).length;
    const totalTests = tests.filter(test => test.approval_required).length;

    return ((completedSections + completedTests) / (totalSections + totalTests)) * 100;
  }

  viewSupportContent(resource: any) {
    if (this.fireActions) {
      AWSUtils.initResourceData(resource, 'content')
      this.contentTrigger.item = resource
      this.contentTrigger.seeResource()
    }
  }
}
