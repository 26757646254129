<div class="d-inline-flex">
  <p-splitButton
    icon="pi pi-file-o"
    (onClick)="primeNgList.exportCSV()"
    [model]="csvItems"
    class="mr-2 ml-auto"
    pTooltip="{{ 'FILE-EXT.CSV' | translate }}"
    tooltipPosition="bottom"
    appendTo="body"
  ></p-splitButton>
  <p-splitButton
    icon="pi pi-file-excel"
    (onClick)="primeNgList.exportExcel()"
    [model]="excelItems"
    styleClass="p-button-success mr-2"
    pTooltip="{{ 'FILE-EXT.XLSX' | translate }}"
    tooltipPosition="bottom"
    appendTo="body"
  ></p-splitButton>
  <p-splitButton
    icon="pi pi-file-pdf"
    (onClick)="primeNgList.exportPdf()"
    [model]="pdfItems"
    styleClass="p-button-warning mr-2"
    pTooltip="{{ 'FILE-EXT.PDF' | translate }}"
    tooltipPosition="bottom"
    appendTo="body"
  ></p-splitButton>
</div>
