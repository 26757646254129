import { Component } from '@angular/core';
import { BaseConfirmationDialogDirective } from 'src/app/directives/base-confirmation-dialog.directive';

@Component({
  selector: 'app-confimation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confimation-dialog.component.sass']
})
export class ConfirmationDialogComponent extends BaseConfirmationDialogDirective {
}
