/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';
import { Course, CourseParser } from 'src/app/models/CourseClasses';

@Component({
  selector: 'app-course-banner',
  templateUrl: './course-banner.component.html',
  styleUrls: ['./course-banner.component.sass']
})
export class CourseBannerComponent extends BaseItemDirective {
  course!: Course

  refreshActionServices(): void {
  }

  override onItemReady(): void {
    super.onItemReady()
    this.course = new CourseParser(this.item, this.utils).result
  }
}
