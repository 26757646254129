<div class="flex flex-column h-full">
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
            <span class="p-float-label">
              <input
                pInputText
                id="title"
                name="title"
                class="ng-dirty w-full"
                aria-describedby="title-help"
                [(ngModel)]="form.name_"
                required
              />
              <label for="title">{{ "FORM.TITLE" | translate }}*</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 mb-4">
            <span class="p-float-label">
              <textarea
                pInputTextarea
                id="description"
                name="description"
                rows="5"
                class="w-full"
                [(ngModel)]="form.description_"
              ></textarea>
              <label for="description">{{
                "FORM.DESCRIPTION" | translate
              }}</label>
            </span>
          </div>
          <div class="flex flex-column gap-2">
            <span class="p-float-label">
              <p-dropdown
                id="color"
                name="color"
                [options]="data.sources['colors-to-select'].data"
                [(ngModel)]="form.color_id"
                [filter]="true"
                [optionValue]="data.sources['colors-to-select'].key"
                [optionLabel]="data.sources['colors-to-select'].label"
                appendTo="body"
              >
                <ng-template let-color pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <app-color-chip
                      [color]="color"
                      [content]="color.name_"
                    ></app-color-chip>
                  </div>
                </ng-template>
                <ng-template let-value pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2">
                    <app-color-chip
                      [color]="value"
                      [content]="value.name_"
                    ></app-color-chip>
                  </div>
                </ng-template>
              </p-dropdown>
              <label for="color">{{ "FORM.COLOR" | translate }}*</label>
            </span>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>

<!-- <div class="flex flex-column h-full">
  <div class="flex" style="height: auto">
    <div class="flex flex-column justify-content-start">
      <h2 class="module-title cursor-pointer" routerLink="/topics">
        {{ title | translate | uppercase }}
      </h2>
    </div>
    <div class="flex mx-4 my-1">
      <i class="pi pi-angle-double-right" style="font-size: 2rem; color: var(--primary-color)"></i>
    </div>
    <div class="flex flex-column justify-content-start">
      <h2 class="module-title">
        {{ (editId > 0 ? 'ENV.EDIT' : 'ENV.NEW') | translate }}
        {{ 'CORE.TOPIC' | translate | lowercase }}
      </h2>
    </div>

    <div class="flex flex-grow-1 flex-initial align-items-center justify-content-end">
      <button pButton pRipple class="p-button-text" icon="pi pi-cloud-upload" label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid" (click)="dataForm.ngSubmit.emit()" *ngIf="!processing"></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="px-4 py-3">
          <div class="flex flex-column gap-2 mt-4 lg:mt-0 mb-4">
            <span class="p-float-label">
              <input pInputText id="title" name="title" class="ng-dirty w-full" aria-describedby="title-help"
                [(ngModel)]="form.name_" required />
              <label for="title">{{ "FORM.TITLE" | translate }}*</label>
            </span>
          </div>
          <div class="flex flex-column gap-2 mb-4">
            <span class="p-float-label">
              <textarea pInputTextarea id="description" name="description" rows="5" class="w-full"
                [(ngModel)]="form.description_"></textarea>
              <label for="description">{{
                "FORM.DESCRIPTION" | translate
                }}</label>
            </span>
          </div>
          <div class="flex flex-column gap-2">
            <span class="p-float-label">
              <p-dropdown id="color" name="color" [options]="data.sources['colors-to-select'].data"
                [(ngModel)]="form.color_id" [filter]="true" [optionValue]="data.sources['colors-to-select'].key"
                [optionLabel]="data.sources['colors-to-select'].label" appendTo="body"></p-dropdown>
              <label for="color">{{ "FORM.COLOR" | translate }}*</label>
            </span>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div> -->
