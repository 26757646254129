import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BaseItemDirective } from 'src/app/directives/base-item.directive';

@Component({
  selector: 'app-evaluation-item',
  templateUrl: './evaluation-item.component.html',
  styleUrls: ['./evaluation-item.component.sass']
})
export class EvaluationItemComponent extends BaseItemDirective {
  @Input() showTags = true;
  @Input() presentation = 1;
  unitName = '';
  override title = 'CORE.TEST';
  override keys = {
    primary_key: 'el_course_unit_test_id',
    label: 'test.poll.title',
  };
  items: MenuItem[] = [
    {
      id: 'QUESTIONS',
      label: this.utils.translate.instant('MENU.QUESTIONS'),
      icon: 'pi pi-question',
      command: () => {
        this.utils.router.navigateByUrl(`/admin/test/detail/${this.item.el_test_id}`)
      },
    },
    {
      id: 'EDIT',
      label: this.utils.translate.instant('ENV.EDIT'),
      icon: 'pi pi-pencil',
      command: () => {
        this.utils.router.navigateByUrl(`/courses/units/tests/edit/${this.item.el_course_unit_test_id}`)
      },
    },
    {
      separator: true
    },
    {
      id: 'INACTIVATE',
      label: this.utils.translate.instant('ENV.INACTIVATE'),
      icon: 'pi pi-ban',
      styleClass: 'text-danger',
      command: () => this.inactivate("WIDGET.INACTIVATE-UNIT-TEST-WARNING"),
    },
  ];

  public timeMap = {
    '=1': '# hora',
    'other': '# horas'
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.unitTestDelete(
      this.item[this.keys.primary_key]
    );
  }
}
