<div class="flex flex-column" [ngClass]="containerStyleClass">
  <div class="flex align-items-center">
    <div
      class="flex flex-wrap gap-2 flex-grow-1 align-items-center mb-2"
      *ngIf="itemList"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-primary"
        icon="pi pi-filter"
        label="{{ 'ENV.FILTER' | translate }}"
        (click)="seeCustomFilters()"
      ></button>

      <span>
        {{ "ENV.APPLIED-FILTERS" | translate }}:
        {{ itemList.filter.appliedFilters }}
      </span>

      <button
        pButton
        pRipple
        class="p-button-text p-button-info"
        icon="pi pi-clone"
        label="{{ 'CORE.CLONE-COURSE' | translate | lowercase }}"
        *ngIf="userType.could_manage_course"
        (click)="seeCustomCourses()"
      ></button>

      <p-dropdown
        id="progress"
        name="progress"
        optionLabel="label"
        optionValue="value"
        *ngIf="itemList.filter.ProgressCourseFilter && !userType.could_manage_course"
        [options]="itemList.filter.ProgressCourseFilter.options"
        [(ngModel)]="itemList.filter.ProgressCourseFilter.value"
        [filter]="true"
        appendTo="body"
        (ngModelChange)="onChangeEnrollment()"
      ></p-dropdown>

    </div>
    <div
      class="flex flex-wrap gap-2"
      *ngIf="userType.could_manage_course && couldShowInactives"
    >
      <p-inputSwitch
        [(ngModel)]="inactives"
        (ngModelChange)="onInactivesChange()"
      ></p-inputSwitch>
      <span class="relative" style="top: -1px">Inactivos</span>
    </div>
  </div>
  <h3 *ngIf="inactives" class="text-danger uppercase">Inactivos</h3>
  <div class="flex flex-grow-1 h-full" *ngIf="!horizontal">
    <div class="flex w-12 relative">
      <div class="absolute h-full w-full">
        <p-scrollPanel
          [style]="{ width: '100%', height: '100%' }"
          *ngIf="!loading; else loadingTemplate"
        >
          <ng-container>
            <ng-container *ngFor="let row of itemList.rows">
              <app-course-item
                [showActions]="showActions"
                [presentation]="presentation"
                [source]="row.source"
                (itemDisabled)="getList()"
                (itemEnabled)="getList()"
                (requestReload)="getList()"
                [couldCloneCourse]="true"
                *ngIf="row.active"
              ></app-course-item>
              <app-course-item-inactive
                [showActions]="showActions"
                [presentation]="presentation"
                [source]="row.source"
                (itemDisabled)="getList()"
                (itemEnabled)="getList()"
                (requestReload)="getList()"
                *ngIf="!row.active"
              ></app-course-item-inactive>
            </ng-container>
          </ng-container>
        </p-scrollPanel>
      </div>
    </div>
  </div>

  <div class="flex flex-grow-1 h-full" *ngIf="horizontal">
    <div class="flex w-12">
      <div class="h-full w-full" *ngIf="!loading; else loadingTemplate">
        <p-carousel
          [value]="itemList.rows"
          [numVisible]="itemList.rows.length <= 3 ? itemList.rows.length : 3"
          [numScroll]="1"
          [circular]="false"
        >
          <ng-template let-row pTemplate="item">
            <div class="m-3">
              <app-course-item
                [showActions]="showActions"
                [showDescription]="showDescription"
                [presentation]="presentation"
                [withUserEnrollment]="withUserEnrollment"
                [source]="row.source"
                (itemDisabled)="getList()"
                (itemEnabled)="getList()"
                (requestReload)="getList()"
                *ngIf="row.active"
              ></app-course-item>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>

  <p-sidebar [(visible)]="showCustomFilters" appendTo="body">
    <ng-template pTemplate="header">
      <h3>{{ "ENV.FILTER" | translate }} | {{ "MENU.COURSES" | translate }}</h3>
    </ng-template>
    <ng-container *ngIf="itemList">
      <div
        class="flex flex-column gap-2 mb-3"
        *ngIf="itemList.filter.tagFilter"
      >
        <span class="p-float-label">
          <p-multiSelect
            id="tags"
            name="tags"
            [options]="itemList.filter.tagFilter.options"
            [(ngModel)]="itemList.filter.tagFilter.value"
            [filter]="true"
            (ngModelChange)="onCustomFilter()"
            appendTo="body"
          ></p-multiSelect>
          <label for="tags">{{ "FORM.TAGS" | translate }}</label>
        </span>
      </div>
      <div
        class="flex flex-column gap-2 mb-3"
        *ngIf="itemList.filter.courseTopicFilter"
      >
        <span class="p-float-label">
          <p-multiSelect
            id="topics"
            name="topics"
            [options]="itemList.filter.courseTopicFilter.options"
            [(ngModel)]="itemList.filter.courseTopicFilter.value"
            [filter]="true"
            optionValue="value"
            optionLabel="name"
            (ngModelChange)="onCustomFilter()"
            appendTo="body"
          >
            <ng-template let-option pTemplate="item">
              <div class="flex align-items-center gap-2">
                <app-color-chip
                  [color]="option.value.topic.color.source"
                  [content]="option.value.topic.name"
                ></app-color-chip>
              </div>
            </ng-template>
            <ng-template let-selected pTemplate="selectedItems">
              <div
                class="flex flex-wrap align-items-center gap-2 px-1"
                style="min-height: 20px"
              >
                <app-color-chip
                  *ngFor="let item of selected"
                  [color]="item.topic.color.source"
                  [content]="item.topic.name"
                ></app-color-chip>
              </div>
            </ng-template>
          </p-multiSelect>
          <label for="topics">{{ "FORM.TOPICS" | translate }}</label>
        </span>
      </div>
    </ng-container>
    <ng-template pTemplate="footer">
      <button pButton (click)="cleanCustomFilters()">Limpiar filtros</button>
    </ng-template>
  </p-sidebar>

  <p-sidebar [(visible)]="showCustomCourses" appendTo="body">
    <ng-template pTemplate="header">
      <h3>{{ "MENU.COURSES" | translate }}</h3>
    </ng-template>
    <ng-container *ngIf="itemList">
      <div
        class="flex flex-column gap-2 mb-3"
        *ngIf="itemList.filter.courseFilter"
      >
        <span class="p-float-label">
          <p-dropdown
            id="courses"
            name="courses"
            optionLabel="name_"
            optionValue="id"
            [options]="itemList.filter.courseFilter.options"
            [(ngModel)]="itemList.filter.courseFilter.value"
            [filter]="true"
            appendTo="body"
          ></p-dropdown>
          <label for="courses">{{ "MENU.COURSES" | translate }}</label>
        </span>
        <span class="p-float-label">
          <input
            type="text"
            pInputText
            [(ngModel)]="nameCourse"
            class="bg-transparent"
            style="width: 100%"
            autocomplete="false"
          />
          <label for="courses">{{ "FORM.NAME" | translate }}</label>
        </span>
      </div>
    </ng-container>
    <ng-template pTemplate="footer">
      <button
        pButton
        [disabled]="!itemList.filter.courseFilter.value || !nameCourse"
        (click)="cloneCourse()"
      >
        {{ "ENV.SAVE" | translate }}
      </button>
    </ng-template>
  </p-sidebar>

  <ng-template #loadingTemplate>
    <div class="loading-container">
      <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
    </div>
  </ng-template>
</div>
