<div class="flex flex-wrap justify-content-end gap-2">
  <ng-container *ngIf="section.visualize_required">
    <p-tag
      [severity]="'danger'"
      [value]="'Requerido' | translate"
      *ngIf="!section.isComplete"
    >
    </p-tag>
  </ng-container>
  <ng-container *ngIf="!section.visualize_required">
    <p-tag
      [severity]="'success'"
      [value]="'Opcional' | translate"
      *ngIf="!section.isComplete"
    >
    </p-tag>
  </ng-container>
  <p-tag
    [severity]="'success'"
    [value]="'Completado' | translate"
    *ngIf="section.isComplete"
  >
  </p-tag>
</div>
