import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-support-section-content-table',
  templateUrl: './support-section-content-table.component.html',
  styleUrls: ['./support-section-content-table.component.sass']
})
export class SupportSectionContentTableComponent extends BaseListDirective {
  override title = 'MENU.TESTS';
  override filterableFields = ['poll.title']
  override keys = {
    primary_key: 'el_test_version_id',
    label: 'test.poll.title'
  }
  override filename = 'MENU.TESTS';
  supportContent = null;
  supportContents = [];
  sectionId = null;

  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(utils,
      data,
      route,
      dialog,
      dialogConfig,
      dialogRef)
  }

  onInit(): void {
    this.sectionId = this.dialogConfig.data.section_id

    this.idsExcluded = this.dialogConfig.data.data.map(c => c.content_id)

    this.supportContents = this.dialogConfig.data.data.map(c => {
      return {
        el_course_unit_section_id: this.sectionId,
        content_id: c.content_id,
        name_: c.content.name_,
        type_: c.content.type_,
      }
    })
    super.onInit()
  }


  override getList() {
    this.query(this.data.core.contents()).subscribe();
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.testVersionDelete(
      this.itemSelected[this.keys.primary_key]
    )
  }

  override handleRows(data: any): void {
    super.handleRows(data)
  }


  removeUser(supportId) {

    const supportContent = this.supportContents.find(it => +it.content_id === +supportId);

    this.data.core.sectionSupportContentDelete({
      parentId: supportContent.el_course_unit_section_id,
      childId: supportContent.content_id
    }).subscribe(() => {
      this.utils.toast.saved(this.title)
    })


    if (this.idsExcluded.includes(supportId)) {
      this.idsExcluded = this.idsExcluded.filter(id => id !== supportId)
    }

    this.supportContents = this.supportContents.filter((it) => it.content_id !== supportId);
    this.processing = true;

  }

  appendUser(selected) {
    setTimeout(() => {
      this.supportContent = null;
    }, 200);

    const rs = this.supportContents.filter(
      (it) => it.content_id === selected.content_id
    );

    if (rs?.length) {
      return;
    }
    this.supportContents.push({
      el_course_unit_section_id: this.sectionId,
      content_id: selected.content_id,
      name_: selected.name_,
      type_: selected.type_
    });
  }

  save() {
    const objects = this.supportContents.filter(c => !this.idsExcluded.includes(c.content_id))

    this.utils.executeRequest(
      this.data.core.supportSectionContentBatch({ objects }), {
      onSuccess: () => {
        this.dialogRef.close()
      }
    })
  }

}

