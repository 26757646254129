import { Component } from '@angular/core';
import { BaseFilterUniqueByKey } from 'src/app/directives/controls/base-filter-unique-by-key.directive';

@Component({
  selector: 'app-filter-comma-separated',
  templateUrl: './filter-comma-separated.component.html',
  styleUrls: ['./filter-comma-separated.component.sass']
})
export class FilterCommaSeparatedComponent extends BaseFilterUniqueByKey {
}
