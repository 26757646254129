import { AfterViewInit, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-html-code-editor',
  templateUrl: './html-code-editor.component.html',
  styleUrls: ['./html-code-editor.component.sass'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: HtmlCodeEditorComponent, multi: true },
  ]
})
export class HtmlCodeEditorComponent implements ControlValueAccessor, AfterViewInit {
  @Input() frameActive = 2
  @Input() showToggle = true
  editorOptions = { theme: 'vs-dark', language: 'html' }

  editorRefreshing = true

  theme = 'vs-dark'

  code = ''

  options = {
    contextmenu: true,
    minimap: {
      enabled: true
    }
  };

  wWrapper: number;

  private onChange: (value: string) => void;

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  writeValue(obj: string): void {
    this.code = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  renderHTML() {
    return this.sanitizer.bypassSecurityTrustHtml(this.code);
  }

  htmlChange() {
    this.onChange(this.code);
  }

  reInitMonacoEditor() {
    this.editorRefreshing = true;
    setTimeout(() => {
      this.editorRefreshing = false;
    }, 100);
  }

  onCodeChanged() {
    this.htmlChange();
  }

  ngAfterViewInit(): void {
    const el = document.querySelector('.editor-wrapper');
    console.log(el);

    this.wWrapper = el.clientWidth;
    setTimeout(() => {
      this.editorRefreshing = false;
      setInterval(() => {
        const elIn = document.querySelector('.editor-wrapper');
        if (this.wWrapper !== elIn.clientWidth) {
          this.wWrapper = elIn.clientWidth;
          this.reInitMonacoEditor();
        }
      }, 250);
    }, 500);
  }

}
