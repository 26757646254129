/* eslint-disable @typescript-eslint/no-empty-function */
import { Input } from '@angular/core';
import { Component, EventEmitter, Output } from '@angular/core';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-course-item-prerrequisite-table-picker',
  templateUrl: './course-item-prerrequisite-table-picker.component.html',
  styleUrls: ['./course-item-prerrequisite-table-picker.component.sass']
})
export class CourseItemPrerrequisiteTablePickerComponent extends BaseListDirective {
  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name_', 'FORM.TITLE', 150, { frozen: true }),
    new PrimeNGTableColumn('description_', 'FORM.DESCRIPTION', 300)
  ];
  override title = 'MENU.COURSES';
  override filterableFields = ['name_', 'description_']
  override keys = {
    primary_key: 'el_course_id',
    label: 'name_'
  }
  override filename = 'MENU.COURSES';

  @Input() courseId = 0
  @Output() selected = new EventEmitter<{ elPrerequisiteId: number }[]>()

  override handleDialogConfigData(): void {
    super.handleDialogConfigData()
    this.courseId = this.dialogConfig.data.el_course_id ?? this.courseId
  }

  onCourseSelected(row: any) {
    const courseItems = this.getCourseItems([row])
    this.dialogRef.close(courseItems)
    this.selected.emit(courseItems)
  }

  onCourseSelectedInBatch() {
    const courseItems = this.getCourseItems(this.selectedRows)
    this.dialogRef.close(courseItems)
    this.selected.emit(courseItems)
  }

  getCourseItems(rows: any[]) {
    return rows.map(row => row.el_course_id);
  }

  getList(): void {
    this.query(this.data.core.availablePrerequisites(this.courseId)).subscribe()
  }

  refreshActionServices(): void {
  }
}
