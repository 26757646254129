import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseListDirective } from 'src/app/directives/base-list.directive';
import { TestUnitItem } from 'src/app/models/AppGenericClasses';
import { GlobalDataService } from 'src/app/services/data/global-data.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { SupportSectionContentTableComponent } from './support-section-content-table/support-section-content-table.component';

@Component({
  selector: 'app-support-section-content',
  templateUrl: './support-section-content.component.html',
  styleUrls: ['./support-section-content.component.sass']
})
export class SupportSectionContentComponent extends BaseListDirective {
  sectionId = null
  sectionName = ''
  override filterableFields = ['name_', 'description_', 'tags'];
  @ViewChild('primeNgList') list: BaseListDirective;


  constructor(
    public utils: UtilsService,
    public data: GlobalDataService,
    protected route: ActivatedRoute,
    protected dialog: DialogService,
    protected dialogConfig: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef
  ) {
    super(utils, data, route, dialog, dialogConfig, dialogRef)
  }

  onInit(): void {
    this.sectionId = this.route.snapshot.paramMap.get('sectionId')
    this.sectionName = localStorage.getItem('sectionName') ?? ''
    super.onInit();
  }

  override getList() {
    this.query(this.data.core.supportSectionsContent(this.sectionId)).subscribe();
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemSelected[this.keys.primary_key]
    );
  }

  handleContentSave(data: any, contents: TestUnitItem[]) {
    this.idsExcluded = [
      ...this.idsExcluded,
      ...contents.map(content => content.testId)
    ]
    this.utils.toast.saved(this.title)
    this.list.getList()
  }

  seeSuportContent() {
    const header = this.utils.translate.instant(
      'FORM.SUPPORT-CONTENT'
    )
    const ref = this.dialog.open(SupportSectionContentTableComponent, {
      header,
      width: '80%',
      height: '100%',
      styleClass: 'p-overflow-hidden',
      contentStyle: { height: '100%', overflow: 'hidden', padding: '.3rem' },
      data: {
        data: this.rows,
        section_id: this.sectionId
      }

    })
    ref.onClose.subscribe(() => {
      this.getList()
    })
  }

}


