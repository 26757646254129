<div class="flex flex-column h-full" *ngIf="testTake; else loadingTemplate">
  <div class="flex align-items-center" style="height: auto">
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <h5 class="module-subtitle">
        {{ "MENU.PENDING-GRADED" | translate }}
      </h5>
      <h2 class="module-title">
        {{ title | translate | uppercase }}
      </h2>
    </div>
    <div
      class="flex flex-grow-1 flex-initial align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text"
        icon="pi pi-cloud-upload"
        label="{{ 'ENV.SAVE' | translate }}"
        [disabled]="!dataForm.valid || processing"
        (click)="dataForm.ngSubmit.emit()"
        *ngIf="!processing"
      ></button>
    </div>
  </div>
  <p-divider styleClass="divider-slim"></p-divider>
  <div class="flex flex-grow-1 relative">
    <div class="card-form absolute h-full w-full">
      <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <form
          #dataForm="ngForm"
          class="flex flex-column h-full"
          (ngSubmit)="onSubmit()"
        >
          <div class="mb-5">
            <app-test-take-summary
              [itemId]="testTake.el_test_take_id"
              [showActions]="false"
              [showResult]="false"
            ></app-test-take-summary>
          </div>
          <div
            *ngFor="let questionData of pollTake.questions; index as i"
            class="box box-elevation p-3 my-3 w-full"
          >
            <p>
              <b>{{ i + 1 }}.</b> {{ questionData.question.content }}
            </p>
            <div
              class="text-sm"
              [innerHTML]="questionData.question.complement"
            ></div>
            <div *ngIf="questionData.question.type_ === 'OPEN'">
              <p>
                {{ "FORM.ANSWER" | translate }}:
                <i>{{ questionData.open.content }}</i>
              </p>
              <div class="flex flex-column mb-4">
                <div class="flex flex-column gap-2">
                  <span class="p-float-label">
                    <input
                      type="text"
                      pInputText
                      [(ngModel)]="questionData.result"
                      class="w-full"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <label>{{ "FORM.RATE-THE-ANSWER" | translate }}</label>
                  </span>
                </div>
                <p-slider
                  [(ngModel)]="questionData.result"
                  class="w-full"
                  [ngModelOptions]="{ standalone: true }"
                ></p-slider>
              </div>
            </div>
          </div>
        </form>
      </p-scrollPanel>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
