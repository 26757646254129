/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { PrimeNGTableColumn } from 'src/app/models/AppGenericClasses';
import { TestJSON } from 'src/app/models/AppSources';
import { BaseItemListDirective } from 'src/app/directives/restruct/base-item-list.directive';
import { TestList } from 'src/app/models/AppLists';
import { Test } from 'src/app/models/AppModels';

@Component({
  selector: 'app-test-table',
  templateUrl: './test-table.component.html',
  styleUrls: ['./test-table.component.sass']
})
export class TestTableComponent extends BaseItemListDirective<Test, TestJSON, TestList> {
  override availableColumns: PrimeNGTableColumn[] = [
    new PrimeNGTableColumn('name', 'FORM.TITLE', 250, { frozen: true }),
    new PrimeNGTableColumn('poll.review', 'FORM.DESCRIPTION', 300),
    new PrimeNGTableColumn('formatter.timeLimit', 'FORM.TIME-LIMIT', 150),
    new PrimeNGTableColumn('formatter.minApprovalPercentage', 'FORM.MIN-APPROVAL-PERCENTAGE', 150),
    new PrimeNGTableColumn('formatter.randomQuestions', 'FORM.QUESTIONS-IN-RANDOM-ORDER', 150)
  ];
  override title = 'MENU.TESTS';
  override filename = 'MENU.TESTS';
  override ListModel = TestList;

  override getList() {
    this.query(this.data.core.tests()).subscribe();
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.testDelete(
      this.itemList.itemSelected.id
    )
  }
}
