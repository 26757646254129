import { Component, Input } from '@angular/core';
import { BreadcrumItem } from 'src/app/models/AppGenericClasses';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.sass']
})
export class BreadcrumComponent {
  @Input() items: BreadcrumItem[] = []
}
