<div class="flex flex-column h-full">
  <div class="flex mb-2" style="height: auto">
    <div class="flex flex-grow-1 align-items-center justify-content-start">
      <h2 class="module-title">
        {{ "MENU.UNITS" | translate | uppercase }} | {{ course_name }}
      </h2>
    </div>
    <div
      class="flex flex-wrap gap-2 flex-grow-1 align-items-center justify-content-end"
    >
      <button
        pButton
        pRipple
        class="p-button-text p-button-primary"
        icon="pi pi-sort-amount-up-alt"
        label="{{ 'ENV.SORT' | translate }}"
        (click)="seeSortableItems()"
      ></button>
      <button
        pButton
        pRipple
        class="p-button-text p-button-success"
        icon="pi pi-plus"
        label="{{ 'ENV.NEW-F' | translate }} {{
          'CORE.UNIT' | translate | lowercase
        }}"
        routerLink="/courses/units/create/{{ courseId }}"
      ></button>
    </div>
  </div>

  <div class="flex flex-grow-1">
    <div class="flex w-12 lg:flex relative">
      <div class="absolute h-full w-full">
        <p-scrollPanel
          [style]="{ width: '100%', height: '100%' }"
          *ngIf="!loading; else loadingTemplate"
        >
          <app-unit-item
            *ngFor="let row of rows; index as i"
            [idx]="i"
            [item]="row"
            [courseId]="courseId"
            (itemDisabled)="getList()"
            (requestReload)="getList()"
          >
          </app-unit-item>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="showSortableItems" position="right" appendTo="body">
  <ng-template pTemplate="header">
    <h3>{{ "ENV.SORT" | translate }} | {{ "CORE.UNITS" | translate }}</h3>
  </ng-template>
  <app-sortable-list
    [data]="rows"
    labelKey="name_"
    (dataSorted)="onReorder()"
  ></app-sortable-list>
</p-sidebar>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <p-progressSpinner styleClass="center-relatively"></p-progressSpinner>
  </div>
</ng-template>
