import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseItemListDirective } from 'src/app/directives/restruct/base-item-list.directive';
import { Course, CourseJSON, CourseList } from 'src/app/models/CourseClasses';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.sass']
})
export class CourseListComponent extends BaseItemListDirective<Course, CourseJSON, CourseList> {
  @Input() withEnrollments = false
  @Input() inactives = false
  @Input() withUserEnrollment = false
  @Input() couldShowInactives = false
  @Input() customListQuery: Observable<any> | null = null
  override ListModel = CourseList;
  activeCoursesQuery: Observable<any> = this.data.core.courses()
  inactiveCoursesQuery: Observable<any> = this.data.core.inactiveCourses()
  nameCourse: string;
  containerStyleClass = "h-full"

  getQuery() {
    if (this.customListQuery) { return this.customListQuery }
    if (!this.inactives) { return this.activeCoursesQuery }
    return this.inactiveCoursesQuery
  }

  override refreshActionServices(): void {
    this.inactivationService = this.data.core.courseDelete(
      this.itemList.itemSelected.id
    )
  }

  handleRows(data: CourseJSON[]): void {
    if (this.withEnrollments) {
      data = data.filter(item => item.course_enrollments_count)
    }
    super.handleRows(data)
  }

  onInactivesChange() {
    this.getList()
  }

  setUpdateItemBatchMethod() {
    this.updateItemBatchService = this.data.core.Clonecourses({
      objects: this.itemList.filter.courseFilter.value,
      name_: this.nameCourse
    })
  }

  onChangeEnrollment() {
    console.log(this.itemList.filter.ProgressCourseFilter.value);

    if (this.itemList.filter.ProgressCourseFilter.value === 'ALL') {
      this.itemList.rows = [...this.itemList.filter.baseItems]
    } else {
      this.itemList.rows = this.itemList.filter.baseItems.filter(row => row.Courseenrrollments.map(enrollment => enrollment.enrollmentStatus).includes(this.itemList.filter.ProgressCourseFilter.value));
    }
  }



}
